import {
    LOAD_PLAYER_REQUEST,
    LOAD_PLAYER_SUCCESS,
    LOAD_PLAYER_FAILURE,
    QUEUE_TOAST,
    LOAD_PROFILE_STATS_REQUEST,
    LOAD_PROFILE_STATS_SUCCESS,
    LOAD_PROFILE_STATS_FAILURE,
    UPDATE_PLAYER_REQUEST,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAILURE,
    FORCED_LOG_OUT,
    LOG_OUT
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'


export const loadPlayer = (player_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Player')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_PLAYER_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/players/${player_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_PLAYER_SUCCESS, payload: { player_id, player: response.data.player } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadPlayer(player_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load player details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_PLAYER_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadProfileStats = (player_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load the users stats for all seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_PROFILE_STATS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/players/profile_stats/${player_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_PROFILE_STATS_SUCCESS, payload: { player_id, profile_stats: response.data.profile_stats } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadProfileStats(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_PROFILE_STATS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const updatePlayer = (player_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load the users stats for all seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    // Check all form fields have been completed.
    const {
        first_name,
        last_name,
        nickname,
        gender,
        birth_year,
        shirt_number,
        position,
        avatar
    } = getState().player[player_id]

    if(!first_name
        || !last_name
        || !gender
        || !birth_year
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: UPDATE_PLAYER_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/players/${player_id}`, {
        user_id,
        token,
        first_name,
        last_name,
        nickname,
        gender,
        birth_year,
        shirt_number,
        position,
        avatar
    })
        .then(response => {
            const { updated } = response.data

            dispatch({ type: UPDATE_PLAYER_SUCCESS })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'You details have been updated successfully' } })
                dispatch(loadPlayer(player_id))
            }

            ReactGA.event({
                category: 'Player Profile',
                action: 'Update Details',
                value: 20
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(updatePlayer(player_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed update Player Details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: UPDATE_PLAYER_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}