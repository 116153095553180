import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
// import { cardButtonClick, loadRoundAttendance, loadRoundCard, saveLineup, loadRound } from '../../actions'

import { Typography } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

const styles = theme => ({
    red: {
        // backgroundColor: red[500],
        color: red[500],
    },
    green: {
        // backgroundColor: green[500],
        color: green[500],
    }
  });

const RoundCardAttendanceDetail = props => {

    const { player, round } = props // Store
    const { player_id, rounds, classes } = props // Parent

    // console.log(`[] Round Card Attendance Detail - r_id: ${round_id} p_id: ${player_id}`)


    const number_of_rounds = round.details.supporting_rounds.length + 1
    const requested_play_count = Number(round.attendance[player_id].can_play_count)
    var can_play_count = requested_play_count === -1 ? number_of_rounds : round.attendance[player_id].can_play_count
    const availability_status = round.attendance[player_id].status

    var availability_text = ''
    var status = ''

    if(availability_status === 'U')
    {
        // availability_text = 'NFI. Haven\'t let us know yet..'
    }
    else if(availability_status === 'O')
    {
        // availability_text = 'Can\'t play this game.'
    }
    else if(round.details.supporting_rounds.length === 0 && availability_status === 'I')
    {
        // availability_text = "Available for this game"
        // status = 'available'
    }
    else if(can_play_count != null)
    {
        const on_primary_round_card = round.card.hasOwnProperty(player_id) ? round.card[player_id].on_card : false
        var card_count = on_primary_round_card ? 1 : 0

        for( var supporting_round_id of round.details.supporting_rounds)
        {
            if( !rounds.hasOwnProperty(supporting_round_id)
                || !rounds[supporting_round_id].hasOwnProperty('card')
                || !rounds[supporting_round_id].hasOwnProperty('details')
                || !rounds[supporting_round_id].hasOwnProperty('attendance')
                || !player
            )
            {
                // Supporting rounds may still be loading in RoundCard
                break;
            }

            const { supporting_team_player_ids } = player

            const supporting_round = rounds[supporting_round_id]

            // Check if it's the same team with multiple games on the same day or if it's different teams
            let supporting_round_player_id

            if(supporting_team_player_ids.hasOwnProperty(supporting_round.details.player_team_season_ids[0])) // Should only be needed for Competition rounds where there will only ever be one team_season_id
            {
                supporting_round_player_id = supporting_team_player_ids[supporting_round.details.player_team_season_ids[0]].player_id // Should only be needed for Competition rounds where there will only ever be one team_season_id
            }
            else
            {
                supporting_round_player_id = player.player_id
            }

            // Che the attendance status for supporting rounds and adjust can_play_count
            if(supporting_round.attendance.hasOwnProperty(supporting_round_player_id))
            {
                const supporting_round_player_status = supporting_round.attendance[supporting_round_player_id].status
                if(supporting_round_player_status === 'O')
                {
                    can_play_count -= 1
                }
            }

            if(supporting_round.card.hasOwnProperty(supporting_round_player_id))
            {
                card_count += supporting_round.card[supporting_round_player_id].on_card ? 1 : 0
            }
        }

        status = Number(can_play_count) - Number(card_count) < 0 ? 'overbooked' : Number(can_play_count) - Number(card_count) === 0 ? 'fully_booked' : 'available'

        // Update counts for output

        card_count = Number(card_count) === 0 ? '0 cards' : Number(card_count) === 1 && on_primary_round_card ? 'this card' : Number(card_count) === 1 ? `${card_count} other card` : on_primary_round_card ? `this and ${card_count-1} other cards` : `${card_count-1} other cards`

        if(requested_play_count !== -1)
        {
            availability_text = `Requested ${requested_play_count === 1 ? `1 game` : `${requested_play_count} games`}, currently on ${card_count}`;
        }
        else if(can_play_count === 1)
        {
            availability_text = `Available for this game only, currently on ${card_count}`;
        }
        else if(can_play_count === number_of_rounds)
        {
            availability_text = `Available for ${can_play_count === 2 ? 'both' : can_play_count } games, currently on ${card_count}`;
        }
        else
        {
            availability_text = `Available for ${can_play_count} of the ${number_of_rounds} games, currently on ${card_count}`;
        }
    }

    return (
            <Typography className={ status === 'available' ? '' : status === 'fully_booked' ? classes.green : classes.red } variant="caption" align="right">{availability_text}</Typography>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { player_id, round_id } = ownProps

    return {
        player: state.player.hasOwnProperty(player_id) ? state.player[player_id] : false,
        round: state.round[round_id],
        // players: state.player[player_id]
    };
}

export default withStyles(styles)(connect(mapStateToProps, {  })(RoundCardAttendanceDetail))


