import React from 'react'
import { Box } from '@material-ui/core/'


const Map = (props) => {
    console.log('[] Map');

    const { location_name } = props

    console.log('location_name', location_name)

    if(!location_name)
    {
      return (
        <Box style={{textAlign: 'center'}}>
          <img alt="" height='400' width='auto' src={`${process.env.REACT_APP_API_URL}/images/maps/pin.png`} />
        </Box>
      )
    }

    // Remove non alpha numberic chars except ',' & ' ' and convert spaces to '+' for the google url
    var url_location = location_name ? location_name.replace(/[^a-z0-9,+]+/gi, '+') : 'Australia'

    return(
      <Box>
        <iframe title={`map-${location_name}`} src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyA75NzgBmi6VA_5eOjIZnMgnPligQZLNQE&maptype=satellite&q=${url_location}`} height='400' width='100%' style={{border: '0'}}/>
      </Box>
    )
}

export default Map

