import {
    LOG_IN_SUCCESS,
    LOG_OUT,
    UPDATE_USER_ID,
    UPDATE_PASSWORD,
    UPDATE_TRUSTED_DEVICE,
    LOAD_ANNOUNCEMENTS_SUCCESS,
    ANNOUNCEMENTS_DISMISSED,
    FORCED_LOG_OUT,
    FORCED_LOG_OUT_RESET,
    SET_WEB_PUSH_SUBSCRIPTION
} from '../actions/types'

const INITIAL_STATE = {
    is_logged_in: false, //null,
    forced_log_out: false,
    web_push_subscription_status: false,
    web_push_subscription_endpoint: false,
    user_id: 1,
    username: '',
    password: '',
    trusted_device: false,
    redirectToReferrer: false,
    push_token: false,
    announcements: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type)
    {
        case LOG_IN_SUCCESS:
            {
                console.log('-- Log In')
                //console.log(action)

                const { authenticated, user_id, token } = action.payload

                return { ...state, is_logged_in: authenticated, user_id: user_id, token: token, redirectToReferrer: true, password: '' }
            }

        case LOG_OUT:
            {
                return { ...state, is_logged_in: false, user_id: null, username: '', password: '', redirectToReferrer: false }
            }

        case FORCED_LOG_OUT:
            {
                return { ...state, is_logged_in: false, forced_log_out: true, user_id: null, username: '', password: '', redirectToReferrer: false }
            }

        case FORCED_LOG_OUT_RESET:
            {
                return { ...state, forced_log_out: false }
            }


        case UPDATE_USER_ID:
            {
                console.log('-- Log Out')
                //console.log('Action - User ID Text')
                //console.log(action)

                const { username_text } = action.payload

                return { ...state, username: username_text }
            }

        case UPDATE_PASSWORD:
            {
                //console.log('Action - User ID Text')
                //console.log(action)

                const { password_text } = action.payload

                return { ...state, password: password_text }
            }

        case UPDATE_TRUSTED_DEVICE:
            {
                const { trusted_device } = action.payload

                return { ...state, trusted_device }
            }

        case SET_WEB_PUSH_SUBSCRIPTION:
            {
                console.log('-- Set Push Token')

                const { web_push_subscription_status, web_push_subscription_endpoint } = action.payload

                // Keep existing endpoint if there is no new one.  Required to send the old subscripiton details to the server if the user opts out of push.
                const endpoint = !web_push_subscription_endpoint ? state.web_push_subscription_endpoint : web_push_subscription_endpoint

                return { ...state, web_push_subscription_status, web_push_subscription_endpoint: endpoint }
            }

        case LOAD_ANNOUNCEMENTS_SUCCESS:
            {
                const { announcements } = action.payload

                return { ...state, announcements }
            }

        case ANNOUNCEMENTS_DISMISSED:
            {
                return { ...state, announcements: [] }
            }

        default:
            return state
    }
}
