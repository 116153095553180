import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, ButtonBase, Grid, ListSubheader } from '@material-ui/core'

import { loadOpponentDetail, updateOpponent, updateCreateOpponentFormFieldValue, loadManagedTeams } from '../../../actions'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuOpponentName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const UpdateOpponent = props => {

    const { team_season_id, opposition_id } = props.match.params

    const { classes } = props
    const { updateOpponent, loadOpponentDetail, updateCreateOpponentFormFieldValue, loadManagedTeams } = props

    const {
        name
    } = props //Form fields

    useEffect(() => {

        console.log('[] Update Opponent')

        loadManagedTeams()

        //Load the opponent details
        loadOpponentDetail(team_season_id, opposition_id)

    }, [])

    const onSubmit = () => {
        updateOpponent()
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h2" variant="h2">{name ? name : 'Loading'}</Typography>
                    <Typography content="h1" variant="h1">Update Opponent</Typography>
                </Box>

                <TextField
                    label="Opponent Name"
                    placeholder="1"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 100,
                    }}
                    name={`name`}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => { updateCreateOpponentFormFieldValue(e.target.name, e.target.value) }}
                />

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('opponent') && state.admin.create.opponent.hasOwnProperty('name') ? state.admin.create.opponent.name : ''
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadOpponentDetail, updateOpponent, updateCreateOpponentFormFieldValue, loadManagedTeams })(UpdateOpponent))
