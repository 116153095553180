import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography, Grid, FormControlLabel, Checkbox, Paper, Avatar, Chip, Divider, Modal, Backdrop, Fade, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

import { createTeamStatType, updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal, clearCreateTeamStatTypeForm } from '../../../actions'
import { FaCheckSquare, FaSquare, FaTrophy } from 'react-icons/fa'

import { RedChip, GreenChip, BlueChip, OrangeChip, YellowChip } from '../../ui/Chips'

// Icons
import { stat_type_icons } from '../../ui/Icons'
import { STAT_ICON_SELECTOR } from '../../../navigation'
import StatIconSelector from './StatIconSelector'
import { randBetween } from '../../../utils/utils'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    helperTextSpaced: {
        marginBottom: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    },
    paper: {
        padding: theme.spacing(1),
        border: 0,
        boxShadow: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    fullWidthCard: {
        width: '100%',
        justifyContent: 'flex-start',
        textAlign: 'center'
    }
})

const CreateStat = props => {

    const history = useHistory()

    const team_id = props.match.params.team_id

    const { classes } = props
    const { createTeamStatType, updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal, clearCreateTeamStatTypeForm } = props

    const {
        name,
        description,
        amount_label,
        amount_multiplier,
        display_on_leaderboard,
        // display_type,
        // display_order,
        amount_icon,
        // amount_icon_color,
        colour,
        active,
        team_stat_type_icon_selector_modal_show
    } = props //Form fields

    const chips = {
        'Green': GreenChip,
        'Orange': OrangeChip,
        'Red': RedChip,
        'Blue': BlueChip,
        'Yellow': YellowChip
    }

    const Chip = chips[colour] ? chips[colour] : chips['Green']
    const AmountIcon = amount_icon ? stat_type_icons[amount_icon].icon : stat_type_icons['FaQuestion'].icon

    useEffect(() => {

        console.log('[] Update Team Stat Type')

        clearCreateTeamStatTypeForm()

    }, [])

    const onSubmit = () => {
        createTeamStatType(team_id, history)
    }

    const toggleModal = (show) => {
        toggleStatIconSelectorModal(show)
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Create Stat</Typography>
                </Box>

                <TextField
                    label="Stat Name"
                    placeholder="Goals, Trys, Touchdowns, Assists, Red Cards, Sin Bin, Bicycle Kicks"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`name`}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.value) }}
                />

                <TextField
                    label="Description"
                    placeholder="Put the ball in the back of the net.. their net"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`description`}
                    autoComplete="off"
                    value={description}
                    onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.value) }}
                />

                <TextField
                    label="Quantity Units/Label"
                    placeholder="Goals, Trys, Touchdowns, Assists, Red Cards, Sin Bin, Bicycles"
                    // error={touched && invalid}
                    // helperText="('Goals', 'Min', 'Reds') - Keep it short"
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`amount_label`}
                    autoComplete="off"
                    value={amount_label}
                    onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.value) }}
                />

                <TextField
                    label="Quantity multiplier"
                    placeholder="1"
                    // error={touched && invalid}
                    helperText={"E.g. Typically '1' but for example the sin bin: 1 x trip = 10 x minutes, so the multiplier would be '10'"}
                    className={`${classes.fullWidth} ${classes.spaced} ${classes.helperTextSpaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`amount_multiplier`}
                    autoComplete="off"
                    value={amount_multiplier}
                    onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.value) }}
                />

                <Box my={2}>
                    <Typography variant="subtitle1">Icon</Typography>
                    <AmountIcon size="3em" onClick={() => toggleModal(true)} />
                </Box>

                <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                    <InputLabel id="sport-select-label">Label Colour</InputLabel>
                    <Select
                        labelId="sport-select-label"
                        id="sport-select"
                        value={colour}
                        name="colour"
                        onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.value) }}
                    >
                        <MenuItem value="Blue">Blue</MenuItem>
                        <MenuItem value="Green">Green</MenuItem>
                        <MenuItem value="Orange">Orange</MenuItem>
                        <MenuItem value="Red">Red</MenuItem>
                        <MenuItem value="Yellow">Yellow</MenuItem>
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={<Checkbox icon={<FaSquare />} checkedIcon={<FaCheckSquare />} name="display_on_leaderboard" />}
                    label="Include this stat on the leaderboard?"
                    checked={display_on_leaderboard}
                    onChange={(e) => { updateTeamStatTypeFormFieldValue(e.target.name, e.target.checked) }}
                />


                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Create Stat
                    </Button>
                </Box>

                <Divider />

                <Box my={3}>
                    <Typography content="h2" variant="h2">Sample</Typography>

                    <Box mb={1} className={classes.fullWidth}>
                        <Box mt={2} mx={1}>
                            <Typography content="h1" variant="h1">{name}</Typography>
                            <Typography content="div" variant="subtitle2">{description}</Typography>
                        </Box>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            wrap='nowrap'
                        >
                            <Grid xs={4} item key={`sample_stat`}>
                                <Paper className={classes.paper}>
                                    <Typography component="div" variant="subtitle1">Eman<br />Ekaf</Typography>
                                    <Avatar alt="" src={`https://avatars.dicebear.com/api/human/${randBetween(0, 9999)}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />
                                    <Box mt={0.5}>
                                        <Chip color="secondary" avatar={<Avatar><AmountIcon /></Avatar>} label={`${1 * (Number(amount_multiplier ? Number(amount_multiplier) : 1))} ${amount_label}`} className={classes.fullWidthCardx} />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid xs={4} item key={`sample_stat`}>
                                <Paper className={classes.paper}>
                                    <Typography component="div" variant="subtitle1">Eman<br />Ekaf</Typography>
                                    <Avatar alt="" src={`https://avatars.dicebear.com/api/human/${randBetween(0, 9999)}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />
                                    <Box mt={0.5}>
                                        <Chip color="secondary" avatar={<Avatar><AmountIcon /></Avatar>} label={`${2 * (Number(amount_multiplier ? Number(amount_multiplier) : 1))} ${amount_label}`} className={classes.fullWidthCardx} />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid xs={4} item key={`sample_stat`}>
                                <Paper className={classes.paper}>
                                    <Typography component="div" variant="subtitle1">Eman<br />Ekaf</Typography>
                                    <Avatar alt="" src={`https://avatars.dicebear.com/api/human/${randBetween(0, 9999)}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />
                                    <Box mt={0.5}>
                                        <Chip color="secondary" avatar={<Avatar><AmountIcon /></Avatar>} label={`${3 * (Number(amount_multiplier ? Number(amount_multiplier) : 1))} ${amount_label}`} className={classes.fullWidthCardx} />
                                    </Box>
                                </Paper>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Dialog
                open={team_stat_type_icon_selector_modal_show}
                onClose={() => { toggleModal(false) }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Icon</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        // ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <StatIconSelector />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { toggleModal(false) }} color="primary">
                        Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = state => {

    return {
        team_id: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('team_id') && state.admin.create.stat_type.team_id !== null ? state.admin.create.stat_type.team_id : '',
        name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('name') && state.admin.create.stat_type.name !== null ? state.admin.create.stat_type.name : '',
        description: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('description') && state.admin.create.stat_type.description !== null ? state.admin.create.stat_type.description : '',
        amount_label: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('amount_label') && state.admin.create.stat_type.amount_label !== null ? state.admin.create.stat_type.amount_label : '',
        amount_icon: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('amount_icon') && state.admin.create.stat_type.amount_icon !== null ? state.admin.create.stat_type.amount_icon : '',
        amount_multiplier: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('amount_multiplier') && state.admin.create.stat_type.amount_multiplier !== null ? state.admin.create.stat_type.amount_multiplier : '',
        display_on_leaderboard: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('display_on_leaderboard') ? state.admin.create.stat_type.display_on_leaderboard : false,
        colour: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('stat_type') && state.admin.create.stat_type.hasOwnProperty('colour') ? state.admin.create.stat_type.colour : false,

        team_stat_type_icon_selector_modal_show: state.ui.hasOwnProperty('admin') && state.ui.admin.hasOwnProperty('team_stat_type_icon_selector_modal_show') ? state.ui.admin.team_stat_type_icon_selector_modal_show : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { createTeamStatType, updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal, clearCreateTeamStatTypeForm })(CreateStat))
