import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { loadRound } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import Divider from "@material-ui/core/Divider"
import { Grid, Box } from '@material-ui/core/'
import RoundResults from "./RoundResults"
import { Link, withRouter } from 'react-router-dom'
import Brightness1Icon from '@material-ui/icons/Brightness1'

import RoundLoading from '../loading/RoundLoading'
import { FaCog } from "react-icons/fa"

import moment from 'moment'

// Navigation
import {
    ROUND_ADMIN,

} from '../../navigation'

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: "inline"
    },
    paddright: {
        paddingRight: "20px"
    },
    navRoundHeading: {
        fontSize: "1.5em",
        fontWeight: "bold",
        paddingTop: "20px",
        paddingBottom: "20px",
        borderBottom: "2px solid #85cbf4"
    },
    navRoundItem: {
        backgroundColor: "transparent",
        border: "0",
        borderBottom: "2px solid #85cbf4",
        boxShadow: "0 20px 20px -20px #000",
        marginBottom: "20px",
        color: "#FFF"
    },
    navRoundDayNumber: {
        FontWeight: "bold",
        FontSize: "2.6em",
        lineHeight: "1em"
    },
    navRoundTeamName: {
        color: "#85cbf4"
    },
    navRoundClub: {
        fontSize: "1.3em"
    },
    navRoundOpposition: {
        fontSize: "1.3em",
        color: "#85cbf4"
    },
    navRoundVoting: {
        // fontSize: "0.9em",
        // lineHeight: '1em',
        whiteSpace: 'nowrap'
    },
    navRoundClubGoals: {
        fontSize: "1.3em"
    },
    navRoundOppositionGoals: {
        fontSize: "1.3em",
        color: "#85cbf4"
    },
    aNavRoundItemHoverElement: {
        color: "#fff",
        backgroundColor: "#0C3A56"
    },
    adminLink: {
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "1.6em"
    },
    roundResultsToggle: {
        borderBottomRightRadius: "15px",
        borderBottomLeftRadius: "15px",
        border: "2px solid #85cbf4",
        borderTop: "0",
        cursor: "pointer",
        marginTop: "-20px",
        backgroundColor: "#0d2431",
        boxShadow: "0 14px 14px -10px #000",
        marginBottom: "10px",
        fontSize: "1.2em"
    },
    alignCenter: {
        textAlign: 'center'
    },
    roundAdmin: {
        position: 'absolute',
        top: '0',
        left: '4px',
        zIndex: '1200' //Need to put it above the edge swipe area
    },
    relative: {
        position: 'relative'
    }
})


function RoundListItem(props) {
    console.log('[] Round List Item')

    const { round } = props // State
    const { loadRound, classes, history } = props // Connect
    const { round_id, link } = props // Parent

    useEffect(() => {
        if(!round || !round.hasOwnProperty('details'))
        {
            loadRound(round_id)
        }
    }, [round_id])

    if(!round || !round.hasOwnProperty('details'))
    {
        return <RoundLoading />
    }

    const {
        type,
        date,
        round_number,
        player_team_name,
        player_club_name,
        opposition_team_name,
        round_status,
        round_status_color,
        round_view_results,
        goals_for,
        goals_against,
        team_admin
    } = round.details

    var dt = new Date(Number(date) * 1000)

    var round_date = moment.unix(date)

    // const month = dt.toLocaleString('default', { month: 'short' })
    // const day_no = dt.toLocaleString(window.navigator.language, {day: '2-digit'})
    // const day_text = dt.toLocaleString(window.navigator.language, {weekday: 'short'})
    // const time = dt.toLocaleString(window.navigator.language, { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '')

    const month = round_date.format("MMM")
    const day_no = round_date.format("DD")
    const day_text = round_date.format("ddd")
    const time = round_date.format("h:mma")



    function render() {
        var output = []

        output.push(
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                wrap='nowrap'
                key={`round-list-item-${round_id}`}
                className={classes.relative}
            >

                <Grid item xs={3}>
                    <Grid
                        container
                        direction='column'
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                    >
                        <Grid item className={classes.navRoundMonth}>{month}</Grid>
                        <Grid item className={classes.navRoundDayNumber} style={{ fontSize: '2.6em', lineHeight: '1em' }}>{day_no}</Grid>
                        <Grid item className={classes.alignCenter}>{day_text} {time}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Box mr={1}>
                        <Grid container
                            direction='column'
                        >
                            {/* Row 1 */}
                            <Grid item>
                                <Grid container
                                    direction='row'
                                    justify="space-between"
                                >
                                    <Grid item className={classes.navRoundTeam}>
                                        {
                                            type === 'G' &&
                                            <>Round {round_number} <span style={{ color: '#85cbf4' }}> - {player_team_name}</span></>
                                        }
                                        {
                                            type === 'T' &&
                                            <><span style={{ color: '#85cbf4' }}>Training</span></>
                                        }
                                    </Grid>
                                    <Grid item className={classes.navRoundVoting}>
                                        {round_status}&nbsp;
                                        <Brightness1Icon style={{ fontSize: 12, color: round_status_color }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Row 2 */}
                            {
                                type === 'G' &&
                                <Grid item>
                                    <Grid container
                                        direction='row'
                                        justify="space-between"
                                    >
                                        <Grid item className={classes.navRoundClub}>
                                            {player_club_name}
                                        </Grid>
                                        <Grid item className={classes.navRoundClubGoals}>
                                            {goals_for}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                type === 'T' &&
                                <Grid item>
                                    <Grid container
                                        direction='row'
                                        justify="space-between"
                                    >
                                        <Grid item className={classes.navRoundClub}>
                                            {player_team_name}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {/* Row 3 */}
                            {
                                type === 'G' &&
                                <Grid item>
                                    <Grid container
                                        direction='row'
                                        justify="space-between"
                                    >
                                        <Grid item className={classes.navRoundOpposition}>
                                            {opposition_team_name}
                                        </Grid>
                                        <Grid item className={classes.navRoundOppositionGoals}>
                                            {goals_against}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                type === 'T' &&
                                <Grid item>
                                    <Grid container
                                        direction='row'
                                        justify="space-between"
                                    >
                                        <Grid item className={classes.navRoundOpposition}>
                                            &nbsp;
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )

        return output
    }


    return (
        <>
            <Box my={2} style={{ position: 'relative' }}>
                {
                    team_admin &&
                    <Grid item className={classes.roundAdmin}>
                        <Link to={`${ROUND_ADMIN}/${round_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                            <div style={{ textDecoration: 'none', color: 'inherit' }} >
                                <FaCog fontSize={'large'} />
                            </div>
                        </Link>
                    </Grid>
                }

                {
                    link ? (
                        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }} >
                            {render()}
                        </Link>
                    ) :
                        render()

                }

            </Box>
            <Divider variant="fullWidth" component="div" />
            {round_view_results ? <RoundResults round={round} round_id={round_id} /> : ''}

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { round_id } = ownProps

    return {
        round: state.round[round_id]
    }
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, { loadRound })(RoundListItem)))
// export default RoundListItem
