// import { LOAD_LEADERBOARD } from '../actions/types';

export default (state = {}, action) => {
    const { type, payload } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) return state;

    console.log('-- Loading');

    const [, requestName, requestState] = matches;

    const id = payload && payload.hasOwnProperty('id') ? '-'+payload.id : ''

    return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving GET_TODOS_REQUEST
        //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
        [`${requestName}${id}`]: requestState === 'REQUEST',
    };
};