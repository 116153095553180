import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, Link } from 'react-router-dom'
import { Box, TextField, Button, Typography, Divider, Checkbox, FormControlLabel, Grid } from '@material-ui/core'

import { logIn, updateUserId, updatePassword, updateTrustedDevice } from '../actions'

// Navigation
import {
    PASSWORD_RESET, REGISTER
} from '../navigation'
import { FaCheckSquare, FaSquare } from 'react-icons/fa'
import { TooltipPrimary } from './ui/Tooltips'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
})

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     transition: background-color 5000s ease-in-out 0s;
//     -webkit-text-fill-color: #fff !important;
// }

const Login = props => {

    console.log('[] Login')

    const [open, setOpen] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const { classes } = props
    const { username, password, trusted_device, redirectToReferrer, logIn, updateUserId, updatePassword, updateTrustedDevice } = props
    const { from } = props.location.state || { from: { pathname: '/' } }

    const onUserIdInputChange = (event) => {
        updateUserId(event.target.value)
    }

    const onPasswordInputChange = (event) => {
        updatePassword(event.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        logIn()
    }

    if(redirectToReferrer === true)
    {
        return <Redirect to={from} />
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Login</Typography>
                </Box>

                <form onSubmit={onSubmit}>

                    <TextField
                        label="Email Address"
                        placeholder="Pelé@gmail.com"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 100,
                            className: classes.input
                        }}
                        name={`username`}
                        autoComplete="off"
                        value={username}
                        onChange={(event) => { onUserIdInputChange(event) }}
                    />

                    <TextField
                        label="Password"
                        placeholder=""
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth} ${classes.spaced}`}
                        inputProps={{
                            maxLength: 100,
                        }}
                        name={`password`}
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(event) => { onPasswordInputChange(event) }}
                    />

                    <Box mt={3} mb={1}>
                        <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                            Login
                        </Button>
                    </Box>
                </form>


                <Grid container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Box mx={1}>
                            <TooltipPrimary open={trusted_device || open} onClose={handleClose} onOpen={handleOpen} title="Stay logged in for longer on this device." placement="bottom" arrow>
                                <FormControlLabel
                                    control={<Checkbox icon={<FaSquare />} checkedIcon={<FaCheckSquare />} name="trusted_device" />}
                                    label={<Typography variant="caption" align="left" display="inline">Trusted Device</Typography>}
                                    checked={trusted_device}
                                    onChange={(e) => { updateTrustedDevice(e.target.checked) }}
                                />
                            </TooltipPrimary>
                        </Box>
                    </Grid>
                    <Grid item
                    >
                        <Box mx={1}>
                            <Link to={PASSWORD_RESET} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Typography variant="caption" align="right" display="inline">Forgot Password</Typography>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>



                <Box my={3}>
                    <Divider />
                </Box>

                <Box mb={3}>
                    <Typography content="h2" variant="h2">Haven't got an account yet?</Typography>
                </Box>

                <Box mb={1}>
                    <Link to={REGISTER} style={{ textDecoration: 'none', color: 'inherit' }} >
                        <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth} >
                            Register
                        </Button>
                    </Link>
                </Box>

                <Box>
                    <Typography variant="caption" display="block">We'll have you setup, on a Team and amongst the banter in no time!</Typography>
                </Box>

            </Box>
        </>
    )
}

const mapStateToProps = state => {
    return {
        username: state.user.username,
        password: state.user.password,
        trusted_device: state.user.trusted_device,
        redirectToReferrer: state.user.redirectToReferrer
    }
}

export default withStyles(styles)(connect(mapStateToProps, { logIn, updateUserId, updatePassword, updateTrustedDevice })(Login))
