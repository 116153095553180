import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core/'

const styles = theme => ({
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    }
})

const LeaderboardLoading = (props) => {
    console.log('[] Leaderboard Loading')

    const { classes } = props  // Connect


    return (
        <>
            <Box mt={2} p={1} >
                <Typography className={`${classes.blurryTextWhite}`} content="h1" variant="h1">Leaderboard</Typography>
            </Box>
        </>
    )
}

export default withStyles(styles)(LeaderboardLoading)
