import { SLIDE_OPEN, SLIDE_CLOSE } from '../actions/types';

const INITIAL_STATE = {
    sideNavShow: false
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SLIDE_OPEN:
            return { ...state, sideNavShow: true };

        case SLIDE_CLOSE:
        return { ...state, sideNavShow: false };

        default:
            return state;
    }
}