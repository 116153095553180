import {
    LOAD_USER_SEASONS_STATS_REQUEST,
    LOAD_USER_SEASONS_STATS_SUCCESS,
    LOAD_USER_SEASONS_STATS_FAILURE,
    LOAD_SUPPORTING_TEAM_SEASONS_REQUEST,
    LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS,
    LOAD_SUPPORTING_TEAM_SEASONS_FAILURE,
    UPDATE_SEASON_SETTINGS_REQUEST,
    UPDATE_SEASON_SETTINGS_SUCCESS,
    UPDATE_SEASON_SETTINGS_FAILURE,
    LOAD_SEASON_SETTINGS_REQUEST,
    LOAD_SEASON_SETTINGS_SUCCESS,
    LOAD_SEASON_SETTINGS_FAILURE,
    LOAD_SEASON_RESULTS_REQUEST,
    LOAD_SEASON_RESULTS_SUCCESS,
    LOAD_SEASON_RESULTS_FAILURE,
    UPDATE_SEASON_ACTIVE_STATUS_REQUEST,
    UPDATE_SEASON_ACTIVE_STATUS_SUCCESS,
    UPDATE_SEASON_ACTIVE_STATUS_FAILURE,
    QUEUE_TOAST,
    FORCED_LOG_OUT,
    LOG_OUT
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'

// Navigation
import { TEAM_STAT_TYPES } from '../navigation'


export const loadUserSeasonsStats = (stat_type_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load the users stats for all seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_USER_SEASONS_STATS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/user-stats/`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_USER_SEASONS_STATS_SUCCESS, payload: { team_stat_type: response.data.team_stat_type } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadUserSeasonsStats(stat_type_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Stats - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_USER_SEASONS_STATS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}


export const loadSupportingTeamSeasons = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Supporting Team Seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_SUPPORTING_TEAM_SEASONS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/supporting/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS, payload: { supporting_team_seasons: response.data.supporting_team_seasons } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSupportingTeamSeasons(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Supporting Team Seasons - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_SUPPORTING_TEAM_SEASONS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadSeasonSettings = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Season Settings')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_SEASON_SETTINGS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/settings/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_SEASON_SETTINGS_SUCCESS, payload: { team_season_id, season_settings: response.data.season_settings } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSeasonSettings(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Settings - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_SEASON_SETTINGS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const updateSeasonSettings = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Update Season Settings')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: UPDATE_SEASON_SETTINGS_REQUEST })

    const {
        show_points_leaderboard,
        obfuscate_points_leaderboard,
        show_stats_leaderboard,
        obfuscate_stats_leaderboard,
        show_round_results,
        obfuscate_round_results
    } = getState().season[team_season_id].admin.leaderboard_settings

    console.log('obfuscate_round_results', obfuscate_round_results ? obfuscate_round_results : false)

    const { user_id, token } = getState().user

    await streams.post(`/seasons/settings/${team_season_id}`, {
        user_id,
        token,
        show_points_leaderboard: show_points_leaderboard ? show_points_leaderboard : false,
        obfuscate_points_leaderboard: obfuscate_points_leaderboard ? obfuscate_points_leaderboard : false,
        show_stats_leaderboard: show_stats_leaderboard ? show_stats_leaderboard : false,
        obfuscate_stats_leaderboard: obfuscate_stats_leaderboard ? obfuscate_stats_leaderboard : false,
        show_round_results: show_round_results ? show_round_results : false,
        obfuscate_round_results: obfuscate_round_results ? obfuscate_round_results : false
    })
        .then(response => {

            const { updated } = response.data

            dispatch({ type: UPDATE_SEASON_SETTINGS_SUCCESS })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Settings Updated Successfully' } })
            }

            ReactGA.event({
                category: 'Season Admin',
                action: 'Update Leaderboard Settings',
                value: 10
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSupportingTeamSeasons(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update Season Settings - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: UPDATE_SEASON_SETTINGS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadSeasonResults = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Season Results')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_SEASON_RESULTS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/results/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_SEASON_RESULTS_SUCCESS, payload: { team_season_id, season_results: response.data.season_results } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSeasonResults(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Results - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_SEASON_RESULTS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const updateSeasonActiveStatus = (team_season_id, active, delay, retry_count) => async (dispatch, getState) => {
    console.log('** End Season')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: UPDATE_SEASON_ACTIVE_STATUS_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/seasons/active-status/${team_season_id}`, {
        user_id,
        token,
        active
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: UPDATE_SEASON_ACTIVE_STATUS_SUCCESS, payload: { team_season_id } })

            ReactGA.event({
                category: 'Season Admin',
                action: active ? 'Activate Season' : 'End Season',
                value: 100
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(updateSeasonActiveStatus(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Results - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: UPDATE_SEASON_ACTIVE_STATUS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}
