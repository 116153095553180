import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Divider } from '@material-ui/core/'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { red, green, blue, yellow } from '@material-ui/core/colors';

// Forms
import TextField from '@material-ui/core/TextField'
import PlayerHeaderBasic from './PlayerHeaderBasic';

const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bigAvatar: {
        display: 'block',
        // margin: 10,
        width: '100%',
        height: 'auto',
    },
    selected: {
        boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${green[400]}`
    },
    fullWidth: {
        width: '100%'
    },
    playerAvatar: {
        minWidth: '128px'
    },
    voteListContainer: {
        // flex: '0',
        // '@media (min-width: 800px)': {
        //     flex: 'unset',
        //     width: "760px"
        // }
    }
});

const RedButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${red[400]}`,
            background: `linear-gradient(270deg, ${red[400]} 0%, ${red[700]} 100%)`,
            textShadow: `0px 0px 3px ${red[100]}`,
            zIndex: 1
        }
    },
}))(Button);

const GreenButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${green[400]}`,
            background: `linear-gradient(270deg, ${green[400]} 0%, ${green[700]} 100%)`,
            textShadow: `0px 0px 3px ${green[100]}`,
            zIndex: 1
        }
    },
}))(Button);

const BlueButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${blue[400]}`,
            background: `linear-gradient(270deg, ${blue[400]} 0%, ${blue[700]} 100%)`,
            textShadow: `0px 0px 3px ${blue[100]}`,
            zIndex: 1
        }
    },
}))(Button);

const YellowButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(yellow[500]),
        backgroundColor: yellow[500],
        '&:hover': {
            backgroundColor: yellow[700],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${yellow[400]}`,
            background: `linear-gradient(270deg, ${yellow[400]} 0%, ${yellow[700]} 100%)`,
            textShadow: `0px 0px 3px ${yellow[100]}`,
            zIndex: 1
        }
    },
}))(Button);


const renderCommentFields = (player_id, vote_option, onInputChange, classes) => {
    const render_output = []

    if (vote_option.hasOwnProperty('reason_3pt')) {
        render_output.push(renderCommentField(
            player_id,
            {
                name: "reason_3pt",
                label: "3 Point Reason",
                value: vote_option.reason_3pt
            },
            onInputChange,
            classes
        ))
    }

    if (vote_option.hasOwnProperty('reason_2pt')) {
        render_output.push(renderCommentField(
            player_id,
            {
                name: "reason_2pt",
                label: "2 Point Reason",
                value: vote_option.reason_2pt
            },
            onInputChange,
            classes
        ))
    }

    if (vote_option.hasOwnProperty('reason_1pt')) {
        render_output.push(renderCommentField(
            player_id,
            {
                name: "reason_1pt",
                label: "1 Point Reason",
                value: vote_option.reason_1pt
            },
            onInputChange,
            classes
        ))
    }

    if (vote_option.hasOwnProperty('reason_glory')) {
        render_output.push(renderCommentField(
            player_id,
            {
                name: "reason_glory",
                label: "Glory Reason",
                value: vote_option.reason_glory
            },
            onInputChange,
            classes
        ))
    }

    if (vote_option.hasOwnProperty('reason_cut')) {
        render_output.push(renderCommentField(
            player_id,
            {
                name: "reason_cut",
                label: "Cut Reason",
                value: vote_option.reason_cut
            },
            onInputChange,
            classes
        ))
    }

    return render_output
}

const renderCommentField = (player_id, { name, label, value }, onInputChange, classes) => {

    return (
        <Grid item key={name}>
            <Box mt={2}>
                <TextField
                    label={label}
                    placeholder={label}
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth}`}
                    inputProps={{
                        maxLength: 500,
                    }}
                    name={name}
                    autoComplete="off"
                    value={value}
                    onChange={(event) => {onInputChange(event, player_id, name)}}
                />
            </Box>
        </Grid>
    )
}

const PlayerRoundVotePlayer = (props) => {
    console.log('[] PlayerROundVotePlayer')

    const { allow_voting, round_id, player_id, vote_option } = props;

    const { classes, onInputChange } = props

    const { pointsButtonClick } = props

    const pointsButton = (e, type) => {
        e.preventDefault()

        if(allow_voting)
        {
            pointsButtonClick(round_id, player_id, type)
        }
    }

    return (
        <>
            <Box p={1} mb={1}>
                <Grid
                    container
                    justify="center"
                >
                    <Grid item
                        className={`${classes.voteListContainer}`}
                    >
                        <Grid
                            container
                            justify="flex-end"
                            alignItems="center"
                            alignContent="center"

                        // spacing={1}
                        >
                            <PlayerHeaderBasic player_id={player_id} />
                            <Grid item
                                xs={12}
                            >
                                <Box mt={1}>
                                    <Grid container
                                        wrap="nowrap"
                                        justify="space-between"
                                        alignItems="center"
                                        alignContent="space-around"
                                        spacing={1}
                                        // xs={12}
                                    >
                                        <Grid item
                                            style={{flexGrow: '4'}}
                                        >
                                            <ButtonGroup
                                                color="secondary"
                                                size="medium"
                                                aria-label="Large outlined secondary button group"
                                                className={`${classes.fullWidth}`}
                                            >
                                                <GreenButton type="button" onClick={(e) => { pointsButton(e, 'reason_3pt') }} style={{flex: '1'}} className={vote_option.hasOwnProperty('reason_3pt') ? 'active' : ''}>3&nbsp;Pt</GreenButton>
                                                <BlueButton type="button" onClick={(e) => { pointsButton(e, 'reason_2pt') }} style={{flex: '1'}} className={vote_option.hasOwnProperty('reason_2pt') ? 'active' : ''}>2&nbsp;Pt</BlueButton>
                                                <YellowButton type="button" onClick={(e) => { pointsButton(e, 'reason_1pt') }} style={{flex: '1'}} className={vote_option.hasOwnProperty('reason_1pt') ? 'active' : ''}>1&nbsp;Pt</YellowButton>
                                            </ButtonGroup>
                                        </Grid>
                                        <Grid item
                                            style={{flexGrow: '1'}}
                                        >
                                            <GreenButton
                                                type="button"
                                                variant="contained"
                                                size="medium"
                                                className={`${classes.fullWidth} ${vote_option.hasOwnProperty('reason_glory') ? 'active' : ''}`}
                                                onClick={(e) => { pointsButton(e, 'reason_glory')}}
                                            >
                                                Glory
                                            </GreenButton>
                                        </Grid>
                                        <Grid item
                                            style={{flexGrow: '1'}}
                                        >
                                            <RedButton
                                                type="button"
                                                variant="contained"
                                                size="medium"
                                                className={`${classes.fullWidth} ${vote_option.hasOwnProperty('reason_cut') ? 'active' : ''}`}
                                                onClick={(e) => { pointsButton(e, 'reason_cut') }}
                                            >
                                                Cut
                                            </RedButton>
                                        </Grid>
                                    </Grid>

                                    {renderCommentFields(player_id, vote_option, onInputChange, classes)}

                                    </Box>
                                    <Box mt={3}>
                                        <Divider />
                                    </Box>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default withStyles(styles)(PlayerRoundVotePlayer)