import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamSeasons, loadManagedTeams } from '../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'
import TeamSeasonListItem from '../TeamSeasonListItem'
import { SEASON_CREATE } from '../../../navigation'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    adminMenuButton: {
        width: '100%'
    },
})


const TeamSeasons = props => {
    console.log('[] Team Seasons')

    const team_id = props.match.params.team_id

    const { classes, loadTeamSeasons, loadManagedTeams, seasons, team } = props // Connect

    const renderSeasons = (seasons, classes) => {

        var ordered_seasons = []

        for(var season_id in seasons)
        {
            ordered_seasons.push([season_id, seasons[season_id].start_date])
        }

        // Sort the seasons by date
        ordered_seasons.sort((a, b) => b[1] > a[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_seasons)
        {
            const team_season_id = ordered_ids[0]

            const { club_name, age_group, division, start_date, end_date } = seasons[team_season_id]

            output.push(
                <>
                    <Box my={2} mx={1} key={team_season_id}>
                        <Link to={`/team/season/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                            <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
                        </Link>
                    </Box>
                    <Divider />
                </>
            )
        }

        return output
    }

    // Load the round data
    useEffect(() => {
        loadTeamSeasons(team_id)
        loadManagedTeams()
    }, [team_id])

    return (
        <>
            <Box my={2} mx={1}>
                <Typography content="h2" variant="h2">{team.name ? team.name : 'Loading'}</Typography>
                <Typography content="h1" variant="h1">Seasons</Typography>
            </Box>

            <Divider />

            <Box my={3} mx={1}>
                <Link to={`${SEASON_CREATE}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                        Create a New Season
                    </Button>
                </Link>
            </Box>

            <Divider />

            {renderSeasons(seasons, classes)}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {},
        seasons: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) && state.admin.team[team_id].hasOwnProperty('seasons') ? state.admin.team[team_id].seasons : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeasons, loadManagedTeams })(TeamSeasons))


