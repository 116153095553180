import {
    CREATE_OPPONENT_REQUEST,
    CREATE_OPPONENT_SUCCESS,
    CREATE_OPPONENT_FAILURE,
    LOAD_OPPONENT_REQUEST,
    LOAD_OPPONENT_SUCCESS,
    LOAD_OPPONENT_FAILURE,
    LOAD_OPPONENT_DETAIL_REQUEST,
    LOAD_OPPONENT_DETAIL_SUCCESS,
    LOAD_OPPONENT_DETAIL_FAILURE,
    UPDATE_OPPONENT_REQUEST,
    UPDATE_OPPONENT_SUCCESS,
    UPDATE_OPPONENT_FAILURE,
    QUEUE_TOAST,
    LOG_OUT,
    FORCED_LOG_OUT,
    ADD_OPPONENT_TEAM_SEASON_REQUEST,
    ADD_OPPONENT_TEAM_SEASON_SUCCESS,
    ADD_OPPONENT_TEAM_SEASON_FAILURE
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'

// Navigation
import {
    TEAM_ADMIN, OPPONENT_ADMIN, OPPOSITION_ADMIN
} from '../navigation'


export const loadOpponent = (team_season_id, opposition_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load User Teams')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_OPPONENT_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_OPPONENT_SUCCESS, payload: { teams: response.data.teams } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadOpponent(team_season_id, opposition_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_OPPONENT_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadOpponentDetail = (team_season_id, opposition_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_OPPONENT_DETAIL_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/opponent/${opposition_id}`, {
        params: {
            user_id,
            token,
            team_season_id
        }
    })
        .then(response => {

            dispatch({ type: LOAD_OPPONENT_DETAIL_SUCCESS, payload: { team_season_id, opponent: response.data.opponent } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadOpponentDetail(opposition_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_OPPONENT_DETAIL_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const createOpponent = (team_season_id, history, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Create Opponent')

    // Check all form fields have been completed.
    const { name } = getState().admin.create.opponent

    if(!name)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot... the only spot. Please complete all the form fields.' } })
        return
    }

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: CREATE_OPPONENT_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/seasons/opponent/${team_season_id}`, {
        user_id,
        token,
        name
    })
        .then(response => {

            // const { opponent_id } = response.data

            dispatch({ type: CREATE_OPPONENT_SUCCESS, payload: { team_season_id, opposition_id: response.data.opposition_id, name } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Opponent created successfully' } })

            // history.push(`${OPPOSITION_ADMIN}/${team_season_id}`)
            // history.goBack()

            ReactGA.event({
                category: 'Team Admin',
                action: 'Create Opponent',
                value: 20
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(createOpponent(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create Opponent - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: CREATE_OPPONENT_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}


export const updateOpponent = () => async (dispatch, getState) => {
    console.log('** Update Opponent')

    // Check all form fields have been completed.
    const { team_season_id, opposition_id, name } = getState().admin.create.opponent

    if(!opposition_id
        || !name
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: UPDATE_OPPONENT_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/seasons/opponent/${opposition_id}`, {
        user_id,
        token,
        name,
        team_season_id
    })
        .then(response => {

            const { updated } = response.data

            dispatch({ type: UPDATE_OPPONENT_SUCCESS })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Opponent Updated Successfully' } })
            }

            ReactGA.event({
                category: 'Team Admin',
                action: 'Update Opponent',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update Opponent - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_OPPONENT_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const addOpponentTeamSeason = (opposition_id, team_season_id, opposition_name, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Add Opponent Team Season')

    dispatch({ type: ADD_OPPONENT_TEAM_SEASON_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/opposition/addTeamSeason/${opposition_id}`, {
        user_id,
        token,
        team_season_id
    })
        .then(response => {

            dispatch({ type: ADD_OPPONENT_TEAM_SEASON_SUCCESS, payload: { opposition_id, team_season_id, opposition_name } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Opponent Added to Season' } })

            ReactGA.event({
                category: 'Team Admin',
                action: 'Add Opponent to new Season',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(addOpponentTeamSeason(opposition_id, team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create Opponent - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: ADD_OPPONENT_TEAM_SEASON_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

