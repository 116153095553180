import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, Link } from 'react-router-dom'
import { Box, TextField, Button, Typography } from '@material-ui/core'

import { updateTeamRegistrationCode, requestTeam } from '../actions'
import RequestTeamForm from './teams/RequestTeamForm'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
})

const RequestTeam = props => {

    console.log('[] Team Registration')

    const { classes } = props

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Register for a Team</Typography>
                </Box>
                <Box my={2} mx={0}>
                    <Typography variant="body1">
                        Joining a new Team is easy just follow the steps below then let the BANTR begin!
                        <ol>
                            <li>
                                Ask your Team Manager for the Team Registration Code
                            </li>
                            <li>
                                Enter it below and click "Send Request"
                            </li>
                            <li>
                                Your Team Manager will review your request
                            </li>
                            <li>
                                Once approved you'll see the Rounds and stats on your Leaderboard
                            </li>
                        </ol>
                    </Typography>
                </Box>

                <RequestTeamForm />

            </Box>
        </>
    )
}


export default withStyles(styles)(RequestTeam)
