import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography } from '@material-ui/core'

import { setNewPassword, updatePassword } from '../actions'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
})

const PasswordResetCreate = props => {

    console.log('[] PasswordReset')

    const { password_reset_token } = props.match.params

    const { classes } = props
    const { password, redirectToReferrer, setNewPassword, updatePassword } = props
    const { from } = props.location.state || { from: { pathname: '/' } }

    const history = useHistory()

    //console.log('Login Component')
    //console.log(props)

    const onPasswordInputChange = (event) => {
        updatePassword(event.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setNewPassword(password_reset_token, history)
    }

    if(redirectToReferrer === true)
    {
        return <Redirect to={from} />
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">New Password</Typography>
                </Box>

                <form onSubmit={onSubmit}>

                    <TextField
                        label="Password"
                        placeholder=""
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 100,
                        }}
                        name={`password`}
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(event) => { onPasswordInputChange(event) }}
                    />

                    <Box my={3}>
                        <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                            Set Password
                        </Button>
                    </Box>

                </form>
            </Box>
        </>
    )
}

const mapStateToProps = state => {
    return {
        password: state.user.password
    }
}

export default withStyles(styles)(connect(mapStateToProps, { setNewPassword, updatePassword })(PasswordResetCreate))
