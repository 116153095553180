import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux'
import './whiteboard.css';
import { updateRoundWhiteboardText, saveRoundWhiteboard, updateRoundWhiteboardPublishStatus, loadWhiteboard } from '../../../actions';
import { Divider, Box, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
  });

function UpdateWhiteboard(props) {

	const { round_id } = props.match.params

	console.log('[] Whiteboard Amdin - Round: ' + round_id);

	const { updateRoundWhiteboardText, saveRoundWhiteboard, updateRoundWhiteboardPublishStatus, loadWhiteboard, scribble, whiteboard_published, classes } = props

    useEffect(() => {

        loadWhiteboard(round_id)

    }, [])

	const modules = {
		toolbar: {
			container: [
				[{ 'header': 1 }, { 'header': 2 }, 'clean'],
				['bold', 'italic', 'underline', 'blockquote'],
				[{ 'list': 'ordered' }, { 'list': 'bullet' }],
				['link'],

			]
		}
	};

	const formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link'
	]

	console.log('scribble', scribble)

	return (
		<>
			<ReactQuill
				theme="snow"
				modules={modules}
				formats={formats}
				value={scribble}
				placeholder="Impart your wisdom"
				readOnly={whiteboard_published}
				className={whiteboard_published && "disabled"}
				onChange={(scribble) => { updateRoundWhiteboardText(round_id, scribble) }}
			/>

			<Divider />

			{
			whiteboard_published ?
				<Box my={2} mx={1}>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						className={classes.fullWidth}
						onClick={ () => {
							updateRoundWhiteboardPublishStatus(round_id, false)
						} }
					>
						Unpublish & Edit
					</Button>
				</Box>
			:
				<>
				<Box my={2} mx={1}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className={classes.fullWidth}
						onClick={() => { saveRoundWhiteboard(round_id) }}
					>
						Save
					</Button>
				</Box>

				<Box my={2} mx={1}>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						className={classes.fullWidth}
						onClick={ () => {
							saveRoundWhiteboard(round_id)
							updateRoundWhiteboardPublishStatus(round_id, true)
						} }
					>
						Save + Publish
					</Button>
				</Box>
				</>
			}
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		scribble: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('whiteboard') && state.admin.create.whiteboard.hasOwnProperty('scribble') ? state.admin.create.whiteboard.scribble : '',
		whiteboard_published: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('whiteboard') && state.admin.create.whiteboard.hasOwnProperty('whiteboard_published') ? state.admin.create.whiteboard.whiteboard_published : ''
	};
}

export default withStyles(styles)(connect(mapStateToProps, { updateRoundWhiteboardText, saveRoundWhiteboard, updateRoundWhiteboardPublishStatus, loadWhiteboard })(UpdateWhiteboard))