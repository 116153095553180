import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Redirect, Link } from 'react-router-dom'
import { Box, TextField, Button, Typography } from '@material-ui/core';

import { updateTeamRegistrationCode, requestTeam } from '../../actions'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    inputText: {
        fontSize: '2em',
        textAlign: 'center',
        textTransform: 'uppercase'
    }
});

const RequestTeamForm = props => {

    console.log('[] Login')

    const { classes } = props
    const { updateTeamRegistrationCode, requestTeam, team_registration_code } = props

    // const onUserIdInputChange = (event) => {
    //     updateUserId(event.target.value)
    // };

    const onTeamRegistrationCodeInputChange = (event) => {
        updateTeamRegistrationCode(event.target.value)
    };

    const onSubmit = (e) => {
        e.preventDefault()

        requestTeam()
    }

    return (
        <>
            <form onSubmit={onSubmit}>

                <TextField
                    label="Team Registration Code"
                    placeholder=""
                    // error={touched && invalid}
                    // helperText={touched && error}
                    InputProps={{
                        classes: {
                            input: classes.inputText
                        }
                    }}
                    className={`${classes.fullWidth}`}
                    inputProps={{
                        maxLength: 50,
                    }}
                    name={`team_registration_code`}
                    autoComplete="off"
                    value={team_registration_code}
                    onChange={(event) => { onTeamRegistrationCodeInputChange(event) }}
                />

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                        Send Request
                    </Button>
                </Box>

            </form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        team_registration_code: state.registration.team_registration_code
    };
}

export default withStyles(styles)(connect(mapStateToProps, { updateTeamRegistrationCode, requestTeam })(RequestTeamForm))
