import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { GreenButton } from '../../ui/Buttons'
import { FaPlus, FaUserPlus } from 'react-icons/fa'
import { arrayIncludes } from '@material-ui/pickers/_helpers/utils'

import moment from 'moment'
import { addOpponentTeamSeason } from '../../../actions'
import Alert from '@material-ui/lab/Alert'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
    iconButton: {
        padding: '1.2em',
        minWidth: 'unset',
        marginTop: '5px'
    },
    pt0: {
        paddingTop: '0 !important'
    },
    pb0: {
        paddingBottom: '0 !important'
    }
})

const OppositionListPastSeasons = props => {
    console.log('[] Opposition List Past Season')

    const { current_team_season_id } = props // Parent

    const { opposition, seasons, addOpponentTeamSeason, classes } = props // Connect

    const renderOpponents = () => {

        var opponents = {}

        for(const team_season_id in opposition)
        {
            // Skipp all opponents in the current Team Season
            if(Number(team_season_id) === Number(current_team_season_id))
            {
                continue
            }

            if(Object.hasOwnProperty.call(opposition, team_season_id))
            {
                const team_season_opposition = opposition[team_season_id]

                // Don't show Opponents from other Teams
                if(seasons[team_season_id].team_id !== seasons[current_team_season_id].team_id)
                {
                    continue
                }

                for(const opposition_id in team_season_opposition)
                {
                    if(Object.hasOwnProperty.call(team_season_opposition, opposition_id))
                    {
                        const opponent = team_season_opposition[opposition_id]

                        // Don't show Opponent if it's already included in the current Season
                        if(opposition[current_team_season_id].hasOwnProperty(opposition_id))
                        {
                            continue
                        }

                        const opponent_team_season_ids = opponents.hasOwnProperty(opposition_id) && opponents[opposition_id].hasOwnProperty('team_seasons') ? opponents[opposition_id].team_seasons : []
                        opponent_team_season_ids.push(Number(team_season_id))

                        opponents = {
                            ...opponents,
                            [opposition_id]: {
                                opposition_id: Number(opposition_id),
                                name: opponent.name,
                                team_season_ids: opponent_team_season_ids
                            }
                        }
                    }
                }
            }
        }

        console.log('opponents', opponents)

        // Sort the Opponents by name

        const ordered_opponents = []

        for(const opponent_id in opponents)
        {
            if(Object.hasOwnProperty.call(opponents, opponent_id))
            {
                const opponent = opponents[opponent_id]
                ordered_opponents.push([opponent_id, opponent.name])
            }
        }

        // ordered_opponents the opponents by date
        ordered_opponents.sort((a, b) => a[1] > b[1] ? 1 : -1)

        console.log('ordered_opponents', ordered_opponents)

        var output = []

        for(var ordered_ids of ordered_opponents)
        {
            const { opposition_id, name, team_season_ids } = opponents[ordered_ids[0]]

            output.push(
                <Box
                    key={`past-season-opposition-option-${opposition_id}`}
                    mx={1} my={2}
                >
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Grid item>
                            <Tooltip placement="top" title="Add to current Season" >
                                <GreenButton
                                    onClick={() => { addOpponentTeamSeason(opposition_id, current_team_season_id, name) }}
                                    className={`${classes.iconButton}`}
                                >
                                    <FaPlus fontSize="large" />
                                </GreenButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Grid container
                                wrap="nowrap"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                                direction="column"
                            >
                                <Grid item className={`${classes.pb0}`}>
                                    <Typography variant="h4">{name}</Typography>
                                </Grid>
                                <Grid item className={`${classes.pb0} ${classes.pt0}`} >
                                    <Typography variant="h5" >From {team_season_ids.length <= 1 ? "Season" : "Seasons"}</Typography>
                                </Grid>
                                {
                                    Object.entries(seasons).length > 0 && team_season_ids.map(team_season_id => {
                                        return (
                                            <Grid item key={`past-opposition-${opposition_id}-season-${team_season_id}`}>
                                                <Typography variant="body1">{moment.unix(seasons[team_season_id].start_date).format("DD MMM YY")} &ndash; {moment.unix(seasons[team_season_id].end_date).format("DD MMM YY")}<br />{seasons[team_season_id].age_group} - Div {seasons[team_season_id].division}</Typography>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box >
            )
        }

        return output
    }

    // If there are no past
    if(Object.entries(seasons).length <= 1)
    {
        return []
    }

    return (
        <>
            <Box my={2} mx={0}>
                <Typography content="h2" variant="h2">Opponents</Typography>
                <Typography content="h3" variant="h3">Past Seasons</Typography>
            </Box>
            <Alert severity="info">
                If your playing against some or all of the same Teams as previous Seasons then adding them to this Season will allow you to track your past results against the Team.<br />
                It's reccomended in most cases to add the Opponents from past Seasons rather than creating new ones.
            </Alert>
            {renderOpponents()}
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        opposition: state.admin.hasOwnProperty('opposition') ? state.admin.opposition : {},
        seasons: state.season
    }
}

export default withStyles(styles)(connect(mapStateToProps, { addOpponentTeamSeason })(OppositionListPastSeasons))


