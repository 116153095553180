import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Avatar  } from '@material-ui/core/'
import Chat from '@material-ui/icons/Chat'
import { GreenChip } from '../ui/Chips'

const styles = theme => ({
    paper: {
      padding: theme.spacing(1),
      border: 0,
      boxShadow: 'none',
      textAlign: 'right',
      color: theme.palette.text.secondary
    }
  });


const PlayerStatComments = (props) => {
    const { classes } = props;

    const { banter } = props

    var output = []
    var x = 0;

    for(var comment of banter)
    {
        output.push(
            <GreenChip key={`banter-${x++}`} color="secondary" avatar={<Avatar><Chat style={{fontSize: '1rem'}} /></Avatar>} label={`${comment}`} style={{margin: '4px'}} />
        )
    }

    return (
        <>
            <Paper className={classes.paper}>
                {output}
            </Paper>
        </>
    )
}


export default withStyles(styles)(PlayerStatComments)
