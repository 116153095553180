import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { loadPlayerVotingStats } from '../../../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Fab, Tooltip, Typography, Divider } from '@material-ui/core/'
import { red, orange, green } from "@material-ui/core/colors"
import PlayerHeaderBasic from "../../../player/PlayerHeaderBasic"
import PlayerHeaderSmall from "../../../player/PlayerHeaderSmall"
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { LightTooltip } from "../../../ui/Tooltips"
import RoundListItem from "../../RoundListItem"



const styles = theme => ({
    showRoundResults: {
        textAlign: 'center',
        borderRadius: "15px",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    red: {
        backgroundColor: red[500],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[600],
            borderColor: red[500],
        },
    },
    orange: {
        backgroundColor: orange[500],
        borderColor: orange[400],
        '&:hover': {
            backgroundColor: orange[600],
            borderColor: orange[500],
        },
    },
    green: {
        backgroundColor: green[500],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[600],
            borderColor: green[500],
        },
    },
    redText: {
        color: red[500],
        '&:hover': {
            color: red[600]
        }
    },
    orangeText: {
        color: orange[500],
        '&:hover': {
            color: orange[600]
        }
    },
    greenText: {
        color: green[500],
        '&:hover': {
            color: green[600]
        }
    },
    votingStat: {
        textAlign: "center",
        // marginTop: "56px",
        padding: "20px"
    },
    votingSubStat: {
        textAlign: "center",
        // marginTop: "56px",
        padding: "4px",
        color: '#85cbf4'
    },
    keystat: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.secondary.main
    }
})

function RoundPlayerVotingStats(props) {
    console.log('[] RoundPlayerVotingStats')

    const { round_id } = props.match.params

    const { player_voting_stats, loadPlayerVotingStats, classes } = props // Connect
    const { user_player_id } = props // Parent

    useEffect(() => {
        loadPlayerVotingStats(round_id)
    }, [round_id])

    console.log('player_voting_stats', player_voting_stats)

    const output = []

    for(const player of player_voting_stats)
    {
        const {
            player_id,
            voted_in_round,
            total_card_player_points,
            pt3_card_player_points,
            pt2_card_player_points,
            pt1_card_player_points,
            total_spectator_points,
            pt3_spectator_points,
            pt2_spectator_points,
            pt1_spectator_points,
            total_points
        } = player

        // var highlightColor = ''

        // switch (players.hasOwnProperty(player_id) ? players[player_id].status : '') {
        //     case 'I':
        //         highlightColor = 'green'
        //         break
        //     case 'M':
        //         highlightColor = 'orange'
        //         break
        //     case 'O':
        //         highlightColor = 'red'
        //         break
        //     default:
        //         highlightColor = ''
        // }

        output.push(
            <Grid container key={player_id}
                alignItems="flex-end"
            >
                <Grid item
                    xs={8}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingStat}`}
                                >
                                    <LightTooltip disableFocusListener title="Points from Card Players" placement="top">
                                        <Typography variant="h5">{total_card_player_points}</Typography>
                                    </LightTooltip>
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingStat}`}
                                >
                                    <LightTooltip disableFocusListener title="Points from Spectators" placement="top">
                                        <Typography variant="h5">{total_spectator_points}</Typography>
                                    </LightTooltip>
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingStat} ${classes.keystat}`}
                                >
                                    <LightTooltip disableFocusListener title="Total Points" placement="top">
                                        <Typography variant="h5">{total_points}</Typography>
                                    </LightTooltip>
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingStat}`}
                                >
                                    <LightTooltip disableFocusListener title="Voted" placement="top">
                                        {voted_in_round ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Divider variant="fullWidth" component="div" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingSubStat}`}
                                >
                                    {Number(pt3_card_player_points) !== 0 && <Typography variant="body2">3pt x {pt3_card_player_points}</Typography>}
                                    {Number(pt2_card_player_points) !== 0 && <Typography variant="body2">2pt x {pt2_card_player_points}</Typography>}
                                    {Number(pt1_card_player_points) !== 0 && <Typography variant="body2">1pt x {pt1_card_player_points}</Typography>}
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingSubStat}`}
                                >
                                    {Number(pt3_spectator_points) !== 0 && <Typography variant="body2">3pt x {pt3_spectator_points}</Typography>}
                                    {Number(pt2_spectator_points) !== 0 && <Typography variant="body2">2pt x {pt2_spectator_points}</Typography>}
                                    {Number(pt1_spectator_points) !== 0 && <Typography variant="body2">1pt x {pt1_spectator_points}</Typography>}
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingSubStat}`}
                                >
                                    {Number(pt3_card_player_points) + Number(pt3_spectator_points) !== 0 && <Typography variant="body2">3pt x {Number(pt3_card_player_points) + Number(pt3_spectator_points)}</Typography>}
                                    {Number(pt2_card_player_points) + Number(pt2_spectator_points) !== 0 && <Typography variant="body2">2pt x {Number(pt2_card_player_points) + Number(pt2_spectator_points)}</Typography>}
                                    {Number(pt1_card_player_points) + Number(pt1_spectator_points) !== 0 && <Typography variant="body2">1pt x {Number(pt1_card_player_points) + Number(pt1_spectator_points)}</Typography>}
                                </Grid>
                                <Grid item
                                    xs={3}
                                    className={`${classes.votingSubStat}`}
                                >
                                    <Typography variant="body2"></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item
                    xs={4}
                >
                    <PlayerHeaderSmall delay player_id={player_id} />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <RoundListItem round_id={round_id} />
            <Box my={2} mx={1}>
                <Typography variant="h1">Player Voting Stats</Typography>
            </Box>
            {output.length > 0 ? output : <Box mx={1}><Typography variant="body1">The round's Card has not been set yet</Typography></Box>}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { round_id } = ownProps.match.params

    return {
        player_voting_stats: state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('player_voting_stats') ? state.round[round_id].player_voting_stats : []
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadPlayerVotingStats })(RoundPlayerVotingStats))
