import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadLeaderboard, loadSeasons } from '../../actions'
import PlayerStatList from '../player/PlayerStatList'
import PlayerStatHeroList from '../player/PlayerStatHeroList'
import RoundList from '../rounds/RoundList'
import { Box, Typography } from '@material-ui/core'
import LeaderboardLoading from '../loading/LeaderboardLoading'
import TeamsWelcome from '../teams/TeamsWelcome'
import TeamSeasonListItem from '../seasons/TeamSeasonListItem'
import Alert from '@material-ui/lab/Alert'

const SeasonLeaderBoard = props => {
    console.log('[] Season Leaderboard')

    const { team_season_id } = props.match.params

    const { leaderboard, loadLeaderboard, leaderboard_loading, user_seasons_loading, seasons, loadSeasons } = props

    // //console.log(leaderboard)
    useEffect(() => {
        loadLeaderboard(team_season_id)
    }, [])

    useEffect(() => {
        loadSeasons()
    }, [])


    const generateLeaderboard = () => {
        var output = []

        if(leaderboard.hasOwnProperty('team'))
        {
            for(var team_id in leaderboard.team)
            {
                const team_output = []
                const team = leaderboard.team[team_id]

                if(team.hasOwnProperty('points') && team.points.length > 0)
                {
                    team_output.push(
                        <PlayerStatHeroList key={`herolist-${team_id}`} heading="Points" items={team.points} />
                    )
                }

                // Stats
                for(var stat in team.stats)
                {
                    if(team.stats.hasOwnProperty(stat))
                    {
                        const stat_id = stat

                        stat = team.stats[stat]

                        team_output.push(
                            <PlayerStatList key={`playerstat-${team_id}-${stat_id}`} stat={stat} />
                        )
                    }
                }

                if(team_output.length > 0)
                {
                    team_output.unshift(
                        <Box mt={2} mx={1} key={`team-heading-${team_id}`}>
                            {/* <Typography content="h3" variant="h3">{team.name}</Typography> */}
                            {!user_seasons_loading && <TeamSeasonListItem team_id={seasons[team_id].team_id} team_season_id={team_id} />}
                        </Box>
                    )

                    output.push(...team_output)
                }
            }

            if(output.length === 0)
            {
                output.push(
                    <>
                        <Box mt={2} mx={1} key={`leaderboard-empty-heading`}>
                            <Typography content="h1" variant="h1">Leaderboard</Typography>
                        </Box>
                        <Box mt={1} mx={1} key={`leaderboard-empty-message`}>
                            <Alert severity="info">As the competition rounds are played and players vote you'll see the results for each of your Teams shown here.</Alert>
                        </Box>
                    </>
                )
            }
        }
        else
        {
            if(leaderboard_loading)
            {
                output.push(
                    <LeaderboardLoading key="leaderboard-loading" />
                )
            }
        }

        return output
    }


    return (
        <>
            {
                !user_seasons_loading && Object.entries(seasons).length === 0
                    ?
                    <TeamsWelcome />
                    :
                    <>
                        { generateLeaderboard()}

                        <RoundList period={`season/${team_season_id}`} />
                    </>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        leaderboard: state.leaderboard,
        leaderboard_loading: state.loading.hasOwnProperty('LOAD_LEADERBOARD') ? state.loading.LOAD_LEADERBOARD : false,
        user_seasons_loading: state.loading.hasOwnProperty('LOAD_SEASONS') ? state.loading.LOAD_SEASONS : false,
        seasons: state.season
    }
}

export default connect(mapStateToProps, { loadLeaderboard, loadSeasons })(SeasonLeaderBoard)
