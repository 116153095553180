import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { register, updateUserId, updatePassword, updateUserRegistrationFormField } from '../actions'
import { DatePicker } from '@material-ui/pickers'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
})

const Register = props => {

    const history = useHistory()

    const { player } = props
    const { classes } = props
    const {
        username,
        password,
        confirm_password,
        first_name,
        last_name,
        gender,
        birth_year,
        redirectToReferrer,
        register,
        updateUserRegistrationFormField,
        updateUserId,
        updatePassword
    } = props
    const { from } = props.location.state || { from: { pathname: '/' } }

    const onSubmit = (e) => {
        e.preventDefault()

        register(history)
    }

    if(redirectToReferrer === true)
    {
        return <Redirect to={from} />
    }

    return (
        <>
            <Box m={1}>

                <Box my={2}>
                    <Typography content="h1" variant="h1">Join the Banter</Typography>
                </Box>

                <form onSubmit={onSubmit}>

                    <Box mt={1}>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            // error={touched && invalid}
                            // helperText={touched && error}
                            className={`${classes.fullWidth}`}
                            inputProps={{
                                maxLength: 45,
                            }}
                            name={`first_name`}
                            autoComplete="off"
                            value={first_name}
                            onChange={(e) => { updateUserRegistrationFormField(e.target.name, e.target.value) }}
                        />
                    </Box>

                    <Box mt={1}>
                        <TextField
                            label="Last Name"
                            placeholder=""
                            // error={touched && invalid}
                            // helperText={touched && error}
                            className={`${classes.fullWidth}`}
                            inputProps={{
                                maxLength: 45,
                            }}
                            name={`last_name`}
                            autoComplete="off"
                            value={last_name}
                            onChange={(e) => { updateUserRegistrationFormField(e.target.name, e.target.value) }}
                        />
                    </Box>

                    <Box mt={3}>
                        <FormControl component="fieldset"
                            className={`${classes.fullWidth}`}>
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup aria-label="gender" name="gender" value={gender} onChange={(e) => { updateUserRegistrationFormField(e.target.name, e.target.value) }}>
                                <FormControlLabel value="F" control={<Radio />} label="Female" />
                                <FormControlLabel value="M" control={<Radio />} label="Male" />
                                <FormControlLabel value="O" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    <Box mt={0}>
                        <DatePicker
                            name={"birth_year"}
                            views={["year"]}
                            label="What year were you born?"
                            initialFocusedDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                            value={birth_year ? new Date(birth_year, 0) : null}
                            className={`${classes.fullWidth}`}
                            onChange={(date) => { updateUserRegistrationFormField('birth_year', new Date(date).getFullYear()) }}
                            animateYearScrolling
                            autoOk
                            disableFuture
                            disableToolbar
                            allowKeyboardControl
                        />
                    </Box>

                    <Box mt={1}>
                        <TextField
                            label="Email Address"
                            placeholder="Pelé@gmail.com"
                            // error={touched && invalid}
                            // helperText={touched && error}
                            className={`${classes.fullWidth}`}
                            inputProps={{
                                maxLength: 100,
                            }}
                            name={`username`}
                            autoComplete="off"
                            type="email"
                            value={username}
                            onChange={(event) => { updateUserId(event.target.value) }}
                        />
                    </Box>

                    <Box mt={1}>
                        <TextField
                            label="Password"
                            placeholder=""
                            // error={touched && invalid}
                            // helperText={touched && error}
                            className={`${classes.fullWidth}`}
                            inputProps={{
                                maxLength: 100,
                            }}
                            name={`password`}
                            type="password"
                            autoComplete="off"
                            value={password}
                            onChange={(event) => { updatePassword(event.target.value) }}
                        />
                    </Box>

                    <Box mt={1}>
                        <TextField
                            label="Confirm Password"
                            placeholder=""
                            // error={touched && invalid}
                            // helperText={touched && error}
                            className={`${classes.fullWidth}`}
                            inputProps={{
                                maxLength: 100,
                            }}
                            name={`confirm_password`}
                            type="password"
                            autoComplete="off"
                            value={confirm_password}
                            onChange={(e) => { updateUserRegistrationFormField(e.target.name, e.target.value) }}
                        />
                    </Box>

                    <Box my={3}>
                        <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                            Register
                        </Button>
                    </Box>

                </form>
            </Box>
        </>
    )
}

const mapStateToProps = state => {
    return {
        username: state.user.hasOwnProperty('username') ? state.user.username : '',
        password: state.user.hasOwnProperty('password') ? state.user.password : '',
        confirm_password: state.registration.hasOwnProperty('confirm_password') ? state.registration.confirm_password : '',
        first_name: state.registration.hasOwnProperty('first_name') ? state.registration.first_name : '',
        last_name: state.registration.hasOwnProperty('last_name') ? state.registration.last_name : '',
        gender: state.registration.hasOwnProperty('gender') ? state.registration.gender : '',
        birth_year: state.registration.hasOwnProperty('birth_year') ? state.registration.birth_year : null
    }
}

export default withStyles(styles)(connect(mapStateToProps, { register, updateUserId, updatePassword, updateUserRegistrationFormField })(Register))
