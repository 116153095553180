import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar, } from '@material-ui/core/'
import { FaTrophy, FaFutbol, FaHandsHelping, FaRegCalendarAlt } from "react-icons/fa"
import { GreenChip, BlueChip } from '../ui/Chips'
import { randBetween } from '../../utils/utils'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        // height: '26px'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    fullHeight: {
        height: '100%'
    },
    bigAvatar: {
        display: 'block',
        // maxWidth: '240px',
        width: '100%',
        height: 'auto'
    },
    playerAvatar: {
        width: '128px'
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    bordered: {
        // border: "1px solid red"
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        paddingLeft: '8px'
    },
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    },
    blurryTextBlack: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(0,0,0,0.5)'
    },
    faded: {
        opacity: '0.2'
    }
})


// const PlayerStatHero = (props) => {
function PlayerStatHero(props) {
    const { classes } = props

    const player_id = randBetween(1, 4)
    const player_name = ['Roger Moore', 'Sean Connery', 'George Lazenby', 'Timothy Dalton', 'Pierce Brosnan', 'Daniel Craig'][randBetween(0, 3)]
    const position = ['Defence', 'Mid', 'Forward', 'Keeper'][randBetween(0, 3)]
    const shirt_number = randBetween(1, 30)

    return (
        <>
            <Box p={1} mb={2}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                // spacing={1}
                >
                    <Grid item xs={4} className={`${classes.textRight} ${classes.blurryTextWhite}`}>
                        <Typography variant="h5" className={`${classes.blurryTextWhite}`}>{player_name}</Typography>
                        <Typography variant="subtitle1">{position}</Typography>
                        <Typography variant="subtitle2">{shirt_number}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={4} className={`${classes.textCenter} ${classes.bordered}`}>
                        <Box px={1}>
                            <Avatar alt="" src={`${process.env.REACT_APP_API_URL}/images/players/loading/player-loading-${player_id}.jpg`} className={`${classes.bigAvatar} ${classes.square}`} >
                                <Avatar alt="" src={`${process.env.REACT_APP_API_URL}/images/players/default.png`} className={`${classes.bigAvatar} ${classes.square}`} />
                            </Avatar>
                            <GreenChip color="secondary" className={`${classes.blurryTextBlack}  ${classes.faded}`} avatar={<Avatar><FaTrophy /></Avatar>} label={`${randBetween(50, 447)} Points`} />
                        </Box>
                    </Grid>

                    <Grid item xs={2} className={`${classes.bordered}`}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item className={`${classes.fullWidth}`}>
                                <GreenChip classes={{ root: `${classes.cardSamll} ${classes.faded} ${classes.fullWidth} ${classes.blurryTextBlack}` }} size="small" color="primary" avatar={<Avatar><FaFutbol /></Avatar>} label={randBetween(0, 10)} />
                            </Grid>
                            <Grid item className={`${classes.fullWidth}`}>
                                <BlueChip classes={{ root: `${classes.cardSamll} ${classes.faded} ${classes.fullWidth} ${classes.blurryTextBlack}` }} size="small" color="primary" avatar={<Avatar><FaRegCalendarAlt /></Avatar>} label={randBetween(0, 20)} />
                            </Grid>
                            <Grid item className={`${classes.fullWidth}`}>
                                <GreenChip classes={{ root: `${classes.cardSamll} ${classes.faded} ${classes.fullWidth} ${classes.blurryTextBlack}` }} size="small" color="primary" avatar={<Avatar><FaHandsHelping /></Avatar>} label={randBetween(0, 10)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default withStyles(styles)(PlayerStatHero)
