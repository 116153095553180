const pushServerPublicKey = "BMcKGDKusIjUQREeYer3q31onDylNFJuZHYIqg4w_PvKstpdY1MrD73eUOx3HCON-Rd3zj6g-v5gkjNSHQK26ps"

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function initializePushNotifications() {
    // request user grant to show notification
    return Notification.requestPermission(function(result) {
        return result
    })
}
/**
 * shows a notification
 */
function sendNotification() {
    const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg"
    const text = "Take a look at this brand new t-shirt!"
    const title = "New Product Available"
    const options = {
        body: text,
        icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
        vibrate: [200, 100, 200],
        tag: "new-product",
        image: img,
        badge: "https://spyna.it/icons/android-icon-192x192.png",
        actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
    }
    navigator.serviceWorker.ready.then(function(serviceWorker) {
        serviceWorker.showNotification(title, options)
    })
}

/**
 *
 */
function registerServiceWorker() {
    navigator.serviceWorker.register("/bantr-messaging-sw.js").then(function(swRegistration) {
        //you can do something with the service wrker registration (swRegistration)
    })
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
function createNotificationSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready.then(function(serviceWorker) {
        // subscribe and return the subscription
        return serviceWorker.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: pushServerPublicKey
            })
            .then(function(subscription) {
                console.log("User is subscribed.", subscription)
                return subscription
            })
    })
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then(function(serviceWorker) {
            return serviceWorker.pushManager.getSubscription()
        })
        .then(function(pushSubscription) {
            return pushSubscription
        })
}

export {
    isPushNotificationSupported,
    initializePushNotifications,
    registerServiceWorker,
    sendNotification,
    createNotificationSubscription,
    getUserSubscription
}
