import {
    LOAD_ANNOUNCEMENTS_REQUEST,
    LOAD_ANNOUNCEMENTS_SUCCESS,
    LOAD_ANNOUNCEMENTS_FAILURE,
    ANNOUNCEMENTS_READ_REQUEST,
    ANNOUNCEMENTS_READ_SUCCESS,
    ANNOUNCEMENTS_READ_FAILURE,
    QUEUE_TOAST,
    LOG_OUT,
    FORCED_LOG_OUT,
    ANNOUNCEMENTS_DISMISSED
} from './types'
import streams from '../apis/streams'

import ReactGA from 'react-ga'

// Navigation
import {
    HOME, LOGIN
} from '../navigation'
import { delayRetry } from './actionHelpers'


export const loadAnnouncements = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load What\'s New')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ANNOUNCEMENTS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/user/announcements`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ANNOUNCEMENTS_SUCCESS, payload: { announcements: response.data.announcements } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadAnnouncements(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load what\'s new - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ANNOUNCEMENTS_FAILURE })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const announcementsRead = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Announcement Read')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: ANNOUNCEMENTS_READ_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/user/announcements-read`, {
        user_id,
        token
    })
        .then(response => {

            dispatch({ type: ANNOUNCEMENTS_READ_SUCCESS, payload: { announcements: response.data.announcements } })
            dispatch(announcementsDismissed())

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(announcementsRead(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to clear announcements - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: ANNOUNCEMENTS_READ_FAILURE })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const announcementsDismissed = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Announcement Read')

    dispatch({ type: ANNOUNCEMENTS_DISMISSED })

}