import { combineReducers } from 'redux'
import navigationReducer from './navigationReducer'
import playerReducer from './playerReducer'
import { reducer as formReducer } from 'redux-form'
import roundReducer from './roundReducer'
import authReducer from './authReducer'
import uiReducer from './uiReducer'
import leaderboardReducer from './leaderboardReducer'
import seasonReducer from './seasonReducer'
import loadingReducer from './loadingReducer'
import errorReducer from './errorReducer'
import toastReducer from './toastReducer'
import adminReducer from './adminReducer'
import registrationReducer from './registrationReducer'
import statsAdminReducer from './admin/statsAdminReducer'
import teamsReducer from './teamsReducer'

const appReducer =  combineReducers({
    registration: registrationReducer,
    user: authReducer,
    navigation: navigationReducer,
    loading: loadingReducer,
    toast: toastReducer,
    error: errorReducer,
    form: formReducer,
    player: playerReducer,
    round: roundReducer,
    team: teamsReducer,
    ui: uiReducer,
    leaderboard: leaderboardReducer,
    season: seasonReducer,
    admin: adminReducer
})

const rootReducer = (state, action) => {

    // Cleaer out the Redux store when the user logs out.
    // If not cleared then if a different user logs in they will inherit the data of the prior user
    if (action.type === 'LOG_OUT') {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer