import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, ButtonGroup } from '@material-ui/core/'
import Divider from "@material-ui/core/Divider"
import {
    loadRound,
    incrementTeamScore,
    incrementOppositionScore,
    saveRoundScore
} from '../../../../actions'
import { connect } from 'react-redux'
import { GreenButton, RedButton } from "../../../ui/Buttons"
import RoundListItem from '../../RoundListItem'
import RoundLoading from '../../../loading/RoundLoading'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    stretch: {
        flex: '1'
    },
    scoreDivider: {
        position: 'absolute',
        // left: '50%',
        left: '0',
        right: '0',
        margin: 'auto'
    }
})


const RoundScore = (props) => {
    console.log('[] Round Score')

    const round_id = props.match.params.round_id

    const {
        round,
        incrementTeamScore,
        incrementOppositionScore,
        loadRound,
        saveRoundScore
    } = props // Redux

    const {
        classes
    } = props

    useEffect(() => {

        if(!round || !round.hasOwnProperty('details'))
        {
            loadRound(round_id)
        }

    }, [round_id])

    if(!round || !round.hasOwnProperty('details'))
    {
        return <RoundLoading quantity={1} />
    }


    return (
        <>
            <RoundListItem round_id={round_id} />

            <Box mb={1} mx={1} mt={2}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap='nowrap'
                    className={classes.fullWidth}
                    style={{ textAlign: 'center' }}
                >
                    <Grid item xs={6}>
                        <img alt={round.details.player_team_name} className={`${classes.logo}`} src={`${process.env.REACT_APP_API_URL}/images/jersey.png`} />
                    </Grid>
                    <Grid item xs={6}>
                        <img alt={round.details.opposition_team_name} className={`${classes.logo}`} src={`${process.env.REACT_APP_API_URL}/images/jersey_away.png`} />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                    className={classes.fullWidth}
                    style={{ textAlign: 'center' }}
                >
                    <Grid item className={`${classes.scoreDivider}`}>
                        <Box my={2} mx={1}>
                            <Typography variant={'h5'}>:</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box my={2} mx={1}>
                            <Typography variant={'h5'}>{round.details.goals_for}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box my={2} mx={1}>
                            <Typography variant={'h5'}>{round.details.goals_against}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                    style={{ textAlign: 'center' }}
                >
                    <Grid item xs={6}>
                        <Box my={2} mx={1}>
                            <ButtonGroup
                                color="secondary"
                                size="small"
                                className={`${classes.flex}`}
                                fullWidth
                            >
                                <RedButton
                                    onClick={() => { incrementTeamScore(round_id, -1) }}
                                >
                                    &ndash;
                                </RedButton>
                                <GreenButton
                                    onClick={() => { incrementTeamScore(round_id, 1) }}
                                >
                                    +
                                </GreenButton>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box my={2} mx={1}>
                            <ButtonGroup
                                color="secondary"
                                size="small"
                                className={`${classes.flex}`}
                                fullWidth
                            >
                                <RedButton
                                    onClick={() => { incrementOppositionScore(round_id, -1) }}
                                >
                                    &ndash;
                                </RedButton>
                                <GreenButton
                                    onClick={() => { incrementOppositionScore(round_id, 1) }}
                                >
                                    +
                                </GreenButton>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                    className={classes.fullWidth}
                    style={{ textAlign: 'center' }}
                >
                    <Grid item xs>
                        <Box my={2} mx={1}>
                            <GreenButton
                                className={classes.fullWidth}
                                onClick={() => { saveRoundScore(round_id) }}
                            >
                                SAVE
                            </GreenButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Divider variant="fullWidth" component="div" />

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const round_id = ownProps.match.params.round_id

    return {
        round: state.round[round_id]
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadRound, incrementTeamScore, incrementOppositionScore, saveRoundScore })(RoundScore))
