

import { FaCheckSquare, FaSquare,

    FaHandshake,
    FaHandsHelping,
    FaHeart,
    FaHeartbeat,
    FaHelicopter,
    FaHippo,
    FaHospitalSymbol,
    FaHourglass,
    FaLemon,
    FaMoneyBillAlt,
    FaMoon,
    FaPaperPlane,
    FaPlane,
    FaPizzaSlice,
    FaPoo,
    FaPoop,
    FaPray,
    FaFireExtinguisher,
    FaFirstAid,
    FaFlushed,
    FaFly,

    FaFootballBall,
    FaFutbol,
    FaGolfBall,
    FaBaseballBall,
    FaBasketballBall,
    FaBowlingBall,
    FaVolleyballBall,

    FaSmile,
    FaFrown,
    FaGrin,
    FaGrinBeamSweat,
    FaGrinSquintTears,
    FaGrinStars,
    FaGrinTears,
    FaGrinTongue,
    FaGrinWink,
    FaAngry,
    FaDizzy,
    FaSurprise,

    FaGavel,
    FaGem,
    FaGhost,
    FaGlobeAsia,
    FaHamburger,
    FaHammer,
    FaHandMiddleFinger,
    FaAccessibleIcon,
    FaHandPeace,
    FaBabyCarriage,
    FaBaby,
    FaBan,
    FaBandAid,
    FaBath,
    FaBed,
    FaBeer,
    FaBell,
    FaBinoculars,
    FaBlind,
    FaBolt,
    FaBomb,
    FaBrain,
    FaBriefcaseMedical,
    FaBroom,
    FaBullhorn,
    FaBullseye,
    FaBurn,
    FaCut,
    FaCalendarAlt,
    FaCarCrash,
    FaCheck,
    FaCheckDouble,
    FaChild,
    FaChurch,
    FaCloudRain,
    FaCocktail,
    FaCoffee,
    FaCookieBite,
    FaCompass,
    FaCouch,
    FaCreditCard,
    FaCrosshairs,
    FaCrown,
    FaCross,
    FaDesktop,
    FaDiceOne,
    FaDiceTwo,
    FaDiceThree,
    FaDiceFour,
    FaDiceFive,
    FaDiceSix,
    FaDoorOpen,
    FaDove,
    FaDragon,
    FaFighterJet,
    FaFeather,
    FaFile,
    FaFireAlt,
    FaRadiationAlt,
    FaRocket,
    FaRoute,
    FaRunning,
    FaWalking,
    FaSave,
    FaShieldAlt,
    FaSkull,
    FaSkullCrossbones,
    FaSmoking,
    FaStar,
    FaSwimmer,
    FaTableTennis,
    FaThumbsUp,
    FaThumbsDown,
    FaToilet,
    FaToiletPaper,
    FaTrashAlt,
    FaTrophy,
    FaUtensils,
    FaVolumeMute,
    FaVolumeUp,
    FaWallet,
    FaWheelchair,
    FaWineBottle,
    FaWineGlassAlt,
    FaQuestion,

    FaUser,
    FaUserInjured,
    FaUserMd,
    FaUserNinja,
    FaUserShield,
    FaUserTie,
    FaUsers,
    FaRegCalendarAlt

 } from 'react-icons/fa';

export const stat_type_icons = {

    FaFootballBall: {icon: FaFootballBall, label: 'Football'},
    FaFutbol: {icon: FaFutbol, label: 'Futbol'},
    FaGolfBall: {icon: FaGolfBall, label: 'Golf Ball'},
    FaBaseballBall: {icon: FaBaseballBall, label: 'Base ball'},
    FaBasketballBall: {icon: FaBasketballBall, label: 'Basketball'},
    FaBowlingBall: {icon: FaBowlingBall, label: 'Bowling Ball'},
    FaVolleyballBall: {icon: FaVolleyballBall, label: 'Volleyball'},

    FaSmile: {icon: FaSmile, label: 'Smile'},
    FaFrown: {icon: FaFrown, label: 'Frown'},
    FaGrin: {icon: FaGrin, label: 'Grin'},
    FaGrinBeamSweat: {icon: FaGrinBeamSweat, label: 'Grin Sweat'},
    FaGrinSquintTears: {icon: FaGrinSquintTears, label: 'Grin Squint Tears'},
    FaGrinStars: {icon: FaGrinStars, label: 'Grin Stars'},
    FaGrinTears: {icon: FaGrinTears, label: 'Grin Tears'},
    FaGrinTongue: {icon: FaGrinTongue, label: 'Grin Tongue'},
    FaGrinWink: {icon: FaGrinWink, label: 'Grin Wink'},
    FaAngry: {icon: FaAngry, label: 'Angry'},
    FaDizzy: {icon: FaDizzy, label: 'Dizzy'},
    FaSurprise: {icon: FaSurprise, label: 'Surprise'},

    FaUser: {icon: FaUser, label: 'User'},
    FaUserInjured: {icon: FaUserInjured, label: 'Injured'},
    FaUserMd: {icon: FaUserMd, label: 'Doctor'},
    FaUserNinja: {icon: FaUserNinja, label: 'Ninja'},
    FaUserShield: {icon: FaUserShield, label: 'Player Shield'},
    FaUserTie: {icon: FaUserTie, label: 'Tie'},
    FaUsers: {icon: FaUsers, label: 'Users'},

    FaHandshake: {icon: FaHandshake, label: 'Handshake'},
    FaHandsHelping: {icon: FaHandsHelping, label: 'Handshake'},
    FaHeart: {icon: FaHeart, label: 'Heart'},
    FaHeartbeat: {icon: FaHeartbeat, label: 'Heartbeat'},
    FaHelicopter: {icon: FaHelicopter, label: 'Helicopter'},
    FaHippo: {icon: FaHippo, label: 'Hippo'},
    FaHospitalSymbol: {icon: FaHospitalSymbol, label: 'Hospital'},
    FaHourglass: {icon: FaHourglass, label: 'Hourglass'},
    FaLemon: {icon: FaLemon, label: 'Lemon'},
    FaMoneyBillAlt: {icon: FaMoneyBillAlt, label: 'Money'},
    FaMoon: {icon: FaMoon, label: 'Moon'},
    FaPaperPlane: {icon: FaPaperPlane, label: 'Paper Plane'},
    FaPlane: {icon: FaPlane, label: 'Plane'},
    FaPizzaSlice: {icon: FaPizzaSlice, label: 'Pizza Slice'},
    FaPoo: {icon: FaPoo, label: 'Poo'},
    FaPoop: {icon: FaPoop, label: 'Poop'},
    FaPray: {icon: FaPray, label: 'Pray'},
    FaFile: {icon: FaFile, label: 'File'},
    FaFireExtinguisher: {icon: FaFireExtinguisher, label: 'Fire Extinguisher'},
    FaFirstAid: {icon: FaFirstAid, label: 'First Aid'},
    FaFlushed: {icon: FaFlushed, label: 'Flushed'},
    FaFly: {icon: FaFly, label: 'Fly'},
    FaGavel: {icon: FaGavel, label: 'Gavel'},
    FaGem: {icon: FaGem, label: 'Gem'},
    FaGhost: {icon: FaGhost, label: 'Ghost'},
    FaGlobeAsia: {icon: FaGlobeAsia, label: 'Globe'},
    FaHamburger: {icon: FaHamburger, label: 'Hamburger'},
    FaHammer: {icon: FaHammer, label: 'Hammer'},
    FaHandMiddleFinger: {icon: FaHandMiddleFinger, label: 'Middle Finger'},
    FaAccessibleIcon: {icon: FaAccessibleIcon, label: 'Accessible'},
    FaHandPeace: {icon: FaHandPeace, label: 'Peace'},
    FaBabyCarriage: {icon: FaBabyCarriage, label: 'Pram'},
    FaBaby: {icon: FaBaby, label: 'Baby'},
    FaBan: {icon: FaBan, label: 'Ban'},
    FaBandAid: {icon: FaBandAid, label: 'BandAid'},
    FaBath: {icon: FaBath, label: 'Bath'},
    FaBed: {icon: FaBed, label: 'Bed'},
    FaBeer: {icon: FaBeer, label: 'Beer'},
    FaBell: {icon: FaBell, label: 'Bell'},
    FaBinoculars: {icon: FaBinoculars, label: 'Binoculars'},
    FaBlind: {icon: FaBlind, label: 'Blind'},
    FaBolt: {icon: FaBolt, label: 'Bolt'},
    FaBomb: {icon: FaBomb, label: 'Bomb'},
    FaBrain: {icon: FaBrain, label: 'Brain'},
    FaBriefcaseMedical: {icon: FaBriefcaseMedical, label: 'Briefcase Medical'},
    FaBroom: {icon: FaBroom, label: 'Broom'},
    FaBullhorn: {icon: FaBullhorn, label: 'Bullhorn'},
    FaBullseye: {icon: FaBullseye, label: 'Bullseye'},
    FaBurn: {icon: FaBurn, label: 'Burn'},
    FaCut: {icon: FaCut, label: 'Scissors'},
    FaCalendarAlt: {icon: FaCalendarAlt, label: 'Calendar'},
    FaRegCalendarAlt: {icon: FaRegCalendarAlt, label: 'Calendar'},
    FaCarCrash: {icon: FaCarCrash, label: 'Car Crash'},
    FaCheck: {icon: FaCheck, label: 'Check'},
    FaCheckDouble: {icon: FaCheckDouble, label: 'Check Double'},
    FaChild: {icon: FaChild, label: 'Child'},
    FaChurch: {icon: FaChurch, label: 'Church'},
    FaCloudRain: {icon: FaCloudRain, label: 'Rain Cloud'},
    FaCocktail: {icon: FaCocktail, label: 'Cocktail'},
    FaCoffee: {icon: FaCoffee, label: 'Coffee'},
    FaCookieBite: {icon: FaCookieBite, label: 'Cookie Bite'},
    FaCompass: {icon: FaCompass, label: 'Compass'},
    FaCouch: {icon: FaCouch, label: 'Couch'},
    FaCreditCard: {icon: FaCreditCard, label: 'Credit Card'},
    FaCrosshairs: {icon: FaCrosshairs, label: 'Crosshairs'},
    FaCrown: {icon: FaCrown, label: 'Crown'},
    FaCross: {icon: FaCross, label: 'Cross'},
    FaDesktop: {icon: FaDesktop, label: 'Monitor'},
    FaDiceOne: {icon: FaDiceOne, label: 'Dice One'},
    FaDiceTwo: {icon: FaDiceTwo, label: 'Dice Two'},
    FaDiceThree: {icon: FaDiceThree, label: 'Dice Three'},
    FaDiceFour: {icon: FaDiceFour, label: 'Dice Four'},
    FaDiceFive: {icon: FaDiceFive, label: 'Dice Five'},
    FaDiceSix: {icon: FaDiceSix, label: 'Dice Six'},
    FaDoorOpen: {icon: FaDoorOpen, label: 'Door Open'},
    FaDove: {icon: FaDove, label: 'Dove'},
    FaDragon: {icon: FaDragon, label: 'Dragon'},
    FaFighterJet: {icon: FaFighterJet, label: 'Fighter Jet'},
    FaFeather: {icon: FaFeather, label: 'Feather'},
    FaQuestion: {icon: FaQuestion, label: 'Question Mark'},
    FaFireAlt: {icon: FaFireAlt, label: 'Fire'},
    FaRadiationAlt: {icon: FaRadiationAlt, label: 'Radiation'},
    FaRocket: {icon: FaRocket, label: 'Rocket'},
    FaRoute: {icon: FaRoute, label: 'Route'},
    FaRunning: {icon: FaRunning, label: 'Running'},
    FaWalking: {icon: FaWalking, label: 'Walking'},
    FaSave: {icon: FaSave, label: 'Save'},
    FaShieldAlt: {icon: FaShieldAlt, label: 'Shield'},
    FaSkull: {icon: FaSkull, label: 'Skull'},
    FaSkullCrossbones: {icon: FaSkullCrossbones, label: 'Skull & Crossbones'},
    FaSmoking: {icon: FaSmoking, label: 'Smoking'},
    FaStar: {icon: FaStar, label: 'Star'},
    FaSwimmer: {icon: FaSwimmer, label: 'Swimmer'},
    FaTableTennis: {icon: FaTableTennis, label: 'Table Tennis'},
    FaThumbsUp: {icon: FaThumbsUp, label: 'Thumbs Up'},
    FaThumbsDown: {icon: FaThumbsDown, label: 'Thumbs Down'},
    FaToilet: {icon: FaToilet, label: 'Toilet'},
    FaToiletPaper: {icon: FaToiletPaper, label: 'Toilet Paper'},
    FaTrashAlt: {icon: FaTrashAlt, label: 'Trash'},
    FaTrophy: {icon: FaTrophy, label: 'Trophy'},
    FaUtensils: {icon: FaUtensils, label: 'Utensils'},
    FaVolumeMute: {icon: FaVolumeMute, label: 'Volume Mute'},
    FaVolumeUp: {icon: FaVolumeUp, label: 'Volume Up'},
    FaWallet: {icon: FaWallet, label: 'Wallet'},
    FaWheelchair: {icon: FaWheelchair, label: 'Wheelchair'},
    FaWineBottle: {icon: FaWineBottle, label: 'Wine Bottle'},
    FaWineGlassAlt: {icon: FaWineGlassAlt, label: 'Wine Glass'},
};
