import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import {  Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

const styles = theme => ({
    statAvatar: {
        color: '#0d2431',
        backgroundColor: '#85cbf4',
        fontSize: '3em',
        width: '120px',
        height: '120px',
        margin: '0 0 10px 20px'
    },
    statListItem: {
        textAlign: 'right',
        '& span': {
            fontSize: '3em'
        },
        '& p': {
            fontSize: '1.4em'
        }
    }
  });

const PlayerProfileStat = props => {

    const { stat, Icon, icon_color } = props
    const { classes } = props

    return (
        <>
            <ListItem>
                <ListItemText className={`${classes.statListItem}`} primary={stat.quantity} secondary={stat.label} />
                <ListItemAvatar>
                <Avatar className={`${classes.statAvatar}`} style={{color: icon_color ? icon_color : ''}}>
                    <Icon />
                </Avatar>
                </ListItemAvatar>
            </ListItem>
        </>
    )
}

const mapStateToProps = state => {
    return {
        player: state.player
    };
}

export default withStyles(styles)(connect(mapStateToProps, { })(PlayerProfileStat))
