import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom'
import { Box, Typography, Grid } from '@material-ui/core';

import { updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal } from '../../../actions'

// Icons
import { stat_type_icons } from '../../ui/Icons'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    icon: {
        margin: theme.spacing(1),
        cursor: 'pointer',
        color: theme.palette.text.primary
    }
});

const StatIconSelector = props => {

    console.log('[] Stat Icon Selector');

    const { classes, updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal } = props

    const history = useHistory();


    const selectIcon = (icon) => {
        // Update the icon in the store
        updateTeamStatTypeFormFieldValue('amount_icon', icon)

        // Hide the modal.
        toggleStatIconSelectorModal(false)
    }

    const renderIcons = () => {
        var output = []

        for (const icon_key in stat_type_icons) {
            if (stat_type_icons.hasOwnProperty(icon_key))
            {
                const icon = stat_type_icons[icon_key];

                output.push(
                    <icon.icon size="3em" className={`${classes.icon}`} onClick={() => selectIcon(icon_key)} />
                )
            }
        }

        return output
    }

    return (
        <>
            <Box mb={1} className={classes.fullWidth}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    { renderIcons() }
                </Grid>
            </Box>
        </>
    )
}

export default withStyles(styles)(connect(null, { updateTeamStatTypeFormFieldValue, toggleStatIconSelectorModal })(StatIconSelector))
