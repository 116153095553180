import {
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    QUEUE_TOAST,
    REQUEST_TEAM_REQUEST,
    REQUEST_TEAM_SUCCESS,
    REQUEST_TEAM_FAILURE,
    UPDATE_TEAM_REGISTRATION_CODE,
    LOAD_TEAM_REGISTRATION_STATUSES_REQUEST,
    LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS,
    LOAD_TEAM_REGISTRATION_STATUSES_FAILURE,
    FORCED_LOG_OUT,
    LOG_OUT
} from './types'
import streams from '../apis/streams'
import { LOGIN } from '../navigation'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'

export const register = (history) => async (dispatch, getState) => {
    console.log('** Register User')

    const { first_name, last_name, gender, birth_year, confirm_password } = getState().registration
    const { username, password } = getState().user

    // Check all form fields have been completed.

    if(!username
        || !first_name
        || !last_name
        || !gender
        || !birth_year
        || !password
        || !confirm_password
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    // Check password and confirm password match
    if(password !== confirm_password)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... your passwords don\'t match.  Give it another go' } })
        return
    }

    dispatch({ type: REGISTRATION_REQUEST })

    await streams.post(`/user/`, {
        first_name,
        last_name,
        gender,
        birth_year,
        username,
        password
    })
        .then(response => {

            dispatch({ type: REGISTRATION_SUCCESS, payload: response.data })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'User created successfully.  Please login to join your Team(s).' } })

            history.push(LOGIN)

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            const response_message = error.message
            const { status } = response
            const { message } = response.data

            if(response && status === 409)
            {
                // Email address already registered.
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Email Address already registered.  Please login and use \'forgot password\' if required.' } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Unable to create your user account.' } })
                history.push(LOGIN)

                ReactGA.event({
                    category: 'User',
                    action: 'Create User',
                    value: 100
                })
            }
            else if(response && response.data.hasOwnProperty('message'))
            {
                // Server was able to generate a valid response.
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Unable to create your user account.' } })
            }
            else
            {
                // Unable to contact the server or server was unable to generate a response
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Unable to create your user account. Please try again.' } })
            }

            dispatch({ type: REGISTRATION_FAILURE, payload: { message: message } })
        })

}

export const updateTeamRegistrationCode = (team_registration_code) => {
    return { type: UPDATE_TEAM_REGISTRATION_CODE, payload: { team_registration_code } }
}

export const requestTeam = () => async (dispatch, getState) => {
    console.log('** Request Team')

    const { team_registration_code } = getState().registration
    const { user_id, token } = getState().user

    dispatch({ type: REQUEST_TEAM_REQUEST })

    await streams.post(`/teams/request/`, {
        user_id,
        token,
        team_registration_code
    })
        .then(response => {

            dispatch({ type: REQUEST_TEAM_SUCCESS, payload: response.data })

            var message = ''

            switch(response.data.status)
            {
                case 'added':
                    message = 'Team registration requested.  You\'re team manager will need to approve your request.  Please check back later.'
                    // Reload the statuses
                    dispatch(getTeamRegistrationStatuses())
                    break

                case 'pending':
                    message = 'Your registration has been submitted previously and is still waiting on your Team Manager to respond.  Maybe give them a nudge.'
                    break

                case 'approved':
                    message = 'Ummm... you\'re actually already part of this team, no need to register again.'
                    break

                case 'rejected':
                    message = 'Well this is awkward. Your registration to this team was rejected.  I\'m sure it\'s just some sort of misunderstanding, best to reach out to your Team Manager to fix it up.'
                    break

                default:
                    message = "You shouldn't be seeing this message.  But if by chance you are and the thing you did didn't do what you expected it to please email 321Banter to let us know.  Thanks."
                    break
            }

            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', duration: 12000, message } })

            ReactGA.event({
                category: 'User',
                action: 'Request Team Registration',
                value: 10
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            console.log('response', response)

            if(response && response.hasOwnProperty('status') && response.status === 404)
            {
                message = "We couldn't find a Team with that Code.  Try again and if you still see this message confirm the Code with your Team Manager."
            }
            else
            {
                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to submit your registration request - ' + message
            }

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: REQUEST_TEAM_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }

        })
}

// Get all the Teams that a user is registered to and the status
export const getTeamRegistrationStatuses = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Registration Statuses')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_REGISTRATION_STATUSES_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/teams-registrations`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS, payload: { teams_registrations: response.data.teams_registrations } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(getTeamRegistrationStatuses(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load attendance - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_REGISTRATION_STATUSES_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}