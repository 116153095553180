import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBack from '@material-ui/icons/ArrowBack'
import EmojiPeople from '@material-ui/icons/EmojiPeople'
import AuthButton from '../auth/AuthButton'
import { slideOpen, slideClose } from '../../actions'
import { Box } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'

// Navigation
import {
    HOME
} from '../../navigation'

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    fullHeight: {
        height: '100%'
    },
    logo: {
        maxHeight: '30px',
        width: 'auto'
    },
    topOfThePile: {
        zIndex: '1201'
    },
    backgroundDark: {
        backgroundColor: '#102d3e',
        color: '#fff'
    }
}))


const burger_pages = [
    '/Leaderboard'
]

const NavAppBar = (props) => {
    const classes = useStyles()

    const history = useHistory()

    const { is_logged_in } = props
    var { current_page } = props

    // The first page load doesn't trigger the current page to be added to the store so get it from the router.
    // Solves the issue of the Leaderboard being refreshed and showing the back arrow rather than the burger
    const location = useLocation()
    if(current_page.length === 0)
    {
        current_page = location.pathname === '/' ? '/Leaderboard' : location.pathname
    }

    const toggleSideNav = () => {
        const { sideNavShow, slideClose, slideOpen } = props

        if(sideNavShow)
        {
            slideClose()
        }
        else
        {
            slideOpen()
        }
    }

    return (
        <AppBar className={`${classes.topOfThePile} ${classes.backgroundDark}`} >
            <Toolbar>
                {
                    is_logged_in ?
                        burger_pages.includes(current_page) ?
                            <IconButton onClick={toggleSideNav} edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
                                <MenuIcon />
                            </IconButton>
                            :
                            <IconButton onClick={() => { history.goBack() }} edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
                                <ArrowBack />
                            </IconButton>
                        :
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
                            <EmojiPeople />
                        </IconButton>
                }
                <Box className={classes.title}>
                    <Link to={HOME} style={{ textDecoration: 'none', color: 'inherit' }} >
                        <img alt="" className={`${classes.logo}`} src={`${process.env.REACT_APP_API_URL}/images/logo/logo-lg.png`} />
                    </Link>
                </Box>
                <AuthButton />
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
    return {
        sideNavShow: state.navigation.sideNavShow,
        is_logged_in: state.user.is_logged_in,
        current_page: state.ui.current_page
    }
}

export default connect(mapStateToProps, { slideOpen, slideClose })(NavAppBar)
