import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Avatar, Box  } from '@material-ui/core/'

import { RedChip, GreenChip, BlueChip, OrangeChip, YellowChip } from '../../ui/Chips'
import { stat_type_icons } from '../../ui/Icons'


import PlayerStatLoading from '../../loading/PlayerStatLoading';
// import shadows from '@material-ui/core/styles/shadows';
// import { CSSTransition } from 'react-transition-group';

const styles = theme => ({
    paper: {
      padding: theme.spacing(1),
      border: 0,
      boxShadow: 'none',
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    fullWidthCard: {
        width: '100%',
        justifyContent: 'flex-start',
        textAlign: 'center'
    },
    highlight: {
        color: theme.palette.primary.main
    }
  });


const PlayerStat = (props) => {
    const { classes } = props;

    const { player, loadPlayer, player_id, stat_total, amount_icon, amount_icon_color, amount_label, colour } = props

    const chips = {
        'Green': GreenChip,
        'Orange': OrangeChip,
        'Red': RedChip,
        'Blue': BlueChip,
        'Yellow': YellowChip
    }

    const AvatarIcon = stat_type_icons[amount_icon].icon ? stat_type_icons[amount_icon].icon : stat_type_icons['FaTrophy'].icon;
    const avatar_icon_styles = amount_icon_color ? {color: `#${amount_icon_color}`} : {}

    const Chip = chips[colour] ? chips[colour] : chips['Green']

    // Check if the player has been loaded
    useEffect(() => {
        if(!player)
        {
            loadPlayer(player_id)
        }
    }, [player_id])

    if(!player)
    {
        return <PlayerStatLoading amount_icon={amount_icon} amount_icon_color={amount_icon_color} amount_label={amount_label} colour={colour} />
    }


    return (
        <>
        <Box m={1} textAlign="center">
            <Typography component="div" variant="subtitle1">
                {
                    player.nickname ?
                        <>
                            {player.first_name}<br/><span className={`${classes.highlight}`}>{player.nickname}</span><br/>{player.last_name}
                        </>
                        :
                        <>
                            {player.first_name}<br/>{player.last_name}
                        </>
                }
            </Typography>
            <Avatar alt="" src={`data:image/jpeg;base64, ${player.avatar}`} className={`${classes.bigAvatar} ${classes.square}`} >
            <Avatar alt="" src={`https://avatars.dicebear.com/api/${player.avatar_gender}/${player_id}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />

            </Avatar>
            {/* <CSSTransition in={true} timeout={4000} classNames="stat-chip"> */}
            <Box mt={0.5}>
                <Chip color="secondary" avatar={<Avatar><AvatarIcon style={avatar_icon_styles} /></Avatar>} label={`${stat_total} ${amount_label}`} className={classes.fullWidthCardx} />
            </Box>
            {/* </CSSTransition> */}
        </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { player_id } = ownProps

    return {
        player: state.player[player_id]
    };
}

export default connect(mapStateToProps, { loadPlayer })(withStyles(styles)(PlayerStat))

