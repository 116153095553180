import {
    LOAD_SEASONS_SUCCESS,
    UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD,
    LOAD_SEASON_SETTINGS_SUCCESS,
    LOAD_SEASON_RESULTS_SUCCESS, LOAD_TEAM_SEASONS_SUCCESS,
    UPDATE_SEASON_ACTIVE_STATUS_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {

    switch(action.type)
    {

        case LOAD_SEASONS_SUCCESS:
            {
                console.log('-- Load Seasons')

                const { seasons } = action.payload

                return seasons
            }

        case UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD:
            {
                const { team_season_id, field_name, value } = action.payload

                const updated_state = { ...state }

                const season_data = updated_state.hasOwnProperty(team_season_id) ? updated_state[team_season_id] : {}
                const admin_data = updated_state.hasOwnProperty(team_season_id) && updated_state[team_season_id].hasOwnProperty('admin') ? updated_state[team_season_id].admin : {}
                const options_data = updated_state.hasOwnProperty(team_season_id) && updated_state[team_season_id].hasOwnProperty('admin') && updated_state[team_season_id].admin.hasOwnProperty('leaderboard_settings') ? updated_state[team_season_id].admin.leaderboard_settings : {}

                return {
                    ...updated_state,
                    [team_season_id]:
                    {
                        ...season_data,
                        admin:
                        {
                            ...admin_data,
                            leaderboard_settings:
                            {
                                ...options_data,
                                [field_name]: value
                            }
                        }
                    }
                }
            }

        case LOAD_SEASON_SETTINGS_SUCCESS:
            {
                const { team_season_id, season_settings } = action.payload

                const updated_state = { ...state }

                const season_data = updated_state.hasOwnProperty(team_season_id) ? updated_state[team_season_id] : {}
                const admin_data = updated_state.hasOwnProperty(team_season_id) && updated_state[team_season_id].hasOwnProperty('admin') ? updated_state[team_season_id].admin : {}

                return {
                    ...updated_state,
                    [team_season_id]:
                    {
                        ...season_data,
                        admin:
                        {
                            ...admin_data,
                            leaderboard_settings: season_settings
                        }
                    }
                }
            }

        case LOAD_SEASON_RESULTS_SUCCESS:
            {
                console.log('results-action.payload', action.payload)
                const { team_season_id, season_results } = action.payload

                const updated_state = { ...state }

                const season_data = updated_state.hasOwnProperty(team_season_id) ? updated_state[team_season_id] : {}
                const admin_data = updated_state.hasOwnProperty(team_season_id) && updated_state[team_season_id].hasOwnProperty('admin') ? updated_state[team_season_id].admin : {}
                const results_data = updated_state.hasOwnProperty(team_season_id) && updated_state[team_season_id].hasOwnProperty('admin') && updated_state[team_season_id].admin.hasOwnProperty('results') ? updated_state[team_season_id].admin.results : {}

                return {
                    ...updated_state,
                    [team_season_id]:
                    {
                        ...season_data,
                        admin:
                        {
                            ...admin_data,
                            results: season_results
                        }
                    }
                }
            }

        case LOAD_TEAM_SEASONS_SUCCESS:
            {
                console.log('-- Load Team Seasons')

                const { team_id, team_seasons } = action.payload

                var updated_state = { ...state }

                for(const team_season_id in team_seasons)
                {
                    if(team_seasons.hasOwnProperty(team_season_id))
                    {

                        const { team_id, club_name, start_date, end_date, age_group, division, active } = team_seasons[team_season_id]

                        const team_season_id_data = updated_state.hasOwnProperty(team_season_id) ? updated_state[team_season_id] : {}

                        updated_state = {
                            ...updated_state,
                            [team_season_id]:
                            {
                                ...team_season_id_data,
                                team_id,
                                club_name,
                                start_date,
                                end_date,
                                age_group,
                                division,
                                active
                            }
                        }
                    }
                }

                return updated_state
            }

        case UPDATE_SEASON_ACTIVE_STATUS_SUCCESS:
            {
                const { team_season_id } = action.payload

                const updated_state = { ...state }

                const season_data = updated_state.hasOwnProperty(team_season_id) ? updated_state[team_season_id] : {}
                season_data.active = false

                return {
                    ...updated_state,
                    [team_season_id]:
                    {
                        ...season_data
                    }
                }
            }


        default:
            return state
    }
}