import React from "react"
import { connect } from 'react-redux'
import { incrementRoundsShowCount, updateRoundAttendancePlayerStatusToShow } from '../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Fab, Tooltip  } from '@material-ui/core/'
import { red, orange, green } from "@material-ui/core/colors";
import PlayerHeaderBasic from "../player/PlayerHeaderBasic";
import { LightTooltip, TooltipGreen, TooltipPrimary, TooltipOrange, TooltipRed } from "../ui/Tooltips";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import RoundAttendancePlayerList from "./RoundAttendancePlayerList";



const styles = theme => ({
    showRoundResults: {
        textAlign: 'center',
        borderRadius: "15px",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    red: {
        backgroundColor: red[500],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[600],
            borderColor: red[500],
        },
    },
    orange: {
        backgroundColor: orange[500],
        borderColor: orange[400],
        '&:hover': {
            backgroundColor: orange[600],
            borderColor: orange[500],
        },
    },
    green: {
        backgroundColor: green[500],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[600],
            borderColor: green[500],
        },
    },
    redText: {
        color: red[500],
        '&:hover': {
            color: red[600]
        }
    },
    orangeText: {
        color: orange[500],
        '&:hover': {
            color: orange[600]
        }
    },
    greenText: {
        color: green[500],
        '&:hover': {
            color: green[600]
        }
    }

})

function RoundAttendanceCounts(props) {
    console.log('[] RoundAttendanceCounts');

    const { show_load_players, round_id } = props;
    const { classes, updateRoundAttendancePlayerStatusToShow, attendance, status_to_show } = props //Connect

    var counts = {
        in: 0,
        out: 0,
        maybe: 0,
        nfi: 0
    }

    for( var player_id in attendance )
    {
        const player = attendance[player_id]

        switch (player.status)
        {
            case 'I':
                counts.in = counts.in + 1
                break
            case 'M':
                counts.maybe = counts.maybe + 1
                break
            case 'O':
                counts.out = counts.out + 1
                break
            default:
                counts.nfi = counts.nfi + 1
        }
    }

    const updateShowStatus = status => {
        if(show_load_players)
        {
            updateRoundAttendancePlayerStatusToShow(round_id, status)
        }
    }

    return (
        <Box my={2}>
            <Grid container
                justify="space-around"
            >
                <Grid item>
                    <TooltipGreen title="In" placement="top">
                        <Fab size="large" color="secondary" aria-label="add" className={`${classes.fab} ${classes.green}`} onClick={() => {updateShowStatus('I')}}>
                            {counts.in}
                        </Fab>
                    </TooltipGreen>
                </Grid>
                <Grid item>
                    <TooltipOrange title="Maybe" placement="top">
                        <Fab size="large" color="secondary" aria-label="add" className={`${classes.fab} ${classes.orange}`} onClick={() => {updateShowStatus('M')}}>
                            {counts.maybe}
                        </Fab>
                    </TooltipOrange>
                </Grid>
                <Grid item>
                    <TooltipRed title="Out" placement="top">
                        <Fab size="large" color="secondary" aria-label="add" className={`${classes.fab} ${classes.red}`} onClick={() => {updateShowStatus('O')}}>
                            {counts.out}
                        </Fab>
                    </TooltipRed>
                </Grid>
                <Grid item>
                    <TooltipPrimary title="NFI" placement="top">
                        <Fab size="large" color="primary" aria-label="add" className={`${classes.fab}`} onClick={() => {updateShowStatus('U')}}>
                            {counts.nfi}
                        </Fab>
                    </TooltipPrimary>
                </Grid>
            </Grid>
            <Grid container
                justify="space-around"
            >
                <Grid item
                    style={{minWidth: '24px'}}
                >
                    {show_load_players && counts.in ?
                    <TooltipGreen title="View Players" placement="bottom">
                        {status_to_show === 'I' ? <ArrowDropDownIcon className={`${classes.greenText}`} /> : <ArrowDropUpIcon className={`${classes.greenText}`} />}
                    </TooltipGreen> : ' '
                    }
                </Grid>
                <Grid item
                    style={{minWidth: '24px'}}
                >
                    {show_load_players && counts.maybe ?
                    <TooltipOrange title="View Players" placement="bottom">
                        {status_to_show === 'M' ? <ArrowDropDownIcon className={`${classes.orangeText}`} /> : <ArrowDropUpIcon className={`${classes.orangeText}`} />}
                    </TooltipOrange> : ' '
                    }
                </Grid>
                <Grid item
                    style={{minWidth: '24px'}}
                >
                    {show_load_players && counts.out ?
                    <TooltipRed title="View Players" placement="bottom">
                        {status_to_show === 'O' ? <ArrowDropDownIcon className={`${classes.redText}`} /> : <ArrowDropUpIcon className={`${classes.redText}`} />}
                    </TooltipRed> : ' '
                    }
                </Grid>
                <Grid item
                    style={{minWidth: '24px'}}
                >
                    {show_load_players && counts.nfi ?
                    <TooltipPrimary title="View Players" placement="bottom">
                        {status_to_show === 'U' ? <ArrowDropDownIcon  color="primary" /> : <ArrowDropUpIcon color="primary" />}
                    </TooltipPrimary> : ' '
                    }
                </Grid>
            </Grid>
        </Box>
    )

}

const mapStateToProps = (state, ownProps) => {

    const { round_id } = ownProps

    return {
        status_to_show: state.ui.hasOwnProperty('round') && state.ui.round.hasOwnProperty(round_id) && state.ui.round[round_id].hasOwnProperty('attendance') && state.ui.round[round_id].attendance.hasOwnProperty('status_to_show')
            ? state.ui.round[round_id].attendance.status_to_show
            : false,
        attendance: state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('attendance')
            ? state.round[round_id].attendance
            : {}
    };
}

export default withStyles(styles)(connect(mapStateToProps, { updateRoundAttendancePlayerStatusToShow })(RoundAttendanceCounts))
