import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import TeamSeasonListItem from '../TeamSeasonListItem'
import { loadTeamSeason, loadManagedTeams, updateSeasonActiveStatus } from '../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { Link } from 'react-router-dom'

// Navigation
import {
    SEASON_ADMIN,
    SEASON_UPDATE,
    SEASON_PLAYER_REGISTRATION,
    ROUND_STATS,
    OPPOSITION_ADMIN,
    ROUND_CREATE,
    ROUNDS_ADMIN,
    LEADERBOARD_SETTINGS, SEASON_LEADERBOARD, SEASON_RESULTS
} from '../../../navigation'
import { RedButton } from '../../ui/Buttons'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
    navRoundDayNumber: {
        FontWeight: "bold",
        FontSize: "2.6em",
        lineHeight: "1em"
    },
    highlight: {
        color: theme.palette.primary.main
    },
    month: {
        fontSize: '2.6em',
        lineHeight: '1em'
    },
    teamName: {
        fontSize: '1.6em'
    },
    ageDivision: {
        fontSize: '1.4em'
    }
})


const TeamSeason = props => {
    console.log('[] Team Season')

    const { team_id, team_season_id } = props.match.params

    const { classes, updateSeasonActiveStatus, season } = props // Connect

    const [open, setOpen] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(!season || !season.hasOwnProperty(team_season_id))
        {
            loadTeamSeason(team_season_id)
        }
    }, [team_season_id])

    const { active, start_date, end_date } = season

    var start_dt = new Date(Number(start_date) * 1000)

    const start_month = start_dt.toLocaleString('default', { month: 'short' })
    const start_day_no = start_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const start_day_text = start_dt.toLocaleString(window.navigator.language, { weekday: 'short' })
    const start_year = start_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    var end_dt = new Date(Number(end_date) * 1000)

    const end_month = end_dt.toLocaleString('default', { month: 'short' })
    const end_day_no = end_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const end_day_text = end_dt.toLocaleString(window.navigator.language, { weekday: 'short' })
    const end_year = end_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    return (
        <>
            <Box mt={2} mb={1}>
                <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
            </Box>

            <Divider />

            {
                active ?
                    <>
                        <Box my={2} mx={1}>
                            <Link to={`${SEASON_UPDATE}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Update Details
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${SEASON_PLAYER_REGISTRATION}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Manage Player Registrations
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${LEADERBOARD_SETTINGS}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Leaderboard Settings
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${OPPOSITION_ADMIN}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Manage Opposition
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${ROUNDS_ADMIN}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Manage Rounds
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${ROUND_CREATE}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}>
                                    Create Round
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Box my={2} mx={1}>
                            <RedButton type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}
                                onClick={() => { setOpen(true) }}>
                                End Season
                        </RedButton>
                        </Box>
                    </>

                    :
                    // Inactive Season
                    <>

                        <Box my={4} mx={1}>
                            <Link to={`${SEASON_RESULTS}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}>
                                    View Season Results
                            </Button>
                            </Link>
                        </Box>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Box my={2} mx={1}>
                            <Link to={`${LEADERBOARD_SETTINGS}/${team_id}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Leaderboard Settings
                            </Button>
                            </Link>
                        </Box>
                    </>
            }


            <Dialog
                open={(open)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Permanently End the Season?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ending the season will not allow you to create any new Rounds in this Season and will show the Season Voting Results.  This can not be undone so ensure there are not more rounds or the finals left to play.<br />
                        <b>Are you sure you want to close this Season?</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }} color="primary" autoFocus>
                        No, we still have Rounds to play
                    </Button>
                    <RedButton onClick={() => { setOpen(false); updateSeasonActiveStatus(team_season_id, false) }} color="primary">
                        Yes, close the Season, we've played all the rounds
                    </RedButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id, team_season_id } = ownProps.match.params

    return {
        season: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) && state.admin.team[team_id].hasOwnProperty('seasons') && state.admin.team[team_id].seasons.hasOwnProperty(team_season_id) ? state.admin.team[team_id].seasons[team_season_id] : {}
        // season: state.season.hasOwnProperty( team_season_id ) ? state.season[ team_season_id ] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeason, loadManagedTeams, updateSeasonActiveStatus })(TeamSeason))


