import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography, Divider } from '@material-ui/core'

import { createOpponent, updateCreateOpponentFormFieldValue, loadManagedTeams, loadTeamSeasonOpposition, loadTeamOpposition } from '../../../actions'
import OppositionList from './OppositionList'
import OppositionListPastSeasons from './OppositionListPastSeasons'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuOpponentName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const CreateOpponent = props => {

    let team_season_id

    if(props.hasOwnProperty('match') && props.match.hasOwnProperty('params'))
    {
        team_season_id = props.match.params.team_season_id
    }
    else
    {
        team_season_id = props.team_season_id
    }

    const history = useHistory()

    const { classes } = props
    const { createOpponent, updateCreateOpponentFormFieldValue, loadManagedTeams, opposition, season, loadTeamOpposition } = props

    const {
        name,
        registration_code,
        sport_id
    } = props //Form fields

    useEffect(() => {
        console.log('[] Create Opponent')

        loadManagedTeams()
        // loadTeamSeasonOpposition(team_season_id)
    }, [])

    useEffect(() => {
        console.log('[] Create Opponent')

        season && loadTeamOpposition(season.team_id)

    }, [season])

    const onSubmit = () => {
        createOpponent(team_season_id, history)
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Create Opponent</Typography>
                </Box>

                <TextField
                    label="Opponent Name"
                    placeholder="Somewhere Someones"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 100,
                    }}
                    name={`name`}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => { updateCreateOpponentFormFieldValue(e.target.name, e.target.value) }}
                />

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Create
                    </Button>
                </Box>

                <Divider />

                <Box>
                    <OppositionList opposition={opposition} />
                </Box>

                <Divider />

                <Box>
                    <OppositionListPastSeasons current_team_season_id={team_season_id} />
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let team_season_id

    if(ownProps.hasOwnProperty('match') && ownProps.match.hasOwnProperty('params'))
    {
        team_season_id = ownProps.match.params.team_season_id
    }
    else
    {
        team_season_id = ownProps.team_season_id
    }

    return {
        season: state.hasOwnProperty('season') && state.season.hasOwnProperty(team_season_id) ? state.season[team_season_id] : false,
        name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('opponent') && state.admin.create.opponent.hasOwnProperty('name') ? state.admin.create.opponent.name : '',
        opposition: state.admin.hasOwnProperty('opposition') && state.admin.opposition.hasOwnProperty(team_season_id) ? state.admin.opposition[team_season_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { createOpponent, updateCreateOpponentFormFieldValue, loadManagedTeams, loadTeamSeasonOpposition, loadTeamOpposition })(CreateOpponent))
