import {
    LOAD_TEAM_SEASONS_SUCCESS,
    LOAD_USER_TEAMS_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {

    switch(action.type)
    {

        case LOAD_USER_TEAMS_SUCCESS:
            {
                console.log('-- Load User Teams')

                const { teams } = action.payload

                var updated_state = { ...state }

                for(const team_id in teams)
                {
                    if(teams.hasOwnProperty(team_id))
                    {

                        const team = teams[team_id]

                        console.log('teamreducer id', team_id)
                        console.log('teamreducer team', team)

                        const team_id_data = updated_state.hasOwnProperty(team_id) ? updated_state[team_id] : {}
                        // const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state[team_id].hasOwnProperty('seasons') ? updated_state[team_id].seasons : []

                        updated_state = {
                            ...updated_state,
                            [team_id]:
                            {
                                ...team,
                                ...team_id_data
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_TEAM_SEASONS_SUCCESS:
            {
                console.log('-- Load Team Seasons')

                const { team_id, team_seasons } = action.payload

                var updated_state = { ...state }

                for(const team_season_id in team_seasons)
                {
                    if(team_seasons.hasOwnProperty(team_season_id))
                    {

                        // const { team_id } = team_seasons[team_season_id]

                        const team_id_data = updated_state.hasOwnProperty(team_id) ? updated_state[team_id] : {}
                        const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state[team_id].hasOwnProperty('seasons') ? updated_state[team_id].seasons : []

                        updated_state = {
                            ...updated_state,
                            [team_id]:
                            {
                                ...team_id_data,
                                seasons:
                                    Array.from(new Set([
                                        ...seasons_data,
                                        ...[Number(team_season_id)]
                                    ]))
                            }
                        }
                    }
                }

                return updated_state
            }

        default:
            return state
    }
}