import { withStyles, Button } from "@material-ui/core";
import { green, red, orange } from "@material-ui/core/colors";

export const RedButton = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(red[500]),
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: red[500],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[700],
            borderColor: red[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${red[400]}`,
            background: `linear-gradient(270deg, ${red[400]} 0%, ${red[700]} 100%)`,
            textShadow: `0px 0px 3px ${red[100]}`,
            zIndex: 1
        }
    },
}))(Button);

export const GreenButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[700],
            borderColor: green[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${green[400]}`,
            background: `linear-gradient(270deg, ${green[400]} 0%, ${green[700]} 100%)`,
            textShadow: `0px 0px 3px ${green[100]}`,
            zIndex: 1
        }
    },
}))(Button);

export const OrangeButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        borderColor: orange[400],
        '&:hover': {
            backgroundColor: orange[700],
            borderColor: orange[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${orange[400]}`,
            background: `linear-gradient(270deg, ${orange[400]} 0%, ${orange[700]} 100%)`,
            textShadow: `0px 0px 3px ${orange[100]}`,
            zIndex: 1
        }
    },
}))(Button);