import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, ButtonBase, Grid, ListSubheader, FormControlLabel, Checkbox, FormHelperText, Divider } from '@material-ui/core'

import { loadRoundDetail, updateRound, updateCreateRoundFormFieldValue, loadManagedTeams, loadTeamSeasons, loadTeamSeasonOpposition, queueToast } from '../../../actions'
import Map from '../../maps/Map'

import moment from 'moment'
import {
    DatePicker,
    TimePicker
} from '@material-ui/pickers'
import { FaSquare, FaCheckSquare } from 'react-icons/fa'
import RoundListItem from '../RoundListItem'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const UpdateRound = props => {
    console.log('[] Update Round')

    const round_id = props.match.params.round_id

    const { player } = props
    const { classes } = props
    const { updateRound, loadRoundDetail, updateCreateRoundFormFieldValue, loadManagedTeams, loadTeamSeasons, loadTeamSeasonOpposition, queueToast } = props

    const {
        teams,
        opposition_teams,

        team_season_id,
        supporting_team_season_ids,

        round_number,
        type,
        date,
        time,
        opposition,
        location_name,
        preview_location_name
    } = props //Form fields


    // const team_season_id = team_season_ids.length > 0 ? team_season_ids[0] : ''

    useEffect(() => {

        console.log('[] Update Round')

        // Load the teams that the user manages
        loadManagedTeams()

        //Load the round details
        loadRoundDetail(round_id)

    }, [])

    useEffect(() => {
        console.log('team seasons')
        // Load the team seasons for the team if they haven't already been loaded
        for(const team_id in teams)
        {
            if(teams.hasOwnProperty(team_id))
            {
                if(teams.hasOwnProperty(team_id) && !teams[team_id].hasOwnProperty('seasons'))
                {
                    loadTeamSeasons(team_id)
                }
            }
        }

        // season_options = renderTeamSeasonOptions()

    }, [teams])

    useEffect(() => {

        if(team_season_id)
        {
            loadTeamSeasonOpposition(team_season_id)
        }

    }, [team_season_id])


    //console.log(props)

    const onSubmit = () => {
        updateRound(round_id)
    }

    const renderTeamSeasonOptions = () => {
        var team_output = []

        for(const team_id in teams)
        {
            if(teams.hasOwnProperty(team_id))
            {
                const team = teams[team_id]

                const seasons = team.hasOwnProperty('seasons') ? team.seasons : {}

                for(const season_id in seasons)
                {
                    if(seasons.hasOwnProperty(season_id))
                    {
                        const season = seasons[season_id]

                        const end_date = moment.unix(season.end_date)
                        const start_date = moment.unix(season.start_date)

                        // Only show active seasons - where the end date is in the future
                        if(end_date.diff(moment()) > 0)
                        {
                            team_output.push(
                                <MenuItem key={season_id} value={season_id}><span className={`${classes.menuTeamName}`}>{team.name}</span> &nbsp;&nbsp;{start_date.format('Do MMM, YYYY')} - {end_date.format('Do MMM, YYYY')}</MenuItem>
                            )
                        }
                    }
                }

                // if(team_output.length)
                // {
                //     team_output.unshift(
                //         <ListSubheader key={team_id}>{team.name}</ListSubheader>
                //     )

                //     output.push(team_output)
                // }
            }
        }

        return team_output
    }

    const renderOppositionOptions = () => {

        var ordered_opposition = []

        for(var opposition_id in opposition_teams)
        {
            ordered_opposition.push([opposition_id, opposition_teams[opposition_id].name])
        }

        // Sort the opposition by date
        ordered_opposition.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_opposition)
        {
            const opposition_team = opposition_teams[ordered_ids[0]]

            output.push(
                <MenuItem key={opposition_team.opposition_id} value={opposition_team.opposition_id}>{opposition_team.name}</MenuItem>
            )
        }

        return output
    }

    const renderSupportingTeams = () => {

        var output = []
        console.log('teams', teams)

        if(type === 'T' && supporting_team_season_ids && supporting_team_season_ids.length > 0)
        {
            for(const supporting_team_season_id of supporting_team_season_ids)
            {

                var supporting_team = {}

                for(const team_id in teams)
                {
                    if(teams.hasOwnProperty(team_id))
                    {
                        const team = teams[team_id]
                        if(team.hasOwnProperty('seasons') && team.seasons.hasOwnProperty(supporting_team_season_id))
                        {
                            supporting_team = team
                            break
                        }
                    }
                }

                if(Object.entries(supporting_team).length !== 0)
                {
                    output.push(
                        <FormControlLabel
                            disabled
                            control={<Checkbox icon={<FaSquare />} checkedIcon={<FaCheckSquare />} name="supporting_team_season" />}
                            label={supporting_team.name}
                            checked={true}
                        />
                    )
                }

            }
        }

        if(output.length > 0)
        {
            return (
                <Box mt={3} mb={1}>
                    <Box mb={1} mx={0}>
                        <FormHelperText>Also include these supporting Teams</FormHelperText>
                    </Box>
                    {output}
                </Box>
            )
        }

        return output
    }

    return (
        <>
            <RoundListItem round_id={round_id} round={{}} />

            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Update Round</Typography>
                </Box>

                <FormControl disabled className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                    <InputLabel id="type-select-label">Round Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={type}
                        name="type"
                        onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.value) }}
                    >
                        <MenuItem value="G">Competition Round</MenuItem>
                        <MenuItem value="F">Non-Competition / Friendly Round</MenuItem>
                        <MenuItem value="T">Training</MenuItem>
                    </Select>
                </FormControl>

                <FormControl disabled className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                    <InputLabel id="team-select-label">Team - Season</InputLabel>
                    <Select
                        labelId="team-select-label"
                        id="team-select"
                        value={team_season_id}
                        name="team_season_id"
                        onChange={(e) => {
                            updateCreateRoundFormFieldValue(e.target.name, e.target.value)
                            updateCreateRoundFormFieldValue('opposition', false) // Clear the Opposition selection
                            updateCreateRoundFormFieldValue('time', null) // Clear the time selection
                            loadTeamSeasonOpposition(e.target.value)
                            // queueToast('warning', 'Changing the Team/Season will remove all votes, stats, points and attendance from the round')
                        }}
                    >
                        {renderTeamSeasonOptions()}
                    </Select>
                </FormControl>

                {renderSupportingTeams()}

                <DatePicker
                    name="date"
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    autoOk={true}
                    emptyLabel='DD/MM/YYYY'
                    margin="normal"
                    id="round-date"
                    label="Round Date"
                    value={date}
                    onChange={(date) => { updateCreateRoundFormFieldValue('date', date) }}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                />

                <TimePicker
                    name="time"
                    margin="normal"
                    ampm={false}
                    autoOk={true}
                    emptyLabel='00:00'
                    minutesStep={5}
                    variant="inline"
                    id="round-time"
                    label="Time"
                    value={time}
                    onChange={(time) => { updateCreateRoundFormFieldValue('time', time) }}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                />
                {
                    type === 'G' &&
                    <TextField
                        label="Round Number"
                        placeholder="1"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth} ${classes.spaced}`}
                        inputProps={{
                            maxLength: 11,
                        }}
                        name={`round_number`}
                        autoComplete="off"
                        value={round_number}
                        onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.value) }}
                    />
                }
                {
                    type === 'G' &&
                    <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                        <InputLabel id="opposition-select-label">Opposition</InputLabel>
                        <Select
                            labelId="opposition-select-label"
                            id="opposition-select"
                            value={opposition}
                            name="opposition"
                            onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.value) }}
                        >
                            {/* <MenuItem value="G">Game / Competition Round</MenuItem> */}
                            {renderOppositionOptions()}
                        </Select>
                    </FormControl>
                }

                <form onSubmit={(e) => { e.preventDefault(); updateCreateRoundFormFieldValue('preview_location_name', location_name) }}>

                    <Grid container
                        alignItems="flex-end"
                        wrap="nowrap"
                        justify="space-between"
                    >
                        <Grid item
                            className={` ${classes.grow}`}
                        >
                            <TextField
                                label="Location Name"
                                placeholder=""
                                // error={touched && invalid}
                                // helperText={touched && error}
                                className={`${classes.fullWidth} ${classes.spaced} ${classes.grow} ${classes.paddedRight}`}
                                inputProps={{
                                    maxLength: 100,
                                }}
                                name={`location_name`}
                                type="text"
                                autoComplete="off"
                                value={location_name}
                                onChange={(e) => {
                                    updateCreateRoundFormFieldValue(e.target.name, e.target.value)
                                    updateCreateRoundFormFieldValue('preview_location_name', false) // Clear the map when the locaiton text changes to ensure the user has previewed the map before submitting
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" className={`${classes.fullWidth}`}
                                onClick={() => { updateCreateRoundFormFieldValue('preview_location_name', location_name) }}
                            >
                                Preview
                            </Button>
                        </Grid>
                    </Grid>

                </form>

                <Box mt={2}>
                    <Map location_name={preview_location_name} />
                </Box>

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = state => {

    const team_season_ids = state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') ? state.admin.round.create.team_season_id : []
    const team_season_id = team_season_ids && team_season_ids.length > 0 ? team_season_ids[0] : ''
    const supporting_team_season_ids = team_season_ids && team_season_ids.length > 1 ? team_season_ids.slice(1) : []

    return {
        teams: state.admin.hasOwnProperty('team') ? state.admin.team : {},
        opposition_teams: team_season_id && team_season_id.length && state.admin.hasOwnProperty('opposition') && state.admin.opposition.hasOwnProperty(team_season_id) ? state.admin.opposition[team_season_id] : {},

        team_season_id,
        supporting_team_season_ids,
        round_number: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('round_number') ? state.admin.round.create.round_number : '',
        type: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('type') ? state.admin.round.create.type : 'G',
        date: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('date') ? state.admin.round.create.date : null,
        time: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('time') ? state.admin.round.create.time : null,
        opposition: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('opposition') ? state.admin.round.create.opposition : '',
        round_location: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('round_location') ? state.admin.round.create.round_location : '',
        location_name: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('location_name') ? state.admin.round.create.location_name : '',
        preview_location_name: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('preview_location_name') ? state.admin.round.create.preview_location_name : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadRoundDetail, updateRound, updateCreateRoundFormFieldValue, loadManagedTeams, loadTeamSeasons, loadTeamSeasonOpposition, queueToast })(UpdateRound))
