import axios from 'axios';

var baseURL = process.env.REACT_APP_API_URL
// var baseURL = 'http://localhost/321s_api/'

// if (process.env.NODE_ENV === 'production') {
//     baseURL = 'https://api.321bantr.com/'
// }

export default axios.create({
    baseURL
});