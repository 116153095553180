import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
    navRoundDayNumber: {
        FontWeight: "bold",
        FontSize: "2.6em",
        lineHeight: "1em"
    },
    highlight: {
        color: theme.palette.primary.main
    },
    main: {
        fontSize: '2.6em',
        lineHeight: '1em'
    },
    teamName: {
        fontSize: '1.6em'
    },
    ageDivision: {
        fontSize: '1.4em'
    },
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    },
    blurryTextBlue: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(133,203,244,0.5)'
    }
});


const TeamSeasonListItemLoading = props => {
    console.log('[] Team Season List Item Loading')

    var { key } = props // parent
    const { classes } = props // withStyles

    var start_dt = new Date()

    const start_month = start_dt.toLocaleString('default', { month: 'short' })
    const start_day_no = start_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const start_year = start_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    var end_dt = new Date()
    end_dt.setMonth(end_dt.getMonth() + 3)

    const end_month = end_dt.toLocaleString('default', { month: 'short' })
    const end_day_no = end_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const end_year = end_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    const output = [];


    return (
        <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            wrap='nowrap'
            key={`round-list-item-loading-${key}`}
        >

            <Grid item xs={3}>
                <Grid
                    container
                    direction='column'
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item className={`${classes.highlight} ${classes.blurryTextBlue}`}>FROM</Grid>
                    <Grid item className={`${classes.main} ${classes.blurryTextWhite}`}>{start_year}</Grid>
                    <Grid item className={`${classes.blurryTextWhite}`}>{start_day_no} <span className={`${classes.highlight} ${classes.blurryTextBlue}`}>{start_month.toUpperCase()}</span></Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Box mr={1}>
                    <Grid container
                        direction='column'
                    >
                        <Grid item>
                            <Grid item className={`${classes.blurryTextWhite}`}>
                                Loading Club
                                </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item className={`${classes.teamName} ${classes.highlight} ${classes.blurryTextBlue}`}>
                                Loaders
                                </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item className={`${classes.ageDivision} ${classes.blurryTextWhite}`}>
                                3... 2.. 1...
                                </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Grid
                    container
                    direction='column'
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item className={`${classes.highlight} ${classes.blurryTextBlue}`}>TO</Grid>
                    <Grid item className={`${classes.main} ${classes.blurryTextWhite}`}>{end_year}</Grid>
                    <Grid item className={`${classes.blurryTextWhite}`}>{end_day_no} <span className={`${classes.highlight} ${classes.blurryTextBlue}`}>{end_month.toUpperCase()}</span></Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default withStyles(styles)(TeamSeasonListItemLoading)


