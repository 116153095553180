import {
    ADD_ROUND_PLAYER_STAT_REQUEST,
    ADD_ROUND_PLAYER_STAT_SUCCESS,
    ADD_ROUND_PLAYER_STAT_FAILURE,
    REMOVE_ROUND_PLAYER_STAT_REQUEST,
    REMOVE_ROUND_PLAYER_STAT_SUCCESS,
    REMOVE_ROUND_PLAYER_STAT_FAILURE,
    LOAD_TEAM_ADMIN_STATS_REQUEST,
    LOAD_TEAM_ADMIN_STATS_SUCCESS,
    LOAD_TEAM_ADMIN_STATS_FAILURE,
    LOAD_ROUND_PLAYERS_STATS_REQUEST,
    LOAD_ROUND_PLAYERS_STATS_SUCCESS,
    LOAD_ROUND_PLAYERS_STATS_FAILURE,
    QUEUE_TOAST,
    LOG_OUT,
    FORCED_LOG_OUT,
    INCREMENT_TEAM_SCORE,
    INCREMENT_OPPOSITION_SCORE,
    SAVE_ROUND_SCORE_REQUEST,
    SAVE_ROUND_SCORE_SUCCESS,
    SAVE_ROUND_SCORE_FAILURE,
    UPDATE_CREATE_ROUND_FORM_FIELD,
    CREATE_ROUND_REQUEST,
    CREATE_ROUND_SUCCESS,
    CREATE_ROUND_FAILURE,
    UPDATE_ROUND_REQUEST,
    UPDATE_ROUND_SUCCESS,
    UPDATE_ROUND_FAILURE,
    DELETE_ROUND_REQUEST,
    DELETE_ROUND_SUCCESS,
    DELETE_ROUND_FAILURE,
    CREATE_SEASON_REQUEST,
    CREATE_SEASON_SUCCESS,
    CREATE_SEASON_FAILURE,
    UPDATE_SEASON_REQUEST,
    UPDATE_SEASON_SUCCESS,
    UPDATE_SEASON_FAILURE,
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'
import moment from 'moment'

// Navigation
import {
    HOME, ROUND_CREATE, SEASON_ADMIN
} from '../navigation'
import { loadRound } from './roundActions'

export const addStat = (round_id) => async (dispatch, getState) => {

    dispatch({ type: ADD_ROUND_PLAYER_STAT_REQUEST })

    const { user_id, token } = getState().user

    const { player_id, stat_id } = getState().ui.admin.hasOwnProperty('round_stats') ? getState().ui.admin.round_stats : {}

    var error = false

    if(!player_id)
    {
        error = true
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "You need to select a Player" } })
    }

    if(!stat_id)
    {
        error = true
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "You need to select a Stat" } })
    }

    if(error)
    {
        return
    }

    const stat_amount =
        getState().admin.hasOwnProperty('round')
            && getState().admin.round.hasOwnProperty(round_id)
            && getState().admin.round[round_id].hasOwnProperty('players_stats')
            && getState().admin.round[round_id].players_stats.hasOwnProperty(stat_id)
            && getState().admin.round[round_id].players_stats[stat_id].hasOwnProperty(player_id)
            ? getState().admin.round[round_id].players_stats[stat_id][player_id].stat_amount
            : 0


    await streams.post(`/rounds/player_stat/${round_id}`, {
        user_id,
        token,
        player_id,
        stat_id,
        client_stat_amount: stat_amount ? stat_amount : 0, //To compare to server to ensure other users haven't also made updates
        add: 1
    })
        .then(response => {

            const { sync_status, stat_amount } = response.data

            if(sync_status)
            {
                dispatch({ type: ADD_ROUND_PLAYER_STAT_SUCCESS, payload: { round_id, stat_id, player_id, stat_amount } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: "Stat added" } })
            }
            else
            {
                dispatch({ type: ADD_ROUND_PLAYER_STAT_SUCCESS, payload: { round_id, stat_id, player_id, stat_amount } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "Looks like your not the only one updating stats.  Stats are updated here now have a look and try again if required.", duration: 12000 } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "Stat not added." } })
            }

            ReactGA.event({
                category: 'Round Admin',
                action: 'Add Round Stat',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to add the stat - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: ADD_ROUND_PLAYER_STAT_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const removeStat = (round_id) => async (dispatch, getState) => {

    dispatch({ type: REMOVE_ROUND_PLAYER_STAT_REQUEST })

    const { user_id, token } = getState().user

    const { player_id, stat_id } = getState().ui.admin.hasOwnProperty('round_stats') ? getState().ui.admin.round_stats : {}

    var error = false

    if(!player_id)
    {
        error = true
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "You need to select a Player" } })
    }

    if(!stat_id)
    {
        error = true
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "You need to select a Stat" } })
    }

    if(error)
    {
        dispatch({ type: REMOVE_ROUND_PLAYER_STAT_FAILURE })
        return
    }

    if(!getState().admin.round[round_id].hasOwnProperty('players_stats') ||
        !getState().admin.round[round_id].players_stats.hasOwnProperty(stat_id) ||
        !getState().admin.round[round_id].players_stats[stat_id].hasOwnProperty(player_id)
        || Number(getState().admin.round[round_id].players_stats[stat_id][player_id].stat_amount) === 0)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "Nothing to remove" } })
        dispatch({ type: REMOVE_ROUND_PLAYER_STAT_FAILURE })
        return
    }

    const stat_amount =
        getState().admin.hasOwnProperty('round')
            && getState().admin.round.hasOwnProperty(round_id)
            && getState().admin.round[round_id].hasOwnProperty('players_stats')
            && getState().admin.round[round_id].players_stats.hasOwnProperty(stat_id)
            && getState().admin.round[round_id].players_stats[stat_id].hasOwnProperty(player_id)
            ? getState().admin.round[round_id].players_stats[stat_id][player_id].stat_amount
            : 0


    await streams.post(`/rounds/player_stat/${round_id}`, {
        user_id,
        token,
        player_id,
        stat_id,
        client_stat_amount: stat_amount ? stat_amount : 0, //To compare to server to ensure other users haven't also made updates
        add: 0
    })
        .then(response => {

            const { sync_status, stat_amount } = response.data

            if(sync_status)
            {
                dispatch({ type: REMOVE_ROUND_PLAYER_STAT_SUCCESS, payload: { round_id, stat_id, player_id, stat_amount } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: "Stat removed" } })
            }
            else
            {
                dispatch({ type: REMOVE_ROUND_PLAYER_STAT_SUCCESS, payload: { round_id, stat_id, player_id, stat_amount } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "Looks like your not the only one updating stats.  Stats are updated here now have a look and try again if required.", duration: 12000 } })
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: "Stat not removed." } })
            }

            ReactGA.event({
                category: 'Round Admin',
                action: 'Remove Round Stat',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to add the stat - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: REMOVE_ROUND_PLAYER_STAT_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const loadTeamStats = (team_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Stats')

    // // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_ADMIN_STATS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/stats/${team_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAM_ADMIN_STATS_SUCCESS, payload: { team_id, stats: response.data.stats } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamStats(team_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load player details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_ADMIN_STATS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadRoundPlayersStats = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Rounds Players Stats')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_PLAYERS_STATS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/player_stats/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_PLAYERS_STATS_SUCCESS, payload: { round_id, stats: response.data.round_player_stats } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRoundPlayersStats(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load the player stats - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_PLAYERS_STATS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const incrementTeamScore = (round_id, increment) => {
    console.log('** Increment Round Team Score')

    return {
        type: INCREMENT_TEAM_SCORE,
        payload: { round_id, increment }
    }
}

export const incrementOppositionScore = (round_id, increment) => {
    console.log('** Increment Round Opposition Score')

    return {
        type: INCREMENT_OPPOSITION_SCORE,
        payload: { round_id, increment }
    }
}

export const saveRoundScore = (round_id) => async (dispatch, getState) => {
    console.log('** Load Round Leaderboard')

    dispatch({ type: SAVE_ROUND_SCORE_REQUEST })

    const { user_id, token } = getState().user
    const { goals_for, goals_against } = getState().round[round_id].details

    await streams.post(`/rounds/score/${round_id}`, {
        user_id,
        token,
        goals_for,
        goals_against
    })
        .then(response => {

            dispatch({ type: SAVE_ROUND_SCORE_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Score saved successfully' } })

            ReactGA.event({
                category: 'Round Admin',
                action: 'Save Round Result',
                value: 20
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed save score - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: SAVE_ROUND_SCORE_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const createRound = () => async (dispatch, getState) => {
    console.log('** Create Round')

    // Check all form fields have been completed.
    const { team_season_id, type, date, create_multiple_training_rounds, end_date, time, round_number, opposition, location_name, preview_location_name, supporting_team_seasons } = getState().admin.round.create

    if(!team_season_id
        || !type
        || !date
        || !time
        || (type === 'G' && !round_number)
        || (type === 'G' && !opposition)
        || (type === 'T' && create_multiple_training_rounds && !end_date)
        || !location_name
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    // Check that the preview_location_name is not false meaning that the user is looking at correct preview for their locaiton.
    if(preview_location_name === false)
    {
        //Fail
        // Trigger an update to the preview_location_name to render the map.  Toast to explain they need to confirm the map preview, then save.
        dispatch({ type: UPDATE_CREATE_ROUND_FORM_FIELD, payload: { field_name: 'preview_location_name', value: location_name } })
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'We\'ve just updated the map preview based on your "Locaiton Name" please check it and then click create again.', duration: 10000 } })
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Round Not Created' } })

        return
    }

    dispatch({ type: CREATE_ROUND_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/rounds/`, {
        user_id,
        token,
        team_season_id,
        type,
        date: moment(date).format('YYYY-MM-DD'),
        end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : false,
        time: moment(time).format('HH:mm'),
        round_number,
        opposition,
        location_name,
        supporting_team_seasons
    })
        .then(response => {

            dispatch({ type: CREATE_ROUND_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Round Created Successfully' } })

            ReactGA.event({
                category: 'Round Admin',
                action: 'Create Round',
                value: 50
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create round - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: CREATE_ROUND_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const updateRound = (round_id) => async (dispatch, getState) => {
    console.log('** Update Round')

    // Check all form fields have been completed.
    const { team_season_id, type, date, time, round_number, opposition, location_name, preview_location_name } = getState().admin.round.create

    if(!team_season_id
        || !type
        || !date
        || !time
        || (type === 'G' && !round_number)
        || (type === 'G' && !opposition)
        || !location_name
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    // Check that the preview_location_name is not false meaning that the user is looking at correct preview for their locaiton.
    if(preview_location_name === false)
    {
        //Fail
        // Trigger an update to the preview_location_name to render the map.  Toast to explain they need to confirm the map preview, then save.
        dispatch({ type: UPDATE_CREATE_ROUND_FORM_FIELD, payload: { field_name: 'preview_location_name', value: location_name } })
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'We\'ve just updated the map preview based on your "Locaiton Name" please check it and then click update again.', duration: 10000 } })
        dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Round not updated' } })

        return
    }

    dispatch({ type: UPDATE_ROUND_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/rounds/${round_id}`, {
        user_id,
        token,
        team_season_id,
        type,
        date: moment(date).format('YYYY-MM-DD'),
        time: moment(time).format('HH:mm'),
        round_number,
        opposition,
        location_name
    })
        .then(response => {

            dispatch({ type: UPDATE_ROUND_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Round Updated Successfully' } })

            dispatch(loadRound(round_id))

            ReactGA.event({
                category: 'Round Admin',
                action: 'Update Round',
                value: 20
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update round - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_ROUND_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const deleteRound = (round_id, history) => async (dispatch, getState) => {
    console.log('** Delete Round')

    console.log('history action', history)

    dispatch({ type: DELETE_ROUND_REQUEST })

    const { user_id, token } = getState().user

    await streams.delete(`/rounds/${round_id}`, {
        data: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: DELETE_ROUND_SUCCESS, payload: { round_id } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Round Deleted Successfully' } })

            ReactGA.event({
                category: 'Round Admin',
                action: 'Update Round',
                value: 10
            })

            history.push(HOME)
            // history.goBack()
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to delete round - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: DELETE_ROUND_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const createSeason = (team_id, history) => async (dispatch, getState) => {
    console.log('** Create Season')

    // Check all form fields have been completed.
    const { club_name, start_date, end_date, age_group, division } = getState().admin.create.season

    if(!club_name
        || !start_date
        || !end_date
        || !age_group
        || !division
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: CREATE_SEASON_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/teams/season`, {
        user_id,
        token,
        team_id,
        club_name,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        age_group,
        division
    })
        .then(response => {

            const { team_season_id } = response.data

            dispatch({ type: CREATE_SEASON_SUCCESS, payload: { team_season_id, team_id, club_name, start_date, end_date, age_group, division, active: true } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Season Created Successfully' } })

            ReactGA.event({
                category: 'Team Admin',
                action: 'Create Season',
                value: 100
            })

            history.push(`${ROUND_CREATE}`)
            // history.push(`${SEASON_ADMIN}/${team_id}/${team_season_id}`)
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create round - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: CREATE_SEASON_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const updateSeason = (team_season_id) => async (dispatch, getState) => {
    console.log('** Update Season')

    // Check all form fields have been completed.
    const { club_name, start_date, end_date, age_group, division } = getState().admin.create.season

    if(!club_name
        || !start_date
        || !end_date
        || !age_group
        || !division
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: UPDATE_SEASON_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/teams/season`, {
        user_id,
        token,
        team_season_id,
        club_name,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        age_group,
        division
    })
        .then(response => {

            const { updated, team_id } = response.data

            dispatch({ type: UPDATE_SEASON_SUCCESS, payload: { team_season_id, team_id, club_name, start_date, end_date, age_group, division } })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Season Updated Successfully' } })
            }


            ReactGA.event({
                category: 'Team Admin',
                action: 'Update Season',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update Season - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_SEASON_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}