import {
    TOGGLE_ADMIN_VIEW,
    UPDATE_ROUND_STATS_PLAYER_SELECT,
    UPDATE_ROUND_STATS_STAT_SELECT,
    INCREMENT_ROUND_SHOW_COUNT,
    UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW,
    TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW,
    SET_CURRENT_PAGE,
    TOGGLE_CREATE_OPPONENT_MODAL_SHOW,
    TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW,
    QUEUE_TOAST,
    SET_WEB_PUSH_SUBSCRIPTION,
    SET_WEB_PUSH_SUBSCRIPTION_REQUEST,
    SET_WEB_PUSH_SUBSCRIPTION_SUCCESS,
    SET_WEB_PUSH_SUBSCRIPTION_FAILURE,
    LOG_OUT,
    FORCED_LOG_OUT
} from './types'
import streams from '../apis/streams'

import ReactGA from 'react-ga'
import { delayRetry } from './actionHelpers'

export const setCurrentPage = (page) => (dispatch, getState) => {
    console.log('** Set current page: ' + page)

    // Check if the page has changed and log in GA
    if(page !== getState().ui.current_page)
    {
        console.log("%cGOOGLE Page view: " + page, "color: green;")
        ReactGA.set({ page: page }) // Update the user's current page
        ReactGA.pageview(page) // Record a pageview for the given page
    }

    dispatch({
        type: SET_CURRENT_PAGE,
        payload: { current_page: page }
    })
}

export const queueToast = (status, message) => (dispatch, getState) => {
    console.log('** Queue Toast')

    dispatch({ type: QUEUE_TOAST, payload: { status, message } })
}

export const resetWebPush = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Reset Push Token')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    const { user_id, token, web_push_subscription_endpoint } = getState().user

    if(!user_id) return // Requires user id

    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION, payload: { web_push_subscription_status: 'ask' } })

    if(web_push_subscription_endpoint)
    {
        dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_REQUEST })

        await streams.post(`/user/web-push-subscription`, {
            user_id,
            token,
            optin: false,
            endpoint: web_push_subscription_endpoint
        })
            .then(response => {

                dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_SUCCESS })

            })
            .catch(error => {
                // Catches network errors and not found responses from the API

                if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
                {
                    dispatch(resetWebPush(delay, retry_count ? ++retry_count : 1))
                }
                else
                {
                    const { response } = error

                    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_FAILURE })

                    if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                    {
                        dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                    }
                }
            })
    }

}

export const subscribeWebPush = (endpoint, p256dh, auth, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Set Push Token')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    const { user_id, token, web_push_subscription_endpoint } = getState().user

    var previous_web_push_subscription_endpoint = false

    if(endpoint !== web_push_subscription_endpoint)
    {
        previous_web_push_subscription_endpoint = web_push_subscription_endpoint
    }

    if(!user_id) return // Requires user id

    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION, payload: { web_push_subscription_status: 'allowed', web_push_subscription_endpoint: endpoint } })
    // dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION, payload: { token } })
    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_REQUEST })

    await streams.post(`/user/web-push-subscription`, {
        user_id,
        token,
        optin: true,
        endpoint,
        previous_web_push_subscription_endpoint,
        p256dh,
        auth
    })
        .then(response => {

            dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_SUCCESS })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(subscribeWebPush(endpoint, p256dh, auth, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error

                dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_FAILURE })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })

}

export const unsubscribeWebPush = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Set Push Token')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    const { user_id, token, web_push_subscription_endpoint } = getState().user

    if(!user_id) return // Requires user id

    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION, payload: { web_push_subscription_status: 'denied' } })
    // dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION, payload: { token } })
    dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_REQUEST })

    await streams.post(`/user/web-push-subscription`, {
        user_id,
        token,
        optin: false,
        endpoint: web_push_subscription_endpoint
    })
        .then(response => {

            dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_SUCCESS })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(unsubscribeWebPush(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error

                dispatch({ type: SET_WEB_PUSH_SUBSCRIPTION_FAILURE })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })

}

export const incrementRoundsShowCount = (section, key, increment) => {
    console.log('** increment Rounds Show Count')

    return {
        type: INCREMENT_ROUND_SHOW_COUNT,
        payload: { section, key, increment }
    }
}

export const toggleAdminView = () => {
    console.log('** Toggle admin view')

    return {
        type: TOGGLE_ADMIN_VIEW
    }
}

export const updateRoundStatsPlayerSelect = (player_id) => {
    console.log('** Update Round Stat Player Select')

    return { type: UPDATE_ROUND_STATS_PLAYER_SELECT, payload: { player_id } }
}

export const updateRoundStatsStatSelect = (stat_id) => {
    console.log('** Update Round Stat Stat Select')

    return { type: UPDATE_ROUND_STATS_STAT_SELECT, payload: { stat_id } }
}

export const updateRoundAttendancePlayerStatusToShow = (round_id, status) => {
    console.log('** Update Round Attendance Player Status To Show')

    return { type: UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW, payload: { round_id, status } }
}

export const toggleStatIconSelectorModal = (show) => {
    console.log('** Toggle Team Stat Type Icon Selector Modal')

    return { type: TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW, payload: { show } }
}

export const toggleCreateOpponentModal = (show) => {
    console.log('** Toggle Create Opponent Modal', show)

    return { type: TOGGLE_CREATE_OPPONENT_MODAL_SHOW, payload: { show } }
}

export const toggleUnregisterTeamUserModal = (show) => {
    console.log('** Toggle Unregister User from Team Modal', show)

    return { type: TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW, payload: { show } }
}