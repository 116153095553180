import {
    ADD_ROUND_PLAYER_STAT_SUCCESS,
    REMOVE_ROUND_PLAYER_STAT_SUCCESS,
    LOAD_TEAM_ADMIN_STATS_SUCCESS,
    LOAD_ROUND_PLAYERS_STATS_SUCCESS,
    LOAD_TEAMS_REGISTRATION_SUCCESS,
    LOAD_TEAMS_MANAGED_SUCCESS,
    UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS,
    LOAD_TEAM_SEASONS_SUCCESS,
    LOAD_TEAM_SEASON_SUCCESS,
    LOAD_TEAM_SEASON_DETAIL_SUCCESS,
    LOAD_TEAM_SEASON_REGISTRATION_SUCCESS,
    ADD_SEASON_PLAYER_SUCCESS,
    DEACTIVATE_SEASON_PLAYER_SUCCESS,
    UPDATE_CREATE_ROUND_FORM_FIELD,
    UPDATE_CREATE_SEASON_FORM_FIELD,
    CREATE_SEASON_SUCCESS,
    UPDATE_SEASON_SUCCESS,
    LOAD_TEAM_SEASON_OPPOSITION_SUCCESS,
    LOAD_ROUND_DETAIL_SUCCESS,
    LOAD_SPORTS_SUCCESS,
    LOAD_TEAM_DETAIL_SUCCESS,
    UPDATE_CREATE_TEAM_FORM_FIELD,
    LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS,

    LOAD_OPPONENT_DETAIL_SUCCESS,
    UPDATE_CREATE_OPPONENT_FORM_FIELD,

    UPDATE_ROUND_WHITEBOARD_TEXT,
    LOAD_ROUND_WHITEBOARD_SUCCESS,

    LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS,
    CLEAR_TEAM_STAT_TYPE_FORM_FIELD,
    UPDATE_TEAM_STAT_TYPE_FORM_FIELD,
    SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS,
    UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS,
    CREATE_OPPONENT_SUCCESS,
    LOAD_TEAM_OPPOSITION_SUCCESS,
    ADD_OPPONENT_TEAM_SEASON_SUCCESS
} from '../actions/types'
import moment from 'moment'

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {

    switch(action.type)
    {
        case ADD_ROUND_PLAYER_STAT_SUCCESS:
        case REMOVE_ROUND_PLAYER_STAT_SUCCESS:
            {
                console.log('-- ' + action.type)

                const { round_id, stat_id, player_id, stat_amount } = action.payload

                const round_data = state.hasOwnProperty('round') ? state.round : {}
                const round_id_data = Object.entries(round_data).length !== 0 && state.round.hasOwnProperty(round_id) ? state.round[round_id] : {}
                const players_stats_data = Object.entries(round_id_data).length !== 0 && state.round[round_id].hasOwnProperty('players_stats') ? state.round[round_id].players_stats : {}
                const stat_id_data = Object.entries(players_stats_data).length !== 0 && state.round[round_id].players_stats.hasOwnProperty(stat_id) ? state.round[round_id].players_stats[stat_id] : {}

                return {
                    ...state,
                    round:
                    {
                        ...round_data,
                        [round_id]:
                        {
                            ...round_id_data,
                            players_stats:
                            {
                                ...players_stats_data,
                                [stat_id]:
                                {
                                    ...stat_id_data,
                                    [player_id]:
                                    {
                                        player_id,
                                        stat_id,
                                        stat_amount
                                    }
                                }
                            }
                        }
                    }
                }
            }


        case LOAD_TEAM_ADMIN_STATS_SUCCESS:
            {
                console.log('-- Load Team admin stats')

                const { team_id, stats } = action.payload

                const team_data = state.hasOwnProperty('team') ? state.team : {}
                const team_id_data = Object.entries(team_data).length !== 0 && state.team.hasOwnProperty(team_id) ? state.team[team_id] : {}

                return {
                    ...state,
                    team:
                    {
                        ...team_data,
                        [team_id]:
                        {
                            ...team_id_data,
                            stats: stats
                        }
                    }
                }

            }

        case LOAD_TEAMS_REGISTRATION_SUCCESS:
            {
                console.log('-- Load Teams Registration')

                const { teams_registration } = action.payload

                var updated_state = { ...state }

                for(const team_id in teams_registration)
                {
                    if(teams_registration.hasOwnProperty(team_id))
                    {
                        const team_registration = teams_registration[team_id]

                        const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                        const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}

                        updated_state = {
                            ...updated_state,
                            team:
                            {
                                ...team_data,
                                [team_id]:
                                {
                                    ...team_id_data,
                                    registration: team_registration
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_TEAMS_MANAGED_SUCCESS:
            {
                console.log('-- Load Teams Managed')

                const { teams_managed } = action.payload

                var updated_state = { ...state }

                for(const team_id in teams_managed)
                {
                    if(teams_managed.hasOwnProperty(team_id))
                    {
                        const team_managed = teams_managed[team_id]

                        const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                        const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}

                        updated_state = {
                            ...updated_state,
                            team:
                            {
                                ...team_data,
                                [team_id]:
                                {
                                    ...team_id_data,
                                    team_id: team_managed.team_id,
                                    name: team_managed.name
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS:
            {
                console.log('-- Update User Team Registration Status')

                const { requestor_user_id, team_id, status } = action.payload

                var updated_state = { ...state }

                updated_state = {
                    ...updated_state,
                    team:
                    {
                        ...state.team,
                        [team_id]:
                        {
                            ...state.team[team_id],
                            registration:
                            {
                                ...state.team[team_id].registration,
                                [requestor_user_id]:
                                {
                                    ...state.team[team_id].registration[requestor_user_id],
                                    status
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case CREATE_SEASON_SUCCESS:
            {
                console.log('-- Create Team Season')

                const { team_season_id, team_id, club_name, start_date, end_date, age_group, division, active } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
                const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') ? updated_state.team[team_id].seasons : {}

                updated_state = {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        season: {}
                    },
                    team:
                    {
                        ...team_data,
                        [team_id]:
                        {
                            ...team_id_data,
                            seasons:
                            {
                                ...seasons_data,
                                [team_season_id]:
                                {
                                    club_name,
                                    start_date: moment(start_date).unix(),
                                    end_date: moment(end_date).unix(),
                                    age_group,
                                    division,
                                    active
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case UPDATE_SEASON_SUCCESS:
            {
                console.log('-- Update Team Season')

                const { team_season_id, team_id, club_name, start_date, end_date, age_group, division } = action.payload

                var updated_state = { ...state }

                const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
                const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') ? updated_state.team[team_id].seasons : {}
                const season_data = Object.entries(seasons_data).length !== 0 && updated_state.team[team_id].seasons.hasOwnProperty(team_season_id) ? updated_state.team[team_id].seasons[team_season_id] : {}

                updated_state = {
                    ...updated_state,
                    team:
                    {
                        ...team_data,
                        [team_id]:
                        {
                            ...team_id_data,
                            seasons:
                            {
                                ...seasons_data,
                                [team_season_id]:
                                {
                                    ...season_data,
                                    club_name: club_name,
                                    start_date: moment(start_date).unix(),
                                    end_date: moment(end_date).unix(),
                                    age_group: age_group,
                                    division: division
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_TEAM_SEASONS_SUCCESS:
            {
                console.log('-- Load Team Seasons')

                const { team_id, team_seasons } = action.payload

                var updated_state = { ...state }

                for(const team_season_id in team_seasons)
                {
                    if(team_seasons.hasOwnProperty(team_season_id))
                    {

                        const { club_name, start_date, end_date, age_group, division, active } = team_seasons[team_season_id]

                        const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                        const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
                        const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') ? updated_state.team[team_id].seasons : {}
                        const team_season_id_data = Object.entries(seasons_data).length !== 0 && updated_state.team[team_id].seasons.hasOwnProperty(team_season_id) ? updated_state.team[team_id].seasons[team_season_id] : {}

                        updated_state = {
                            ...updated_state,
                            team:
                            {
                                ...team_data,
                                [team_id]:
                                {
                                    ...team_id_data,
                                    seasons:
                                    {
                                        ...seasons_data,
                                        [team_season_id]:
                                        {
                                            ...team_season_id_data,
                                            club_name,
                                            start_date,
                                            end_date,
                                            age_group,
                                            division,
                                            active
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_TEAM_SEASON_SUCCESS:
            {
                console.log('-- Load Team Season')

                const { team_id, team_season_id, club_name, start_date, end_date, age_group, division, active } = action.payload.team_season

                var updated_state = { ...state }

                const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
                const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') ? updated_state.team[team_id].seasons : {}
                const team_season_data = Object.entries(seasons_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') && updated_state.team[team_id].seasons.hasOwnProperty(team_season_id) ? updated_state.team[team_id].seasons[team_season_id] : {}

                updated_state = {
                    ...updated_state,
                    team:
                    {
                        ...team_data,
                        [team_id]:
                        {
                            ...team_id_data,
                            seasons:
                            {
                                ...seasons_data,
                                [team_season_id]:
                                {
                                    ...team_season_data,
                                    club_name,
                                    start_date,
                                    end_date,
                                    age_group,
                                    division,
                                    active
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_TEAM_SEASON_DETAIL_SUCCESS:
            {
                console.log('-- Load Team Season Detail')

                const { team_season_id, club_name, start_date, end_date, age_group, division } = action.payload.team_season

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                updated_state = {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        season:
                        {
                            team_season_id,
                            club_name,
                            start_date: moment.unix(start_date),
                            end_date: moment.unix(end_date),
                            age_group,
                            division
                        }
                    }
                }

                return updated_state
            }

        case UPDATE_CREATE_SEASON_FORM_FIELD:
            {
                const { field_name, value } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const season_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('season') ? updated_state.create.season : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        season:
                        {
                            ...season_data,
                            [field_name]: value
                        }
                    }
                }
            }

        case LOAD_TEAM_SEASON_REGISTRATION_SUCCESS:
            {
                console.log('-- Load Team Season Registration')

                const { team_id, team_season_id, team_season_registration } = action.payload

                var updated_state = { ...state }

                const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
                const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
                const seasons_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('seasons') ? updated_state.team[team_id].seasons : {}
                const season_id_data = Object.entries(seasons_data).length !== 0 && updated_state.team[team_id].seasons.hasOwnProperty(team_season_id) ? updated_state.team[team_id].seasons[team_season_id] : {}

                updated_state = {
                    ...updated_state,
                    team:
                    {
                        ...team_data,
                        [team_id]:
                        {
                            ...team_id_data,
                            seasons:
                            {
                                ...seasons_data,
                                [team_season_id]:
                                {
                                    ...season_id_data,
                                    registrations: team_season_registration
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case ADD_SEASON_PLAYER_SUCCESS:
            {
                console.log('-- Add season player')

                const { team_id, team_season_id, registration_user_id } = action.payload

                var updated_state = { ...state }

                updated_state = {
                    ...state,
                    team:
                    {
                        ...state.team,
                        [team_id]:
                        {
                            ...state.team[team_id],
                            seasons:
                            {
                                ...state.team[team_id].seasons,
                                [team_season_id]:
                                {
                                    ...state.team[team_id].seasons[team_season_id],
                                    registrations:
                                    {
                                        ...state.team[team_id].seasons[team_season_id].registrations,
                                        [registration_user_id]:
                                        {
                                            ...state.team[team_id].seasons[team_season_id].registrations[registration_user_id],
                                            is_player: true
                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                return updated_state
            }

        case DEACTIVATE_SEASON_PLAYER_SUCCESS:
            {
                console.log('-- Deactivate season player')

                const { team_id, team_season_id, registration_user_id } = action.payload

                var updated_state = { ...state }

                updated_state = {
                    ...state,
                    team:
                    {
                        ...state.team,
                        [team_id]:
                        {
                            ...state.team[team_id],
                            seasons:
                            {
                                ...state.team[team_id].seasons,
                                [team_season_id]:
                                {
                                    ...state.team[team_id].seasons[team_season_id],
                                    registrations:
                                    {
                                        ...state.team[team_id].seasons[team_season_id].registrations,
                                        [registration_user_id]:
                                        {
                                            ...state.team[team_id].seasons[team_season_id].registrations[registration_user_id],
                                            is_player: false
                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                return updated_state
            }


        case LOAD_ROUND_PLAYERS_STATS_SUCCESS:
            {
                console.log('-- Load Round Players Stats')

                const { round_id, stats } = action.payload

                if(!state.hasOwnProperty(round_id))
                {
                    return {
                        ...state,
                        round:
                        {
                            [round_id]:
                            {
                                players_stats: stats
                            }
                        }
                    }
                }
                else
                {
                    return {
                        ...state,
                        round:
                        {
                            ...state.round,
                            [round_id]:
                            {
                                ...state.round[round_id],
                                players_stats: stats
                            }
                        }
                    }
                }
            }

        case UPDATE_CREATE_ROUND_FORM_FIELD:
            {

                const { field_name, value, additional_data } = action.payload

                var updated_state = { ...state }

                const round_data = updated_state.hasOwnProperty('round') ? updated_state.round : {}
                const create_data = Object.entries(round_data).length !== 0 && updated_state.round.hasOwnProperty('create') ? updated_state.round.create : {}
                const supporting_team_seasons = updated_state.hasOwnProperty('round') && updated_state.round.hasOwnProperty('create') && updated_state.round.create.hasOwnProperty('supporting_team_seasons')
                    ? updated_state.round.create.supporting_team_seasons
                    : []

                if(field_name === 'supporting_team_season')
                {
                    const { supporting_team_season_id } = additional_data

                    if(value === false && supporting_team_seasons.includes(supporting_team_season_id))
                    {
                        const index = supporting_team_seasons.indexOf(supporting_team_season_id)
                        supporting_team_seasons.splice(index, 1)
                    }
                    else if(value === true && supporting_team_seasons.includes(supporting_team_season_id) === false)
                    {
                        supporting_team_seasons.push(supporting_team_season_id)
                    }

                    return {
                        ...updated_state,
                        round:
                        {
                            ...round_data,
                            create:
                            {
                                ...create_data,
                                supporting_team_seasons: supporting_team_seasons.slice()
                            }
                        }
                    }
                }

                return {
                    ...updated_state,
                    round:
                    {
                        ...round_data,
                        create:
                        {
                            ...create_data,
                            [field_name]: value,
                            supporting_team_seasons
                        }
                    }
                }
            }

        case LOAD_TEAM_SEASON_OPPOSITION_SUCCESS:
            {
                const { team_season_id, opposition } = action.payload

                var updated_state = { ...state }

                const opposition_data = updated_state.hasOwnProperty('opposition') ? updated_state.opposition : {}

                return {
                    ...updated_state,
                    opposition:
                    {
                        ...opposition_data,
                        [team_season_id]: { ...opposition }
                    }
                }
            }

        case LOAD_TEAM_OPPOSITION_SUCCESS:
            {
                const { opposition } = action.payload

                var updated_state = { ...state }

                for(const opposition_id in opposition)
                {
                    if(Object.hasOwnProperty.call(opposition, opposition_id))
                    {
                        const opponent = opposition[opposition_id]

                        for(const team_season_id of opponent.team_season_ids)
                        {
                            const opposition_data = updated_state.hasOwnProperty('opposition') ? updated_state.opposition : {}
                            const team_season_data = updated_state.hasOwnProperty('opposition') && updated_state.opposition.hasOwnProperty(team_season_id) ? updated_state.opposition[team_season_id] : {}

                            updated_state = {
                                ...updated_state,
                                opposition:
                                {
                                    ...opposition_data,
                                    [team_season_id]:
                                    {
                                        ...team_season_data,
                                        [opposition_id]: { ...opponent }
                                    }
                                }
                            }
                        }

                    }
                }

                console.log('updated_state', updated_state)

                return updated_state
            }

        case ADD_OPPONENT_TEAM_SEASON_SUCCESS:
            {
                const { opposition_id, team_season_id, opposition_name } = action.payload

                var updated_state = { ...state }

                const opposition_data = updated_state.hasOwnProperty('opposition') ? updated_state.opposition : {}
                const team_season_data = updated_state.hasOwnProperty('opposition') && updated_state.opposition.hasOwnProperty(team_season_id) ? updated_state.opposition[team_season_id] : {}

                updated_state = {
                    ...updated_state,
                    opposition:
                    {
                        ...opposition_data,
                        [team_season_id]:
                        {
                            ...team_season_data,
                            [opposition_id]:
                            {
                                team_season_id,
                                opposition_id: String(opposition_id),
                                name: opposition_name
                            }
                        }
                    }
                }

                return updated_state
            }

        case LOAD_OPPONENT_DETAIL_SUCCESS:
            {
                const { opposition_id, name } = action.payload.opponent
                const { team_season_id } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        opponent:
                        {
                            team_season_id,
                            opposition_id,
                            name
                        }
                    }
                }
            }

        case UPDATE_CREATE_OPPONENT_FORM_FIELD:
            {

                const { field_name, value } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const opponent_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('opponent') ? updated_state.create.opponent : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        opponent:
                        {
                            ...opponent_data,
                            [field_name]: value
                        }
                    }
                }
            }

        case CREATE_OPPONENT_SUCCESS:
            {
                console.log('-- Create Opponent Success')

                const { team_season_id, opposition_id, name } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const opposition_data = updated_state.hasOwnProperty('opposition') ? updated_state.opposition : {}
                const team_season_data = Object.entries(opposition_data).length !== 0 && updated_state.opposition.hasOwnProperty(team_season_id) ? updated_state.opposition[team_season_id] : {}

                updated_state = {
                    ...updated_state,
                    opposition:
                    {
                        ...opposition_data,
                        [team_season_id]:
                        {
                            ...team_season_data,
                            [opposition_id]:
                            {
                                opposition_id,
                                name
                            }
                        }
                    },
                    create:
                    {
                        ...create_data,
                        opponent: {}
                    }
                }

                return updated_state
            }

        case LOAD_ROUND_DETAIL_SUCCESS:
            {

                const {
                    team_season_id,
                    round_number,
                    type,
                    date_time,
                    opposition,
                    location_name } = action.payload.round_detail

                var updated_state = { ...state }

                const round_data = updated_state.hasOwnProperty('round') ? updated_state.round : {}

                return {
                    ...state,
                    round:
                    {
                        ...round_data,
                        create:
                        {
                            original_team_season_id: team_season_id,
                            team_season_id,
                            round_number,
                            type,
                            date: moment.unix(date_time),
                            time: moment.unix(date_time),
                            opposition,
                            location_name,
                            preview_location_name: location_name
                        }
                    }
                }
            }

        case LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS:
            {

                const { supporting_team_seasons } = action.payload

                var updated_state = { ...state }

                const round_data = updated_state.hasOwnProperty('round') ? updated_state.round : {}
                const create_data = Object.entries(round_data).length !== 0 && updated_state.round.hasOwnProperty('create') ? updated_state.round.create : {}

                return {
                    ...state,
                    round:
                    {
                        ...round_data,
                        create:
                        {
                            ...create_data,
                            supporting_team_seasons_options: supporting_team_seasons
                        }
                    }
                }
            }

        case UPDATE_CREATE_TEAM_FORM_FIELD:
            {

                const { field_name, value } = action.payload

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const team_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('team') ? updated_state.create.team : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        team:
                        {
                            ...team_data,
                            [field_name]: value
                        }
                    }
                }
            }

        case LOAD_TEAM_DETAIL_SUCCESS:
            {

                const {
                    team_id,
                    name,
                    registration_code,
                    sport_id
                } = action.payload.team_detail

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        team:
                        {
                            team_id,
                            name,
                            registration_code,
                            sport_id
                        }
                    }
                }
            }

        case LOAD_SPORTS_SUCCESS:
            {
                const { sports } = action.payload

                var updated_state = { ...state }

                return {
                    ...state,
                    sports
                }
            }



        // STATS

        // case CREATE_TEAM_STAT_TYPE_SUCCESS:
        // {
        // console.log('-- Create Team Team_stat_type')

        // const { team_stat_type_id, team_id, club_name, start_date, end_date, age_group, division } = action.payload

        // var updated_state = { ...state }

        // const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
        // const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
        // const team_stat_types_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('team_stat_types') ? updated_state.team[team_id].team_stat_types : {}

        // updated_state = {
        //     ...updated_state,
        //     team:
        //     {
        //         ...team_data,
        //         [team_id]:
        //         {
        //             ...team_id_data,
        //             team_stat_types:
        //             {
        //                 ...team_stat_types_data,
        //                 [team_stat_type_id]:
        //                 {
        //                     club_name: club_name,
        //                     start_date: moment(start_date).format('YYYY-MM-DD'),
        //                     end_date: moment(end_date).format('YYYY-MM-DD'),
        //                     age_group: age_group,
        //                     division: division
        //                 }
        //             }
        //         }
        //     }
        // }

        // return updated_state
        // }

        // case UPDATE_TEAM_STAT_TYPE_SUCCESS:
        // {
        // console.log('-- Update Team Team_stat_type')

        // const { team_stat_type_id, team_id, club_name, start_date, end_date, age_group, division } = action.payload

        // var updated_state = { ...state }

        // const team_data = updated_state.hasOwnProperty('team') ? updated_state.team : {}
        // const team_id_data = Object.entries(team_data).length !== 0 && updated_state.team.hasOwnProperty(team_id) ? updated_state.team[team_id] : {}
        // const team_stat_types_data = Object.entries(team_id_data).length !== 0 && updated_state.team[team_id].hasOwnProperty('team_stat_types') ? updated_state.team[team_id].team_stat_types : {}
        // const team_stat_type_data = Object.entries(team_stat_types_data).length !== 0 && updated_state.team[team_id].team_stat_types.hasOwnProperty(team_stat_type_id) ? updated_state.team[team_id].team_stat_types[team_stat_type_id] : {}

        // updated_state = {
        //     ...updated_state,
        //     team:
        //     {
        //         ...team_data,
        //         [team_id]:
        //         {
        //             ...team_id_data,
        //             team_stat_types:
        //             {
        //                 ...team_stat_types_data,
        //                 [team_stat_type_id]:
        //                 {
        //                     ...team_stat_type_data,
        //                     club_name: club_name,
        //                     start_date: moment(start_date).format('YYYY-MM-DD'),
        //                     end_date: moment(end_date).format('YYYY-MM-DD'),
        //                     age_group: age_group,
        //                     division: division
        //                 }
        //             }
        //         }
        //     }
        // }

        // return updated_state
        // }

        case LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS:
            {
                console.log('-- Load Team Team Stat Type Detail')

                const {
                    team_id,
                    stat_type_id,
                    name,
                    description,
                    colour,
                    amount_label,
                    amount_icon,
                    amount_multiplier,
                    display_on_leaderboard,
                    active
                } = action.payload.team_stat_type

                var updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                updated_state = {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        stat_type:
                        {
                            team_id,
                            stat_type_id,
                            name,
                            description,
                            colour,
                            amount_label,
                            amount_icon,
                            amount_multiplier,
                            display_on_leaderboard: Boolean(Number(display_on_leaderboard)),
                            active: Boolean(Number(active))
                        }
                    }
                }

                return updated_state
            }

        case UPDATE_TEAM_STAT_TYPE_FORM_FIELD:
            {

                const { field_name, value } = action.payload

                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const stat_type_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('stat_type') ? updated_state.create.stat_type : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        stat_type:
                        {
                            ...stat_type_data,
                            [field_name]: value
                        }
                    }
                }
            }

        case CLEAR_TEAM_STAT_TYPE_FORM_FIELD:
            {
                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        stat_type: {}
                    }
                }
            }

        case SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS:
            {
                const { active } = action.payload

                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const stat_type_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('stat_type') ? updated_state.create.stat_type : {}

                return {
                    ...state,
                    create:
                    {
                        ...create_data,
                        stat_type:
                        {
                            ...stat_type_data,
                            active
                        }
                    }
                }
            }

        case LOAD_ROUND_WHITEBOARD_SUCCESS:
            {
                console.log('** Load Round Whiteboard Success')

                const { scribble, whiteboard_published } = action.payload

                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const whiteboard_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('whiteboard') ? updated_state.create.whiteboard : {}

                return {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        whiteboard:
                        {
                            ...whiteboard_data,
                            scribble,
                            whiteboard_published: Boolean(Number(whiteboard_published))
                        }
                    }
                }
            }

        case UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS:
            {
                console.log('-- Update Round Whiteboard Publish Status')

                const { whiteboard_published } = action.payload

                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const whiteboard_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('whiteboard') ? updated_state.create.whiteboard : {}

                return {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        whiteboard:
                        {
                            ...whiteboard_data,
                            whiteboard_published
                        }
                    }
                }
            }

        case UPDATE_ROUND_WHITEBOARD_TEXT:
            {
                // Load the whiteboard for editing by an admin
                const { scribble } = action.payload

                updated_state = { ...state }

                const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
                const whiteboard_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('whiteboard') ? updated_state.create.whiteboard : {}

                return {
                    ...updated_state,
                    create:
                    {
                        ...create_data,
                        whiteboard:
                        {
                            ...whiteboard_data,
                            scribble
                        }
                    }
                }
            }

        // case LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS:
        // {
        //     console.log('-- Load Team Team Stat Type Detail')

        //     const {
        //         team_id,
        //         stat_type_id,
        //         name,
        //         description,
        //         amount_label,
        //         amount_multiplier,
        //         display_on_leaderboard } = action.payload.team_stat_type

        //     var updated_state = { ...state }

        //     const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
        //     const teams_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('team') ? updated_state.create.team : {}
        //     const team_data = Object.entries(teams_data).length !== 0 && updated_state.create.team.hasOwnProperty(team_id) ? updated_state.create.team[team_id] : {}
        //     const stats_data = Object.entries(team_data).length !== 0 && updated_state.create.team[team_id].hasOwnProperty('stats') ? updated_state.create.team[team_id].stats : {}
        //     const stat_data = Object.entries(stats_data).length !== 0 && updated_state.create.team[team_id].stats.hasOwnProperty(stat_type_id) ? updated_state.create.team[team_id].stats[stat_type_id] : {}

        //     updated_state = {
        //         ...updated_state,
        //         create:
        //         {
        //             ...create_data,
        //             team:
        //             {
        //                 ...teams_data,
        //                 [team_id]:
        //                 {
        //                     ...team_data,
        //                     stats:
        //                     {
        //                         ...stats_data,
        //                         [stat_type_id]:
        //                         {
        //                             ...stat_data,
        //                             name,
        //                             description,
        //                             amount_label,
        //                             amount_multiplier,
        //                             display_on_leaderboard
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     return updated_state
        // }

        // case UPDATE_CREATE_TEAM_STAT_TYPE_FORM_FIELD:
        // {
        // const { field_name, value } = action.payload

        // updated_state = { ...state };

        // const create_data = updated_state.hasOwnProperty('create') ? updated_state.create : {}
        // const team_stat_type_data = Object.entries(create_data).length !== 0 && updated_state.create.hasOwnProperty('team_stat_type') ? updated_state.create.team_stat_type : {}

        // return {
        //     ...state,
        //     create:
        //     {
        //         ...create_data,
        //         team_stat_type:
        //         {
        //             ...team_stat_type_data,
        //             [field_name]: value
        //         }
        //     }
        // }
        // }

        // case DEACTIVATE_TEAM_STAT_TYPE_PLAYER_SUCCESS:
        // {
        // console.log('-- Deactivate team_stat_type player')

        // const { team_id, team_stat_type_id, registration_user_id } = action.payload

        // var updated_state = { ...state }

        // updated_state = {
        //     ...state,
        //     team:
        //     {
        //         ...state.team,
        //         [team_id]:
        //         {
        //             ...state.team[team_id],
        //             team_stat_types:
        //             {
        //                 ...state.team[team_id].team_stat_types,
        //                 [team_stat_type_id]:
        //                 {
        //                     ...state.team[team_id].team_stat_types[team_stat_type_id],
        //                     registrations:
        //                     {
        //                         ...state.team[team_id].team_stat_types[team_stat_type_id].registrations,
        //                         [registration_user_id]:
        //                         {
        //                             ...state.team[team_id].team_stat_types[team_stat_type_id].registrations[registration_user_id],
        //                             is_player: false
        //                         }

        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }

        // return updated_state
        // }

        default:
            return state
    }
}
