import React, { useEffect } from 'react'
import {
    Router,
    Route,
    Switch
} from 'react-router-dom'
import history from '../history'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Container, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, Typography, Box } from '@material-ui/core/'
import Header from './header/Header'
import Vote from './Vote'
import RoundAdmin from './rounds/admin/RoundAdmin'
import LeaderBoard from './LeaderBoard'
import PlayerProfile from './player/profile/PlayerProfile'
import RoundAttendance from './RoundAttendance'
import Login from './Login'
import PrivateRoute from './auth/PrivateRoute'
import Toast from './notifications/Toast'
import PasswordReset from './PasswordReset'
import PasswordResetCreate from './PasswordResetCreate'
import RoundCard from './rounds/admin/card/RoundCard'
import RoundStats from './rounds/admin/stats/RoundStats'
import RoundScore from './rounds/admin/stats/RoundScore'

// Web Push Notifications
import Messaging from './notifications/Messaging'

// Navigation
import {
    ROOT,
    HOME,
    LOGIN,
    REGISTER,
    PASSWORD_RESET,
    SET_PASSWORD,
    PLAYER_PROFILE,
    ROUND_VOTE,
    ROUND_CREATE,
    ROUND_UPDATE,
    ROUND_ADMIN,
    ROUND_CARD,
    ROUND_STATS,
    ROUND_SCORE,
    ROUND_ATTENDANCE,
    REGISTER_FOR_TEAM,
    TEAM_UPDATE,
    TEAM_CREATE,
    TEAMS_ADMIN,
    TEAM_ADMIN,
    TEAM_STAT_TYPES,
    TEAM_PLAYER_REGISTRATION,
    SEASON_PLAYER_REGISTRATION,
    TEAM_SEASONS,
    TEAM_STAT_TYPE_ADMIN,
    SEASON_CREATE,
    SEASON_UPDATE,
    SEASON_ADMIN,
    STAT_ICON_SELECTOR,
    TEAM_STAT_TYPE_CREATE,
    ROUNDS_ADMIN,
    WHITEBOARD_ADMIN,
    WHITEBOARD_SCRIBBLE,
    OPPOSITION_ADMIN,
    OPPONENT_ADMIN,
    OPPONENT_CREATE,
    OPPONENT_UPDATE,
    PLAYER_UPDATE,
    ROUND_PLAYER_VOTING_STATS,
    LEADERBOARD_SETTINGS,
    TEAM_SEASONS_PAST,
    SEASON_RESULTS, SEASON_LEADERBOARD, USER_SEASONS_PAST
} from '../navigation'

// Google Analytics
import ReactGA from 'react-ga'
import Register from './Register'
import TeamRegistration from './TeamRegistration'
import TeamsRegistration from './teams/admin/TeamsRegistration'
import TeamsAdmin from './teams/admin/TeamsAdmin'
import TeamSeasonRegistration from './seasons/admin/TeamSeasonRegistration'

//Materiel-UI Pickers (date/time pickers)
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CreateRound from './rounds/admin/CreateRound'
import UpdateRound from './rounds/admin/UpdateRound'
import TeamSeasons from './seasons/admin/TeamSeasons'
import CreateSeason from './seasons/admin/CreateSeason'
import TeamSeason from './seasons/admin/TeamSeason'
import UpdateSeason from './seasons/admin/UpdateSeason'
import TeamAdmin from './teams/admin/TeamAdmin'
import UpdateTeam from './teams/admin/UpdateTeam'
import CreateTeam from './teams/admin/CreateTeam'
import StatsAdmin from './stats/admin/StatsAdmin'
import StatAdmin from './stats/admin/StatAdmin'
import StatIconSelector from './stats/admin/StatIconSelector'
import CreateStat from './stats/admin/CreateStat'
import RoundsAdmin from './rounds/admin/RoundsAdmin'
import UpdateWhiteboard from './whiteboard/admin/UpdateWhiteboard'
import WhiteboardScribble from './whiteboard/WhiteboardScribble'
import { connect } from 'react-redux'
import { setCurrentPage, announcementsRead, announcementsDismissed, forcedLogout } from '../actions'
import OppositionAdmin from './opposition/admin/OppositionAdmin'
import OpponentAdmin from './opposition/admin/OpponentAdmin'
import CreateOpponent from './opposition/admin/CreateOpponent'
import UpdateOpponent from './opposition/admin/UpdateOpponent'
import UpdatePlayerProfile from './player/profile/UpdatePlayerProfile'
import RoundPlayerVotingStats from './rounds/admin/voting/RoundPlayerVotingStats'
import LeaderboardSettings from './seasons/admin/LeaderboardSettings'
import PastTeamSeasons from './seasons/PastTeamSeasons'
import SeasonResults from './seasons/admin/results/SeasonResults'
import SeasonLeaderBoard from './seasons/SeasonLeaderBoard'
import PastSeasons from './PastSeasons'

// Initialize google analytics page view tracking
ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID)
ReactGA.set({
    // userId: 'test',
    siteSpeedSampleRate: 100
    // any data that is relevant to the user session
    // that you would like to track with google analytics
})

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: 0
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    toolbar: theme.mixins.toolbar
})

const App = (props) => {

    const { classes, setCurrentPage, announcementsRead, announcementsDismissed, user, forcedLogout } = props

    const { is_logged_in, forced_log_out, announcements } = user

    useEffect(() => {
        if(forced_log_out)
        {
            forcedLogout(history)
        }
    }, [forced_log_out])

    history.listen(location => {
        console.log('location', location)

        // Remove the URL paramaters
        const { pathname } = location

        const parts = pathname.split('/')
        const param = parts.pop()
        let pagepathname

        if(pathname.includes('passwordresetcreate'))
        {
            pagepathname = parts.join('/')
        }
        else
        {
            pagepathname = isNaN(param) ? pathname : parts.join('/')
        }

        pagepathname = pagepathname.length ? pagepathname : '/Leaderboard'

        setCurrentPage(pagepathname)
    })

    const renderAnnouncements = () => {

        const output = []

        for(const announcement of announcements)
        {
            const { announcement_id, publish_date, announcement_json } = announcement

            const { heading, detail } = JSON.parse(announcement_json)

            output.push(
                <Box mb={1}>
                    <Typography variant='h4' style={{ color: '#fff' }}>{heading}</Typography>
                </Box>
            )

            for(const content of detail)
            {
                console.log('content', content)

                if(content.hasOwnProperty('text'))
                {
                    output.push(
                        <Box mb={1}>
                            <Typography variant='body1'>{content.text}</Typography>
                        </Box>
                    )
                }
                else if(content.hasOwnProperty('img'))
                {
                    output.push(
                        <Box mb={1}>
                            <img alt="" style={{ width: '100%' }} src={`${process.env.REACT_APP_API_URL}/images/announcements/${content.img}`} />
                        </Box>
                    )
                }
            }

        }

        return output
    }


    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline />
                <Router history={history}>
                    <Header />
                    <Paper>
                        <div className={classes.toolbar} />
                    </Paper>
                    <Container maxWidth="md" className={classes.root}>
                        <Box mb={16}>
                            <Switch>
                                <Route path={LOGIN} component={Login} />
                                <Route path={REGISTER} component={Register} />
                                <Route path={PASSWORD_RESET} component={PasswordReset} />
                                <Route path={`${SET_PASSWORD}/:password_reset_token`} component={PasswordResetCreate} />
                                <PrivateRoute path={HOME} exact component={LeaderBoard} />

                                <PrivateRoute path={PLAYER_PROFILE} exact component={PlayerProfile} />
                                <PrivateRoute path={`${PLAYER_UPDATE}/:player_id`} exact component={UpdatePlayerProfile} />

                                <PrivateRoute path={`${ROUND_VOTE}/:round_id`} exact component={Vote} />
                                <PrivateRoute path={ROUND_CREATE} exact component={CreateRound} />
                                <PrivateRoute path={`${ROUNDS_ADMIN}/:team_id/:team_season_id`} exact component={RoundsAdmin} />
                                <PrivateRoute path={`${ROUND_UPDATE}/:round_id`} exact component={UpdateRound} />
                                <PrivateRoute path={`${ROUND_ADMIN}/:round_id`} exact component={RoundAdmin} />
                                <PrivateRoute path={`${ROUND_CARD}/:round_id`} exact component={RoundCard} />
                                <PrivateRoute path={`${ROUND_STATS}/:round_id`} exact component={RoundStats} />
                                <PrivateRoute path={`${ROUND_SCORE}/:round_id`} exact component={RoundScore} />
                                <PrivateRoute path={`${ROUND_ATTENDANCE}/:round_id`} exact component={RoundAttendance} />
                                <PrivateRoute path={`${ROUND_PLAYER_VOTING_STATS}/:round_id`} exact component={RoundPlayerVotingStats} />

                                <PrivateRoute path={REGISTER_FOR_TEAM} exact component={TeamRegistration} />
                                <PrivateRoute path={`${TEAM_UPDATE}/:team_id`} exact component={UpdateTeam} />
                                <PrivateRoute path={TEAM_CREATE} exact component={CreateTeam} />
                                <PrivateRoute path={TEAMS_ADMIN} exact component={TeamsAdmin} />
                                <PrivateRoute path={`${TEAM_ADMIN}/:team_id`} exact component={TeamAdmin} />
                                <PrivateRoute path={`${STAT_ICON_SELECTOR}`} exact component={StatIconSelector} />
                                <PrivateRoute path={`${TEAM_STAT_TYPES}/:team_id`} exact component={StatsAdmin} />
                                <PrivateRoute path={`${TEAM_STAT_TYPE_ADMIN}/:stat_type_id`} exact component={StatAdmin} />
                                <PrivateRoute path={`${TEAM_STAT_TYPE_CREATE}/:team_id`} exact component={CreateStat} />
                                <PrivateRoute path={`${TEAM_PLAYER_REGISTRATION}/:team_id`} exact component={TeamsRegistration} />
                                <PrivateRoute path={`${SEASON_PLAYER_REGISTRATION}/:team_id/:team_season_id`} exact component={TeamSeasonRegistration} />
                                <PrivateRoute path={`${WHITEBOARD_ADMIN}/:round_id`} exact component={UpdateWhiteboard} />
                                <PrivateRoute path={`${WHITEBOARD_SCRIBBLE}/:round_id`} exact component={WhiteboardScribble} />

                                <PrivateRoute path={`${SEASON_LEADERBOARD}/:team_season_id`} exact component={SeasonLeaderBoard} />
                                <PrivateRoute path={`${LEADERBOARD_SETTINGS}/:team_id/:team_season_id`} exact component={LeaderboardSettings} />

                                <PrivateRoute path={`${OPPOSITION_ADMIN}/:team_id/:team_season_id`} exact component={OppositionAdmin} />
                                <PrivateRoute path={`${OPPONENT_CREATE}/:team_season_id`} exact component={CreateOpponent} />
                                <PrivateRoute path={`${OPPONENT_UPDATE}/:team_season_id/:opposition_id`} exact component={UpdateOpponent} />
                                <PrivateRoute path={`${OPPONENT_ADMIN}/:team_season_id/:opposition_id`} exact component={UpdateOpponent} />

                                <PrivateRoute path={`${SEASON_RESULTS}/:team_season_id`} exact component={SeasonResults} />

                                <PrivateRoute path={`${TEAM_SEASONS_PAST}/:team_id`} exact component={PastTeamSeasons} />

                                <PrivateRoute path={`${USER_SEASONS_PAST}`} exact component={PastSeasons} />

                                <PrivateRoute path={`${TEAM_SEASONS}/:team_id`} exact component={TeamSeasons} />
                                <PrivateRoute path={`${SEASON_CREATE}/:team_id`} exact component={CreateSeason} />
                                <PrivateRoute path={`${SEASON_UPDATE}/:team_id/:team_season_id`} exact component={UpdateSeason} />
                                <PrivateRoute path={`${SEASON_ADMIN}/:team_id/:team_season_id`} exact component={TeamSeason} />

                                {/* Default Route */}
                                <PrivateRoute path={ROOT} exact component={LeaderBoard} />
                            </Switch>
                        </Box>
                    </Container>
                </Router>


                <Dialog
                    // open={false}
                    open={is_logged_in && announcements.length > 0}
                    // onClose={() => {toggleModal(false)}}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">What's New</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            // ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            {renderAnnouncements()}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { announcementsRead() }} color="primary">
                            Don't show again
                    </Button>
                        <Button onClick={() => { announcementsDismissed() }} color="secondary">
                            Dismiss
                    </Button>
                    </DialogActions>
                </Dialog>
                <Messaging />
                <Toast></Toast>
            </MuiPickersUtilsProvider>
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withStyles(styles)(connect(mapStateToProps, { setCurrentPage, announcementsRead, announcementsDismissed, forcedLogout })(App))
