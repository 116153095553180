import {
    LOAD_TEAM_STAT_TYPE_DETAIL_REQUEST,
    LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS,
    LOAD_TEAM_STAT_TYPE_DETAIL_FAILURE,
    CREATE_TEAM_STAT_TYPE_REQUEST,
    CREATE_TEAM_STAT_TYPE_SUCCESS,
    CREATE_TEAM_STAT_TYPE_FAILURE,
    UPDATE_TEAM_STAT_TYPE_REQUEST,
    UPDATE_TEAM_STAT_TYPE_SUCCESS,
    UPDATE_TEAM_STAT_TYPE_FAILURE,
    SET_TEAM_STAT_TYPE_ACTIVE_STATUS_REQUEST,
    SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS,
    SET_TEAM_STAT_TYPE_ACTIVE_STATUS_FAILURE,
    QUEUE_TOAST,
    FORCED_LOG_OUT,
    LOG_OUT
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'

// Navigation
import { TEAM_STAT_TYPES } from '../navigation'


export const loadTeamStatTypeDetail = (stat_type_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Stat Type Detail for update form')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_STAT_TYPE_DETAIL_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/stats/type/${stat_type_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS, payload: { team_stat_type: response.data.team_stat_type } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamStatTypeDetail(stat_type_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Stat Type Detail - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_STAT_TYPE_DETAIL_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const createTeamStatType = (team_id, history, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Create Team')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    // Check all form fields have been completed.
    const {
        name,
        description,
        colour,
        amount_label,
        amount_icon,
        amount_multiplier,
        display_on_leaderboard
    } = getState().admin.create.stat_type

    if(
        !name
        || !description
        || !colour
        || !amount_label
        || !amount_icon
        || !amount_multiplier
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: CREATE_TEAM_STAT_TYPE_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/stats/type/${team_id}`, {
        user_id,
        token,
        name,
        description,
        colour,
        amount_label,
        amount_icon,
        amount_multiplier,
        display_on_leaderboard: display_on_leaderboard ? 1 : 0
    })
        .then(response => {

            dispatch({ type: CREATE_TEAM_STAT_TYPE_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Stat created successfully' } })

            console.log(`history`, { ...history })

            // history.push(`${TEAM_STAT_TYPES}/${team_id}`)
            history.goBack()

            ReactGA.event({
                category: 'Team Admin',
                action: 'Create State Type',
                value: 20
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(createTeamStatType(team_id, history, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create Team - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: CREATE_TEAM_STAT_TYPE_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const deactivateTeamStatType = (team_id, team_season_id, registration_user_id, delay, retry_count) => async (dispatch, getState) => {
    // console.log('** Deactivate Season Player')

    // // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    // await delayRetry(delay, retry_count)

    // dispatch({ type: DEACTIVATE_SEASON_PLAYER_REQUEST })

    // const { user_id, token } = getState().user

    // await streams.patch(`/players/${team_season_id}`, {
    //         user_id,
    //         token,
    //         registration_user_id
    // })
    // .then(response => {

    //     console.log('response', response)

    //     dispatch({ type: DEACTIVATE_SEASON_PLAYER_SUCCESS, payload: { team_id, team_season_id, registration_user_id } })
    //     dispatch({ type: QUEUE_TOAST, payload: {status: 'success', message: 'Updated registration status successfully' } })

    //     if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
    //     {
    //         dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
    //     }

    // })
    // .catch(error => {
    //     // Catches network errors and not found responses from the API

    //     if(error.message === 'Network Error' && (!retry_count || retry_count < 3 )) // Allow 3 retries
    //     {
    //         dispatch(loadTeams(team_id, team_season_id, registration_user_id, delay, retry_count ? ++retry_count : 1))
    //     }
    //     else
    //     {
    //         const { response } = error
    //         var { message } = error

    //         message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to unregister the player - ' + message

    //         getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
    //         dispatch({ type: DEACTIVATE_SEASON_PLAYER_FAILURE, payload: { message } })

    //         if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
    //         {
    //             dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
    //         }
    //     }
    // });
}

export const updateTeamStatType = () => async (dispatch, getState) => {
    console.log('** Update Team Stat Type')

    // Check all form fields have been completed.
    const {
        stat_type_id,
        name,
        description,
        colour,
        amount_label,
        amount_icon,
        amount_multiplier,
        display_on_leaderboard
    } = getState().admin.create.stat_type

    if(
        !name
        || !description
        || !colour
        || !amount_label
        || !amount_icon
        || !amount_multiplier
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: UPDATE_TEAM_STAT_TYPE_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/stats/type/${stat_type_id}`, {
        user_id,
        token,
        name,
        description,
        colour,
        amount_label,
        amount_icon,
        amount_multiplier,
        display_on_leaderboard: display_on_leaderboard ? 1 : 0
    })
        .then(response => {

            const { updated } = response.data

            dispatch({ type: UPDATE_TEAM_STAT_TYPE_SUCCESS })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Team Stat Updated Successfully' } })
            }

            ReactGA.event({
                category: 'Team Admin',
                action: 'Update Stat Type',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update Team Stat - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_TEAM_STAT_TYPE_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const setStatTypeActiveStatus = (stat_type_id, active) => async (dispatch, getState) => {
    console.log('** Achive/Activate Team Stat Type')

    console.log('setStatTypeActiveStatus', active)

    dispatch({ type: SET_TEAM_STAT_TYPE_ACTIVE_STATUS_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/stats/type/${stat_type_id}`, {
        user_id,
        token,
        active: active ? 1 : 0
    })
        .then(response => {

            const { updated } = response.data

            dispatch({ type: SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS, payload: { active } })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Team Stat ' + (active ? 'Archived' : 'Activated') + ' Successfully' } })
            }

            ReactGA.event({
                category: 'Team Admin',
                action: (active ? 'Archive' : 'Activate') + ' Team Stat Type',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to ' + (active ? 'archive' : 'activate') + ' Team Stat - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: SET_TEAM_STAT_TYPE_ACTIVE_STATUS_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

