import React from "react"
import { connect } from 'react-redux'
import { incrementRoundsShowCount } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Fab, Tooltip } from '@material-ui/core/'
import { red, orange, green } from "@material-ui/core/colors"
import PlayerHeaderBasic from "../player/PlayerHeaderBasic"
import { LightTooltip, TooltipGreen, TooltipPrimary, TooltipOrange, TooltipRed } from "../ui/Tooltips"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'



const styles = theme => ({
    showRoundResults: {
        textAlign: 'center',
        borderRadius: "15px",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    red: {
        backgroundColor: red[500],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[600],
            borderColor: red[500],
        },
    },
    orange: {
        backgroundColor: orange[500],
        borderColor: orange[400],
        '&:hover': {
            backgroundColor: orange[600],
            borderColor: orange[500],
        },
    },
    green: {
        backgroundColor: green[500],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[600],
            borderColor: green[500],
        },
    },
    redText: {
        color: red[500],
        '&:hover': {
            color: red[600]
        }
    },
    orangeText: {
        color: orange[500],
        '&:hover': {
            color: orange[600]
        }
    },
    greenText: {
        color: green[500],
        '&:hover': {
            color: green[600]
        }
    }

})

function RoundAttendancePlayerList(props) {
    console.log('[] RoundAttendancePlayerList')

    const { status_to_show, players } = props // Connect
    const { user_player_id } = props // Parent

    var ordered_players = []

    console.log('players', players)

    if(!status_to_show && players.length !== 0 && user_player_id)
    {
        ordered_players.push([user_player_id])
    }
    else
    {
        // Copy players into an array to sort

        for(var player_id in players)
        {
            // Exclude the user as we'll add them to the top of the pile to make it easier for them to see their current status
            if(players[player_id].status === status_to_show)
            {
                ordered_players.push([player_id, players[player_id].sort_order])
            }
        }

        // Sort the players by date
        ordered_players.sort(function(a, b) {
            return a[1] - b[1]
        })
    }

    console.log('ordered_players', ordered_players)

    var output = []

    for(var ordered_ids of ordered_players)
    {
        player_id = ordered_ids[0]
        console.log('player_id', player_id)

        var comment = players.hasOwnProperty(player_id) ? players[player_id].player_comment : ''

        var highlightColor = ''

        switch(players.hasOwnProperty(player_id) ? players[player_id].status : '')
        {
            case 'I':
                highlightColor = 'green'
                break
            case 'M':
                highlightColor = 'orange'
                break
            case 'O':
                highlightColor = 'red'
                break
            default:
                highlightColor = ''
        }

        output.push(
            <div key={player_id}>
                <Box my={2}>
                    <PlayerHeaderBasic delay player_id={player_id} comment={comment} highlightColor={highlightColor} />
                </Box>
            </div>
        )
    }

    return output
}

const mapStateToProps = (state, ownProps) => {

    const { round_id } = ownProps

    return {
        status_to_show: state.ui.hasOwnProperty('round') && state.ui.round.hasOwnProperty(round_id) && state.ui.round[round_id].hasOwnProperty('attendance') && state.ui.round[round_id].attendance.hasOwnProperty('status_to_show')
            ? state.ui.round[round_id].attendance.status_to_show
            : false,
        players: state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('attendance')
            ? state.round[round_id].attendance
            : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { incrementRoundsShowCount })(RoundAttendancePlayerList))
