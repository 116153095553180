import {
    SET_CURRENT_PAGE,
    TOGGLE_ADMIN_VIEW,
    UPDATE_ROUND_STATS_PLAYER_SELECT,
    UPDATE_ROUND_STATS_STAT_SELECT,
    INCREMENT_ROUND_SHOW_COUNT,
    UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW,
    TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW,
    TOGGLE_CREATE_OPPONENT_MODAL_SHOW,
    TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW
} from '../actions/types'

const INITIAL_STATE = {
    leaderboard: {
        show_count_past_rounds: 5,
        show_count_voting_open_rounds: 5,
        show_count_upcoming_rounds: 5,
        show_count_past_season_rounds: 5
    },
    admin: {
        admin_view: false,
        show_count_rounds_admin_rounds: 10,
        create_round_create_opponent_show: false
    },
    current_page: ''
}

export default (state = INITIAL_STATE, action) => {

    switch(action.type)
    {
        case SET_CURRENT_PAGE:
            {
                console.log('-- Set current page')

                const { current_page } = action.payload

                return {
                    ...state, current_page
                }
            }

        case INCREMENT_ROUND_SHOW_COUNT:
            {
                console.log('-- Increment Round Show Count')

                const { section, key, increment } = action.payload

                const updated_show_count = Number(state[section][key]) + Number(increment)

                return {
                    ...state,
                    [section]:
                    {
                        ...state[section],
                        [key]: updated_show_count
                    }
                }
            }

        case TOGGLE_ADMIN_VIEW:
            {
                console.log('-- Toggle admin view')

                return {
                    ...state,
                    admin:
                    {
                        ...state.admin,
                        admin_view: state.admin.admin_view ? false : true
                    }
                }
            }

        case UPDATE_ROUND_STATS_PLAYER_SELECT:
            {
                console.log('-- Update round stat player select')

                const { player_id } = action.payload

                if(!state.admin.hasOwnProperty('round_stats'))
                {
                    return {
                        ...state,
                        admin:
                        {
                            ...state.admin,
                            round_stats:
                            {
                                player_id: player_id
                            }
                        },

                    }
                }

                return {
                    ...state,
                    admin:
                    {
                        ...state.admin,
                        round_stats:
                        {
                            ...state.admin.round_stats,
                            player_id: player_id
                        }
                    }
                }
            }

        case UPDATE_ROUND_STATS_STAT_SELECT:
            {
                console.log('-- Update round stat stat select')

                const { stat_id } = action.payload

                if(!state.admin.hasOwnProperty('round_stats'))
                {
                    return {
                        ...state,
                        admin:
                        {
                            ...state.admin,
                            round_stats:
                            {
                                stat_id: stat_id
                            }
                        },

                    }
                }

                return {
                    ...state,
                    admin:
                    {
                        ...state.admin,
                        round_stats:
                        {
                            ...state.admin.round_stats,
                            stat_id: stat_id
                        }
                    }
                }
            }

        case UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW:
            {
                console.log('-- Update Round Attendance Player Status To Show')

                const { round_id } = action.payload
                var { status } = action.payload


                const round_data = state.hasOwnProperty('round') ? state.round : {}
                const round_id_data = state.hasOwnProperty('round') && state.round.hasOwnProperty(round_id) ? state.round[round_id] : {}
                const attendance_data = state.hasOwnProperty('round') && state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('attendance') ? state.round[round_id].attendance : {}

                // Hide on second request for the same status
                if(attendance_data.hasOwnProperty('status_to_show') && attendance_data.status_to_show === status)
                {
                    status = ''
                }

                return {
                    ...state,
                    round:
                    {
                        ...round_data,
                        [round_id]:
                        {
                            ...round_id_data,
                            attendance:
                            {
                                ...attendance_data,
                                status_to_show: status
                            }
                        }
                    }
                }
            }

        case TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW:
            {
                console.log('-- Toggle Team Stat Type Icon Selector Modal')

                const { show } = action.payload  // Data from action

                // Keep data already in the store
                const admin_data = state.hasOwnProperty('admin') ? state.admin : {}

                return {
                    ...state,
                    admin:
                    {
                        ...admin_data,
                        team_stat_type_icon_selector_modal_show: show
                    },

                }
            }

        case TOGGLE_CREATE_OPPONENT_MODAL_SHOW:
            {
                console.log('-- Toggle Create Opponent Modal')

                const { show } = action.payload  // Data from action

                // Keep data already in the store
                const admin_data = state.hasOwnProperty('admin') ? state.admin : {}

                return {
                    ...state,
                    admin:
                    {
                        ...admin_data,
                        create_round_create_opponent_show: show
                    },

                }
            }

        case TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW:
            {
                console.log('-- Toggle Create Opponent Modal')

                const { show } = action.payload  // Data from action

                // Keep data already in the store
                const admin_data = state.hasOwnProperty('admin') ? state.admin : {}

                return {
                    ...state,
                    admin:
                    {
                        ...admin_data,
                        unregister_team_user_modal_show: show
                    },

                }
            }



        default:
            return state
    }
}
