import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Redirect
} from 'react-router-dom'

// Navigation
import {
    LOGIN
} from '../../navigation'

const PrivateRoute = (props) => {

    const { component: Component, ...rest } = props

    const { is_logged_in } = props

    return (
        <Route {...rest} render={(props) => (
            is_logged_in
                ? <Component {...props} />
                : <Redirect to={{
                        pathname: LOGIN,
                        state: { from: props.location }
                    }} {...props} />
        )} />
    )
}

const mapStateToProps = state => {
    return {
        is_logged_in: state.user.is_logged_in
    };
}

export default connect(mapStateToProps, { })(PrivateRoute)
