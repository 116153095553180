import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, TextField, Button, Typography, InputLabel, Select, ButtonBase, Grid, ListSubheader, Divider, Switch, FormControlLabel } from '@material-ui/core'

import { loadSeasonSettings, updateSeason, updateSeasonLeaderboardOptionsFormFieldValue, loadManagedTeams, loadTeamSeasons, updateSeasonSettings } from '../../../actions'

import moment from 'moment'
import {
    DatePicker
} from '@material-ui/pickers'
import TeamSeasonListItem from '../TeamSeasonListItem'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})


const LeaderboardSettings = props => {

    const { team_id, team_season_id } = props.match.params

    const { classes } = props
    const { loadSeasonSettings, updateSeasonSettings, updateSeasonLeaderboardOptionsFormFieldValue } = props

    const {
        show_points_leaderboard,
        obfuscate_points_leaderboard,
        show_stats_leaderboard,
        obfuscate_stats_leaderboard,
        show_round_results,
        obfuscate_round_results

    } = props //Form fields

    useEffect(() => {

        console.log('[] Leaderboard Settings')

        loadSeasonSettings(team_season_id)

    }, [])

    //console.log(props)

    const onSubmit = () => {
        updateSeasonSettings(team_season_id)
    }

    // Show points leaderboard?
    // Obfuscate points leaderboard
    // Show stats leaderboard?
    // Obfuscate stats leaderboard
    // Show round results?
    // Objuscate round results

    return (
        <>
            <Box m={1}>

                <Box mt={2} mb={1}>
                    <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
                </Box>

                <Divider />

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Leaderboard Settings</Typography>
                </Box>

                <Box>
                    <FormControlLabel
                        value=""
                        control={
                            <Switch
                                checked={show_points_leaderboard}
                                onChange={(e) => {
                                    updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked)
                                    !e.target.checked && updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, 'obfuscate_points_leaderboard', false)
                                }}
                                color="secondary"
                                name="show_points_leaderboard"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Show the Points Leaderboard"
                        labelPlacement="end"
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        disabled={!show_points_leaderboard}
                        value=""
                        control={
                            <Switch
                                checked={obfuscate_points_leaderboard}
                                onChange={(e) => { updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked) }}
                                color="secondary"
                                name="obfuscate_points_leaderboard"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Obfuscate the Points Leaderboard"
                        labelPlacement="end"
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        value=""
                        control={
                            <Switch
                                checked={show_stats_leaderboard}
                                onChange={(e) => {
                                    updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked)
                                    !e.target.checked && updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, 'obfuscate_stats_leaderboard', false)
                                }}
                                color="secondary"
                                name="show_stats_leaderboard"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Show the Stats Leaderboard"
                        labelPlacement="end"
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        disabled={!show_stats_leaderboard}
                        value=""
                        control={
                            <Switch
                                checked={obfuscate_stats_leaderboard}
                                onChange={(e) => { updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked) }}
                                color="secondary"
                                name="obfuscate_stats_leaderboard"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Obfuscate the Stats Leaderboard"
                        labelPlacement="end"
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        value=""
                        control={
                            <Switch
                                checked={show_round_results}
                                onChange={(e) => {
                                    updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked)
                                    !e.target.checked && updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, 'obfuscate_round_results', false)
                                }}
                                color="secondary"
                                name="show_round_results"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Show Round Results"
                        labelPlacement="end"
                    />
                </Box>

                <Box>
                    <FormControlLabel
                        disabled={!show_round_results}
                        value=""
                        control={
                            <Switch
                                checked={obfuscate_round_results}
                                onChange={(e) => { updateSeasonLeaderboardOptionsFormFieldValue(team_season_id, e.target.name, e.target.checked) }}
                                color="secondary"
                                name="obfuscate_round_results"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label="Obfuscate Round Results"
                        labelPlacement="end"
                    />
                </Box>



                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_season_id } = ownProps.match.params

    return {
        show_points_leaderboard: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('show_points_leaderboard') ? state.season[team_season_id].admin.leaderboard_settings.show_points_leaderboard : false,
        obfuscate_points_leaderboard: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('obfuscate_points_leaderboard') ? state.season[team_season_id].admin.leaderboard_settings.obfuscate_points_leaderboard : false,
        show_stats_leaderboard: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('show_stats_leaderboard') ? state.season[team_season_id].admin.leaderboard_settings.show_stats_leaderboard : false,
        obfuscate_stats_leaderboard: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('obfuscate_stats_leaderboard') ? state.season[team_season_id].admin.leaderboard_settings.obfuscate_stats_leaderboard : false,
        show_round_results: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('show_round_results') ? state.season[team_season_id].admin.leaderboard_settings.show_round_results : false,
        obfuscate_round_results: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('leaderboard_settings') && state.season[team_season_id].admin.leaderboard_settings.hasOwnProperty('obfuscate_round_results') ? state.season[team_season_id].admin.leaderboard_settings.obfuscate_round_results : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadSeasonSettings, updateSeasonSettings, updateSeasonLeaderboardOptionsFormFieldValue, loadManagedTeams, loadTeamSeasons })(LeaderboardSettings))
