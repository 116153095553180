import {
    UPDATE_VOTE_COMMENT,
    POINTS_BUTTON_CLICK,
    CARD_BUTTON_CLICK,
    ATTENDANCE_BUTTON_CLICK,
    UPDATE_ATTENDANCE_COMMENT,
    NUMBER_OF_GAMES_BUTTON_CLICK,
    LOAD_ROUND_SUCCESS,
    LOAD_ROUNDS_SUCCESS,
    LOAD_ROUND_ATTENDANCE_SUCCESS,
    LOAD_ROUND_CARD_SUCCESS,
    LOAD_ROUND_VOTES_SUCCESS,
    LOAD_ROUND_LEADERBOARD_SUCCESS,
    SHOW_ROUND_LEADERBOARD,
    HIDE_ROUND_LEADERBOARD,
    CAST_VOTE_SUCCESS,
    INCREMENT_TEAM_SCORE,
    INCREMENT_OPPOSITION_SCORE,
    DELETE_ROUND_SUCCESS,
    LOAD_ROUND_SCRIBBLE_SUCCESS,
    LOAD_PLAYER_VOTING_STATS_SUCCESS,
} from '../actions/types';


const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {
    // //console.log('Reducer Action')
    // //console.log(action)

    // let updated_state
    // , round_id, player_id, type, comment_text,
    //     status, rounds, number_of_games, round, votes, leaderboard,
    //     increment, updated_score

    switch(action.type) {

        case LOAD_ROUNDS_SUCCESS:
        {
            console.log('-- Load Rounds')

            const { rounds } = action.payload

            var updated_state = { ...state }

            for (const i in rounds) {

                const round = rounds[i]
                const round_id = round.id

                if(!updated_state.hasOwnProperty(round_id))
                {
                    // add round
                    // const new_round = {[round_id]: {details: {}}}
                    updated_state = {
                        ...updated_state,
                        [round_id]:
                        {
                            details: round
                        }
                    }
                }
                else
                {
                    // update round

                    if(updated_state[round_id].hasOwnProperty('details') && updated_state[round_id].details.hasOwnProperty('period') && updated_state[round_id].details.period === 'voting_open')
                    {
                        continue
                    }

                    updated_state = {
                        ...updated_state,
                        [round_id]:
                        {
                            ...updated_state[round_id],
                            details: round
                        }
                    }
                }

            }

            return updated_state
        }

        case LOAD_ROUND_SUCCESS:
        {
            const { round_id, round } = action.payload

            console.log(`-- Load Round ${round_id}`);

            var updated_state = { ...state }

            if(!updated_state.hasOwnProperty(round_id))
            {
                // add round
                // const new_round = {[round_id]: {details: {}}}
                updated_state = { ...updated_state, [round_id]: { details: round } }
            }
            else
            {
                // update round
                // updated_state[round_id]details = round

                updated_state = {
                    ...updated_state,
                    [round_id]:
                    {
                        ...updated_state[round_id],
                        details: round
                    }
                }
            }

            return updated_state

        }

        case LOAD_ROUND_ATTENDANCE_SUCCESS:
        {
            console.log('-- Load Round Attendance')

            const { round_id, round } = action.payload

            var updated_state = { ...state }

            if(!updated_state.hasOwnProperty(round_id))
            {
                updated_state = { ...updated_state, [round_id]: { attendance: round } }
            }
            else
            {
                updated_state = {
                    ...updated_state,
                    [round_id]:
                    {
                        ...updated_state[round_id],
                        attendance: round
                    }
                }
            }


            return updated_state
        }

        case LOAD_ROUND_CARD_SUCCESS:
        {
            console.log('-- Load Round Card')

            const { round_id, round } = action.payload

            var updated_state = { ...state }

            if(!updated_state.hasOwnProperty(round_id))
            {
                //console.log('New Round')
                updated_state = { ...updated_state, [round_id]: { card: round } }
            }
            else
            {
                //console.log('Update Round')
                updated_state = {
                    ...updated_state,
                    [round_id]:
                    {
                        ...updated_state[round_id],
                        card: round
                    }
                }
            }

            return updated_state
        }

        case LOAD_ROUND_VOTES_SUCCESS:
        {
            console.log('-- Load Round Votes')

            const { round_id, votes } = action.payload

            var updated_state = { ...state }

            if(!updated_state.hasOwnProperty(round_id))
            {
                updated_state = { ...updated_state, [round_id]: { vote: {votes: votes} } }
            }
            else
            {
                // updated_state[round_id]vote = {votes: votes}
                updated_state = {
                    ...updated_state,
                    [round_id]:
                    {
                        ...updated_state[round_id],
                        vote: {votes: votes}
                    }
                }
            }

            if(Object.entries(votes).length !== 0)
            {
                updated_state[round_id]['vote']['votes_submitted'] = true
            }
            else
            {
                updated_state[round_id]['vote']['votes_submitted'] = false
            }

            return updated_state
        }

        case CAST_VOTE_SUCCESS:
        {
            console.log('-- Cast Vote')

            const { round_id } = action.payload

            var updated_state = { ...state }

            updated_state[round_id].vote.votes_submitted = true

            return updated_state
        }

        case UPDATE_VOTE_COMMENT:
        {
            //console.log('Action - Update Points Comment')
            //console.log(action)

            const { round_id, player_id, type, comment_text } = action.payload

            var updated_state = { ...state }

            updated_state[round_id].vote.votes[player_id].vote[type] = comment_text

            return updated_state
        }

        case POINTS_BUTTON_CLICK:
        {
            console.log('-- Points Button Click');

            var updated_state = { ...state }

            const { round_id, player_id, type } = action.payload

            var glory_cut_toggle_off = false;  // Need to allow for the Glory and Cut options to be deselected.  This is not required for the 3,2,1's as they must be set to cast votes.

            for (const vote_player_id in updated_state[round_id].vote.votes) {
                let vote_player = updated_state[round_id].vote.votes[vote_player_id];

                if(vote_player_id === player_id) {
                    if(type.includes('pt'))
                    {
                        if(type === 'reason_3pt' && vote_player.vote.hasOwnProperty('reason_3pt'))
                        {
                            return state; // Nothing to change so return original state
                        }
                        if(type === 'reason_2pt' && vote_player.vote.hasOwnProperty('reason_2pt'))
                        {
                            return state; // Nothing to change so return original state
                        }
                        if(type === 'reason_1pt' && vote_player.vote.hasOwnProperty('reason_1pt'))
                        {
                            return state; // Nothing to change so return original state
                        }

                        delete vote_player.vote['reason_3pt']
                        delete vote_player.vote['reason_2pt']
                        delete vote_player.vote['reason_1pt']

                        vote_player.vote[type] = ''
                    }
                    else if(type === 'reason_glory')
                    {
                        if(vote_player.vote.hasOwnProperty('reason_glory'))
                        {
                            console.log('reason_glory exists')
                            delete vote_player.vote[type]
                            glory_cut_toggle_off = true
                        }
                        else
                        {
                            console.log('reason_glory new')
                            vote_player.vote[type] = ''
                        }
                    }
                    else if(type === 'reason_cut')
                    {
                        if(vote_player.vote.hasOwnProperty('reason_cut'))
                        {
                            delete vote_player.vote[type]
                            glory_cut_toggle_off = true
                        }
                        else
                        {
                            vote_player.vote[type] = ''
                        }
                    }

                }
                else if(vote_player.vote.hasOwnProperty(type))
                {
                    delete vote_player.vote[type]
                }

                // Remove player from votes if they don't have any votes for them
                if(Object.entries(vote_player.vote).length === 0)
                {
                    delete updated_state[round_id].vote.votes[vote_player_id]
                }
            }

            // // Add player to votes if not exists.. Unless it's the Glory or Cut buttons toggling off.
            if(!updated_state[round_id].vote.votes.hasOwnProperty(player_id) && !glory_cut_toggle_off)
            {
                updated_state[round_id].vote.votes = {...updated_state[round_id].vote.votes, [player_id]: {'player_id': player_id, vote: {[type]: ''}}}
            }

            return updated_state
        }

        case CARD_BUTTON_CLICK:
        {
            console.log('-- Update player lineup status');
            //console.log(action.payload)

            var updated_state = { ...state }

            const { round_id, player_id, user_id, status } = action.payload

            updated_state = {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    card:
                    {
                        ...state[round_id].card,
                        [player_id]:
                        {
                            ...state[round_id].card[player_id],
                            user_id,
                            on_card: status === 'card' ? true : false
                        }
                    }
                }
            }

            return updated_state
        }

        case ATTENDANCE_BUTTON_CLICK:
        {
                console.log('-- Update player attendance status');

                console.log('action.payload', action.payload)

                var updated_state = { ...state }

                const { round_id, player_id, status } = action.payload

                // updated_state[round_id].attendance[player_id].status = status

                // updated_state[round_id].attendance[player_id].can_play_count = null
                updated_state = {
                    ...state,
                    [round_id]:
                    {
                        ...state[round_id],
                        attendance:
                        {
                            ...state[round_id].attendance,
                            [player_id]:
                            {
                                ...state[round_id].attendance[player_id],
                                status: status
                            }
                        }
                    }
                }

                // // If "Out" then we need to remove the can play count as it no longer relates to this round
                // if(status == 'O')
                // {
                //     updated_state[round_id].attendance[player_id].can_play_count = null
                //     // updated_state = {
                //     //     ...state,
                //     //     [round_id]:
                //     //     {
                //     //         ...state[round_id],
                //     //         attendance:
                //     //         {
                //     //             ...state[round_id].attendance,
                //     //             [player_id]:
                //     //             {
                //     //                 ...state[round_id].attendance[player_id],
                //     //                 ['can_play_count']: null
                //     //             }
                //     //         }
                //     //     }
                //     // }
                // }

                return updated_state
            }

        case UPDATE_ATTENDANCE_COMMENT:
        {
            //console.log('Action - Update Attendance Comment')
            //console.log(action)
            //console.log(JSON.parse(JSON.stringify(state)))

            const { round_id, player_id, comment_text } = action.payload

            var updated_state = { ...state }

            return {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    attendance:
                    {
                        ...state[round_id].attendance,
                        [player_id]:
                        {
                            ...state[round_id].attendance[player_id],
                            player_comment: comment_text
                        }
                    }
                }
            }
        }

        case NUMBER_OF_GAMES_BUTTON_CLICK:
        {
            console.log('-- Update Number of Games');
            //console.log(action)
            //console.log(JSON.parse(JSON.stringify(state)));

            console.log('action.payload', action.payload)

            const { rounds, number_of_games } = action.payload

            var updated_state = { ...state }

            for(var attendance_round_id of rounds)
            {
                const player_id = state[attendance_round_id].details.user_player_id
                // const status = state[attendance_round_id].attendance[player_id].status

                // if(status != 'U')
                // {
                    updated_state[attendance_round_id].attendance[player_id].can_play_count = Number(number_of_games) === 0 ? -1 : number_of_games
                // }
            }

            return updated_state
        }

        case LOAD_ROUND_LEADERBOARD_SUCCESS:
        {
            console.log('-- Load Round Leaderboard')

            const { round_id, leaderboard } = action.payload

            leaderboard.show = state[round_id].leaderboard.show

            return {
                ...state, [round_id]:
                {
                    ...state[round_id], leaderboard: leaderboard
                }
            }
        }

        case SHOW_ROUND_LEADERBOARD:
        {
            console.log('-- Show Round Leaderboard')

            const { round_id } = action.payload

            return {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    leaderboard:
                    {
                        ...state[round_id].leaderboard,
                        show: true
                    }
                },

            }
        }

        case HIDE_ROUND_LEADERBOARD:
        {
            console.log('-- Show Round Leaderboard')

            const { round_id } = action.payload

            return {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    leaderboard:
                    {
                        ...state[round_id].leaderboard,
                        show: false
                    }
                }
            }
        }

        case INCREMENT_TEAM_SCORE:
        {
            console.log('-- Increment Round Team Score')

            const { round_id, increment } = action.payload

            const updated_score = Number(state[round_id].details.goals_for) + Number(increment)

            return {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    details:
                    {
                        ...state[round_id].details,
                        goals_for: updated_score >= 0 ? updated_score : 0
                    }
                }
            }
        }

        case INCREMENT_OPPOSITION_SCORE:
        {
            console.log('-- Increment Round Opposition Score')

            const { round_id, increment } = action.payload

            const updated_score = Number(state[round_id].details.goals_against) + Number(increment)

            return {
                ...state,
                [round_id]:
                {
                    ...state[round_id],
                    details:
                    {
                        ...state[round_id].details,
                        goals_against: updated_score >= 0 ? updated_score : 0
                    }
                }
            }
        }

        case DELETE_ROUND_SUCCESS:
        {
            console.log('-- Delete Round')

            const { round_id } = action.payload

            delete state[round_id]

            return state
        }

        case LOAD_ROUND_SCRIBBLE_SUCCESS:
        {
            // Load the published whiteboard for team members
            console.log('** Load Round Scribble Success');

            const { round_id, scribble } = action.payload

            updated_state = { ...state };

            const round_data = updated_state.hasOwnProperty(round_id) ? updated_state[round_id] : {}

            return {
                ...updated_state,
                [round_id]:
                {
                    ...round_data,
                    whiteboard:
                    {
                        scribble
                    }
                }
            }
        }

        case LOAD_PLAYER_VOTING_STATS_SUCCESS:
        {
            // Load the published whiteboard for team members
            console.log('** Load Round Scribble Success');

            const { round_id, player_voting_stats } = action.payload

            updated_state = { ...state };

            const round_data = updated_state.hasOwnProperty(round_id) ? updated_state[round_id] : {}

            return {
                ...updated_state,
                [round_id]:
                {
                    ...round_data,
                    player_voting_stats
                }
            }
        }


        // case SAVE_ATTENDANCE_SUCCESS:
        //     //console.log('Action - Update Attendance Comment')
        //     //console.log(action)
        //     //console.log(JSON.parse(JSON.stringify(state)));

        //     ({ round_id, message } = action.payload)

        //     var updated_state = { ...state }

        //     if(message.ok)
        //     {
        //         updated_state[round_id].details.votes_submitted = true
        //     }

        //     return updated_state

        default:
            return state;
    }

}
