import React, { useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import {
    Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select,
    Grid, FormControlLabel, Checkbox, FormHelperText, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions, Switch
} from '@material-ui/core'

import {
    createRound, updateCreateRoundFormFieldValue, loadManagedTeams, loadTeamSeasons,
    loadTeamSeasonOpposition, loadSupportingTeamSeasons, toggleCreateOpponentModal
} from '../../../actions'
import Map from '../../maps/Map'

import moment from 'moment'
import {
    DatePicker,
    TimePicker
} from '@material-ui/pickers'
import { FaSquare, FaCheckSquare } from 'react-icons/fa'
import CreateOpponent from '../../opposition/admin/CreateOpponent'
import Alert from '@material-ui/lab/Alert'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})


const CreateRound = props => {
    console.log('[] Create Round')

    const { player } = props
    const { classes } = props
    const {
        createRound,
        updateCreateRoundFormFieldValue,
        loadManagedTeams,
        loadTeamSeasons,
        loadTeamSeasonOpposition,
        loadSupportingTeamSeasons,
        loading_teams_managed,
        loading_team_seasons,
        loaded_team_seasons,
        toggleCreateOpponentModal,
        create_round_create_opponent_show
    } = props

    var {
        team_season_id
    } = props //Form fields

    // team_season_id = 24

    const {
        teams,
        opposition_teams,
        supporting_team_seasons_options,
        supporting_team_seasons,
        seasons,

        round_number,
        type,
        date,
        end_date,
        create_multiple_training_rounds,
        include_supporting_teams,
        time,
        opposition,
        // round_location,
        location_name,
        preview_location_name,
    } = props //Form fields

    const number_of_training_rounds = date && end_date ? moment(end_date).diff(moment(date), 'week') + 1 : 0
    const training_session_day = date && end_date ? moment(date).format('dddd') : ''

    var round_date_in_the_past = false

    if(moment(date).isBefore(moment().startOf('day')))
    {
        round_date_in_the_past = true
    }

    var round_end_date_in_the_past = false

    if(moment(end_date).isBefore(moment().startOf('day')) || moment(end_date).startOf('day').isSameOrBefore(moment(date).startOf('day')))
    {
        round_end_date_in_the_past = true
    }

    useEffect(() => {

        console.log('[] Create Round')

        // Set form defaults
        updateCreateRoundFormFieldValue('type', 'G')

        // Load the teams that the user manages
        loadManagedTeams()

    }, [])

    var no_seasons_to_load = true

    useEffect(() => {
        // Load the team seasons for the team if they haven't already been loaded
        if(!loading_team_seasons && !loaded_team_seasons)
        {
            if(teams)
            {
                for(const team_id in teams)
                {
                    if(teams.hasOwnProperty(team_id) && !teams[team_id].hasOwnProperty('seasons'))
                    {
                        loadTeamSeasons(team_id)
                        // loading_team_seasons = true
                        no_seasons_to_load = false
                    }
                }
            }
        }

        // season_options = renderTeamSeasonOptions()

    }, [teams])

    useEffect(() => {
        console.log('Set default Team Season')

        // Needs to happen after all the individual Teams have had their Seasons loaded.  Need to track the loading status for above

        if((!loading_team_seasons && loaded_team_seasons) || (!loading_teams_managed && !loading_team_seasons && no_seasons_to_load))
        {
            if(!team_season_id)
            {
                var active_team_seasons = []

                for(const team_id in teams)
                {
                    if(teams.hasOwnProperty(team_id))
                    {
                        const team = teams[team_id]

                        const team_seasons = team.hasOwnProperty('seasons') ? team.seasons : {}

                        for(const season_id in team_seasons)
                        {
                            if(team_seasons.hasOwnProperty(season_id))
                            {
                                if(team_seasons[season_id].active)
                                {
                                    active_team_seasons = { ...active_team_seasons, [season_id]: team_seasons[season_id] }
                                }
                            }
                        }
                    }
                }

                if(Object.entries(active_team_seasons).length === 1)
                {

                    team_season_id = Number(Object.keys(active_team_seasons)[0])
                    console.log('Only team_season_id', team_season_id)
                    updateCreateRoundFormFieldValue('team_season_id', team_season_id)
                    loadTeamSeasonOpposition(team_season_id)
                    loadSupportingTeamSeasons(team_season_id)
                }
            }
        }


    }, [loaded_team_seasons])


    const { from } = props.location.state || { from: { pathname: '/' } }

    //console.log(props)

    const onSubmit = () => {
        createRound()
    }

    const toggleModal = (show) => {
        toggleCreateOpponentModal(show)
    }

    const renderTeamSeasonOptions = () => {
        var team_output = []

        for(const team_id in teams)
        {
            if(teams.hasOwnProperty(team_id))
            {
                const team = teams[team_id]

                const seasons = team.hasOwnProperty('seasons') ? team.seasons : {}

                for(const season_id in seasons)
                {
                    if(seasons.hasOwnProperty(season_id))
                    {
                        const season = seasons[season_id]

                        const end_date = moment.unix(season.end_date)
                        const start_date = moment.unix(season.start_date)

                        if(season.active)
                        {
                            team_output.push(
                                <MenuItem key={season_id} value={season_id}><span className={`${classes.menuTeamName}`}>{team.name}</span> &nbsp;&nbsp;{start_date.format('Do MMM, YYYY')} - {end_date.format('Do MMM, YYYY')}</MenuItem>
                            )
                        }

                        // const end_date = moment.unix( season.end_date )
                        // const start_date = moment.unix( season.start_date )

                        // // Only show active seasons - where the end date is in the future
                        // if( end_date.diff( moment() ) > 0 )
                        // {
                        //     team_output.push(
                        //         <MenuItem key={season_id} value={season_id}><span className={`${classes.menuTeamName}`}>{team.name}</span> &nbsp;&nbsp;{start_date.format( 'Do MMM, YYYY' )} - {end_date.format( 'Do MMM, YYYY' )}</MenuItem>
                        //     )
                        // }
                    }
                }

                // if(team_output.length)
                // {
                //     team_output.unshift(
                //         <ListSubheader key={team_id}>{team.name}</ListSubheader>
                //     )

                //     output.push(team_output)
                // }
            }
        }

        return team_output
    }

    const renderOppositionOptions = () => {

        var ordered_opposition = []

        if(opposition_teams)
        {
            for(var opposition_id in opposition_teams)
            {
                ordered_opposition.push([opposition_id, opposition_teams[opposition_id].name])
            }
        }

        // Sort the opposition by date
        ordered_opposition.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_opposition)
        {
            const opposition_team = opposition_teams[ordered_ids[0]]

            output.push(
                <MenuItem key={opposition_team.opposition_id} value={opposition_team.opposition_id}>{opposition_team.name}</MenuItem>
            )
        }

        return output
    }

    const renderSupportingTeams = () => {

        var output = []
        console.log('teams', teams)

        if(type === 'T' && team_season_id && supporting_team_seasons_options && supporting_team_seasons_options.length > 0)
        {
            for(const supporting_team_season_id of supporting_team_seasons_options)
            {

                var supporting_team = {}

                for(const team_id in teams)
                {
                    if(teams.hasOwnProperty(team_id))
                    {
                        const team = teams[team_id]
                        if(team.hasOwnProperty('seasons') && team.seasons.hasOwnProperty(supporting_team_season_id))
                        {
                            supporting_team = team
                            break
                        }
                    }
                }

                if(Object.entries(supporting_team).length !== 0)
                {
                    output.push(
                        <FormControlLabel
                            control={<Checkbox icon={<FaSquare />} checkedIcon={<FaCheckSquare />} name="supporting_team_season" />}
                            label={supporting_team.name}
                            checked={supporting_team_seasons.includes(supporting_team_season_id)}
                            onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.checked, { supporting_team_season_id: supporting_team_season_id }) }}
                        />
                    )
                }

            }
        }

        if(output.length > 0)
        {
            return (
                <Box mt={3} mb={1}>
                    <Box mb={1} mx={0}>
                        <FormHelperText>Also include these supporting Teams</FormHelperText>
                        {/* <Typography content="div" variant="body2">Also include these supporting Teams</Typography> */}
                    </Box>
                    {output}
                </Box>
            )
        }

        return output
    }

    const team_season_options = renderTeamSeasonOptions()

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Create Round</Typography>
                </Box>
                {
                    !loading_teams_managed && ((!loading_team_seasons && loaded_team_seasons) || (!loading_teams_managed && !loading_team_seasons && no_seasons_to_load)) && team_season_options.length === 0 ?

                        <Box my={2} mx={0}>
                            <Typography content="h1" variant="h1">So close!</Typography>
                            <Box my={1} mx={0}>
                                <img alt="" className={`${classes.logo}`} src="https://media.giphy.com/media/EXHHMS9caoxAA/giphy.gif" />
                            </Box>
                            <Typography content="div" variant="body1">There currently isn't any active Seasons for any of your Teams.</Typography>
                            <Typography content="div" variant="body1">Go to the admin area of the Team you want to create the round for and create a Season, then check back here.</Typography>
                        </Box>

                        :

                        <>
                            <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                                <InputLabel id="type-select-label">Round Type</InputLabel>
                                <Select
                                    labelId="type-select-label"
                                    id="type-select"
                                    value={type}
                                    name="type"
                                    onChange={(e) => {
                                        updateCreateRoundFormFieldValue(e.target.name, e.target.value)
                                        team_season_id && e.target.value === 'T' && loadSupportingTeamSeasons(team_season_id)
                                        e.target.value === 'T' && updateCreateRoundFormFieldValue('opposition', false) // Clear the Opposition selection
                                        e.target.value === 'T' && updateCreateRoundFormFieldValue('round_number', '') // Clear the Round Number
                                        e.target.value === 'G' && updateCreateRoundFormFieldValue('end_date', null) // Clear the Last Training Date
                                        e.target.value === 'T' && updateCreateRoundFormFieldValue('create_multiple_training_rounds', false) // Reset create multiple training rounds to false/no
                                    }}
                                >
                                    <MenuItem value="G">Competition Round</MenuItem>
                                    {/* <MenuItem value="F">Non-Competition / Friendly Round</MenuItem> */}
                                    <MenuItem value="T">Training</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                                <InputLabel id="team-select-label">Team - Season</InputLabel>
                                <Select
                                    labelId="team-select-label"
                                    id="team-select"
                                    value={team_season_id}
                                    name="team_season_id"
                                    onChange={(e) => {
                                        updateCreateRoundFormFieldValue(e.target.name, e.target.value)
                                        updateCreateRoundFormFieldValue('opposition', false) // Clear the Opposition selection
                                        updateCreateRoundFormFieldValue('time', null) // Clear the time selection
                                        loadTeamSeasonOpposition(e.target.value)
                                        console.log('e.target.value', e.target.value)
                                        type === 'T' && loadSupportingTeamSeasons(e.target.value)
                                    }}
                                >
                                    {team_season_options}
                                </Select>
                            </FormControl>

                            {/* <FormControlLabel
                                    value=""
                                    control={
                                        <Switch
                                            checked={create_multiple_training_rounds}
                                            onChange={(e) => {
                                                updateCreateRoundFormFieldValue(e.target.name, e.target.checked)
                                            }}
                                            color="secondary"
                                            name="create_multiple_training_rounds"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Create multiple Training Sessions"
                                    labelPlacement="end"
                                /> */}

                            {renderSupportingTeams()}

                            <DatePicker
                                name="date"
                                disableToolbar
                                variant="inline"
                                format="DD/MM/YYYY"
                                autoOk={true}
                                emptyLabel='DD/MM/YYYY'
                                margin="normal"
                                id="round-date"
                                label={create_multiple_training_rounds ? `First Training Date` : `Round Date`}
                                value={date}
                                onChange={(date) => { updateCreateRoundFormFieldValue('date', date) }}
                                className={`${classes.fullWidth} ${classes.spaced}`}
                                error={round_date_in_the_past}
                            />

                            {round_date_in_the_past &&
                                <Alert severity="error">Umm.. you can't play in the past.  Please select a date from today onwards.</Alert>
                            }

                            {type === 'T' &&
                                <FormControlLabel
                                    value=""
                                    control={
                                        <Switch
                                            checked={create_multiple_training_rounds}
                                            onChange={(e) => {
                                                updateCreateRoundFormFieldValue(e.target.name, e.target.checked)
                                            }}
                                            color="secondary"
                                            name="create_multiple_training_rounds"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Create multiple Training Sessions"
                                    labelPlacement="end"
                                />
                            }

                            {type === 'T' && create_multiple_training_rounds &&
                                <DatePicker
                                    name="end_date"
                                    disableToolbar
                                    variant="inline"
                                    format="DD/MM/YYYY"
                                    autoOk={true}
                                    emptyLabel='DD/MM/YYYY'
                                    margin="normal"
                                    id="round-end-date"
                                    label="Last Training Date"
                                    value={end_date}
                                    onChange={(date) => { updateCreateRoundFormFieldValue('end_date', date) }}
                                    className={`${classes.fullWidth} ${classes.spaced}`}
                                    error={round_end_date_in_the_past}
                                />
                            }

                            {round_end_date_in_the_past &&
                                <Alert severity="error">Please select a date after the First Training Date.</Alert>
                            }

                            {type === 'T' && create_multiple_training_rounds &&
                                <Alert severity="info">{`${number_of_training_rounds} Training Sessions, on ${training_session_day}'s`}</Alert>
                            }

                            <TimePicker
                                name="time"
                                margin="normal"
                                ampm={false}
                                autoOk={true}
                                emptyLabel='00:00'
                                minutesStep={5}
                                variant="inline"
                                id="round-time"
                                label="Time"
                                value={time}
                                onChange={(time) => { updateCreateRoundFormFieldValue('time', time) }}
                                className={`${classes.fullWidth} ${classes.spaced}`}
                            />
                            {
                                type === 'G' &&
                                <TextField
                                    label="Round Number"
                                    placeholder="1"
                                    // error={touched && invalid}
                                    // helperText={touched && error}
                                    className={`${classes.fullWidth} ${classes.spaced}`}
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                    name={`round_number`}
                                    autoComplete="off"
                                    value={round_number}
                                    onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.value) }}
                                />
                            }
                            {
                                type === 'G' &&
                                <Grid container
                                    alignItems="flex-end"
                                    wrap="nowrap"
                                    justify="space-between"
                                >
                                    <Grid item
                                        className={` ${classes.grow}`}
                                    >
                                        <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                                            <InputLabel id="opposition-select-label">Opposition</InputLabel>
                                            <Select
                                                labelId="opposition-select-label"
                                                id="opposition-select"
                                                value={opposition}
                                                name="opposition"
                                                disabled={team_season_id ? false : true}
                                                onChange={(e) => { updateCreateRoundFormFieldValue(e.target.name, e.target.value) }}
                                            >
                                                {/* <MenuItem value="G">Game / Competition Round</MenuItem> */}
                                                {renderOppositionOptions()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" className={`${classes.fullWidth}`}
                                            disabled={team_season_id ? false : true}
                                            onClick={() => { toggleModal(true) }}
                                        >
                                            + New
                                        </Button>
                                    </Grid>
                                </Grid>

                            }

                            {
                                !team_season_id &&
                                <Box mb={2}><Alert severity="info">Select a Team - Season first</Alert></Box>
                            }

                            <form onSubmit={(e) => { e.preventDefault(); updateCreateRoundFormFieldValue('preview_location_name', location_name) }}>

                                <Grid container
                                    alignItems="flex-end"
                                    wrap="nowrap"
                                    justify="space-between"
                                >
                                    <Grid item
                                        className={` ${classes.grow}`}
                                    >
                                        <TextField
                                            label="Location Name"
                                            placeholder=""
                                            // error={touched && invalid}
                                            // helperText={touched && error}
                                            className={`${classes.fullWidth} ${classes.spaced} ${classes.grow} ${classes.paddedRight}`}
                                            name={`location_name`}
                                            inputProps={{
                                                maxLength: 100,
                                            }}
                                            type="text"
                                            autoComplete="off"
                                            value={location_name}
                                            onChange={(e) => {
                                                updateCreateRoundFormFieldValue(e.target.name, e.target.value)
                                                updateCreateRoundFormFieldValue('preview_location_name', false) // Clear the map when the locaiton text changes to ensure the user has previewed the map before submitting
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" className={`${classes.fullWidth}`}
                                            onClick={() => { updateCreateRoundFormFieldValue('preview_location_name', location_name) }}
                                        >
                                            Preview
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Box mb={2}><Alert severity="info">Type in what you would search for in Google Maps.  Try to be specific enough to have a single pin appear with the place name in a white box at the top left of the map.</Alert></Box>

                            </form>

                            <Box mt={2}>
                                <Map location_name={preview_location_name} />
                            </Box>

                            <Box my={3}>
                                <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                                    disabled={round_date_in_the_past || round_end_date_in_the_past}
                                    onClick={() => { onSubmit() }}
                                >
                                    Create
                                </Button>
                            </Box>
                        </>
                }
            </Box>


            <Dialog
                open={create_round_create_opponent_show}
                onClose={() => { toggleModal(false) }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {/* <DialogTitle id="scroll-dialog-title">Select Icon</DialogTitle> */}
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        // ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <CreateOpponent team_season_id={team_season_id} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { toggleModal(false) }} color="secondary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = state => {

    const team_season_id = state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') ? Number(state.admin.round.create.team_season_id) : undefined

    const loading_team_season_keys = Object.keys(state.loading).filter(function(k) { return ~k.indexOf("LOAD_TEAM_SEASONS-") })

    var loading_team_seasons = false
    for(const loading_key in loading_team_season_keys)
    {
        if(Object.hasOwnProperty.call(loading_team_season_keys, loading_key))
        {
            const event = state.loading[loading_team_season_keys[loading_key]]

            if(event)
            {
                loading_team_seasons = true
                break
            }
            loading_team_seasons = false
        }
    }

    var loaded_team_seasons = false
    for(const loading_key in loading_team_season_keys)
    {
        if(Object.hasOwnProperty.call(loading_team_season_keys, loading_key))
        {
            const event = state.loading[loading_team_season_keys[loading_key]]

            if(event)
            {
                loaded_team_seasons = false
                break
            }
            loaded_team_seasons = true
        }
    }

    return {
        teams: state.admin.hasOwnProperty('team') ? state.admin.team : undefined,
        opposition_teams: team_season_id && state.admin.hasOwnProperty('opposition') && state.admin.opposition.hasOwnProperty(team_season_id) ? state.admin.opposition[team_season_id] : undefined,
        supporting_team_seasons_options: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('supporting_team_seasons_options') ? state.admin.round.create.supporting_team_seasons_options : undefined,
        supporting_team_seasons: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('supporting_team_seasons') ? state.admin.round.create.supporting_team_seasons : undefined,
        seasons: state.hasOwnProperty('season') ? state.season : false,

        team_season_id,
        round_number: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('round_number') ? state.admin.round.create.round_number : '',
        type: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('type') ? state.admin.round.create.type : 'G',
        date: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('date') ? state.admin.round.create.date : null,
        end_date: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('end_date') ? state.admin.round.create.end_date : null,
        create_multiple_training_rounds: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('create_multiple_training_rounds') ? state.admin.round.create.create_multiple_training_rounds : null,
        time: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('time') ? state.admin.round.create.time : null,
        opposition: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('opposition') ? state.admin.round.create.opposition : '',
        location_name: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('location_name') ? state.admin.round.create.location_name : '',
        preview_location_name: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty('create') && state.admin.round.create.hasOwnProperty('preview_location_name') ? state.admin.round.create.preview_location_name : false,
        loading_teams_managed: !state.loading.hasOwnProperty('LOAD_TEAMS_MANAGED') || (state.loading.hasOwnProperty('LOAD_TEAMS_MANAGED') && state.loading.LOAD_TEAMS_MANAGED) ? true : false,
        loading_team_seasons,
        loaded_team_seasons,

        create_round_create_opponent_show: state.ui.hasOwnProperty('admin') && state.ui.admin.hasOwnProperty('create_round_create_opponent_show') ? state.ui.admin.create_round_create_opponent_show : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { createRound, updateCreateRoundFormFieldValue, loadManagedTeams, loadTeamSeasons, loadTeamSeasonOpposition, loadSupportingTeamSeasons, toggleCreateOpponentModal })(CreateRound))
