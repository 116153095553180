// Authentication
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE'

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE'

export const LOG_OUT = 'LOG_OUT'

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'

export const UPDATE_USER_ID = 'UPDATE_USER_ID'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_TRUSTED_DEVICE = 'UPDATE_TRUSTED_DEVICE'

export const FORCED_LOG_OUT = 'FORCED_LOG_OUT'
export const FORCED_LOG_OUT_RESET = 'FORCED_LOG_OUT_RESET'

// Navigation
export const SLIDE_OPEN = 'SLIDE_OPEN'
export const SLIDE_CLOSE = 'SLIDE_CLOSE'

// User
export const LOAD_ANNOUNCEMENTS_REQUEST = 'LOAD_ANNOUNCEMENTS_REQUEST'
export const LOAD_ANNOUNCEMENTS_SUCCESS = 'LOAD_ANNOUNCEMENTS_SUCCESS'
export const LOAD_ANNOUNCEMENTS_FAILURE = 'LOAD_ANNOUNCEMENTS_FAILURE'

export const ANNOUNCEMENTS_READ_REQUEST = 'ANNOUNCEMENTS_READ_REQUEST'
export const ANNOUNCEMENTS_READ_SUCCESS = 'ANNOUNCEMENTS_READ_SUCCESS'
export const ANNOUNCEMENTS_READ_FAILURE = 'ANNOUNCEMENTS_READ_FAILURE'

export const ANNOUNCEMENTS_DISMISSED = 'ANNOUNCEMENTS_DISMISSED'

export const SET_WEB_PUSH_SUBSCRIPTION = 'SET_WEB_PUSH_SUBSCRIPTION'

export const SET_WEB_PUSH_SUBSCRIPTION_REQUEST = 'SET_WEB_PUSH_SUBSCRIPTION_REQUEST'
export const SET_WEB_PUSH_SUBSCRIPTION_SUCCESS = 'SET_WEB_PUSH_SUBSCRIPTION_SUCCESS'
export const SET_WEB_PUSH_SUBSCRIPTION_FAILURE = 'SET_WEB_PUSH_SUBSCRIPTION_FAILURE'

// Vote
export const VOTE_BUTTON_CLICK = 'VOTE_BUTTON_CLICK'
export const POINTS_BUTTON_CLICK = 'POINTS_BUTTON_CLICK'
export const UPDATE_VOTE_COMMENT = 'UPDATE_VOTE_COMMENT'

export const CAST_VOTE_REQUEST = 'CAST_VOTE_REQUEST'
export const CAST_VOTE_SUCCESS = 'CAST_VOTE_SUCCESS'
export const CAST_VOTE_FAILURE = 'CAST_VOTE_FAILURE'

export const LOAD_ROUND_VOTES_REQUEST = 'LOAD_ROUND_VOTES_REQUEST'
export const LOAD_ROUND_VOTES_SUCCESS = 'LOAD_ROUND_VOTES_SUCCESS'
export const LOAD_ROUND_VOTES_FAILURE = 'LOAD_ROUND_VOTES_FAILURE'

// Round
export const CARD_BUTTON_CLICK = 'CARD_BUTON_CLICK'

export const ATTENDANCE_BUTTON_CLICK = 'ATTENDANCE_BUTTON_CLICK'
export const UPDATE_ATTENDANCE_COMMENT = 'UPDATE_ATTENDANCE_COMMENT'
export const NUMBER_OF_GAMES_BUTTON_CLICK = 'NUMBER_OF_GAMES_BUTTON_CLICK'

export const CREATE_ROUND_REQUEST = 'CREATE_ROUND_REQUEST'
export const CREATE_ROUND_SUCCESS = 'CREATE_ROUND_SUCCESS'
export const CREATE_ROUND_FAILURE = 'CREATE_ROUND_FAILURE'

export const UPDATE_ROUND_REQUEST = 'UPDATE_ROUND_REQUEST'
export const UPDATE_ROUND_SUCCESS = 'UPDATE_ROUND_SUCCESS'
export const UPDATE_ROUND_FAILURE = 'UPDATE_ROUND_FAILURE'

export const DELETE_ROUND_REQUEST = 'DELETE_ROUND_REQUEST'
export const DELETE_ROUND_SUCCESS = 'DELETE_ROUND_SUCCESS'
export const DELETE_ROUND_FAILURE = 'DELETE_ROUND_FAILURE'

export const SAVE_ATTENDANCE_REQUEST = 'SAVE_ATTENDANCE_REQUEST'
export const SAVE_ATTENDANCE_SUCCESS = 'SAVE_ATTENDANCE_SUCCESS'
export const SAVE_ATTENDANCE_FAILURE = 'SAVE_ATTENDANCE_FAILURE'

export const LOAD_ROUND_REQUEST = 'LOAD_ROUND_REQUEST'
export const LOAD_ROUND_SUCCESS = 'LOAD_ROUND_SUCCESS'
export const LOAD_ROUND_FAILURE = 'LOAD_ROUND_FAILURE'

export const LOAD_ROUND_DETAIL_REQUEST = 'LOAD_ROUND_DETAIL_REQUEST'
export const LOAD_ROUND_DETAIL_SUCCESS = 'LOAD_ROUND_DETAIL_SUCCESS'
export const LOAD_ROUND_DETAIL_FAILURE = 'LOAD_ROUND_DETAIL_FAILURE'

export const LOAD_ROUNDS_REQUEST = 'LOAD_ROUNDS_REQUEST'
export const LOAD_ROUNDS_SUCCESS = 'LOAD_ROUNDS_SUCCESS'
export const LOAD_ROUNDS_FAILURE = 'LOAD_ROUNDS_FAILURE'

// export const LOAD_TEAM_ROUNDS_REQUEST = 'LOAD_TEAM_ROUNDS_REQUEST'
// export const LOAD_TEAM_ROUNDS_SUCCESS = 'LOAD_TEAM_ROUNDS_SUCCESS'
// export const LOAD_TEAM_ROUNDS_FAILURE = 'LOAD_TEAM_ROUNDS_FAILURE'

export const LOAD_ROUND_ATTENDANCE_REQUEST = 'LOAD_ROUND_ATTENDANCE_REQUEST'
export const LOAD_ROUND_ATTENDANCE_SUCCESS = 'LOAD_ROUND_ATTENDANCE_SUCCESS'
export const LOAD_ROUND_ATTENDANCE_FAILURE = 'LOAD_ROUND_ATTENDANCE_FAILURE'

export const LOAD_ROUND_CARD_REQUEST = 'LOAD_ROUND_CARD_REQUEST'
export const LOAD_ROUND_CARD_SUCCESS = 'LOAD_ROUND_CARD_SUCCESS'
export const LOAD_ROUND_CARD_FAILURE = 'LOAD_ROUND_CARD_FAILURE'

export const SAVE_LINEUP_REQUEST = 'SAVE_LINEUP_REQUEST'
export const SAVE_LINEUP_SUCCESS = 'SAVE_LINEUP_SUCCESS'
export const SAVE_LINEUP_FAILURE = 'SAVE_LINEUP_FAILURE'

export const LOAD_ROUND_LEADERBOARD_REQUEST = 'LOAD_ROUND_LEADERBOARD_REQUEST'
export const LOAD_ROUND_LEADERBOARD_SUCCESS = 'LOAD_ROUND_LEADERBOARD_SUCCESS'
export const LOAD_ROUND_LEADERBOARD_FAILURE = 'LOAD_ROUND_LEADERBOARD_FAILURE'

export const LOAD_ROUND_WHITEBOARD_REQUEST = 'LOAD_ROUND_WHITEBOARD_REQUEST'
export const LOAD_ROUND_WHITEBOARD_SUCCESS = 'LOAD_ROUND_WHITEBOARD_SUCCESS'
export const LOAD_ROUND_WHITEBOARD_FAILURE = 'LOAD_ROUND_WHITEBOARD_FAILURE'

export const SAVE_ROUND_WHITEBOARD_REQUEST = 'SAVE_ROUND_WHITEBOARD_REQUEST'
export const SAVE_ROUND_WHITEBOARD_SUCCESS = 'SAVE_ROUND_WHITEBOARD_SUCCESS'
export const SAVE_ROUND_WHITEBOARD_FAILURE = 'SAVE_ROUND_WHITEBOARD_FAILURE'

export const UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_REQUEST = 'UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_REQUEST'
export const UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS = 'UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS'
export const UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_FAILURE = 'UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_FAILURE'

export const LOAD_ROUND_SCRIBBLE_REQUEST = 'LOAD_ROUND_SCRIBBLE_REQUEST'
export const LOAD_ROUND_SCRIBBLE_SUCCESS = 'LOAD_ROUND_SCRIBBLE_SUCCESS'
export const LOAD_ROUND_SCRIBBLE_FAILURE = 'LOAD_ROUND_SCRIBBLE_FAILURE'

export const LOAD_PLAYER_VOTING_STATS_REQUEST = 'LOAD_PLAYER_VOTING_STATS_REQUEST'
export const LOAD_PLAYER_VOTING_STATS_SUCCESS = 'LOAD_PLAYER_VOTING_STATS_SUCCESS'
export const LOAD_PLAYER_VOTING_STATS_FAILURE = 'LOAD_PLAYER_VOTING_STATS_FAILURE'

export const SHOW_ROUND_LEADERBOARD = 'SHOW_ROUND_LEADERBOARD'
export const HIDE_ROUND_LEADERBOARD = 'HIDE_ROUND_LEADERBOARD'

export const UPDATE_CREATE_ROUND_FORM_FIELD = 'UPDATE_CREATE_ROUND_FORM_FIELD'

export const UPDATE_ROUND_WHITEBOARD_TEXT = 'UPDATE_ROUND_WHITEBOARD_TEXT'


// Player
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const LOAD_PLAYER_REQUEST = 'LOAD_PLAYER_REQUEST'
export const LOAD_PLAYER_SUCCESS = 'LOAD_PLAYER_SUCCESS'
export const LOAD_PLAYER_FAILURE = 'LOAD_PLAYER_FAILURE'

export const LOAD_PROFILE_STATS_REQUEST = 'LOAD_PROFILE_STATS_REQUEST'
export const LOAD_PROFILE_STATS_SUCCESS = 'LOAD_PROFILE_STATS_SUCCESS'
export const LOAD_PROFILE_STATS_FAILURE = 'LOAD_PROFILE_STATS_FAILURE'

export const UPDATE_PLAYER_REQUEST = 'UPDATE_PLAYER_REQUEST'
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS'
export const UPDATE_PLAYER_FAILURE = 'UPDATE_PLAYER_FAILURE'

export const UPDATE_UPDATE_PLAYER_FORM_FIELD = 'UPDATE_UPDATE_PLAYER_FORM_FIELD'

//UI
export const INCREMENT_ROUND_SHOW_COUNT = 'INCREMENT_ROUND_SHOW_COUNT'

export const TOGGLE_ADMIN_VIEW = 'TOGGLE_ADMIN_VIEW'

export const UPDATE_ROUND_STATS_PLAYER_SELECT = 'UPDATE_ROUND_STATS_PLAYER_SELECT'
export const UPDATE_ROUND_STATS_STAT_SELECT = 'UPDATE_ROUND_STATS_STAT_SELECT'

export const UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW = 'UPDATE_ROUND_ATTENDANCE_PLAYER_STATUS_TO_SHOW'

export const TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW = 'TOGGLE_TEAM_STAT_TYPE_ICON_SELECTOR_MODAL_SHOW'

export const TOGGLE_CREATE_OPPONENT_MODAL_SHOW = 'TOGGLE_CREATE_OPPONENT_MODAL_SHOW'

export const TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW = 'TOGGLE_UNREGISTER_TEAM_USER_MODAL_SHOW'

//Leaderboard
export const LOAD_LEADERBOARD_REQUEST = 'LOAD_LEADERBOARD_REQUEST'
export const LOAD_LEADERBOARD_SUCCESS = 'LOAD_LEADERBOARD_SUCCESS'
export const LOAD_LEADERBOARD_FAILURE = 'LOAD_LEADERBOARD_FAILURE'

// Teams

export const LOAD_USER_TEAMS_REQUEST = 'LOAD_USER_TEAMS_REQUEST'
export const LOAD_USER_TEAMS_SUCCESS = 'LOAD_USER_TEAMS_SUCCESS'
export const LOAD_USER_TEAMS_FAILURE = 'LOAD_USER_TEAMS_FAILURE'

export const LOAD_SEASONS_REQUEST = 'LOAD_SEASONS_REQUEST'
export const LOAD_SEASONS_SUCCESS = 'LOAD_SEASONS_SUCCESS'
export const LOAD_SEASONS_FAILURE = 'LOAD_SEASONS_FAILURE'

export const LOAD_TEAMS_MANAGED_REQUEST = 'LOAD_TEAMS_MANAGED_REQUEST'
export const LOAD_TEAMS_MANAGED_SUCCESS = 'LOAD_TEAMS_MANAGED_SUCCESS'
export const LOAD_TEAMS_MANAGED_FAILURE = 'LOAD_TEAMS_MANAGED_FAILURE'

export const UPDATE_USER_TEAM_REGISTRATION_STATUS_REQUEST = 'UPDATE_USER_TEAM_REGISTRATION_STATUS_REQUEST'
export const UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS = 'UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS'
export const UPDATE_USER_TEAM_REGISTRATION_STATUS_FAILURE = 'UPDATE_USER_TEAM_REGISTRATION_STATUS_FAILURE'

export const ADD_SEASON_PLAYER_REQUEST = 'ADD_SEASON_PLAYER_REQUEST'
export const ADD_SEASON_PLAYER_SUCCESS = 'ADD_SEASON_PLAYER_SUCCESS'
export const ADD_SEASON_PLAYER_FAILURE = 'ADD_SEASON_PLAYER_FAILURE'

export const DEACTIVATE_SEASON_PLAYER_REQUEST = 'DEACTIVATE_SEASON_PLAYER_REQUEST'
export const DEACTIVATE_SEASON_PLAYER_SUCCESS = 'DEACTIVATE_SEASON_PLAYER_SUCCESS'
export const DEACTIVATE_SEASON_PLAYER_FAILURE = 'DEACTIVATE_SEASON_PLAYER_FAILURE'

// Registration

export const LOAD_TEAM_REGISTRATION_STATUSES_REQUEST = 'LOAD_TEAM_REGISTRATION_STATUSES_REQUEST'
export const LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS = 'LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS'
export const LOAD_TEAM_REGISTRATION_STATUSES_FAILURE = 'LOAD_TEAM_REGISTRATION_STATUSES_FAILURE'

// Team
export const LOAD_TEAM = 'LOAD_TEAM'

export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST'
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE'

export const LOAD_TEAM_ADMIN_STATS_REQUEST = 'LOAD_TEAM_ADMIN_STATS_REQUEST'
export const LOAD_TEAM_ADMIN_STATS_SUCCESS = 'LOAD_TEAM_ADMIN_STATS_SUCCESS'
export const LOAD_TEAM_ADMIN_STATS_FAILURE = 'LOAD_TEAM_ADMIN_STATS_FAILURE'

export const LOAD_TEAM_SEASONS_REQUEST = 'LOAD_TEAM_SEASONS_REQUEST'
export const LOAD_TEAM_SEASONS_SUCCESS = 'LOAD_TEAM_SEASONS_SUCCESS'
export const LOAD_TEAM_SEASONS_FAILURE = 'LOAD_TEAM_SEASONS_FAILURE'

export const LOAD_TEAM_SEASON_REQUEST = 'LOAD_TEAM_SEASON_REQUEST'
export const LOAD_TEAM_SEASON_SUCCESS = 'LOAD_TEAM_SEASON_SUCCESS'
export const LOAD_TEAM_SEASON_FAILURE = 'LOAD_TEAM_SEASON_FAILURE'

export const LOAD_TEAM_SEASON_DETAIL_REQUEST = 'LOAD_TEAM_SEASON_DETAIL_REQUEST'
export const LOAD_TEAM_SEASON_DETAIL_SUCCESS = 'LOAD_TEAM_SEASON_DETAIL_SUCCESS'
export const LOAD_TEAM_SEASON_DETAIL_FAILURE = 'LOAD_TEAM_SEASON_DETAIL_FAILURE'

export const LOAD_TEAM_SEASON_REGISTRATION_REQUEST = 'LOAD_TEAM_SEASON_REGISTRATION_REQUEST'
export const LOAD_TEAM_SEASON_REGISTRATION_SUCCESS = 'LOAD_TEAM_SEASON_REGISTRATION_SUCCESS'
export const LOAD_TEAM_SEASON_REGISTRATION_FAILURE = 'LOAD_TEAM_SEASON_REGISTRATION_FAILURE'

export const LOAD_TEAM_DETAIL_REQUEST = 'LOAD_TEAM_DETAIL_REQUEST'
export const LOAD_TEAM_DETAIL_SUCCESS = 'LOAD_TEAM_DETAIL_SUCCESS'
export const LOAD_TEAM_DETAIL_FAILURE = 'LOAD_TEAM_DETAIL_FAILURE'

export const LOAD_SPORTS_REQUEST = 'LOAD_SPORTS_REQUEST'
export const LOAD_SPORTS_SUCCESS = 'LOAD_SPORTS_SUCCESS'
export const LOAD_SPORTS_FAILURE = 'LOAD_SPORTS_FAILURE'

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST'
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS'
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE'

export const UPDATE_CREATE_TEAM_FORM_FIELD = 'UPDATE_CREATE_TEAM_FORM_FIELD'

export const LOAD_TEAM_OPPOSITION_REQUEST = 'LOAD_TEAM_OPPOSITION_REQUEST'
export const LOAD_TEAM_OPPOSITION_SUCCESS = 'LOAD_TEAM_OPPOSITION_SUCCESS'
export const LOAD_TEAM_OPPOSITION_FAILURE = 'LOAD_TEAM_OPPOSITION_FAILURE'

// OPPOSITION

export const LOAD_OPPONENT_REQUEST = 'LOAD_OPPONENT_DETAIL_REQUEST'
export const LOAD_OPPONENT_SUCCESS = 'LOAD_OPPONENT_DETAIL_SUCCESS'
export const LOAD_OPPONENT_FAILURE = 'LOAD_OPPONENT_DETAIL_FAILURE'

export const LOAD_OPPONENT_DETAIL_REQUEST = 'LOAD_OPPONENT_DETAIL_REQUEST'
export const LOAD_OPPONENT_DETAIL_SUCCESS = 'LOAD_OPPONENT_DETAIL_SUCCESS'
export const LOAD_OPPONENT_DETAIL_FAILURE = 'LOAD_OPPONENT_DETAIL_FAILURE'

export const CREATE_OPPONENT_REQUEST = 'CREATE_OPPONENT_REQUEST'
export const CREATE_OPPONENT_SUCCESS = 'CREATE_OPPONENT_SUCCESS'
export const CREATE_OPPONENT_FAILURE = 'CREATE_OPPONENT_FAILURE'

export const UPDATE_OPPONENT_REQUEST = 'UPDATE_OPPONENT_REQUEST'
export const UPDATE_OPPONENT_SUCCESS = 'UPDATE_OPPONENT_SUCCESS'
export const UPDATE_OPPONENT_FAILURE = 'UPDATE_OPPONENT_FAILURE'

export const UPDATE_CREATE_OPPONENT_FORM_FIELD = 'UPDATE_CREATE_OPPONENT_FORM_FIELD'

// Stats

export const UPDATE_TEAM_STAT_TYPE_FORM_FIELD = 'UPDATE_TEAM_STAT_TYPE_FORM_FIELD'

export const CLEAR_TEAM_STAT_TYPE_FORM_FIELD = 'CLEAR_TEAM_STAT_TYPE_FORM_FIELD'

export const LOAD_TEAM_STAT_TYPE_DETAIL_REQUEST = 'LOAD_TEAM_STAT_TYPE_DETAIL_REQUEST'
export const LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS = 'LOAD_TEAM_STAT_TYPE_DETAIL_SUCCESS'
export const LOAD_TEAM_STAT_TYPE_DETAIL_FAILURE = 'LOAD_TEAM_STAT_TYPE_DETAIL_FAILURE'

export const CREATE_TEAM_STAT_TYPE_REQUEST = 'CREATE_TEAM_STAT_TYPE_REQUEST'
export const CREATE_TEAM_STAT_TYPE_SUCCESS = 'CREATE_TEAM_STAT_TYPE_SUCCESS'
export const CREATE_TEAM_STAT_TYPE_FAILURE = 'CREATE_TEAM_STAT_TYPE_FAILURE'

export const UPDATE_TEAM_STAT_TYPE_REQUEST = 'UPDATE_TEAM_STAT_TYPE_REQUEST'
export const UPDATE_TEAM_STAT_TYPE_SUCCESS = 'UPDATE_TEAM_STAT_TYPE_SUCCESS'
export const UPDATE_TEAM_STAT_TYPE_FAILURE = 'UPDATE_TEAM_STAT_TYPE_FAILURE'

export const SET_TEAM_STAT_TYPE_ACTIVE_STATUS_REQUEST = 'SET_TEAM_STAT_TYPE_ACTIVE_STATUS_REQUEST'
export const SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS = 'SET_TEAM_STAT_TYPE_ACTIVE_STATUS_SUCCESS'
export const SET_TEAM_STAT_TYPE_ACTIVE_STATUS_FAILURE = 'SET_TEAM_STAT_TYPE_ACTIVE_STATUS_FAILURE'

// Season

export const LOAD_TEAM_SEASON_OPPOSITION_REQUEST = 'LOAD_TEAM_SEASON_OPPOSITION_REQUEST'
export const LOAD_TEAM_SEASON_OPPOSITION_SUCCESS = 'LOAD_TEAM_SEASON_OPPOSITION_SUCCESS'
export const LOAD_TEAM_SEASON_OPPOSITION_FAILURE = 'LOAD_TEAM_SEASON_OPPOSITION_FAILURE'

export const UPDATE_CREATE_SEASON_FORM_FIELD = 'UPDATE_CREATE_SEASON_FORM_FIELD'

export const CREATE_SEASON_REQUEST = 'CREATE_SEASON_REQUEST'
export const CREATE_SEASON_SUCCESS = 'CREATE_SEASON_SUCCESS'
export const CREATE_SEASON_FAILURE = 'CREATE_SEASON_FAILURE'

export const UPDATE_SEASON_REQUEST = 'UPDATE_SEASON_REQUEST'
export const UPDATE_SEASON_SUCCESS = 'UPDATE_SEASON_SUCCESS'
export const UPDATE_SEASON_FAILURE = 'UPDATE_SEASON_FAILURE'

export const LOAD_USER_SEASONS_STATS_REQUEST = 'LOAD_USER_SEASONS_STATS_REQUEST'
export const LOAD_USER_SEASONS_STATS_SUCCESS = 'LOAD_USER_SEASONS_STATS_SUCCESS'
export const LOAD_USER_SEASONS_STATS_FAILURE = 'LOAD_USER_SEASONS_STATS_FAILURE'

export const LOAD_USER_SEASONS_REQUEST = 'LOAD_USER_SEASONS_REQUEST'
export const LOAD_USER_SEASONS_SUCCESS = 'LOAD_USER_SEASONS_SUCCESS'
export const LOAD_USER_SEASONS_FAILURE = 'LOAD_USER_SEASONS_FAILURE'

export const LOAD_SUPPORTING_TEAM_SEASONS_REQUEST = 'LOAD_SUPPORTING_TEAM_SEASONS_REQUEST'
export const LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS = 'LOAD_SUPPORTING_TEAM_SEASONS_SUCCESS'
export const LOAD_SUPPORTING_TEAM_SEASONS_FAILURE = 'LOAD_SUPPORTING_TEAM_SEASONS_FAILURE'

export const UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD = 'UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD'

export const LOAD_SEASON_SETTINGS_REQUEST = 'LOAD_SEASON_SETTINGS_REQUEST'
export const LOAD_SEASON_SETTINGS_SUCCESS = 'LOAD_SEASON_SETTINGS_SUCCESS'
export const LOAD_SEASON_SETTINGS_FAILURE = 'LOAD_SEASON_SETTINGS_FAILURE'

export const UPDATE_SEASON_SETTINGS_REQUEST = 'UPDATE_SEASON_SETTINGS_REQUEST'
export const UPDATE_SEASON_SETTINGS_SUCCESS = 'UPDATE_SEASON_SETTINGS_SUCCESS'
export const UPDATE_SEASON_SETTINGS_FAILURE = 'UPDATE_SEASON_SETTINGS_FAILURE'

export const LOAD_SEASON_RESULTS_REQUEST = 'LOAD_SEASON_RESULTS_REQUEST'
export const LOAD_SEASON_RESULTS_SUCCESS = 'LOAD_SEASON_RESULTS_SUCCESS'
export const LOAD_SEASON_RESULTS_FAILURE = 'LOAD_SEASON_RESULTS_FAILURE'

export const UPDATE_SEASON_ACTIVE_STATUS_REQUEST = 'UPDATE_SEASON_ACTIVE_STATUS_REQUEST'
export const UPDATE_SEASON_ACTIVE_STATUS_SUCCESS = 'UPDATE_SEASON_ACTIVE_STATUS_SUCCESS'
export const UPDATE_SEASON_ACTIVE_STATUS_FAILURE = 'UPDATE_SEASON_ACTIVE_STATUS_FAILURE'

export const ADD_OPPOSITION_TO_SEASON_REQUEST = 'ADD_OPPOSITION_TO_SEASON_REQUEST'
export const ADD_OPPOSITION_TO_SEASON_SUCCESS = 'ADD_OPPOSITION_TO_SEASON_SUCCESS'
export const ADD_OPPOSITION_TO_SEASON_FAILURE = 'ADD_OPPOSITION_TO_SEASON_FAILURE'


// Opposition

export const ADD_OPPONENT_TEAM_SEASON_REQUEST = 'ADD_OPPONENT_TEAM_SEASON_REQUEST'
export const ADD_OPPONENT_TEAM_SEASON_SUCCESS = 'ADD_OPPONENT_TEAM_SEASON_SUCCESS'
export const ADD_OPPONENT_TEAM_SEASON_FAILURE = 'ADD_OPPONENT_TEAM_SEASON_FAILURE'



// Toast
export const QUEUE_TOAST = 'QUEUE_TOAST'
export const QUEUE_TOAST_CUSTOM = 'QUEUE_TOAST_CUSTOM'
export const REMOVE_TOAST = 'REMOVE_TOAST'


// Admin
export const ADD_ROUND_PLAYER_STAT_REQUEST = 'ADD_ROUND_PLAYER_STAT_REQUEST'
export const ADD_ROUND_PLAYER_STAT_SUCCESS = 'ADD_ROUND_PLAYER_STAT_SUCCESS'
export const ADD_ROUND_PLAYER_STAT_FAILURE = 'ADD_ROUND_PLAYER_STAT_FAILURE'

export const LOAD_ROUND_PLAYERS_STATS_REQUEST = 'LOAD_ROUND_PLAYERS_STATS_REQUEST'
export const LOAD_ROUND_PLAYERS_STATS_SUCCESS = 'LOAD_ROUND_PLAYERS_STATS_SUCCESS'
export const LOAD_ROUND_PLAYERS_STATS_FAILURE = 'LOAD_ROUND_PLAYERS_STATS_FAILURE'

export const REMOVE_ROUND_PLAYER_STAT_REQUEST = 'REMOVE_ROUND_PLAYER_STAT_REQUEST'
export const REMOVE_ROUND_PLAYER_STAT_SUCCESS = 'REMOVE_ROUND_PLAYER_STAT_SUCCESS'
export const REMOVE_ROUND_PLAYER_STAT_FAILURE = 'REMOVE_ROUND_PLAYER_STAT_FAILURE'

export const INCREMENT_TEAM_SCORE = 'INCREMENT_TEAM_SCORE'
export const INCREMENT_OPPOSITION_SCORE = 'INCREMENT_OPPOSITION_SCORE'
export const SAVE_ROUND_SCORE_REQUEST = 'SAVE_ROUND_SCORE_REQUEST'
export const SAVE_ROUND_SCORE_SUCCESS = 'SAVE_ROUND_SCORE_SUCCESS'
export const SAVE_ROUND_SCORE_FAILURE = 'SAVE_ROUND_SCORE_FAILURE'


// Registration
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'
export const UPDATE_USER_REGISTRATION_FORM_FIELD = 'UPDATE_USER_REGISTRATION_FORM_FIELD'

export const UPDATE_TEAM_REGISTRATION_CODE = 'UPDATE_TEAM_REGISTRATION_CODE'

export const REQUEST_TEAM_REQUEST = 'REQUEST_TEAM_REQUEST'
export const REQUEST_TEAM_SUCCESS = 'REQUEST_TEAM_SUCCESS'
export const REQUEST_TEAM_FAILURE = 'REQUEST_TEAM_FAILURE'

export const LOAD_TEAMS_REGISTRATION_REQUEST = 'LOAD_TEAMS_REGISTRATION_REQUEST'
export const LOAD_TEAMS_REGISTRATION_SUCCESS = 'LOAD_TEAMS_REGISTRATION_SUCCESS'
export const LOAD_TEAMS_REGISTRATION_FAILURE = 'LOAD_TEAMS_REGISTRATION_FAILURE'