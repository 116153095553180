import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamSeasons, loadManagedTeams, loadUserTeams } from '../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'
import TeamSeasonListItem from './TeamSeasonListItem'
import { SEASON_LEADERBOARD } from '../../navigation'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    adminMenuButton: {
        width: '100%'
    },
})


const PastTeamSeasons = props => {
    console.log('[] Team Seasons')

    const team_id = props.match.params.team_id

    const { classes, loadTeamSeasons, loadManagedTeams, loadUserTeams, seasons, team } = props // Connect

    const renderSeasons = (team_seasons) => {

        var ordered_seasons = []

        console.log('team', team)

        for(const season_id of team_seasons)
        {
            ordered_seasons.push([season_id, seasons[season_id].start_date])
        }

        // Sort the seasons by date
        ordered_seasons.sort((a, b) => b[1] > a[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_seasons)
        {
            const team_season_id = ordered_ids[0]

            const { club_name, age_group, division, start_date, end_date, active } = seasons[team_season_id]

            if(active)
            {
                continue
            }

            output.push(
                <React.Fragment key={team_season_id}>
                    <Box my={2} mx={1} >
                        <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} link={`${SEASON_LEADERBOARD}/${team_season_id}`} />
                    </Box>
                    <Divider />
                </React.Fragment>
            )
        }

        return output
    }

    // Load the round data
    useEffect(() => {
        loadTeamSeasons(team_id)
        // loadManagedTeams()
        loadUserTeams()
    }, [team_id])

    return (
        <>
            <Box my={2} mx={1}>
                <Typography content="h2" variant="h2">{team.name ? team.name : 'Loading'}</Typography>
                <Typography content="h1" variant="h1">Past Seasons</Typography>
            </Box>

            <Divider />

            {renderSeasons(team.hasOwnProperty('seasons') ? team.seasons : [])}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id } = ownProps.match.params

    return {
        team: state.team.hasOwnProperty(team_id) ? state.team[team_id] : {},
        seasons: state.season
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeasons, loadManagedTeams, loadUserTeams })(PastTeamSeasons))


