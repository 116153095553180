import {
    QUEUE_TOAST,
    QUEUE_TOAST_CUSTOM,
    REMOVE_TOAST
} from '../actions/types'

export default (state = {}, action) => {
    const { type, payload } = action

    switch(type)
    {
        case QUEUE_TOAST:
            {
                console.log('-- Queue Toast')

                const { status, message, duration } = payload

                const id = new Date().getTime() + Math.random()

                return {
                    ...state,
                    // Store whether a request is happening at the moment or not
                    // e.g. will be true when receiving GET_TODOS_REQUEST
                    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
                    [id]: { status, message: message, duration },
                }
            }

        // case QUEUE_TOAST_CUSTOM:
        //     {

        //         console.log('-- Queue Toast Custom')

        //         const { message } = payload

        //         const id = new Date().getTime() + Math.random()

        //         return {
        //             ...state,
        //             // Store whether a request is happening at the moment or not
        //             // e.g. will be true when receiving GET_TODOS_REQUEST
        //             //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
        //             [id]: { type: type, message: message },
        //         }
        //     }

        case REMOVE_TOAST:
            {

                console.log('-- Remove Toast')

                const { toast_id } = payload

                if(!state.hasOwnProperty(toast_id)) return state

                var updated_state = { ...state }

                // delete the toast message
                delete updated_state[toast_id]

                return updated_state
            }

        default:
            return state
    }
}