import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar } from '@material-ui/core/'
import { green, orange, red } from '@material-ui/core/colors'
import { randBetween } from '../../utils/utils'

const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    playerAvatar: {
        width: '128px'
    },
    avatarShadowGreen: {
        boxShadow: `10px 0px 10px -4px ${green[500]}`,
    },
    avatarShadowOrange: {
        boxShadow: `10px 0px 10px -4px ${orange[500]}`,
    },
    avatarShadowRed: {
        boxShadow: `10px 0px 10px -4px ${red[500]}`,
    },
    textGreen: {
        color: green[500]
    },
    textOrange: {
        color: orange[500]
    },
    textRed: {
        color: red[500]
    },
    MuiAvatar: {
        img: {
            height: 'auto'
        }
    },
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    },
    blurryTextGreen: {
        color: 'transparent !important',
        textShadow: `0 0 5px ${green[500]}`,
        opacity: '0.5'
    },
    blurryTextOrange: {
        color: 'transparent !important',
        textShadow: `0 0 5px ${orange[500]}`,
        opacity: '0.5'
    },
    blurryTextRed: {
        color: 'transparent !important',
        textShadow: `0 0 5px ${red[500]}`,
        opacity: '0.5'
    },
    blurryTextBlack: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(0,0,0,0.5)'
    }
})

const PlayerHeaderBasicLoading = (props) => {

    console.log('[] Player Header Basic Loading')
    // //console.log(props)

    const { classes, attendance_highlight } = props

    var colour = ['green', 'orange', 'red', 'none'][randBetween(0, 3)]
    var avatarHighlightColor = attendance_highlight ? { green: 'avatarShadowGreen', orange: 'avatarShadowOrange', red: 'avatarShadowRed', none: '' }[colour] : ''
    var commentColor = { green: 'textGreen', orange: 'textOrange', red: 'textRed', none: '' }[colour]
    var blur_class = { green: classes.blurryTextGreen, orange: classes.blurryTextOrange, red: classes.blurryTextRed, none: classes.blurryTextWhite }[colour]

    const player_id = randBetween(1, 4)
    const player_name = ['Roger Moore', 'Sean Connery', 'George Lazenby', 'Timothy Dalton', 'Pierce Brosnan', 'Daniel Craig'][randBetween(0, 3)]
    const position = ['Defence', 'Mid', 'Forward', 'Keeper'][randBetween(0, 3)]
    const shirt_number = randBetween(1, 30)
    const comment = '' //['Need to check my shifts at work', 'Would prefer this game', 'My wife said I can\'t make this one', '', ''][randBetween(0,4)]


    return (
        <Grid item>
            <Grid container
                justify="flex-end"
                alignItems="center"
                alignContent="center"
                wrap="nowrap" >
                <Grid item className={`${classes.textRight} ${classes.blurryTextWhite}`}>
                    <Typography variant="h5" className={`${classes.blurryTextWhite}`}>{player_name}</Typography>
                    <Typography variant="subtitle1">{position}</Typography>
                    <Typography variant="subtitle2">{shirt_number}</Typography>
                    {comment ? <Typography variant="subtitle2" className={`${classes[commentColor]} ${blur_class}`}>"{comment}"</Typography> : ''}
                </Grid>

                <Grid item className={`${classes.textCenter}`}>
                    <Box px={1}>
                        <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`${process.env.REACT_APP_API_URL}/images/players/loading/player-loading-${player_id}.jpg`} >
                            <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`${process.env.REACT_APP_API_URL}/images/players/default.png`} />
                        </Avatar>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}


export default withStyles(styles)(PlayerHeaderBasicLoading)