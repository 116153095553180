import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadManagedTeams, loadTeamsRegistration, updateUserTeamRegistrationStatus, toggleUnregisterTeamUserModal } from '../../../actions'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { red, green, orange } from '@material-ui/core/colors'
import { Grid, Typography, Box, Fab, Tooltip, Divider, DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle } from '@material-ui/core'
import User from '../../user/User'
import { GreenButton, RedButton, OrangeButton } from '../../ui/Buttons'
import {
    FaFutbol,
    FaHandsHelping,
    FaFile,
    FaCut,
    FaMinus,
    FaTrophy,
    FaTrashAlt,
    FaThumbsUp,
    FaUserPlus,
    FaUserMinus
} from "react-icons/fa"

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center',
        position: 'relative'
    },
    textLeft: {
        justifyContent: 'flex-start'
    },
    textRight: {
        textAlign: 'right'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    bordered: {
        border: '1px solid red',
        margin: '4px'
    },
    flex: {
        display: 'flex'
    },
    stretch: {
        flex: '1'
    },
    fab: {
        position: 'fixed',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 100,
        backgroundColor: green[500],
        borderColor: green[400],
    },
    red: {
        backgroundColor: red[500],
        borderColor: red[400],
    },
    orange: {
        backgroundColor: orange[500],
        borderColor: orange[400],
    },
    green: {
        backgroundColor: green[500],
        borderColor: green[400],
    },
    iconButton: {
        padding: '1.2em',
        minWidth: 'unset'
    }
})

var user_to_remove = false

const renderList = (team_id, registration, updateUserTeamRegistrationStatus, toggleModal, classes) => { //}, pointsButtonClick, onInputChange) => {
    // Copy players into an array to sort
    var pending_players = []
    var approved_players = []
    var rejected_players = []
    var inactive_players = []

    for(var user_id in registration)
    {

        const { status, first_name, last_name } = registration[user_id]

        switch(status)
        {
            case 'P':
                pending_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
                break

            case 'A':
                approved_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
                break

            case 'R':
                rejected_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
                break

            case 'I':
                inactive_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
                break

            default:
                break
        }

    }

    // Sort the players by first name
    pending_players.sort((a, b) => a[1] > b[1] ? 1 : -1)
    approved_players.sort((a, b) => a[1] > b[1] ? 1 : -1)
    rejected_players.sort((a, b) => a[1] > b[1] ? 1 : -1)
    inactive_players.sort((a, b) => a[1] > b[1] ? 1 : -1)


    var pending_output = []

    for(var pending_ids of pending_players)
    {
        const requestor_user = registration[pending_ids[0]]

        pending_output.push(
            <>
                <Box mx={1} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <ButtonGroup
                                color="secondary"
                                size="medium"
                                className={`${classes.flex}`}
                            >
                                <Tooltip placement="top" title="Approve" >
                                    <GreenButton
                                        onClick={() => { updateUserTeamRegistrationStatus('A', team_id, requestor_user.user_id) }}
                                        className={`${classes.iconButton}`}
                                    >
                                        <FaUserPlus fontSize="large" />
                                    </GreenButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Reject" >
                                    <RedButton
                                        onClick={() => { updateUserTeamRegistrationStatus('R', team_id, requestor_user.user_id) }}
                                        className={`${classes.iconButton}`}
                                    >
                                        <FaUserMinus fontSize="large" />
                                    </RedButton>
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <User user={requestor_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(pending_output.length !== 0)
    {
        pending_output.unshift(
            <Box mt={2} key={`heading-pending`}>
                <Typography content="h2" variant="h2">Pending Approval</Typography>
            </Box>
        )
    }


    var approved_output = []

    for(var approved_ids of approved_players)
    {
        const requestor_user = registration[approved_ids[0]]

        approved_output.push(
            <>
                <Box mx={1} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Tooltip placement="top" title="Archive" >
                                <OrangeButton
                                    onClick={() => {
                                        user_to_remove = requestor_user
                                        console.log('user_to_remove*', user_to_remove)
                                        toggleModal(true)
                                    }}
                                    // onClick={() => { updateUserTeamRegistrationStatus('I', team_id, requestor_user.user_id) }}
                                    className={`${classes.iconButton}`}
                                >
                                    <FaUserMinus fontSize="large" />
                                </OrangeButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <User user={requestor_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(approved_output.length !== 0)
    {
        approved_output.unshift(
            <Box mt={2} key={`heading-approved`}>
                <Typography content="h2" variant="h2">Approved</Typography>
            </Box>
        )
    }


    var rejected_output = []

    for(var rejected_ids of rejected_players)
    {
        const requestor_user = registration[rejected_ids[0]]

        rejected_output.push(
            <>
                <Box mx={1} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <ButtonGroup
                                color="secondary"
                                size="medium"
                                className={`${classes.flex}`}
                            >
                                <Tooltip placement="top" title="Approve" >
                                    <GreenButton
                                        onClick={() => { updateUserTeamRegistrationStatus('A', team_id, requestor_user.user_id) }}
                                        className={`${classes.iconButton}`}
                                    >
                                        <FaUserPlus fontSize="large" />
                                    </GreenButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Archive" >
                                    <OrangeButton
                                        onClick={() => { updateUserTeamRegistrationStatus('I', team_id, requestor_user.user_id) }}
                                        className={`${classes.iconButton}`}
                                    >
                                        <FaUserMinus fontSize="large" />
                                    </OrangeButton>
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <User user={requestor_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(rejected_output.length !== 0)
    {
        rejected_output.unshift(
            <Box mt={2} key={`heading-rejected`}>
                <Typography content="h2" variant="h2">Rejected</Typography>
            </Box>
        )
    }


    var inactive_output = []

    for(var inactive_ids of inactive_players)
    {
        const requestor_user = registration[inactive_ids[0]]

        inactive_output.push(
            <>
                <Box mx={1} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Tooltip placement="top" title="Approve" >
                                <GreenButton
                                    onClick={() => { updateUserTeamRegistrationStatus('A', team_id, requestor_user.user_id) }}
                                    className={`${classes.iconButton}`}
                                >
                                    <FaUserPlus fontSize="large" />
                                </GreenButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <User user={requestor_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(inactive_output.length !== 0)
    {
        inactive_output.unshift(
            <Box mt={2} key={`heading-inactive`}>
                <Typography content="h2" variant="h2">Inactive</Typography>
            </Box>
        )
    }


    return (
        <>
            {pending_output}
            {approved_output}
            {rejected_output}
            {inactive_output}
        </>
    )
}


const TeamsRegistration = props => {
    console.log('[] Teams Registration')
    // const round_id = 1;

    const team_id = props.match.params.team_id

    const {
        classes,
        loadManagedTeams,
        loadTeamsRegistration,
        team,
        updateUserTeamRegistrationStatus,
        toggleUnregisterTeamUserModal,
        unregister_team_user_modal_show
    } = props

    // Load the round data
    useEffect(() => {

        if(!team.hasOwnProperty('name'))
        {
            loadManagedTeams()
        }

    }, [])

    useEffect(() => {

        loadTeamsRegistration(team_id)

    }, [])



    const toggleModal = (show) => {
        toggleUnregisterTeamUserModal(show)
    }

    console.log('user_to_remove', user_to_remove)


    return (
        <>
            <Box mx={1} mb={25}>

                <Box my={2} mx={0}>
                    <Typography content="h2" variant="h2">{team.name ? team.name : 'Loading'}</Typography>
                    <Typography content="h1" variant="h1">Player Registration</Typography>
                </Box>

                <Divider />

                {renderList(team.team_id, team.registration, updateUserTeamRegistrationStatus, toggleModal, classes)}

            </Box>

            <Dialog
                open={unregister_team_user_modal_show}
                onClose={() => { toggleModal(false) }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Remove / Archive User from Team</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        // ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="body1">Archiving the User will remove their access to all Seasons for this Team.</Typography>
                        <Typography variant="body1">You can remove their access to specific Seasons in the Season Registration admin.</Typography>
                        <Typography variant="body1" color="primary"><br />
                            Are you sure you want to Remove/Archive {user_to_remove && user_to_remove.hasOwnProperty('first_name') ? user_to_remove.first_name : 'this User'} trom the {team && team.hasOwnProperty('name') ? team.name : ''} Team?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { toggleModal(false) }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        updateUserTeamRegistrationStatus('I', team_id, user_to_remove.user_id)
                        toggleModal(false)
                    }} color="primart">
                        Archive Player
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') ? state.admin.team[team_id] : {},

        unregister_team_user_modal_show: state.ui.hasOwnProperty('admin') && state.ui.admin.hasOwnProperty('unregister_team_user_modal_show') ? state.ui.admin.unregister_team_user_modal_show : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamsRegistration, loadManagedTeams, updateUserTeamRegistrationStatus, toggleUnregisterTeamUserModal })(TeamsRegistration))


