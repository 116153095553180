import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { loadRounds, loadManagedTeams } from '../../../actions'
import { makeStyles } from "@material-ui/core/styles"
import RoundListItem from '../RoundListItem'
import { Box, Typography, Button, Divider, withStyles } from "@material-ui/core"
import RoundListShow from "../RoundListShow"
import RoundLoading from "../../loading/RoundLoading"
import { Link } from 'react-router-dom'

// Navigation
import {
    ROUND_ADMIN, ROUND_CREATE
} from '../../../navigation'
import TeamSeasonListItem from "../../seasons/TeamSeasonListItem"

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
})


const listRounds = (team_season_id, rounds, show_count) => {

    console.log(`List Rounds Admin - Show ${show_count}`)
    console.log('team_season_id', team_season_id)

    var list_output = []
    var x = 0

    // Copy rounds into an array to sort
    var ordered_rounds = []

    for(var round in rounds)
    {
        //Filter out Training rounds for now untile they're fully implemented
        // if(rounds[round].details.type === 'T')
        // {
        //     continue;
        // }

        //Filter out Rounds not associated to the Team
        if(!rounds[round].details.player_team_season_ids.includes(team_season_id))
        {
            continue
        }

        ordered_rounds.push([rounds[round].details.date, round])
    }

    // Sort the rounds by date
    ordered_rounds.sort(function(a, b) {
        return b[0] - a[0]
    })

    // Loop though the rounds
    for(let i = 0; i < ordered_rounds.length; i++)
    {
        if(x++ === Number(show_count))
        {
            break
        }

        const round_id = ordered_rounds[i][1]

        list_output.push(
            <div key={round_id}>
                <RoundListItem link={`${ROUND_ADMIN}/${round_id}`} round_id={round_id} />
            </div>
        )
    }

    if(list_output.length === 0)
    {
        return ''
    }

    var more = false
    var less = false

    if(x - 1 === Number(show_count))
    {
        more = true
    }

    if(show_count > 10)
    {
        less = true
    }

    list_output.push(
        <RoundListShow section="admin" period="rounds_admin" more={more} less={less} key={`show-admin`} />
    )


    return list_output
}

function RoundsAdmin(props) {
    console.log('[] Round List')

    const { team_season_id, team_id } = props.match.params

    const { loadRounds, loadManagedTeams, rounds, ui, loading, team, classes } = props
    const { show_count_rounds_admin_rounds } = props

    // Load the round data
    useEffect(() => {

        loadRounds('upcoming')
        loadRounds('voting_open')
        loadRounds('past')

        loadManagedTeams()

    }, [])

    const rounds_loading = !loading.hasOwnProperty(`LOAD_ROUNDS-upcoming`) || (loading.hasOwnProperty(`LOAD_ROUNDS-voting_open`) && loading[`LOAD_ROUNDS-past`])
        ? true
        : false

    return (
        <>
            <Box mt={2} mb={1}>
                <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
            </Box>

            <Divider />

            <Box my={2} mx={1} key={`heading`}>
                <Typography content="h1" variant="h1">Season Rounds</Typography>
            </Box>

            <Divider />

            <Box my={2} mx={1}>
                <Link to={ROUND_CREATE} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="secondary" className={`${classes.adminMenuButton}`}>
                        Create a New Round
                    </Button>
                </Link>
            </Box>

            <Divider />

            { rounds_loading ? <RoundLoading quantity={10} /> : listRounds(team_season_id, rounds, show_count_rounds_admin_rounds)}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id } = ownProps.match.params

    return {
        rounds: state.round,
        show_count_rounds_admin_rounds: state.ui.admin.show_count_rounds_admin_rounds,
        loading: state.loading,
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadRounds, loadManagedTeams })(RoundsAdmin))
