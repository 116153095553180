import {
    REGISTRATION_SUCCESS,
    UPDATE_USER_REGISTRATION_FORM_FIELD,
    UPDATE_TEAM_REGISTRATION_CODE,
    LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {
    switch(action.type)
    {
        case REGISTRATION_SUCCESS:
            console.log('-- Register')
            //console.log(action)

            // const { authenticated, user_id, auth_token } = action.payload

            // return { ...state, is_logged_in: authenticated, user_id: user_id, auth_token: auth_token, redirectToReferrer: true, password: '' }

            return state

        case UPDATE_USER_REGISTRATION_FORM_FIELD:
            {
                const { field_name, value } = action.payload

                return {
                    ...state, [field_name]: value
                }
            }

        case UPDATE_TEAM_REGISTRATION_CODE:
            {
                return { ...state, team_registration_code: action.payload.team_registration_code }
            }

        case LOAD_TEAM_REGISTRATION_STATUSES_SUCCESS:
            {
                console.log('-- Load Team Registration Statuses')

                const { teams_registrations } = action.payload

                return { ...state, teams_registrations }
            }

        default:
            return state
    }
}
