import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, ButtonBase, Grid, ListSubheader, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { loadPlayer, loadProfileStats, loadSeasons, loadUserTeams, updatePlayer, updateUpdatePlayerFormFieldValue } from '../../../actions'
import { DatePicker } from '@material-ui/pickers'
import PlayerHeaderDetailed from '../PlayerHeaderDetailed'
import ImageUpload from './photo-upload/ImageUpload'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuPlayerName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    },
    sublabel: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const UpdatePlayerProfile = props => {

    const player_id = props.match.params.player_id

    const { classes } = props
    const { updatePlayer, updateUpdatePlayerFormFieldValue, loadPlayer } = props

    const { seasons, player } = props // Store

    const {
        first_name,
        last_name,
        nickname,
        shirt_number,
        position,
        gender,
        birth_year
    } = props //Form fields

    useEffect(() => {

        console.log('[] Update Player Profile')

        loadUserTeams()
        loadSeasons()

        //Load the team details
        // ser_id)

    }, [])

    useEffect(() => {
        for(const season_id in seasons)
        {
            if(seasons.hasOwnProperty(season_id))
            {
                const season = seasons[season_id]

                // An Admin may not be a player, skip in this case
                if(!season.user_player_id)
                {
                    continue
                }

                if(!player.hasOwnProperty(season.user_player_id))
                {
                    loadPlayer(season.user_player_id)
                }
            }
        }
    }, [seasons])

    const onSubmit = () => {
        updatePlayer(player_id)
    }

    const fillFromPreviousSeason = () => {
        updateUpdatePlayerFormFieldValue(player_id, 'avatar', most_recent_team_season_player.avatar)

        if(!nickname)
        {
            updateUpdatePlayerFormFieldValue(player_id, 'nickname', most_recent_team_season_player.nickname)
        }

        if(!shirt_number)
        {
            updateUpdatePlayerFormFieldValue(player_id, 'shirt_number', most_recent_team_season_player.shirt_number)
        }

        if(!position)
        {
            updateUpdatePlayerFormFieldValue(player_id, 'position', most_recent_team_season_player.position)
        }
    }

    // Get the players most recent previous Season (Player) for the same Team.
    // This is used to give the option to copy the details into the current Seasons Player Profile
    var most_recent_team_season_player = {}

    for(const p_id in player)
    {
        if(Object.hasOwnProperty.call(player, p_id))
        {
            const p = player[p_id]

            if(Number(player_id) === Number(p_id))
            {
                continue // It's the current Season
            }

            if(Number(p.team_id) === Number(player[player_id].team_id))
            {
                if(!most_recent_team_season_player.hasOwnProperty('player_id') || (Number(p_id) > Number(most_recent_team_season_player.player_id)))
                {
                    most_recent_team_season_player = p
                }
            }
        }
    }


    return (
        <>
            <Box m={1}>

                <PlayerHeaderDetailed key={`player-header-detailed-${player_id}`} player_id={player_id} show_update_button={false} />

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <ImageUpload player_id={player_id} />
                    </Grid>
                </Grid>

                {Object.entries(most_recent_team_season_player).length > 0
                    && most_recent_team_season_player.avatar.length > 0 && !player[player_id].avatar &&

                    <Box my={2}>
                        <Button onClick={() => fillFromPreviousSeason()} type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                            Prefill from last Season
                        </Button>
                    </Box>
                }

                <Box mt={1}>
                    <TextField
                        label={<>First Name <Typography variant="caption" className={`${classes.sublabel}`}>[USER]</Typography></>}
                        placeholder="First Name"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 45,
                        }}
                        name={`first_name`}
                        autoComplete="off"
                        value={first_name}
                        onChange={(e) => { updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value) }}
                    />
                </Box>

                <Box mt={1}>
                    <TextField
                        label={<>Last Name <Typography variant="caption" className={`${classes.sublabel}`}>[USER]</Typography></>}
                        placeholder=""
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 45,
                        }}
                        name={`last_name`}
                        autoComplete="off"
                        value={last_name}
                        onChange={(e) => { updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value) }}
                    />
                </Box>

                <Box mt={1}>
                    <TextField
                        label={<>Nickname <Typography variant="caption" className={`${classes.sublabel}`}>[SEASON]</Typography></>}
                        placeholder=""
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 45,
                        }}
                        name={`nickname`}
                        autoComplete="off"
                        value={nickname}
                        onChange={(e) => { updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value) }}
                    />
                </Box>

                <Box mt={1}>
                    <TextField
                        label={<>Shirt Number <Typography variant="caption" className={`${classes.sublabel}`}>[SEASON]</Typography></>}
                        placeholder=""
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 4,
                        }}
                        name={`shirt_number`}
                        type="number"
                        autoComplete="off"
                        value={shirt_number}
                        onChange={(e) => { updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value) }}
                    />
                </Box>

                <Box mt={1}>
                    <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                        <InputLabel id="position-select-label">Position <Typography variant="caption" className={`${classes.sublabel}`}>[SEASON]</Typography></InputLabel>
                        <Select
                            labelId="position-select-label"
                            id="position-select"
                            value={position}
                            name="position"
                            onChange={(e) => {
                                updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value)
                            }}
                        >
                            <MenuItem value="Defence">Defence</MenuItem>
                            <MenuItem value="Mid">Mid</MenuItem>
                            <MenuItem value="Forward">Forward</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box mt={3}>
                    <FormControl component="fieldset"
                        className={`${classes.fullWidth}`}>
                        <FormLabel component="legend">Gender <Typography variant="caption" className={`${classes.sublabel}`}>[USER]</Typography></FormLabel>
                        <RadioGroup aria-label="gender" name="gender" value={gender}
                            onChange={(e) => {
                                updateUpdatePlayerFormFieldValue(player_id, e.target.name, e.target.value)
                                updateUpdatePlayerFormFieldValue(player_id, 'avatar_gender', e.target.value === 'M' ? 'male' : e.target.value === 'F' ? 'female' : 'human')
                            }}
                        >
                            <FormControlLabel value="F" control={<Radio />} label="Female" />
                            <FormControlLabel value="M" control={<Radio />} label="Male" />
                            <FormControlLabel value="O" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box mt={0}>
                    <DatePicker
                        name={"birth_year"}
                        views={["year"]}
                        label={<>What year were you born? <Typography variant="caption" className={`${classes.sublabel}`}>[USER]</Typography></>}
                        initialFocusedDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                        value={birth_year ? new Date(birth_year, 0) : null}
                        className={`${classes.fullWidth}`}
                        onChange={(date) => { updateUpdatePlayerFormFieldValue(player_id, 'birth_year', new Date(date).getFullYear()) }}
                        animateYearScrolling
                        autoOk
                        disableFuture
                        disableToolbar
                        allowKeyboardControl
                    />
                </Box>


                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { player_id } = ownProps.match.params

    return {
        player: state.player,
        seasons: state.season,

        first_name: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('first_name') ? state.player[player_id].first_name : '',
        last_name: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('last_name') ? state.player[player_id].last_name : '',
        nickname: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('nickname') && state.player[player_id].nickname !== null ? state.player[player_id].nickname : '',
        shirt_number: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('shirt_number') && state.player[player_id].shirt_number !== null ? state.player[player_id].shirt_number : '',
        position: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('position') && state.player[player_id].position !== null ? state.player[player_id].position : '',
        gender: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('gender') ? state.player[player_id].gender : '',
        birth_year: state.player.hasOwnProperty(player_id) && state.player[player_id].hasOwnProperty('birth_year') ? state.player[player_id].birth_year : null
    }
}

export default withStyles(styles)(connect(mapStateToProps, { updatePlayer, updateUpdatePlayerFormFieldValue, loadPlayer })(UpdatePlayerProfile))
