import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadManagedTeams } from '../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

// Navigation
import {
    TEAM_UPDATE,
    TEAM_PLAYER_REGISTRATION,
    TEAM_SEASONS,
    TEAM_STAT_TYPES,
    ROUNDS_ADMIN,
    ROUND_CREATE
} from '../../../navigation'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
})


const OpponentAdmin = props => {
    console.log('[] Team Admin')

    const team_id = props.match.params.team_id

    const { classes, loadManagedTeams, team } = props // Connect

    // Load the round data
    useEffect(() => {
        if(Object.entries(team).length === 0 || !team[Object.keys(team)[0]].hasOwnProperty('name'))
        {
            loadManagedTeams()
        }
    }, [])

    return (
        <>
            <Box mt={2} p={1}>
                <Typography content="h1" variant="h1">{team.name ? team.name : 'Loading'}</Typography>
            </Box>

            <Box my={2} mx={1}>
                <Link to={`${TEAM_UPDATE}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Update Details
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${TEAM_PLAYER_REGISTRATION}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Manage Player Registrations
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${TEAM_STAT_TYPES}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Manage Tracked Stats
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${TEAM_SEASONS}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Manage Seasons
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${ROUNDS_ADMIN}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Manage Rounds
                    </Button>
                </Link>
            </Box>

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadManagedTeams })(OpponentAdmin))


