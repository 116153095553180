import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, } from '@material-ui/core/'
import Divider from "@material-ui/core/Divider"
import PlayerStat from './stats/PlayerStat'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    }
})


const PlayerStatList = (props) => {
    console.log('[] PlayerStatList')

    const { classes, stat } = props

    var output = []

    const renderPlayers = stat => {

        for(var item in stat.items)
        {
            if(stat.items.hasOwnProperty(item))
            {
                item = stat.items[item]

                output.push(
                    <Grid xs={4} item key={`stat-container-${stat.stat_id}-${item.player_id}`}>
                        <PlayerStat key={`${stat.name}-${item.player_id}`} player_id={item.player_id} amount_icon={stat.amount_icon} amount_icon_color={stat.amount_icon_color} stat_total={Number(item.stat_total) * (stat.amount_multiplier == null ? 1 : Number(stat.amount_multiplier))} amount_label={stat.amount_label} colour={stat.colour} />
                    </Grid>
                )
            }
        }

        return output
    }

    return (
        <>
            <Box mb={1} className={classes.fullWidth}>
                <Box mt={2} mx={1}>
                    <Typography content="h1" variant="h1">{stat.name}</Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    wrap='nowrap'
                >
                    {renderPlayers(stat)}

                </Grid>
            </Box>

            <Divider variant="fullWidth" component="div" />
        </>
    )
}

export default withStyles(styles)(PlayerStatList)