import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { loadRounds } from '../../actions'
import { makeStyles } from "@material-ui/core/styles"
import RoundListItem from './RoundListItem'
import { Box, Typography } from "@material-ui/core"
import RoundListShow from "./RoundListShow"
import RoundLoading from "../loading/RoundLoading"

// Navigation
import {
    ROUND_VOTE,
    ROUND_ATTENDANCE
} from '../../navigation'
import Alert from "@material-ui/lab/Alert"


const listRounds = (rounds, period, show_count, heading) => {

    console.log(`[] List Rounds - ${period} - Show ${show_count}`)

    var list_output = []
    var x = 0

    // Copy rounds into an array to sort
    var ordered_rounds = []

    for(var round in rounds)
    {
        // Filter out any rounds from other periods
        if(rounds[round].details.period === period)
        {
            ordered_rounds.push([rounds[round].details.date, round])
        }
    }

    // Sort the rounds by date
    ordered_rounds.sort(function(a, b) {
        if(period !== 'past')
        {
            return a[0] - b[0]
        }
        else
        {
            return b[0] - a[0]
        }
    })

    // Loop though the rounds
    for(let i = 0; i < ordered_rounds.length; i++)
    {
        if(x++ === Number(show_count))
        {
            break
        }

        const round_id = ordered_rounds[i][1]
        const round = rounds[round_id]

        var link = ''

        console.log('round', round)

        // if(round.details.user_player_id !== false)
        // {
        //User is playing this season
        switch(period)
        {
            case 'upcoming':
                link = `${ROUND_ATTENDANCE}/${round_id}`
                break

            case 'past':
                if(round.details.voting_open)
                {
                    // Can still update comments
                    link = `${ROUND_VOTE}/${round_id}`
                }
                else
                {
                    link = false
                }
                break

            case 'voting_open':
                link = `${ROUND_VOTE}/${round_id}`
                break

            default:
                link = false
                break
        }
        // }
        // else
        // {
        //     // User is an admin for the Team but is not playing the season so they don't need to be able to set attendance or vote in the rounds.
        //     link = false
        // }

        list_output.push(
            <div key={round_id}>
                <RoundListItem link={link} round_id={round_id} />
            </div>
        )
    }

    if(list_output.length === 0)
    {
        return ''
    }

    list_output.unshift(
        <Box mt={2} p={1} key={`heading-${period}`}>
            <Typography content="h1" variant="h1">{heading}</Typography>
        </Box>
    )

    var more = false
    var less = false

    if(x - 1 === Number(show_count))
    {
        more = true
    }

    if(show_count > 5) // 5 Is the default showc_ount
    {
        less = true
        console.log('less', less)
    }

    if(!['upcoming', 'past', 'voting_open'].includes(period))
    {
        period = 'past_season'
    }

    console.log('period', period)

    list_output.push(
        <RoundListShow section={'leaderboard'} period={period} more={more} less={less} key={`show-${period}`} />
    )


    return list_output
}

function RoundList(props) {
    console.log('[] Round List')

    const { period, loadRounds, rounds, ui, loading } = props
    const { show_count_past_rounds, show_count_voting_open_rounds, show_count_upcoming_rounds, show_count_past_season_rounds } = ui

    var heading = ''
    var show_count = 0

    switch(period)
    {
        case 'upcoming':
            heading = 'Upcoming Rounds'
            show_count = show_count_upcoming_rounds
            break

        case 'past':
            heading = 'Past Rounds'
            show_count = show_count_past_rounds

            break

        case 'voting_open':
            heading = 'Voting Open'
            show_count = show_count_voting_open_rounds

            break

        default:
            heading = 'Rounds'
            show_count = show_count_past_season_rounds
            break
    }

    useEffect(() => {

        loadRounds(period)

    }, [period])

    const rounds_loading = period === 'voting_open'
        ? false
        : !loading.hasOwnProperty(`LOAD_ROUNDS-${period}`) || (loading.hasOwnProperty(`LOAD_ROUNDS-${period}`) && loading[`LOAD_ROUNDS-${period}`])
            ? true
            : false

    return (
        <>
            { rounds_loading ? <RoundLoading quantity={5} /> : listRounds(rounds, period, show_count, heading)}
        </>
    )
}

const mapStateToProps = state => {
    return {
        rounds: state.round,
        ui: state.ui.leaderboard,
        loading: state.loading
    }
}

export default connect(mapStateToProps, { loadRounds })(RoundList)
