import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { Box, Typography } from '@material-ui/core'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
})

const OppositionList = props => {
    console.log('[] Opposition List')

    const { opposition } = props // Parent

    const { classes } = props // Connect

    const renderOpponents = () => {

        var ordered_opposition = []
        const opp = { ...opposition }

        for(var opponent_id in opp)
        {
            ordered_opposition.push([opponent_id, opp[opponent_id].name])
        }

        // Sort the opponents by date
        ordered_opposition.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_opposition)
        {
            const { opposition_id, name } = opp[ordered_ids[0]]

            output.push(
                <li key={`season-opposition-option-${name}`}><Typography variant="h4">{name}</Typography></li>
            )
        }

        return output
    }

    return (
        <>
            <Box my={2} mx={0}>
                <Typography content="h2" variant="h2">Opponents</Typography>
                <Typography content="h3" variant="h3">Current Season</Typography>
            </Box>
            <ul>{renderOpponents()}</ul>
        </>
    )
}


export default withStyles(styles)(OppositionList)


