import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { removeToast } from '../../actions'
import Button from '@material-ui/core/Button';

import { withSnackbar } from "notistack";



function Toast(props) {

    const { toaster, removeToast, enqueueSnackbar, closeSnackbar } = props;

    useEffect(() => {
        popupToast()
    })

    const popupToast = () => {

        for (let id in toaster) {
            let toast = toaster[id];

            console.log('toast', toast)

            const action = key => (
                <>
                    <Button onClick={() => { closeSnackbar(key) }}>
                        X
                    </Button>
                </>
            );

            const { message, status, duration } = toast

            // Display the toast notification
            enqueueSnackbar(message, {
                key: id,
                variant: status,
                preventDuplicate: true,
                autoHideDuration: duration ? duration : 7000,
                action
            })

            // Remove it from the queue
            removeToast(id)
        }
    }




    return (
        <>
        </>
    )
}

const mapStateToProps = state => {
    return {
        toaster: state.toast
    };
}

export default connect(mapStateToProps, { removeToast })(withSnackbar(Toast))
