import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar } from '@material-ui/core/'
import { green, orange, red } from '@material-ui/core/colors'
import PlayerHeaderBasicLoading from '../loading/PlayerHeaderBasicLoading'

const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    playerAvatar: {
        width: '128px'
    },
    avatarShadowGreen: {
        boxShadow: `10px 0px 10px -4px ${green[500]}`,
    },
    avatarShadowOrange: {
        boxShadow: `10px 0px 10px -4px ${orange[500]}`,
    },
    avatarShadowRed: {
        boxShadow: `10px 0px 10px -4px ${red[500]}`,
    },
    textWhite: {
        color: '#fff'
    },
    textGreen: {
        color: green[500]
    },
    textOrange: {
        color: orange[500]
    },
    textRed: {
        color: red[500]
    },
    MuiAvatar: {
        img: {
            height: 'auto'
        }
    },
    highlight: {
        color: theme.palette.primary.main
    }
})

const PlayerHeaderBasic = (props) => {

    console.log('[] Player Header Basic')
    // //console.log(props)

    const { player_id, comment, highlightColor, season_rounds_played_count, delay } = props // Parent
    const { classes, player, loadPlayer } = props // Connect

    useEffect(() => {

        if(!player || !player.first_name)
        {
            loadPlayer(player_id, delay)
        }

    }, [player_id])

    if(!player || !player.first_name)
    {
        return <PlayerHeaderBasicLoading />
    }

    var avatarHighlightColor = ''
    var commentColor = ''

    switch(highlightColor)
    {
        case 'green':
            avatarHighlightColor = 'avatarShadowGreen'
            commentColor = 'textGreen'
            break
        case 'orange':
            avatarHighlightColor = 'avatarShadowOrange'
            commentColor = 'textOrange'
            break
        case 'red':
            avatarHighlightColor = 'avatarShadowRed'
            commentColor = 'textRed'
            break

        default:
            avatarHighlightColor = ''
            commentColor = 'textGreen'
            break
    }

    return (
        <Grid item>
            <Grid container
                justify="flex-end"
                alignItems="center"
                alignContent="center"
                wrap="nowrap" >
                <Grid item className={`${classes.textRight}`}>
                    {
                        player.nickname ?
                            <>
                                <Typography variant="body1">{player.first_name}</Typography>
                                <Typography variant="h5" className={`${classes.highlight}`}>{player.nickname}</Typography>
                                <Typography variant="body1">{player.last_name}</Typography>
                            </>
                            :
                            <Typography variant="h5" className={`${classes.textWhite}`}>{player.first_name} {player.last_name}</Typography>
                    }
                    <Typography variant="subtitle1">{player.position} {player.shirt_number ? <>&nbsp;<span className={`${classes.highlight}`}>#{player.shirt_number}</span></> : ''}</Typography>
                    <Typography variant="subtitle2"></Typography>
                    {comment ? <Typography variant="subtitle2" className={`${classes[commentColor]}`}>"{comment}"</Typography> : ''}
                    {season_rounds_played_count ? <Typography variant="subtitle2" className={`${classes.highlight}`}>Played {season_rounds_played_count} Round{season_rounds_played_count == 1 ? '' : 's'}</Typography> : ''}
                </Grid>

                <Grid item className={`${classes.textCenter}`}>
                    <Box px={1}>
                        <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`data:image/jpeg;base64, ${player.avatar}`} >
                            <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`https://avatars.dicebear.com/api/${player.avatar_gender}/${player_id}.svg?mood[]=happy`} />
                        </Avatar>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { player_id } = ownProps

    return {
        player: state.player[player_id]
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadPlayer })(PlayerHeaderBasic))