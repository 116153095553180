import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadManagedTeams, loadTeamSeasonOpposition } from '../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Divider, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

// Navigation
import {
    OPPONENT_ADMIN,
    OPPONENT_CREATE
} from '../../../navigation'
import moment from 'moment'
import TeamSeasonListItem from '../../seasons/TeamSeasonListItem'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
})



const OppositionAdmin = props => {
    console.log('[] Opposition Admin')

    const { team_season_id, team_id } = props.match.params

    const { classes, loadManagedTeams, loadTeamSeasonOpposition, opposition } = props // Connect

    console.log('opposition', opposition)

    // Load the round data
    useEffect(() => {
        loadManagedTeams()
        loadTeamSeasonOpposition(team_season_id)
    }, [])


    const renderOpponents = () => {
        console.log('renderOpponents')
        console.log('opposition', opposition)

        var ordered_opposition = []
        const opp = { ...opposition }

        for(var opponent_id in opp)
        {
            ordered_opposition.push([opponent_id, opp[opponent_id].name])
        }

        // Sort the opponents by date
        ordered_opposition.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_opposition)
        {
            const { opposition_id, name } = opp[ordered_ids[0]]

            console.log('opposition_id', opposition_id)
            console.log('name', name)

            output.push(
                <Box my={2} mx={1} key={`opponent-option-${opposition_id}`}>
                    <Link to={`${OPPONENT_ADMIN}/${team_season_id}/${opposition_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                        <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                            {name}
                        </Button>
                    </Link>
                </Box>
            )
        }

        return output
    }


    return (
        <>
            <Box mt={2} mb={1}>
                <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
            </Box>

            <Divider />

            <Box mt={2} mx={1} key={`heading`}>
                <Typography content="h1" variant="h1">Opposition Teams</Typography>
            </Box>

            {renderOpponents()}

            <Divider />

            <Box my={2} mx={1} key="addnewopponent">
                <Link to={`${OPPONENT_CREATE}/${team_season_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}>
                        Create a New Opponent
                    </Button>
                </Link>
            </Box>

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_season_id } = ownProps.match.params

    return {
        opposition: state.admin.hasOwnProperty('opposition') && state.admin.opposition.hasOwnProperty(team_season_id) ? state.admin.opposition[team_season_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadManagedTeams, loadTeamSeasonOpposition })(OppositionAdmin))


