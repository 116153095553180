import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Avatar, Box  } from '@material-ui/core/'
import { randBetween } from '../../utils/utils'

import { RedChip, GreenChip, BlueChip, OrangeChip, YellowChip } from '../ui/Chips'

import {
    FaFutbol,
    FaHandsHelping,
    FaFile,
    FaCut,
    FaTrophy,
    FaTrashAlt,
    FaThumbsUp
} from "react-icons/fa";
// import shadows from '@material-ui/core/styles/shadows';
// import { CSSTransition } from 'react-transition-group';

const styles = theme => ({
    paper: {
      padding: theme.spacing(1),
      border: 0,
      boxShadow: 'none',
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    fullWidthCard: {
        width: '100%',
        justifyContent: 'flex-start',
        textAlign: 'center'
    },
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    },
    blurryTextBlack: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(0,0,0,0.5)'
    },
    faded: {
        opacity: '0.2'
    }
  });


const PlayerStat = (props) => {
    const { classes } = props;

    const { amount_icon, amount_icon_color, amount_label, colour } = props

    const icons = {
        FaTrophy,
        FaThumbsUp,
        FaCut,
        FaFutbol,
        FaHandsHelping,
        FaFile,
        FaTrashAlt
    };

    const chips = {
        'Green': GreenChip,
        'Orange': OrangeChip,
        'Red': RedChip,
        'Blue': BlueChip,
        'Yellow': YellowChip
    }

    const AvatarIcon = icons[amount_icon] ? icons[amount_icon] : icons['FaTrophy'];
    const avatar_icon_styles = amount_icon_color ? {color: `#${amount_icon_color}`} : {}

    const Chip = chips[colour] ? chips[colour] : chips['Green']

    const player_id = randBetween(1,4)
    const player_name = ['Roger Moore', 'Sean Connery', 'George Lazenby', 'Timothy Dalton', 'Pierce Brosnan', 'Daniel Craig'][randBetween(0,3)]

    return (
        <>
        <Paper className={classes.paper}>
            <Typography component="div" variant="subtitle1" className={`${classes.blurryTextWhite}`}>{player_name}</Typography>
            <Avatar alt="" src={`${process.env.REACT_APP_API_URL}/images/players/loading/player-loading-${player_id}.jpg`} className={`${classes.bigAvatar} ${classes.square}`} >
            <Avatar alt="" src={`${process.env.REACT_APP_API_URL}/images/players/default.png`} className={`${classes.bigAvatar} ${classes.square}`} />

            </Avatar>
            <Box mt={0.5}>
                <Chip color="secondary" avatar={<Avatar><AvatarIcon style={avatar_icon_styles} /></Avatar>} label={`${randBetween(1, 23)} ${amount_label}`} className={`${classes.fullWidthCardx} ${classes.blurryTextBlack} ${classes.faded}`} />
            </Box>
        </Paper>
        </>
    )
}


export default withStyles(styles)(PlayerStat)

