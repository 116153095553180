import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, Link } from 'react-router-dom'
import { Box, TextField, Button, Typography, Divider } from '@material-ui/core'

import { getTeamRegistrationStatuses } from '../../actions'
import RequestTeamForm from './RequestTeamForm'
import { ROUND_CREATE, SEASON_CREATE, TEAM_CREATE } from '../../navigation'
import Alert from '@material-ui/lab/Alert'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
})

const TeamsWelcome = props => {

    console.log('[] Teams Welcome')

    const { classes } = props  // Styles
    const { teams, seasons } = props // Store
    const { getTeamRegistrationStatuses } = props // Connect

    // Get the Teams the User is registered to and the status
    const { teams_registrations, teams_registrations_loading } = props // Store

    // Is a Team Admin if 'teams' is not empty
    // The Sidebar loads the Teams Managed
    const is_team_admin = Object.keys(teams).length ? true : false

    // Are the seaosns still being loaded
    const { user_seasons_loading } = props // Store

    // Are there any Teams with no Seasons
    // var has_teams_without_seasons = false
    // var teams_without_seasons = []
    // Object.entries(seasons).forEach(([team_season_id, season]) => {
    //     if(!seasons.hasOwnProperty(team_id))
    //     {
    //         has_teams_without_seasons = true
    //         teams_without_seasons.push(team_id)
    //     }
    // })


    // Figure out if there are any Teams that don't have any Seasons
    var teams_with_seasons = []
    var teams_with_active_seasons = []

    Object.entries(seasons).forEach(([team_season_id, team_season]) => {
        teams_with_seasons.push(team_season.team_id)

        if(team_season.active)
        {
            teams_with_active_seasons.push(team_season.team_id)
        }
    })
    // Remove duplicates from the array
    teams_with_seasons = Array.from(new Set(teams_with_seasons))


    // 1: no seasonse, 2: no active seasons, 3: active seasons
    // teams_with_seasons: 2, 3
    // teams_with_active_seasons: 3

    var has_teams_with_no_season = false
    var teams_with_no_season = []

    var has_teams_with_no_active_season = false
    var teams_with_no_active_season = []

    Object.entries(teams).forEach(([team_id, team]) => {
        team_id = Number(team_id)
        if(!teams_with_seasons.includes(team_id))
        {
            has_teams_with_no_season = true
            teams_with_no_season.push(team_id)
            return
        }

        if(!teams_with_active_seasons.includes(team_id))
        {
            has_teams_with_no_active_season = true
            teams_with_no_active_season.push(team_id)
        }
    })

    // teams_with_seasons: 1
    // teams_with_active_seasons: 1, 2

    // Check if there are any Rounds for the active seasons
    // These are loaded by the RoundList component displayed on the Leaderboard page.
    // For the side bar it means that the notifications won't be displayed until the user views the Leaderboard page
    // in most cases this will be their first page after login so I think that's fine.
    const { rounds, rounds_loading } = props // Store
    const has_rounds = Object.entries(rounds).length !== 0

    // Get all the Teams that a user is registered to and the registration status
    useEffect(() => {
        getTeamRegistrationStatuses()
    }, [])

    var on_a_team = false
    var teams_pending_approval = []
    var teams_approved = []
    for(const team_id in teams_registrations)
    {
        if(teams_registrations.hasOwnProperty(team_id))
        {
            const team = teams_registrations[team_id]

            if(team.status === 'A')
            {
                on_a_team = true
                teams_approved.push(team)
            }
            if(team.status === 'P')
            {
                on_a_team = true
                teams_pending_approval.push(team)
            }
        }
    }

    // console.log('@@ rounds_loading', rounds_loading)
    // console.log('@@ has_rounds', has_rounds)
    // console.log('@@ is_team_admin', is_team_admin)
    // console.log('@@ user_seasons_loading', user_seasons_loading)
    // console.log('@@ has_teams_with_no_season', has_teams_with_no_season)
    // console.log('@@ has_teams_with_no_active_season', has_teams_with_no_active_season)

    var output = []

    // If there are rounds to show on the Leaderboard then we won't show any helper promps here.  Will show in the Sidebar.
    if(!rounds_loading && !has_rounds)
    {
        output.push(
            <>
                <Box mx={1} key="welcome">
                    <Box my={2} mx={0}>
                        <Typography content="h1" variant="h1">Welcome</Typography>
                    </Box>
                </Box>
                <Box mb={4}>
                    <Divider />
                </Box>
            </>
        )

        // ---------------------
        //  COACH JOURNEY
        // ---------------------
        // If the user is an Admin for one or more Teams
        if(is_team_admin)
        {
            output.push(
                <Box mx={1} key="welcome-coach">
                    <Box mt={2} mb={2} mx={0}>
                        <Typography content="h1" variant="h1">Hi Coach</Typography>
                    </Box>
                </Box>
            )
            // Check that the Seasons have finidhed loading and that there is at least one Team that doesn't have any Seasons created
            // If show prompt the user to create a Season for the Team(s)
            if(!user_seasons_loading && (has_teams_with_no_season || has_teams_with_no_active_season))
            {
                output.push(
                    <Box mx={1} key="teams-needing-seasons">
                        <Box>
                            <Box mt={2} mb={2} mx={0}>
                                <Typography content="h2" variant="h2">Create Seasons</Typography>
                            </Box>
                        </Box>
                        {has_teams_with_no_season &&
                            <>
                                <Box mb={2} mx={0}>
                                    <Alert severity="success">
                                        Congratulations! You created your Team.<br />
                                        Now it's time to create a Season and start adding Rounds and Players.
                                    </Alert>
                                </Box>
                                <Box mb={4}>
                                    {
                                        teams_with_no_season.map(team_id => {
                                            return (
                                                <Box mb={2} key={`addnewteam-${team_id}`}>
                                                    <Box mb={1}>
                                                        <Typography variant="h3">{teams[team_id].name}</Typography>
                                                    </Box>
                                                    <Link to={`${SEASON_CREATE}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                                        <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                                                            Create a New Season
                                                    </Button>
                                                    </Link>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </>
                        }
                        {has_teams_with_no_active_season &&
                            <>
                                <Box mb={2} mx={0}>
                                    <Alert severity="info">
                                        These Teams don't have any active Seasons.  Is it time to start the next one or maybe organise some pre-season training?
                                    </Alert>
                                </Box>
                                <Box mb={4}>
                                    {
                                        teams_with_no_active_season.map(team_id => {
                                            return (
                                                <Box mb={2} key={`addnewteam-${team_id}`}>
                                                    <Box mb={1}>
                                                        <Typography variant="h3">{teams[team_id].name}</Typography>
                                                    </Box>
                                                    <Link to={`${SEASON_CREATE}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                                        <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                                                            Create a New Season
                                                    </Button>
                                                    </Link>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </>
                        }
                    </Box>
                )
            }

            // List Teams with Seasons but no Rounds
            if(!user_seasons_loading)
            {
                output.push(
                    <Box mx={1} key="teams-needing-rounds">
                        <Box>
                            <Box mt={2} mb={2} mx={0}>
                                <Typography content="h2" variant="h2">Create Rounds</Typography>
                            </Box>
                        </Box>
                        <Box mb={2} mx={0}>
                            <Alert severity="info">
                                It's time to create your first Round for the Season! It could be a Training or Competition Round.
                            </Alert>
                        </Box>
                        <Box mb={4}>
                            {
                                // Don't need to check if the season has any rounds as currently this component isn't
                                // displayed if there are rounds to show on the Leaderboard page
                                teams_with_active_seasons.map(team_id => {
                                    return (
                                        <Box mb={2} key={`addnewteam-${team_id}`}>
                                            <Box mb={1}>
                                                <Typography variant="h3">{teams[team_id].name}</Typography>
                                            </Box>
                                            <Link to={`${ROUND_CREATE}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                                <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                                                    Create a Round
                                                </Button>
                                            </Link>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                )
            }

            output.push(
                <Box mb={4} key="coach-div">
                    <Divider />
                </Box>
            )
        }


        // ---------------------
        //  PLAYER JOURNEY
        // ---------------------

        if(
            !on_a_team ||
            (teams_pending_approval.length > 0 || teams_approved.length > 0)
        )
        {
            output.push(
                <Box mx={1} key="welcome-player">
                    <Box mt={2} mb={2} mx={0}>
                        <Typography content="h1" variant="h1">Hey Player</Typography>
                    </Box>
                </Box>
            )
        }

        if(!on_a_team)
        {
            output.push(
                <Box mx={1} key="player-no-team">
                    <Box mb={2} mx={0}>
                        <Alert severity="info">
                            It looks like you aren't registered to any Teams yet.
                            You'll need to ask your Team Manager for the Team Registration Code and enter it below.
                            This will send off a notification to your Team Manager to review and approve your registration to the team.
                            Once they have approved, the next time you log in you'll be able to see the rounds and leaderboard here.
                        </Alert>
                    </Box>
                    <Box my={2} mx={0}>
                        <Box mb={2}>
                            <Alert severity="info">
                                Enter your Team Registration Code below to get started.
                            </Alert>
                        </Box>
                    </Box>

                    <RequestTeamForm />
                </Box>
            )

            output.push(
                <Box mb={4} key="player-not-registered-div">
                    <Divider />
                </Box>
            )
        }

        // TEAM REGISTRATION STATUS
        if(teams_pending_approval.length > 0 || teams_approved.length > 0)
        {
            output.push(
                <Box mx={1} key="welcome-team-registratoin-status">
                    <Box mt={2} mb={2} mx={0}>
                        <Typography content="h2" variant="h2">Team Registration Status</Typography>
                    </Box>
                </Box>
            )

            // Regitration status for Teams Pending Approval
            for(const team of teams_pending_approval)
            {
                output.push(

                    <Box mx={1} key={`teams-pending-approval-${team.team_id}`}>
                        <Box mb={2} mx={0}>
                            <Box mb={1}>
                                <Typography variant="h3">{team.name}</Typography>
                            </Box>
                            <Box mb={1}>
                                <Alert severity="warning">
                                    Your registraiton is pending approval
                            </Alert>
                            </Box>
                            <Box mb={1}>
                                <Alert severity="info">
                                    Hang in there, we're still waiting on your Coach to approve your request to join the team.
                                    We'll send them a reminder or 2, but feel free to give them a nudge if it's been a
                                    little while.
                            </Alert>
                            </Box>
                        </Box>
                    </Box>
                )
            }

            // Regitration status for Approved Teams
            for(const team of teams_approved)
            {
                output.push(
                    <Box mx={1} key={`teams-approved-${team.team_id}`}>
                        <Box mb={2} mx={0}>
                            <Box mb={1}>
                                <Typography variant="h3">{team.name}</Typography>
                            </Box>
                            <Box mb={1}>
                                <Alert severity="success">
                                    Your registraiton has been approved!
                            </Alert>
                            </Box>
                            <Box mb={1}>
                                {
                                    // User is approved at the Team level but has not been added to an active Season
                                    !teams_with_active_seasons.includes(Number(team.team_id)) &&
                                    <Alert severity="info">
                                        Your on the Team!  ..but your not currently added to an active Season yet. <br />
                                        Hang tight, there is nothing more you need to do, just wait for the Coach to get things set up.
                                    </Alert>
                                }
                                {
                                    // User is approved at the Team level and has been added to an active Season, but there are still no Rounds
                                    teams_with_active_seasons.includes(Number(team.team_id)) &&
                                    <Alert severity="info">
                                        Your on the Team and you've been added to the Season!<br />
                                        Once the coach set's up the Rounds you'll see them appearing here.<br />
                                        Check back here and keep an eye out for attendace reminder emails in your inbox.
                                    </Alert>
                                }
                            </Box>
                        </Box>
                    </Box>
                )
            }

            output.push(
                <Box mb={4} key="welecom-team-registration-div">
                    <Divider />
                </Box>
            )

        }

        {
            on_a_team &&

                output.push(
                    <Box mx={1} key="join-new-team">
                        <Box>
                            <Box mt={2} mb={2} mx={0}>
                                <Typography content="h1" variant="h1">Join a new Team</Typography>
                            </Box>
                        </Box>
                        <Box my={2} mx={0}>
                            <Box mb={2}>
                                <Alert severity="info">
                                    Enter your Team Registration Code below to get started.<br />
                            You can get this from your Team Manager / Coach
                        </Alert>
                            </Box>
                        </Box>

                        <RequestTeamForm />
                    </Box>
                )
        }

        {
            on_a_team &&
            output.push(
                <Box mb={4} key="join-new-team-div">
                    <Divider />
                </Box>
            )
        }
    }


    return (output)


    // if(period === 'upcoming' && Object.entries(rounds).length === 0)
    // {
    //     // There are no rounds for any period
    //     return (
    //         <>
    //             <Box mt={2} mx={1} key={`heading-${period}`}>
    //                 <Typography content="h1" variant="h1">Rounds</Typography>
    //             </Box>

    //             <Box mx={1} mt={1} key={`heading-waiting-rounds`}>
    //                 {/* <Typography variant="body1">You're registered to a Team and you've been added to a Season where just waiting on the powers that be to add some rounds.</Typography> */}
    //                 {/* <Typography variant="body1">Once they do you'll see them appear here, then just tap to let them know if you can make it.</Typography> */}

    //                 <Alert severity="info">
    //                     Once you're registered to a Team and the admins add some Rounds to your Season you'll see them appear here.  You'll then be able to tap/click on them
    //                     to set your attendance, and post game, cast your votes.  Check back later to see if some Rounds are available.
    //                 </Alert>
    //             </Box>
    //         </>
    //     )
    // }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Welcome</Typography>
                </Box>
                {
                    !is_team_admin &&
                    <>
                        <Box my={2} mx={0}>
                            <Typography content="h2" variant="h2">I'm a Player</Typography>
                        </Box>
                        <Box my={2} mx={0}>
                            {
                                on_a_team ?
                                    <Typography variant="body1">
                                        You're registration to a Team has been successful but you haven't been added to an active Season yet.
                                        Hang tight.  Your admin is likely still setting it all up so check back later and you should start seeing some Rounds appear.
                            </Typography>
                                    :
                                    <Box mb={2}>
                                        <Alert severity="info">
                                            It looks like you aren't registered to any Teams yet.
                                            You'll need to ask your Team Manager for the Team Registration Code and enter it below.
                                            This will send off a notification to your Team Manager to review and approve your registration to the team.
                                            Once they have approved, the next time you log in you'll be able to see the rounds and leaderboard here.
                                        </Alert>
                                    </Box>

                            }
                        </Box>
                        <Box my={2} mx={0}>
                            {
                                on_a_team &&
                                <Typography variant="h1">
                                    Join another Team
                                </Typography>
                            }

                            <Box mb={2}>
                                <Alert severity="info">
                                    Enter your Team Registration Code below to get started.
                                </Alert>
                            </Box>
                        </Box>

                        <RequestTeamForm />
                    </>
                }
                {
                    // New user not sure if they're a player or coach
                    !is_team_admin && !on_a_team &&
                    <>
                        <Box mt={4} mb={2} mx={0}>
                            <Typography content="h2" variant="h2">I'm a Coach</Typography>
                        </Box>
                        <Box my={2} mx={0}>
                            <Alert severity="info">
                                Let's get started creating your team.  We'll guide you though getting everything ready to get the banter started.
                            </Alert>
                        </Box>
                        <Box my={4} key="addnewteam">
                            <Link to={TEAM_CREATE} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                                    Create a New Team
                            </Button>
                            </Link>
                        </Box>
                    </>
                }
                {
                    is_team_admin &&
                    <>
                        <Box mt={4} mb={2} mx={0}>
                            <Typography content="h2" variant="h2">Hi Coach</Typography>
                        </Box>
                        <Box my={2} mx={0}>
                            <Typography variant="body1">
                                Congratulations! You created your Team.  Now it's time to create a Season and start adding Rounds and Players.
                        </Typography>
                        </Box>
                        <Box my={4} key="addnewteam">
                            <Link to={`${SEASON_CREATE}/${teams[Object.keys(teams)[0]].team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                                    Create a New Season
                            </Button>
                            </Link>
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        teams_registrations: state.registration.hasOwnProperty('teams_registrations') ? state.registration.teams_registrations : {},
        teams_registrations_loading: !state.loading.hasOwnProperty('LOAD_TEAM_REGISTRATION_STATUSES') || (state.loading.hasOwnProperty('LOAD_TEAM_REGISTRATION_STATUSES') && state.loading.LOAD_TEAM_REGISTRATION_STATUSES) ? true : false,
        teams: state.admin.hasOwnProperty('team') ? state.admin.team : {},
        user_seasons_loading: !state.loading.hasOwnProperty('LOAD_SEASONS') || (state.loading.hasOwnProperty('LOAD_SEASONS') && state.loading.LOAD_SEASONS) ? true : false,
        seasons: state.season ? state.season : {},
        rounds: state.round,
        rounds_loading:
            (!state.loading.hasOwnProperty('LOAD_ROUNDS-upcoming') || (state.loading.hasOwnProperty('LOAD_ROUNDS-upcoming') && state.loading['LOAD_ROUNDS-upcoming']))
            || (!state.loading.hasOwnProperty('LOAD_ROUNDS-voting_open') || (state.loading.hasOwnProperty('LOAD_ROUNDS-voting_open') && state.loading['LOAD_ROUNDS-voting_open']))
            || (!state.loading.hasOwnProperty('LOAD_ROUNDS-past') || (state.loading.hasOwnProperty('LOAD_ROUNDS-past') && state.loading['LOAD_ROUNDS-past']))
    }
}

export default withStyles(styles)(connect(mapStateToProps, { getTeamRegistrationStatuses })(TeamsWelcome))
