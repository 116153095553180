import {
    SLIDE_CLOSE,
    SLIDE_OPEN
} from './types';

export const slideOpen = () => {
    return {
        type: SLIDE_OPEN
    }
}

export const slideClose = () => {
    return {
        type: SLIDE_CLOSE
    }
}