import React from "react"
import { connect } from 'react-redux'
import { incrementRoundsShowCount } from '../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box  } from '@material-ui/core/'

const styles = theme => ({
    showRoundResults: {
        textAlign: 'center',
        borderRadius: "15px",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }

})

function RoundListShow(props) {
    console.log('[] RoundResults');

    const { classes, section, period, more, less, incrementRoundsShowCount } = props;


    return (
        <Grid container justify="center">
            {
                less &&
                    <Grid item xs={6}><Box m={1} className={classes.showRoundResults} onClick={ () => { incrementRoundsShowCount(section, `show_count_${period}_rounds`, -5) } }>Show Less Rounds</Box></Grid>
            }
            {
                more &&
                    <Grid item xs={6}><Box m={1} className={classes.showRoundResults} onClick={ () => { incrementRoundsShowCount(section, `show_count_${period}_rounds`, 5) } }>Show More Rounds</Box></Grid>
            }
        </Grid>
    )
}

export default withStyles(styles)(connect(null, { incrementRoundsShowCount })(RoundListShow))
