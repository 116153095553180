import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar, Button } from '@material-ui/core/'
import { Link } from 'react-router-dom'
import { green, orange, red } from '@material-ui/core/colors'
import PlayerHeaderBasicLoading from '../loading/PlayerHeaderBasicLoading'
import { PLAYER_UPDATE } from '../../navigation'

const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    highlight: {
        color: theme.palette.primary.main
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    playerAvatar: {
        width: '128px'
    },
    avatarShadowGreen: {
        boxShadow: `10px 0px 10px -4px ${green[500]}`,
    },
    avatarShadowOrange: {
        boxShadow: `10px 0px 10px -4px ${orange[500]}`,
    },
    avatarShadowRed: {
        boxShadow: `10px 0px 10px -4px ${red[500]}`,
    },
    textWhite: {
        color: '#fff'
    },
    textGreen: {
        color: green[500]
    },
    textOrange: {
        color: orange[500]
    },
    textRed: {
        color: red[500]
    },
    MuiAvatar: {
        img: {
            height: 'auto'
        }
    }
})

const PlayerHeaderDetailed = (props) => {

    console.log('[] Player Header Basic')
    // //console.log(props)

    const { player_id, comment, highlightColor, delay } = props // Parent
    const { classes, player, loadPlayer } = props // Connect

    var show_update_button = props.show_update_button === false ? false : true

    useEffect(() => {

        if(!player || !player.first_name)
        {
            loadPlayer(player_id, delay)
        }

    }, [player_id])

    if(!player || !player.first_name)
    {
        return <PlayerHeaderBasicLoading />
    }

    var avatarHighlightColor = ''
    var commentColor = ''

    switch(highlightColor)
    {
        case 'green':
            avatarHighlightColor = 'avatarShadowGreen'
            commentColor = 'textGreen'
            break
        case 'orange':
            avatarHighlightColor = 'avatarShadowOrange'
            commentColor = 'textOrange'
            break
        case 'red':
            avatarHighlightColor = 'avatarShadowRed'
            commentColor = 'textRed'
            break

        default:
            avatarHighlightColor = ''
            commentColor = 'textGreen'
            break
    }

    return (
        <Grid item>
            <Grid container
                justify="flex-end"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
            >
                <Grid item className={`${classes.textRight}`}>
                    {
                        player.nickname ?
                            <>
                                <Typography variant="body1">{player.first_name}</Typography>
                                <Typography variant="h5" className={`${classes.highlight}`}>{player.nickname}</Typography>
                                <Typography variant="body1">{player.last_name}</Typography>
                            </>
                            :
                            <Typography variant="h5" className={`${classes.textWhite}`}>{player.first_name} {player.last_name}</Typography>
                    }
                    <Typography variant="subtitle1"><span className={classes.highlight}>Position:</span> {player.position ? player.position : '?'}</Typography>
                    <Typography variant="subtitle2"><span className={classes.highlight}>Gender:</span> {player.gender ? player.gender : '?'} <span className={classes.highlight}>&nbsp;&nbsp; Shirt:</span> #{player.shirt_number ? player.shirt_number : '?'}</Typography>
                    <Typography variant="subtitle2"><span className={classes.highlight}>Nickname:</span> {player.nickname ? player.nickname : '?'}</Typography>
                </Grid>

                <Grid item className={`${classes.textCenter}`}>
                    <Box px={1}>
                        <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`data:image/jpeg;base64, ${player.avatar}`} >
                            <Avatar className={`${classes.bigAvatar} ${classes.playerAvatar} ${classes.square} ${avatarHighlightColor ? classes[avatarHighlightColor] : ''}`} alt="" src={`https://avatars.dicebear.com/api/${player.avatar_gender}/${player_id}.svg?mood[]=happy`} />
                        </Avatar>
                    </Box>
                </Grid>
            </Grid>
            {
                show_update_button &&
                <Grid container
                    justify="flex-end"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                >
                    <Grid item className={`${classes.textRight}`}>
                        <Box mt={1} mx={1}>
                            <Link to={`${PLAYER_UPDATE}/${player_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                                    Update Profile
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { player_id } = ownProps

    return {
        player: state.player[player_id]
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadPlayer })(PlayerHeaderDetailed))