import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider"
import PlayerStatList from '../player/PlayerStatList';
import { loadRoundLeaderboard } from '../../actions';
import PlayerStatCommentsList from '../player/PlayerStatCommentsList';
import { Box, Typography } from '@material-ui/core';


const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    fullHeight: {
        height: '100%'
    },
    bigAvatar: {
        display: 'block',
        // margin: 10,
        width: '100%',
        height: 'auto',
    },
    bordered: {
        // border: "1px solid red"
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        paddingLeft: '8px'
    }
  });

const RoundResult = (props) => {
    console.log('[] RoundResult');

    const { round_id } = props // Parent
    const { classes, loadRoundLeaderboard } = props // Connect
    const { round } = props // State

    useEffect(() => {
        if(!round.leaderboard.hasOwnProperty('points') && !round.leaderboard.hasOwnProperty('stats')) {
            loadRoundLeaderboard(round_id)
        }
    }, [round, loadRoundLeaderboard])

    // if(!round.leaderboard.hasOwnProperty('points') && !round.leaderboard.hasOwnProperty('stats')) {
    //     return ''
    // }

    console.log('round', round)

    const { leaderboard } = round

    const generateLeaderboard = () => {
        var output = [];

        if(leaderboard.hasOwnProperty('points') && leaderboard.points.length !== 0)
        {
            output.push(
                <PlayerStatCommentsList stat={{stat_id: 'points', name: 'Points', amount_label: 'Points', amount_icon: 'FaTrophy', items: leaderboard.points, colour: 'Green'}} key={`points-${round_id}`} heading="Points" />
            )
        }
        if(leaderboard.hasOwnProperty('glory') && leaderboard.glory.length !== 0)
        {
            output.push(
                <PlayerStatCommentsList stat={{stat_id: 'glory', name: 'Glory', amount_label: 'Glory', amount_icon: 'FaThumbsUp', items: leaderboard.glory, colour: 'Green'}} key={`glory-${round_id}`} heading="Glory" />
            )
        }
        if(leaderboard.hasOwnProperty('cut') && leaderboard.cut.length !== 0)
        {
            output.push(
                <PlayerStatCommentsList stat={{stat_id: 'cut', name: 'Cut', amount_label: 'Cut', amount_icon: 'FaCut', items: leaderboard.cut, colour: 'Red'}} key={`cut-${round_id}`} heading="Cut" />
            )
        }

        // Stats
        for(var stat in leaderboard.stats)
        {
            if(leaderboard.stats.hasOwnProperty(stat))
            {
                const stat_id = stat

                stat = leaderboard.stats[stat]

                output.push(
                    <PlayerStatList key={`playerstat-${round_id}-${stat_id}`} stat={stat} />
                )
            }
        }

        return output
    }

    const leaderboard_output = generateLeaderboard()

    return (
        <>
            {leaderboard_output.length !== 0 ? leaderboard_output : <Box my={2} mx={2}><Typography variant="body2">Looks like there are not votes/stats yet or your manager has turned them off.</Typography></Box>}
            <Divider variant="fullWidth" className={`${classes.fullWidth}`} component="div" />
        </>
    )

}

const mapStateToProps = (state, ownProps) => {

    const { round_id } = ownProps

    return {
        round: state.round[round_id]
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadRoundLeaderboard })(RoundResult))
