import {
    UPDATE_USER_REGISTRATION_FORM_FIELD,
    UPDATE_CREATE_ROUND_FORM_FIELD,
    UPDATE_CREATE_SEASON_FORM_FIELD,
    UPDATE_CREATE_TEAM_FORM_FIELD,
    UPDATE_TEAM_STAT_TYPE_FORM_FIELD,
    CLEAR_TEAM_STAT_TYPE_FORM_FIELD,
    UPDATE_CREATE_OPPONENT_FORM_FIELD,
    UPDATE_UPDATE_PLAYER_FORM_FIELD,
    UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD
    // QUEUE_TOAST
} from './types';
// import streams from '../apis/streams';

export const updateUserRegistrationFormField = (field_name, value) => {

    return { type: UPDATE_USER_REGISTRATION_FORM_FIELD, payload: {field_name, value} };

};

export const updateCreateRoundFormFieldValue = (field_name, value, additional_data) => {

    return { type: UPDATE_CREATE_ROUND_FORM_FIELD, payload: {field_name, value, additional_data} };

};

export const updateCreateSeasonFormFieldValue = (field_name, value) => {

    return { type: UPDATE_CREATE_SEASON_FORM_FIELD, payload: {field_name, value} };

};

export const updateCreateTeamFormFieldValue = (field_name, value) => {

    return { type: UPDATE_CREATE_TEAM_FORM_FIELD, payload: {field_name, value} };

};

export const updateTeamStatTypeFormFieldValue = (field_name, value) => {

    return { type: UPDATE_TEAM_STAT_TYPE_FORM_FIELD, payload: {field_name, value} };

};

export const clearCreateTeamStatTypeForm = (field_name, value) => {

    return { type: CLEAR_TEAM_STAT_TYPE_FORM_FIELD, payload: {field_name, value} };

};

export const updateCreateOpponentFormFieldValue = (field_name, value) => {

    return { type: UPDATE_CREATE_OPPONENT_FORM_FIELD, payload: {field_name, value} };

};

export const updateUpdatePlayerFormFieldValue = (player_id, field_name, value) => {

    return { type: UPDATE_UPDATE_PLAYER_FORM_FIELD, payload: {player_id, field_name, value} };

};

export const updateSeasonLeaderboardOptionsFormFieldValue = (team_season_id, field_name, value) => {

    return { type: UPDATE_SEASON_LEADERBOARD_SETTINGS_FORM_FIELD, payload: {team_season_id, field_name, value} };

};
