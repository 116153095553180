import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect, useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography } from '@material-ui/core'

import { sendPasswordResetEmail, updateUserId } from '../actions'


const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
})


const PasswordReset = props => {
    console.log('[] PasswordReset')

    const { classes } = props
    const { username, redirectToReferrer, sendPasswordResetEmail, updateUserId } = props
    const { from } = props.location.state || { from: { pathname: '/' } }

    const history = useHistory()

    const onUserIdInputChange = (event) => {
        updateUserId(event.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        //console.log('Submit Form')
        //console.log(e)

        sendPasswordResetEmail(history)
    }

    if(redirectToReferrer === true)
    {
        return <Redirect to={from} />
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h1" variant="h1">Password Reset</Typography>
                </Box>

                <form onSubmit={onSubmit}>

                    <TextField
                        label="Email Address"
                        placeholder="Pelé@gmail.com"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        className={`${classes.fullWidth}`}
                        inputProps={{
                            maxLength: 100,
                        }}
                        name={`username`}
                        autoComplete="off"
                        value={username}
                        onChange={(event) => { onUserIdInputChange(event) }}
                    />

                    <Box my={3}>
                        <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                            Send Reset Email
                        </Button>
                    </Box>

                </form>
            </Box>
        </>
    )
}

const mapStateToProps = state => {
    return {
        username: state.user.username
    }
}

export default withStyles(styles)(connect(mapStateToProps, { sendPasswordResetEmail, updateUserId })(PasswordReset))
