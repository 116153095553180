import React from "react"
import { connect } from 'react-redux'
import { showRoundLeaderboard, hideRoundLeaderboard } from '../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/'
import RoundResult from "./RoundResult";

const styles = theme => ({
    showRoundResults: {
        textAlign: 'center',
        borderBottomRightRadius: "15px",
        borderBottomLeftRadius: "15px",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        borderTop: "0",
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }

})

function RoundResults(props) {
    console.log('[] RoundResults');

    const { classes, showRoundLeaderboard, hideRoundLeaderboard } = props;
    const { round_id, round } = props;


    return (
        <>
            <Grid container justify="center">
                {
                    !round.leaderboard || !round.leaderboard.show ?
                        <Grid item xs={6} className={classes.showRoundResults} onClick={ () => { showRoundLeaderboard(round_id) } }>View round results</Grid> :
                        <Grid item xs={6} className={classes.showRoundResults} onClick={ () => { hideRoundLeaderboard(round_id) } }>Hide round results</Grid>
                }
                {round.leaderboard && round.leaderboard.show ? <RoundResult round_id={round_id} /> : ''}
            </Grid>
        </>
    )
}

export default withStyles(styles)(connect(null, { showRoundLeaderboard, hideRoundLeaderboard })(RoundResults))
