import {
    CARD_BUTTON_CLICK,
    ATTENDANCE_BUTTON_CLICK,
    UPDATE_ATTENDANCE_COMMENT,
    NUMBER_OF_GAMES_BUTTON_CLICK,
    LOAD_ROUND_ATTENDANCE_REQUEST,
    LOAD_ROUND_ATTENDANCE_SUCCESS,
    LOAD_ROUND_ATTENDANCE_FAILURE,
    LOAD_ROUND_REQUEST,
    LOAD_ROUND_SUCCESS,
    LOAD_ROUND_FAILURE,
    LOAD_ROUNDS_REQUEST,
    LOAD_ROUNDS_SUCCESS,
    LOAD_ROUNDS_FAILURE,
    LOAD_TEAM_ROUNDS_REQUEST,
    LOAD_TEAM_ROUNDS_SUCCESS,
    LOAD_TEAM_ROUNDS_FAILURE,
    LOAD_ROUND_DETAIL_REQUEST,
    LOAD_ROUND_DETAIL_SUCCESS,
    LOAD_ROUND_DETAIL_FAILURE,
    SAVE_ATTENDANCE_REQUEST,
    SAVE_ATTENDANCE_SUCCESS,
    SAVE_ATTENDANCE_FAILURE,
    LOAD_ROUND_CARD_REQUEST,
    LOAD_ROUND_CARD_SUCCESS,
    LOAD_ROUND_CARD_FAILURE,
    SAVE_LINEUP_REQUEST,
    SAVE_LINEUP_SUCCESS,
    SAVE_LINEUP_FAILURE,
    QUEUE_TOAST,
    LOAD_ROUND_LEADERBOARD_REQUEST,
    LOAD_ROUND_LEADERBOARD_SUCCESS,
    LOAD_ROUND_LEADERBOARD_FAILURE,
    SAVE_ROUND_WHITEBOARD_REQUEST,
    SAVE_ROUND_WHITEBOARD_SUCCESS,
    SAVE_ROUND_WHITEBOARD_FAILURE,
    LOAD_ROUND_WHITEBOARD_REQUEST,
    LOAD_ROUND_WHITEBOARD_SUCCESS,
    LOAD_ROUND_WHITEBOARD_FAILURE,
    UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_REQUEST,
    UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS,
    UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_FAILURE,
    LOAD_ROUND_SCRIBBLE_REQUEST,
    LOAD_ROUND_SCRIBBLE_SUCCESS,
    LOAD_ROUND_SCRIBBLE_FAILURE,
    LOAD_PLAYER_VOTING_STATS_REQUEST,
    LOAD_PLAYER_VOTING_STATS_SUCCESS,
    LOAD_PLAYER_VOTING_STATS_FAILURE,
    SHOW_ROUND_LEADERBOARD,
    HIDE_ROUND_LEADERBOARD,
    UPDATE_ROUND_WHITEBOARD_TEXT,
    FORCED_LOG_OUT,
    LOG_OUT
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'


export const cardButtonClick = (round_id, player_id, user_id, status) => {
    console.log('** Card button click')

    return { type: CARD_BUTTON_CLICK, payload: { round_id, player_id, user_id, status } }

}

// export const attendanceButtonClick = (round_id, player_id, status) => {
export const attendanceButtonClick = (round_id, player_id, status) => (dispatch, getState) => {
    console.log('** Attendance button click')

    dispatch({ type: ATTENDANCE_BUTTON_CLICK, payload: { round_id, player_id, status } })

    // If they are changing to "Out" reset the can play count to "All" (-1)
    if(status === 'O')
    {
        const rounds = [round_id, ...getState().round[round_id].details.supporting_rounds]

        const update_rounds = []

        for(const round_id of rounds)
        {
            // If user is an admin but not a registered player for the season then skip
            if(getState().round[round_id].details.user_player_id)
            {
                update_rounds.push(round_id)
            }
        }

        dispatch({ type: NUMBER_OF_GAMES_BUTTON_CLICK, payload: { rounds: update_rounds, number_of_games: -1 } })
    }

}

export const updateAttendanceComment = (round_id, player_id, comment_text) => {

    return { type: UPDATE_ATTENDANCE_COMMENT, payload: { round_id, player_id, comment_text } }

}

export const saveAttendance = (round_id) => async (dispatch, getState) => {
    console.log('** Save Attendance')

    const round = getState().round
    const { user_id, token } = getState().user
    const { supporting_rounds } = round[round_id].details
    const attendance_rounds = [round_id, ...supporting_rounds]

    dispatch({ type: SAVE_ATTENDANCE_REQUEST })

    var failed = false

    console.log('attendance_rounds', attendance_rounds)

    for(var r_id of attendance_rounds)
    {
        const { user_player_id } = round[r_id].details

        console.log('user_player_id', user_player_id)

        // If user is an admin but not a registered player for the season then don't show the set attendance options for that round.
        if(!user_player_id)
        {
            continue
        }

        const { status, player_comment, can_play_count } = round[r_id].attendance[user_player_id]

        if(status !== 'U')
        {
            await streams.post(`/rounds/attendance/${r_id}`, {
                user_id,
                token,
                player_id: user_player_id,
                player_comment: player_comment ? player_comment : '',
                status,
                can_play_count: can_play_count === null ? -1 : can_play_count // ensure the param gets sent, if null it's not sent by default
            })
                .then(

                    dispatch(loadRound(r_id)) // Reload round to update attendance status indicator
                )
                .catch(error => {
                    // Catches network errors and not found responses from the API

                    const { response } = error
                    var { message } = error

                    message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to save your attendance - ' + message

                    getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                    dispatch({ type: SAVE_ATTENDANCE_FAILURE, payload: { message } })

                    if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                    {
                        dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                    }

                    failed = true
                })

            if(failed) break
        }
        else
        {
            var dt = new Date(Number(round[r_id].details.date) * 1000)
            const time = dt.toLocaleString(window.navigator.language, { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '')
            dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: `Don't forget to let us know about the ${time} game vs ${round[r_id].details.opposition_team_name}` } })
        }
    }

    if(!failed)
    {
        dispatch({ type: SAVE_ATTENDANCE_SUCCESS })
        dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Saved. Thanks for letting us know!' } })

        ReactGA.event({
            category: 'Round',
            action: 'Set Attendance',
            value: 20
        })
    }
}

export const saveLineup = (round_id) => async (dispatch, getState) => {
    console.log('** Save Lineup')

    dispatch({ type: SAVE_LINEUP_REQUEST })

    const { card } = getState().round[round_id]
    const { user_id, token } = getState().user

    await streams.post(`/rounds/lineup/${round_id}`, {
        user_id,
        token,
        card
    })
        .then(response => {

            dispatch(loadRound(round_id))

            dispatch({ type: SAVE_LINEUP_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })

            ReactGA.event({
                category: 'Round Admin',
                action: 'Set Card',
                value: 20
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to save your attendance - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: SAVE_LINEUP_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const loadRoundLeaderboard = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Round Leaderboard')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_LEADERBOARD_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/leaderboard/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_LEADERBOARD_SUCCESS, payload: { round_id, leaderboard: response.data.leaderboard } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRoundLeaderboard(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load attendance - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_LEADERBOARD_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const showRoundLeaderboard = (round_id) => {
    console.log('** Show Round Leaderboard')

    return { type: SHOW_ROUND_LEADERBOARD, payload: { round_id } }
}

export const hideRoundLeaderboard = (round_id, getState) => (dispatch, getState) => {
    console.log('** Hide Round Leaderboard')

    const round = getState().hasOwnProperty('round') && getState().round.hasOwnProperty(round_id) ? getState().round[round_id] : false

    if(round && round.hasOwnProperty('leaderboard') && getState().round[round_id].leaderboard.show)
    {
        dispatch({ type: HIDE_ROUND_LEADERBOARD, payload: { round_id } })
    }
}

export const numberOfGamesButtonClick = (rounds, number_of_games) => {

    return { type: NUMBER_OF_GAMES_BUTTON_CLICK, payload: { rounds, number_of_games } }

}

export const loadRound = (round_id, delay, retry_count) => async (dispatch, getState) => {
    // await streams.get(`/round/${round_id}`);
    // //console.log('Action Load Round - getState:')
    console.log('** Load Round - ' + round_id)

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {
            dispatch({ type: LOAD_ROUND_SUCCESS, payload: { round_id, round: response.data.round } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRound(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the round details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadRoundDetail = (round_id, delay, retry_count) => async (dispatch, getState) => {
    // await streams.get(`/round/${round_id}`);
    // //console.log('Action Load Round - getState:')
    console.log('** Load Round Detail - ' + round_id)

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_DETAIL_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/detail/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {
            dispatch({ type: LOAD_ROUND_DETAIL_SUCCESS, payload: { round_id, round_detail: response.data.round_detail } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRoundDetail(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the round details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_DETAIL_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadRoundAttendance = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Round Attendance')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_ATTENDANCE_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/attendance/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_ATTENDANCE_SUCCESS, payload: { round_id, round: response.data.attendance } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRoundAttendance(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load attendance - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_ATTENDANCE_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadRoundCard = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Round Card')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_CARD_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/card/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_CARD_SUCCESS, payload: { round_id, round: response.data.card } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRoundCard(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the lineup - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_CARD_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadRounds = (period, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Rounds')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUNDS_REQUEST, payload: { id: period } })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/${period}`, {
        params: {
            user_id,
            token,
            period
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUNDS_SUCCESS, payload: { id: period, rounds: response.data.rounds } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadRounds(period, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load rounds - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })

                dispatch({ type: LOAD_ROUNDS_FAILURE, payload: { id: period, message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}


export const updateRoundWhiteboardText = (round_id, scribble) => {
    console.log('** Update Round Whiteboard Text')

    console.log('scribble', scribble)

    return { type: UPDATE_ROUND_WHITEBOARD_TEXT, payload: { scribble } }

}

export const loadWhiteboard = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Scribble')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_WHITEBOARD_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/whiteboard/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_WHITEBOARD_SUCCESS, payload: { scribble: response.data.scribble, whiteboard_published: response.data.whiteboard_published } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadWhiteboard(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load whiteboard - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_WHITEBOARD_FAILURE, payload: { round_id, message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadScribble = (round_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Scribble')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_ROUND_SCRIBBLE_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/scribble/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_SCRIBBLE_SUCCESS, payload: { round_id, scribble: response.data.scribble } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadWhiteboard(round_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load whiteboard - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_ROUND_SCRIBBLE_FAILURE, payload: { round_id, message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const saveRoundWhiteboard = (round_id) => async (dispatch, getState) => {
    console.log('** Save Round Whiteboard')

    dispatch({ type: SAVE_ROUND_WHITEBOARD_REQUEST })

    const { scribble } = getState().admin.create.whiteboard
    const { user_id, token } = getState().user

    await streams.post(`/rounds/whiteboard/${round_id}`, {
        user_id,
        token,
        scribble
    })
        .then(response => {

            dispatch({ type: SAVE_ROUND_WHITEBOARD_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })

            ReactGA.event({
                category: 'Round Admin',
                action: 'Scribble on Whiteboard',
                value: 20
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to save your attendance - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: SAVE_ROUND_WHITEBOARD_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

export const updateRoundWhiteboardPublishStatus = (round_id, status) => async (dispatch, getState) => {
    console.log('** Update Round Whiteboard Publish Status')

    dispatch({ type: UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/rounds/whiteboard/${round_id}`, {
        user_id,
        token,
        status: status ? 1 : 0
    })
        .then(response => {

            dispatch({ type: UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_SUCCESS, payload: { whiteboard_published: status } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update the publish status - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_ROUND_WHITEBOARD_PUBLISH_STATUS_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}


export const loadPlayerVotingStats = (round_id, status) => async (dispatch, getState) => {
    console.log('** Load Player Voting Stats')

    dispatch({ type: LOAD_PLAYER_VOTING_STATS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/player_voting_stats/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_PLAYER_VOTING_STATS_SUCCESS, payload: { round_id, player_voting_stats: response.data.player_voting_stats } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the player voting stats - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: LOAD_PLAYER_VOTING_STATS_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}


