import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ImageCropper from './ImageCropper'
import { loadPlayer, updateUpdatePlayerFormFieldValue } from '../../../../actions'
import styled from 'styled-components'
import PlayerHeaderDetailed from '../../PlayerHeaderDetailed'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    }
})

const DialogFullWidth = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        width: 100%;
        margin: 8px;
    }
`

const ImageUpload = props => {
    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('')

    const { classes, updateUpdatePlayerFormFieldValue, loadPlayer } = props // Connect
    const { player_id } = props // Parent

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
        }, false)

        if(file)
        {
            reader.readAsDataURL(file)
        }
    }


    useEffect(() => {

        if(blob)
        {
            const myReader = new FileReader()
            myReader.readAsDataURL(blob)
            myReader.onload = function() {
                console.log(myReader.result)
                updateUpdatePlayerFormFieldValue(player_id, 'avatar', myReader.result.replace('data:image/jpeg;base64,', ''))
            }
        }

    }, [blob])


    const handleSubmitImage = () => {
        setInputImg(null)
    }


    return (
        <Box>

            <input
                id='photo-upload-button'
                accept="image/*"
                style={{ display: 'none' }}
                type="file"
                onChange={onInputChange}
            />
            <label htmlFor="photo-upload-button">
                <Button component="span" variant="contained" color="secondary" className={classes.fullWidth}>
                    Update Profile Photo
                    </Button>
            </label>


            <DialogFullWidth
                open={Boolean(inputImg)}
                onClose={() => { setInputImg(null) }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Icon</DialogTitle>
                <DialogContent dividers={true}>

                    <PlayerHeaderDetailed key={`player-header-detailed-${player_id}`} player_id={player_id} show_update_button={false} />

                    <ImageCropper
                        getBlob={getBlob}
                        inputImg={inputImg}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleSubmitImage() }} type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                        Save
                    </Button>
                    <Button onClick={() => { setInputImg(null); loadPlayer(player_id) }} color="primary">
                        Cancel
                </Button>
                </DialogActions>
            </DialogFullWidth>

        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {

    // const player_id = ownProps.player_id

    // return {
    //     player: state.player.hasOwnProperty(player_id) ? state.player[player_id] : false
    // }

    return {}
}

export default connect(mapStateToProps, { updateUpdatePlayerFormFieldValue, loadPlayer })(withStyles(styles)(ImageUpload))
