

export const randBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const sleep = duraiton => {
    return new Promise(r => setTimeout(r, duraiton))
}


// // Shows the diff of props that have changed since the last render
// // For debug only
// // Usage: Call the function at the top of the comonent and pass props
// // propDiffBetweenRenders(props)
// export const propDiffBetweenRenders = (props) => {
//     const prev = propDiffBetweenRenders(props)
//     useEffect(() => {
//         const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//             if(prev.current[k] !== v)
//             {
//                 ps[k] = [prev.current[k], v]
//             }
//             return ps
//         }, {})
//         if(Object.keys(changedProps).length > 0)
//         {
//             console.info('$$ Changed props:', changedProps)
//         }
//         prev.current = props
//     })
// }