import {
    LOAD_LEADERBOARD_REQUEST,
    LOAD_LEADERBOARD_SUCCESS,
    LOAD_LEADERBOARD_FAILURE,
    QUEUE_TOAST
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'


export const loadLeaderboard = (team_season_id = '', delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Leaderboard')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_LEADERBOARD_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/leaderboard/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_LEADERBOARD_SUCCESS, payload: { leaderboard: response.data.leaderboard } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadLeaderboard(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load leaderboard - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_LEADERBOARD_FAILURE, payload: { message } })
            }
        })
}
