import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Divider, Typography  } from '@material-ui/core/'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import { randBetween } from '../../utils/utils'

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: "inline"
    },
    paddright: {
        paddingRight: "20px"
    },
    navRoundHeading: {
        fontSize: "1.5em",
        fontWeight: "bold",
        paddingTop: "20px",
        paddingBottom: "20px",
        borderBottom: "2px solid #85cbf4"
    },
    navRoundItem: {
        backgroundColor: "transparent",
        border: "0",
        borderBottom: "2px solid #85cbf4",
        boxShadow: "0 20px 20px -20px #000",
        marginBottom: "20px",
        color: "#FFF"
    },
    navRoundDayNumber: {
        FontWeight: "bold",
        FontSize: "2.6em",
        lineHeight: "1em"
    },
    navRoundTeamName: {
        color: "#85cbf4"
    },
    navRoundClub: {
        fontSize: "1.3em"
    },
    navRoundOpposition: {
        fontSize: "1.3em",
        color: "#85cbf4"
    },
    navRoundVoting: {
        // fontSize: "0.9em",
        // lineHeight: '1em',
        whiteSpace: 'nowrap'
    },
    navRoundClubGoals: {
        fontSize: "1.3em"
    },
    navRoundOppositionGoals: {
        fontSize: "1.3em",
        color: "#85cbf4"
    },
    aNavRoundItemHoverElement: {
        color: "#fff",
        backgroundColor: "#0C3A56"
    },
    adminLink: {
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "1.6em"
    },
    roundResultsToggle: {
        borderBottomRightRadius: "15px",
        borderBottomLeftRadius: "15px",
        border: "2px solid #85cbf4",
        borderTop: "0",
        cursor: "pointer",
        marginTop: "-20px",
        backgroundColor: "#0d2431",
        boxShadow: "0 14px 14px -10px #000",
        marginBottom: "10px",
        fontSize: "1.2em"
    },
    alignCenter: {
        textAlign: 'center'
    },
    roundAdmin: {
        position: 'absolute',
        top: '0',
        left: '4px',
        zIndex: '1200' //Need to put it above the edge swipe area
    },
    relative: {
        position: 'relative'
    },
    blurryTextWhite: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(255,255,255,0.5)'
    },
    blurryTextBlue: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(133,203,244,0.5)'
    }
})

const RoundLoading = (props) => {
    console.log('[] Round Loading');

    const { classes } = props  // Connect
    var { quantity } = props // Parent

    quantity = quantity ? quantity : 1

    const renderItems = () => {
        var output = []

        for (let i = 0; i < quantity; i++)
        {
            const icon_colour = ['green', 'orange', 'red', 'white'][randBetween(0,3)]

            output.push(
                <Box my={2} key={`round-loading-item-${i}`}>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        wrap='nowrap'
                        key={`round-list-item-loading`}
                        className={classes.relative}
                    >
                        <Grid item xs={3}>
                            <Grid
                                container
                                direction='column'
                                justify="center"
                                alignItems="center"
                                alignContent="center"
                            >
                                <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundMonth}`}>{['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][randBetween(0,11)]}</Grid>
                                <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundDayNumber}`} style={{fontSize: '2.6em', lineHeight: '1em'}}>{randBetween(1, 31)}</Grid>
                                <Grid item className={`${classes.blurryTextWhite} ${classes.alignCenter}`}>{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][randBetween(0,6)]} {randBetween(1, 8)}:00pm</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Box mr={1}>
                                <Grid container
                                    direction='column'
                                >
                                    <Grid item>
                                        <Grid container
                                            direction='row'
                                            justify="space-between"
                                        >
                                            <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundTeam}`}>
                                                Round {randBetween(1,30)} <span style={{color: '#85cbf4'}} className={`${classes.blurryTextBlue}`}> - Div X Winners</span>
                                            </Grid>
                                            <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundVoting}`}>
                                                {['Can you make it', 'I\'m in!', 'I\'m out', 'On the card', 'Maybe', 'Voting Open', 'Vote Submitted', 'Voting Closed'][randBetween(0,7)]}&nbsp;
                                                <Brightness1Icon style={{ fontSize: 12, color: icon_colour, borderRadius: '50%', opacity: '0.2',  boxShadow: `0 0 5px ${icon_colour}` }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction='row'
                                            justify="space-between"
                                        >
                                            <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundClub}`}>
                                                Winners
                                            </Grid>
                                            <Grid item className={`${classes.blurryTextWhite} ${classes.navRoundClubGoals}`}>
                                                {randBetween(0,13)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction='row'
                                            justify="space-between"
                                        >
                                            <Grid item className={`${classes.blurryTextBlue} ${classes.navRoundOpposition}`}>
                                                No Chance
                                            </Grid>
                                            <Grid item className={`${classes.blurryTextBlue} ${classes.navRoundOppositionGoals}`}>
                                                {randBetween(0,13)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )

            output.push(
                <Divider variant="fullWidth" component="div" key={`round-loading-div-${i}`} />
            )
        }

        return output
    }

    return (
        <>
            <Box mt={2} p={1} >
                <Typography className={`${classes.blurryTextWhite}`} content="h4" variant="h4">Loading Rounds</Typography>
            </Box>
            {renderItems()}
        </>
    )
}

export default withStyles(styles)(RoundLoading)
