import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Divider } from '@material-ui/core/'
import PlayerStatHero from './PlayerStatHero'


const styles = theme => ({

    fullWidth: {
        width: '100%'
    },
})

const PlayerStatHeroList = (props) => {
    const { classes } = props

    // Props
    const { heading, items } = props

    console.log('items', items)

    const renderPlayerStats = () => {
        return items.map(stat => {

            const secondary_stats = []

            if(stat.hasOwnProperty('goals'))
            {
                secondary_stats.push({
                    stat_id: stat.goals.stat_id,
                    tooltip: stat.goals.amount_label,
                    stat_total: stat.goals.stat_total,
                    colour: stat.goals.colour,
                    amount_icon: stat.goals.amount_icon
                })
            }

            if(stat.hasOwnProperty('assists'))
            {
                secondary_stats.push({
                    stat_id: stat.assists.stat_id,
                    tooltip: stat.assists.amount_label,
                    stat_total: stat.assists.stat_total,
                    colour: stat.assists.colour,
                    amount_icon: stat.assists.amount_icon
                })
            }

            if(stat.hasOwnProperty('games_played'))
            {
                secondary_stats.push({
                    stat_id: 'rounds_played',
                    tooltip: 'Rounds Played',
                    stat_total: stat.games_played,
                    colour: 'Blue',
                    amount_icon: 'FaRegCalendarAlt'
                })
            }

            console.log('secondary_stats', secondary_stats)

            return (
                <Grid item key={stat.player_id}
                    className={`${classes.fullWidth}`}>
                    <PlayerStatHero player_id={stat.player_id} points_total={stat.stat_total} secondary_stats={secondary_stats} />
                    {/* <PlayerStatHero player_id={stat.player_id} points_total={stat.stat_total} secondary_stats={{goals: stat.goals, assists: stat.assists, games_played: stat.games_played}} /> */}
                </Grid>
            )
        })
    }

    return (
        <>
            <Box mt={2} mb={1} mx={1}>
                <Typography content="h1" variant="h1">{heading}</Typography>
            </Box>
            <Grid
                container
                direction="row"
                alignContent="space-around"
                justify="center"
                alignItems="center"
            >
                {renderPlayerStats()}
            </Grid>

            <Divider variant="fullWidth" component="div" />
        </>
    )
}


export default withStyles(styles)(PlayerStatHeroList)
