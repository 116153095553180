import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, FormControl, InputLabel, Select, MenuItem, ButtonGroup, Button } from '@material-ui/core/'
import Divider from "@material-ui/core/Divider"
import { Link } from 'react-router-dom'
import {
    loadTeamStats, loadRoundCard, loadPlayer, addStat, removeStat,
    updateRoundStatsPlayerSelect, updateRoundStatsStatSelect, loadRoundPlayersStats,
    loadRound
} from '../../../../actions'
import { connect } from 'react-redux'
import { GreenButton, RedButton } from "../../../ui/Buttons"
import PlayerStat from '../../../player/stats/PlayerStat'
import RoundListItem from '../../RoundListItem'
import RoundLoading from '../../../loading/RoundLoading'
import { TEAM_STAT_TYPE_CREATE } from '../../../../navigation'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    stretch: {
        flex: '1'
    }
})


const RoundStats = (props) => {
    console.log('[] Round Stats')

    const round_id = props.match.params.round_id

    const {
        classes,
        stats,
        round_players_stats,
        round,
        player,
        loadTeamStats,
        loadPlayer,
        loadRoundCard,
        loadRound,
        addStat,
        removeStat,
        updateRoundStatsPlayerSelect,
        updateRoundStatsStatSelect,
        loadRoundPlayersStats,
        selected_player_id,
        selected_stat_id,
        loading
    } = props



    useEffect(() => {

        if(!round || !round.hasOwnProperty('details'))
        {
            loadRound(round_id)
        }

        if(!round || !round.hasOwnProperty('card'))
        {
            loadRoundCard(round_id)
        }

        if(!round_players_stats)
        {
            loadRoundPlayersStats(round_id)
        }

    }, [round_id])

    const team_id = round && round.hasOwnProperty('details') ? round.details.player_team_ids[0] : false // Should only be needed for Competition rounds where there will only ever be one team_season_id

    console.log('team_id', team_id)

    useEffect(() => {
        if(team_id && !stats)
        {
            loadTeamStats(team_id)
        }
    }, [team_id])

    useEffect(() => {
        if(round && round.hasOwnProperty('card'))
        {
            for(var player_id in round.card)
            {
                console.log('player_id', player_id)
                loadPlayer(player_id)
            }
        }
    }, [round])

    console.log('round*', round)

    if(!stats || !round || !round.hasOwnProperty('card'))
    {
        return <RoundLoading />
    }

    const renderStats = () => {
        var output = []

        for(var x in stats)
        {
            const { stat_type_id, name, active } = stats[x]

            active && output.push(
                <MenuItem key={`statitem-${stat_type_id}`} value={stat_type_id}>{name}</MenuItem>
            )

        }

        return output
    }

    const renderCard = () => {
        var output = []

        for(var player_id in round.card)
        {

            const card_player = player[player_id]

            if(card_player)
            {
                output.push(
                    <MenuItem key={`playeritem-${player_id}`} value={player_id}>#{card_player.shirt_number} - {card_player.first_name} {card_player.last_name}</MenuItem>
                )
            }

        }

        return output
    }


    const renderStatsList = () => {
        var output = []

        console.log('round_playerse_stats', round_players_stats)

        for(var stat_id in round_players_stats)
        {
            console.log('round_players_stats[stat_id]', round_players_stats[stat_id])

            var stat_output = []

            for(var player_id in round_players_stats[stat_id])
            {

                const { stat_amount } = round_players_stats[stat_id][player_id]
                const stat = stats[stat_id]

                if(stat_amount > 0)
                {
                    stat_output.push(
                        <Grid xs={4} item key={`${stat_id}-${player_id}`} >
                            <PlayerStat player_id={player_id} stat_total={Number(stat_amount) * (stat.amount_multiplier == null ? 1 : Number(stat.amount_multiplier))} amount_icon={stat.amount_icon} amount_icon_color={stat.amount_icon_color} amount_label={stat.amount_label} colour={stat.colour} />
                            {/* <PlayerStat key={`${stat.name}-${item.player_id}`} player_id={item.player_id} amount_icon={stat.amount_icon} amount_icon_color={stat.amount_icon_color} stat_total={Number(item.stat_total) * (stat.amount_multiplier == null ? 1 : Number(stat.amount_multiplier))} /> */}
                        </Grid>
                    )
                }
            }

            if(stat_output.length > 0)
            {
                output.push(
                    <>
                        <Box mt={2} mx={1} key={`box-${stat_id}-${player_id}`}>
                            <Typography content="h1" variant="h1">{stats[stat_id].name}</Typography>
                        </Box>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            key={`grid-${stat_id}-${player_id}`}
                        >
                            {stat_output}
                        </Grid>

                    </>
                )
            }

        }

        return output
    }

    return (
        <>
            <RoundListItem round_id={round_id} />

            <Box mb={1} mx={1}>
                <Box mt={2} >
                    {/* <Typography content="h1" variant="h1">{stat.name}</Typography> */}
                </Box>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                    className={classes.fullWidth}
                >
                    <Grid item xs>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="player-select-label">Player</InputLabel>
                            {console.log('selected_player_id', selected_player_id)}
                            <Select
                                labelId="playerselect-label"
                                id="player-select"
                                value={selected_player_id}
                                onChange={(e) => { updateRoundStatsPlayerSelect(e.target.value) }}
                            >
                                {renderCard()}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                >
                    <Grid item xs>
                        <Box mt={1}>
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel id="stat-select-label">Stat</InputLabel>
                                <Select
                                    labelId="statselect-label"
                                    id="stat-select"
                                    value={selected_stat_id}
                                    onChange={(e) => { updateRoundStatsStatSelect(e.target.value) }}
                                >
                                    {renderStats()}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container
                    wrap="nowrap"
                    justify="center"
                    alignItems="stretch"
                    alignContent="stretch"
                    direction="column"
                    spacing={1}
                >
                    <Grid item xs>
                        <Box my={3}>
                            <ButtonGroup
                                color="secondary"
                                size="small"
                                className={`${classes.flex}`}
                                fullWidth
                            >
                                <RedButton
                                    disabled={loading}
                                    onClick={() => { removeStat(round_id) }}
                                >
                                    &ndash;
                                </RedButton>
                                <GreenButton
                                    disabled={loading}
                                    onClick={() => { addStat(round_id) }}
                                >
                                    +
                                </GreenButton>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Divider variant="fullWidth" component="div" />

            <Box my={3} mx={1} key="addnewstat">
                <Link to={`${TEAM_STAT_TYPE_CREATE}/${round.details.player_team_ids[0]}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="secondary" className={classes.fullWidth}>
                        Create a New Stat
                    </Button>
                </Link>
            </Box>

            <Divider variant="fullWidth" component="div" />

            {renderStatsList()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const round_id = ownProps.match.params.round_id
    const team_id = state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('details') ? state.round[round_id].details.player_team_ids[0] : false // Should only be needed for Competition rounds where there will only ever be one team_season_id

    return {
        stats: state.hasOwnProperty('admin') && state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) && state.admin.team[team_id].hasOwnProperty('stats') ? state.admin.team[team_id].stats : false,
        // round_players_stats: state.admin.hasOwnProperty('round') ? state.admin.round[round_id] : false,
        round_players_stats: state.admin.hasOwnProperty('round') && state.admin.round.hasOwnProperty(round_id) && state.admin.round[round_id].hasOwnProperty('players_stats') ? state.admin.round[round_id].players_stats : false,
        round: state.round[round_id],
        player: state.player,
        selected_player_id: state.ui.admin.hasOwnProperty('round_stats') && state.ui.admin.round_stats.hasOwnProperty('player_id') ? state.ui.admin.round_stats.player_id : "",
        selected_stat_id: state.ui.admin.hasOwnProperty('round_stats') && state.ui.admin.round_stats.hasOwnProperty('stat_id') ? state.ui.admin.round_stats.stat_id : "",
        loading: (state.loading.hasOwnProperty('REMOVE_ROUND_PLAYER_STAT') && state.loading.REMOVE_ROUND_PLAYER_STAT)
            || (state.loading.hasOwnProperty('ADD_ROUND_PLAYER_STAT') && state.loading.ADD_ROUND_PLAYER_STAT)
            ? true : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamStats, loadPlayer, loadRoundCard, loadRound, addStat, removeStat, updateRoundStatsPlayerSelect, updateRoundStatsStatSelect, loadRoundPlayersStats })(RoundStats))

