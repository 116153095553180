
export const ROOT                       = ""
export const HOME                       = "/"
export const LOGIN                      = "/login"
export const REGISTER                   = "/register"
export const PASSWORD_RESET             = "/passwordreset"
export const SET_PASSWORD               = "/passwordresetcreate"

export const PLAYER_PROFILE             = "/player/profile"
export const PLAYER_UPDATE              = "/player/update"

export const ROUNDS_ADMIN               = "/rounds/admin"
export const ROUND_VOTE                 = "/round/vote"
export const ROUND_CREATE               = "/round/create"
export const ROUND_UPDATE               = "/round/update"
export const ROUND_ADMIN                = "/round/admin"
export const ROUND_CARD                 = "/round/admin/card"
export const ROUND_STATS                = "/round/admin/stats"
export const ROUND_SCORE                = "/round/admin/score"
export const ROUND_ATTENDANCE           = "/round/attendance"
export const WHITEBOARD_ADMIN           = "/round/whiteboard/impart-wisdom"
export const WHITEBOARD_SCRIBBLE        = "/round/whiteboard/scribble"
export const ROUND_PLAYER_VOTING_STATS  = "/round/admin/player_voting_stats"

export const REGISTER_FOR_TEAM          = "/team-registration"
export const TEAM_UPDATE                = "/team/update"
export const TEAM_CREATE                = "/team/create"
export const TEAMS_ADMIN                = "/teams/admin"
export const TEAM_ADMIN                 = "/team/admin"
export const TEAM_PLAYER_REGISTRATION   = "/team/admin/registration"
export const TEAM_STAT_TYPES            = "/team/tracked-stats"
export const TEAM_STAT_TYPE_ADMIN       = "/team/tracked-stat"
export const TEAM_STAT_TYPE_CREATE      = "/team/tracked-stats/create"
export const SEASON_PLAYER_REGISTRATION = "/team/season/registration"
export const TEAM_SEASONS               = "/team/seasons"
export const TEAM_SEASONS_PAST          = "/team/seasons/past"

export const USER_SEASONS_PAST          = "/seasons/past"

export const SEASON_CREATE              = "/team/seasons/create"
export const SEASON_UPDATE              = "/team/seasons/update"
export const SEASON_ADMIN               = "/team/season"
export const STAT_ICON_SELECTOR         = "/team/tracked-stat/icon-selector"
export const SEASON_LEADERBOARD         = "/team/season/leaderboard"
export const LEADERBOARD_SETTINGS       = "/team/season/leaderboard-settings"

export const SEASON_RESULTS             = "/team/season/results"

export const OPPOSITION_ADMIN           = "/team/season/opposition"
export const OPPONENT_ADMIN             = "/team/season/opponent"
export const OPPONENT_CREATE            = "/team/season/opponent/create"
export const OPPONENT_UPDATE            = "/team/season/opponent/update"