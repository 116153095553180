import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PlayerHeaderDetailed from '../PlayerHeaderDetailed'
import PlayerProfileStat from '../PlayerProfileStat'
import { loadSeasons, loadUserTeams, loadProfileStats } from '../../../actions'
import { Box, Typography, Chip, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@material-ui/core'
import { FaFutbol, FaHandsHelping, FaVoteYea, FaRegCalendarAlt, FaTrophy, FaCut, FaTrash, FaFile } from "react-icons/fa"
import { RedChip, GreenChip, BlueChip, OrangeChip, YellowChip } from '../../ui/Chips'
import Chat from '@material-ui/icons/Chat'

import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver'
import TeamSeasonListItem from '../../seasons/TeamSeasonListItem'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center'
    },
    textLeft: {
        justifyContent: 'flex-start'
    },
    textRight: {
        textAlign: 'right'
    },
    fullHeight: {
        height: '100%'
    },
    bigAvatar: {
        display: 'block',
        // margin: 10,
        width: '100%',
        height: 'auto',
    },
    bordered: {
        // border: "1px solid red"
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        paddingLeft: '8px'
    },
    horizontalList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        '& li': {
            flex: '1',
            // minWidth: '200px',
            paddingLeft: '0',
            paddingRight: '0'
        }
    },
    avatar: {
        margin: 10,
    },
    statAvatar: {
        color: '#0d2431',
        backgroundColor: '#ff355c',
        fontSize: '3em',
        width: '120px',
        height: '120px',
        margin: '0 0 10px 20px'
    },
    statListItem: {
        textAlign: 'right',
        '& span': {
            fontSize: '3em'
        },
        '& p': {
            fontSize: '1.4em'
        }
    }
})

const PlayerProfile = props => {
    console.log('[] Player Profile')

    const { player } = props
    const { classes, seasons, loadSeasons, loadUserTeams, loadProfileStats } = props

    useEffect(() => {
        loadUserTeams()
        loadSeasons()
    }, [])

    useEffect(() => {
        for(const season_id in seasons)
        {
            if(seasons.hasOwnProperty(season_id))
            {
                const season = seasons[season_id]

                // An Admin may not be a player, skip in this case
                if(!season.user_player_id)
                {
                    continue
                }

                if(!player.hasOwnProperty(season.user_player_id) || !player[season.user_player_id].hasOwnProperty('profile_stats'))
                {
                    loadProfileStats(season.user_player_id)
                }
            }
        }
    }, [seasons])

    const renderPlayerStats = () => {

        console.log('seasons', seasons)


        var ordered_seasons = []

        for(var season_id in seasons)
        {
            // if(seasons[season_id].active)
            // {
            ordered_seasons.push([season_id, seasons[season_id].start_date])
            // }
        }

        // Sort the seasons by date
        ordered_seasons.sort((a, b) => b[1] > a[1] ? 1 : -1)

        var output = []

        console.log('ordered_seasons', ordered_seasons)


        var x = 0

        // for (const season_id in seasons) {
        //     if (seasons.hasOwnProperty(season_id)) {
        //         const season = seasons[season_id];
        for(var ordered_ids of ordered_seasons)
        {
            // const { season_id, club_name, age_group, division, start_date, end_date} = seasons[ordered_ids[0]]

            const { team_season_id, team_id, active, user_player_id } = seasons[ordered_ids[0]]

            // Admins may not also be a player so skip in that case
            if(!user_player_id)
            {
                continue
            }

            const votes_output = []

            if(player && player.hasOwnProperty(user_player_id) && player[user_player_id].hasOwnProperty('profile_stats'))
            {
                const { vote_totals, comments } = player[user_player_id].profile_stats
                const { vote_count, points_total, cut_total, glory_total } = vote_totals

                const points_output = []

                for(const comment of comments.points)
                {
                    points_output.push(
                        <GreenChip key={`banter-${x++}`} color="secondary" avatar={<Avatar><Chat style={{ fontSize: '1rem' }} /></Avatar>} label={`${comment}`} style={{ margin: '4px' }} />
                    )
                }

                votes_output.push(
                    <>
                        <Box my={2}>
                            <Typography variant="h2">Points <span style={{ color: '#ff355c', fontSize: '70%' }}>{points_total ? points_total : 0} {points_total === 1 ? 'Point' : 'Points'} - {vote_count ? vote_count : 0} {vote_count === 1 ? 'Vote' : 'Votes'}</span></Typography>
                        </Box>
                        <Box>
                            {points_output}
                        </Box>
                    </>
                )

                const glory_output = []

                for(const comment of comments.glory)
                {
                    glory_output.push(
                        <GreenChip key={`banter-${x++}`} color="secondary" avatar={<Avatar><FaTrophy style={{ fontSize: '1rem' }} /></Avatar>} label={`${comment}`} style={{ margin: '4px' }} />
                    )
                }

                votes_output.push(
                    <>
                        <Box my={2}>
                            <Typography variant="h2">Glory <span style={{ color: '#ff355c', fontSize: '70%' }}>{glory_total ? glory_total : 0} {glory_total === 1 ? 'Vote' : 'Votes'}</span></Typography>
                        </Box>
                        <Box>
                            {glory_output}
                        </Box>
                    </>
                )

                const cut_output = []

                for(const comment of comments.cut)
                {
                    cut_output.push(
                        <RedChip key={`banter-${x++}`} color="secondary" avatar={<Avatar><FaCut style={{ fontSize: '1rem' }} /></Avatar>} label={`${comment}`} style={{ margin: '4px' }} />
                    )
                }

                votes_output.push(
                    <>
                        <Box my={2}>
                            <Typography variant="h2">Cut <span style={{ color: '#ff355c', fontSize: '70%' }}>{cut_total ? cut_total : 0} {cut_total === 1 ? 'Vote' : 'Votes'}</span></Typography>
                        </Box>
                        <Box>
                            {cut_output}
                        </Box>
                    </>
                )


            }

            output.push(
                <Box mb={3} key={`player-profile-${team_season_id}`}>
                    <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
                    {/* <Divider /> */}
                    <Box mt={2}>
                        <PlayerHeaderDetailed key={`player-header-basic-${user_player_id}`} player_id={user_player_id} />
                    </Box>
                    {votes_output}
                </Box>
            )
        }

        return output
    }

    return (
        <>
            <Box m={1}>

                {renderPlayerStats()}



                {/*
                <Box mt={5} mx={1}>

                <Divider />
                <PlayerHeaderBasic player={player[1]} />
                    <Typography content="h4" variant="h4">Div 6 Firsts</Typography>

                    <List className={classes.horizontalList}>
                        <PlayerProfileStat stat={{ quantity: 17, label: 'Games Played' }} Icon={FaRegCalendarAlt} />
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Goals' }} Icon={FaFutbol} />
                        <PlayerProfileStat stat={{ quantity: 4, label: 'Assists' }} Icon={FaHandsHelping} />
                        <ListItem>
                            <ListItemText className={`${classes.statListItem}`} primary="4" secondary="Hat Tricks" />
                            <ListItemAvatar>
                            <Avatar className={`${classes.statAvatar}`}>
                                <FaFutbol />
                                <FaFutbol />
                                <FaFutbol />
                            </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Yellow Cards' }} Icon={FaFile} icon_color='#F4CF22' />
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Red Cards' }} Icon={FaFile} icon_color='#F42722' />
                        <PlayerProfileStat stat={{ quantity: <>10<span style={{fontSize: '0.4em'}}>min</span></>,  label: 'Superiority Bin' }} Icon={FaTrash} />
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Point Votes' }} Icon={FaVoteYea} />
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Glory Votes' }} Icon={FaTrophy} />
                        <PlayerProfileStat stat={{ quantity: 5, label: 'Cut Votes' }} Icon={FaCut} />
                    </List>

                    <Typography content="h4" variant="h4">Banter</Typography>

                        <Chip color="secondary" className={`${classes.marginBottom} ${classes.marginRight}`} avatar={<Avatar><RecordVoiceOver /></Avatar>} label={`Did all the things`} />
                        <Chip color="secondary" className={`${classes.marginBottom} ${classes.marginRight}`} avatar={<Avatar><RecordVoiceOver /></Avatar>} label={`Boom!`} />
                        <Chip color="secondary" className={`${classes.marginBottom} ${classes.marginRight}`} avatar={<Avatar><RecordVoiceOver /></Avatar>} label={`Did all the things over and over again Did all the things over and over again Did all the things over and over againDid all the things over and over againDid all the things over and over again`} />
                        <Chip color="primary" className={`${classes.marginBottom} ${classes.marginRight}`} avatar={<Avatar><RecordVoiceOver /></Avatar>} label={`Ran all day`} />
                        <Chip color="primary" className={`${classes.marginBottom} ${classes.marginRight}`} avatar={<Avatar><RecordVoiceOver /></Avatar>} label={`3 goals = 3 points`} />

                </Box> */}
            </Box>
        </>
    )
}

const mapStateToProps = state => {
    return {
        player: state.player,
        seasons: state.season
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadSeasons, loadUserTeams, loadProfileStats })(PlayerProfile))
