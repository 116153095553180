import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { pointsButtonClick , updateVoteComment, castVote, loadRound, loadRoundVotes, loadRoundCard } from '../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core/'
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';

// Forms
import PlayerRoundVotePlayer from './PlayerRoundVotePlayer';
import RoundLoading from '../loading/RoundLoading';
import MuiAlert from '@material-ui/lab/Alert';


const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bigAvatar: {
        display: 'block',
        // margin: 10,
        width: '100%',
        height: 'auto',
    },
    selected: {
        boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${green[400]}`
    },
    fullWidth: {
        width: '100%'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


const renderList = (user_player_id, round_id, round_card, votes_submitted, round_votes, pointsButtonClick, onInputChange) => {

    var output = []

    // If there are votes then the player has already voted so just show the previous votes and allow comment updates
    if(votes_submitted)
    {
        return Object.keys(round_votes).map(player_id => {
            return (
                <div key={player_id}>
                    <PlayerRoundVotePlayer allow_voting={false} round_id={round_id} vote_option={round_votes.hasOwnProperty(player_id) ? round_votes[player_id].vote : false} player_id={player_id} pointsButtonClick={pointsButtonClick} onInputChange={onInputChange} />
                </div>
            )
        })
    }

    // Check if there are any players to vote for.  If it's 0 then the admin hasn't set the lineup yet.  Also best to check that there are
    //  at least 3 players on the card that aren't the voting player ( less than 4 ) as that is the minimum to case a vote.
    if(Object.entries(round_card).length <= 3)
    {
        return (
            <Box my={2} mx={1}>
                <Typography variant="body1">Looks like the coach is being a bit lazy and hasn't set the Card yet.</Typography>
                <Typography variant="body1">Please check back later.</Typography>
            </Box>
        )
    }

    var ordered_card = [];

    for (var player_id in round_card)
    {
        if(user_player_id !== player_id) // Remove player so they can't vote for themselves
        {
            ordered_card.push([player_id, round_card[player_id].sort_order])
        }
    }

    // Sort the players by date
    ordered_card.sort(function(a, b) {
        return a[1] - b[1];
    });

    // Player hasn't voted yet show show full card and allow voting
    for(var player_id of ordered_card)
    {
        player_id = player_id[0]

        output.push(
            <div key={player_id}>
                <PlayerRoundVotePlayer allow_voting={true} round_id={round_id} vote_option={round_votes.hasOwnProperty(player_id) ? round_votes[player_id].vote : false} player_id={player_id} pointsButtonClick={pointsButtonClick} onInputChange={onInputChange} />
            </div>
        )
    }

    return output
}

const PlayerRoundVote = (props) => {
    console.log('[] PlayerRoundVote')

    const { round_id, pointsButtonClick, rounds, castVote, loadRound, loadRoundVotes, loadRoundCard} = props

    const { classes } = props

    useEffect(() => {
        if(!rounds.hasOwnProperty(round_id) || !rounds[round_id].hasOwnProperty('details')) {
            //console.log('Trigger the action to load round')
            loadRound(round_id)
        }

        // if(!rounds.hasOwnProperty(round_id) || !rounds[round_id].hasOwnProperty('card')) {
            //console.log('Trigger the action to load round')
            loadRoundCard(round_id)
        // }

        if(!rounds.hasOwnProperty(round_id) || !rounds[round_id].hasOwnProperty('vote')) {
            //console.log('Trigger the action to load round')
            loadRoundVotes(round_id)
        }

    }, [round_id])

    if(!rounds.hasOwnProperty(round_id)
        || !rounds[round_id].hasOwnProperty('details')
        || !rounds[round_id].hasOwnProperty('vote')
        || !rounds[round_id].hasOwnProperty('card')
    ) {
        //console.log('Round not loaded yet')
        return ''
    }

    const round_card = rounds[round_id].card
    const round_votes = rounds[round_id].vote.votes
    const votes_submitted = rounds[round_id].vote.votes_submitted
    const { user_player_id } = rounds[round_id].details

    const onInputChange = (event, player_id, type) => {
        props.updateVoteComment(round_id, player_id, type, event.target.value)
    };

    const onSubmit = (e) => {
        e.preventDefault()
        console.log('Submit Form')
        // //console.log(round)
        castVote(round_id)
        // this.props.onSubmit(formValues);
    }

    return (
        <>
            <Box mb={3}>
                <Box m={1}>
                {
                    round_card.hasOwnProperty(user_player_id)
                        ? <Alert severity="success">You'll have to convince others to vote for you but you're on the card and can receive votes.</Alert>
                        : <Alert severity="error">You are not on the card and can't be voted for.  If you played let an admin know asap!</Alert>
                }
                </Box>

                <form onSubmit={onSubmit}>
                    {renderList(user_player_id, round_id, round_card, votes_submitted, round_votes, pointsButtonClick, onInputChange, updateVoteComment)}

                    <Box mx={1} mt={4} mb={8}>
                        <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                            {votes_submitted ? 'Update Comments' : 'Submit Votes'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </>
    )
}


const mapStateToProps = state => {
    return {
        rounds: state.round
    };
}

export default withStyles(styles)(connect(mapStateToProps, { pointsButtonClick, updateVoteComment, castVote, loadRound, loadRoundVotes, loadRoundCard })(PlayerRoundVote))






// const round_players = [
//     {
//         player_id: 1,
//         vote: {
//             reason_1pt: 'Comment for pt1',
//             glory: 'Glory comment'
//         }
//     },
//     {
//         player_id: 2,
//         vote: {}
//     },
//     {
//         player_id: 3,
//         vote: {
//             reason_2pt: 'Comment for pt2',
//             cut: 'Cut comment'
//         }
//     },
//     {
//         player_id: 4,
//         vote: {
//             reason_3pt: ''
//         }
//     }
// ]

// const player = {
//     1: {
//         player_id: '1',
//         first_name: 'Rob',
//         last_name: 'Horton',
//         shirt_number: '11',
//         position: 'Defence'
//     },
//     2: {
//         player_id: '2',
//         first_name: 'Trent',
//         last_name: 'Morrow',
//         shirt_number: '7',
//         position: 'Striker'
//     },
//     3: {
//         player_id: '3',
//         first_name: 'Ryan',
//         last_name: 'Farrow',
//         shirt_number: '23',
//         position: 'Striker'
//     },
//     4: {
//         player_id: '4',
//         first_name: 'Martin',
//         last_name: 'Mulkerrinsasdfas',
//         shirt_number: '27',
//         position: 'Mid'
//     },
// }