import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar } from '@material-ui/core/'
import { green, orange, red } from '@material-ui/core/colors'
import PlayerHeaderBasicLoading from '../loading/PlayerHeaderBasicLoading'

const styles = theme => ({
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    playerAvatar: {
        width: '128px'
    },
    avatarShadowGreen: {
        boxShadow: `10px 0px 10px -4px ${green[500]}`,
    },
    avatarShadowOrange: {
        boxShadow: `10px 0px 10px -4px ${orange[500]}`,
    },
    avatarShadowRed: {
        boxShadow: `10px 0px 10px -4px ${red[500]}`,
    },
    textGreen: {
        color: green[500]
    },
    textOrange: {
        color: orange[500]
    },
    textRed: {
        color: red[500]
    },
    MuiAvatar: {
        img: {
            height: 'auto'
        }
    }
})

const User = (props) => {

    console.log('[] Player Header Basic')
    // //console.log(props)

    const { user } = props // Parent
    const { classes } = props // Connect

    return (
        <Typography variant="h5">{user.first_name} {user.last_name}</Typography>
    )
}

export default withStyles(styles)(connect(null, {})(User))