import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamStats, loadManagedTeams } from '../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Divider, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

// Navigation
import {
    TEAM_STAT_TYPE_ADMIN,
    TEAM_STAT_TYPE_CREATE
} from '../../../navigation'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
})



const renderStats = (stats, classes) => {

    var active_stats = []
    var inactive_stats = []

    for(var stat_type_id in stats)
    {

        const { active, name } = stats[stat_type_id]

        if(active)
        {
            active_stats.push([stat_type_id, name])
        }
        else
        {
            inactive_stats.push([stat_type_id, name])
        }
    }

    // Sort the players by first name
    active_stats.sort((a, b) => a[1] > b[1] ? 1 : -1)
    inactive_stats.sort((a, b) => a[1] > b[1] ? 1 : -1)

    var active_stats_output = []

    for(var ordered_ids of active_stats)
    {
        const { stat_type_id, name } = stats[ordered_ids[0]]

        active_stats_output.push(

            <Box my={2} mx={1} key={stat_type_id}>
                <Link to={`${TEAM_STAT_TYPE_ADMIN}/${stat_type_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        {name}
                    </Button>
                </Link>
            </Box>
        )
    }

    if(active_stats_output.length !== 0)
    {
        active_stats_output.unshift(
            <Box mt={2} p={1} key={`heading-players`}>
                <Typography content="h1" variant="h1">Tracked Stats</Typography>
            </Box>
        )
    }

    // Inactive stats

    var inactive_stats_output = []

    for(var ordered_ids of inactive_stats)
    {
        const { stat_type_id, name } = stats[ordered_ids[0]]

        inactive_stats_output.push(

            <Box my={2} mx={1} key={stat_type_id}>
                <Link to={`${TEAM_STAT_TYPE_ADMIN}/${stat_type_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        {name}
                    </Button>
                </Link>
            </Box>
        )
    }

    if(inactive_stats_output.length !== 0)
    {
        inactive_stats_output.unshift(
            <Box mt={2} p={1} key={`heading-players`}>
                <Typography content="h1" variant="h1">Untracked Stats</Typography>
            </Box>
        )
    }

    return (
        <>
            {active_stats_output}
            {inactive_stats_output}
        </>
    )
}

const StatsAdmin = props => {
    console.log('[] Stats Admin')

    const team_id = props.match.params.team_id

    const { classes, loadTeamStats, stats, team, loadManagedTeams, loading } = props // Connect

    console.log('stats-main', stats)

    // Load the round data
    useEffect(() => {
        loadTeamStats(team_id)
        loadManagedTeams()
    }, [])

    console.log('Object.entries(stats).length', Object.entries(stats).length)

    return (
        <>
            <Box my={2} mx={1}>
                <Typography content="h2" variant="h2">{team.name ? team.name : 'Loading'}</Typography>
                <Typography content="h1" variant="h1">Stats</Typography>
            </Box>

            <Divider />

            {
                loading === false && Object.entries(stats).length === 0 &&

                <Box my={3} mx={1} key="stat-get-started">
                    <Typography variant="h1">Time to set up some stats</Typography>
                    <Typography variant="body1">You need to let us know what you want to track.</Typography>
                    <Typography variant="body1">A good place to start is goals/points/trys/touchdowns or whatever you call it when the ball/thing ends up in the right place.</Typography>
                    <Typography variant="body1">Next up you might have Red cards, Yellow cards, trips to the Sin Bin, 3 Pointers, Bicycle Kicks, Intercepts, Saves or any meanful moment in the game.</Typography>
                    <Typography variant="body1">Create your first stat now!</Typography>
                </Box>
            }
            <Box my={3} mx={1} key="addnewstat">
                <Link to={`${TEAM_STAT_TYPE_CREATE}/${team_id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <Button type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}>
                        Create a New Stat
                    </Button>
                </Link>
            </Box>

            <Divider />

            {renderStats(stats, classes)}

            <Divider />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {},
        stats: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) && state.admin.team[team_id].hasOwnProperty('stats') ? state.admin.team[team_id].stats : {},
        loading: state.loading.hasOwnProperty('LOAD_TEAM_ADMIN_STATS') && state.loading.LOAD_TEAM_ADMIN_STATS ? true : false
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamStats, loadManagedTeams })(StatsAdmin))


