import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { attendanceButtonClick, updateAttendanceComment, numberOfGamesButtonClick, loadRound, loadRoundAttendance, loadRoundCard, saveAttendance } from '../actions'
import Map from './maps/Map'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { red, green, blue, orange } from '@material-ui/core/colors'
import RoundListItem from './rounds/RoundListItem'
import { Grid, Typography, Box, TextField, Divider } from '@material-ui/core'
import PlayerHeaderBasic from './player/PlayerHeaderBasic'
import RoundLoading from './loading/RoundLoading'
import RoundAttendanceCounts from './rounds/RoundAttendanceCounts'
import RoundAttendancePlayerList from './rounds/RoundAttendancePlayerList'
import WhiteboardScribble from './whiteboard/WhiteboardScribble'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center',
        position: 'relative'
    },
    textLeft: {
        justifyContent: 'flex-start'
    },
    textRight: {
        textAlign: 'right'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    bordered: {
        border: '1px solid red',
        margin: '4px'
    },
    flex: {
        display: 'flex'
    },
    stretch: {
        flex: '1'
    }
})


const RedButton = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(red[500]),
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: red[500],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[700],
            borderColor: red[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${red[400]}`,
            background: `linear-gradient(270deg, ${red[400]} 0%, ${red[700]} 100%)`,
            textShadow: `0px 0px 3px ${red[100]}`,
            zIndex: 1
        }
    },
}))(Button)

const OrangeButton = withStyles(theme => ({
    root: {
        // color: theme.palette.getContrastText(orange[500]),
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: orange[500],
        borderColor: orange[400],
        '&:hover': {
            backgroundColor: orange[700],
            borderColor: orange[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${orange[400]}`,
            background: `linear-gradient(270deg, ${orange[400]} 0%, ${orange[700]} 100%)`,
            textShadow: `0px 0px 3px ${orange[100]}`,
            zIndex: 1
        }
    },
}))(Button)

const GreenButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[700],
            borderColor: green[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${green[400]}`,
            background: `linear-gradient(270deg, ${green[400]} 0%, ${green[700]} 100%)`,
            textShadow: `0px 0px 3px ${green[100]}`,
            zIndex: 1
        }
    },
}))(Button)

const BlueButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        borderColor: blue[400],
        '&:hover': {
            backgroundColor: blue[700],
            borderColor: blue[600],
        },
        '&.active': {
            boxShadow: `0px 4px 2px 2px #0d2431, 0px 24px 2px -13px ${blue[400]}`,
            background: `linear-gradient(270deg, ${blue[400]} 0%, ${blue[700]} 100%)`,
            textShadow: `0px 0px 3px ${blue[100]}`,
            zIndex: 1
        }
    },
}))(Button)


const renderSetAttendanceList = (round_id, rounds, attendance_rounds, classes, attendanceButtonClick, onInputChange, onSubmit) => { //}, pointsButtonClick, onInputChange) => {

    return (
        <>
            {
                attendance_rounds.map(round_id => {

                    const { user_player_id } = rounds[round_id].details

                    // If user is an admin but not a registered player for the season then don't show the set attendance options for that round.
                    if(!user_player_id)
                    {
                        return
                    }

                    const round_player_attendance = rounds[round_id].attendance.hasOwnProperty(user_player_id) ? rounds[round_id].attendance[user_player_id] : null

                    return (
                        <div key={user_player_id}>
                            <RoundListItem round_id={round_id} />

                            <Box mx={1} my={2}>

                                <Grid item>
                                    <Grid container
                                        wrap="nowrap"
                                        justify="center"
                                        alignItems="stretch"
                                        alignContent="stretch"
                                        direction="column"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <ButtonGroup
                                                color="secondary"
                                                size="medium"
                                                className={`${classes.flex}`}
                                            >
                                                <GreenButton
                                                    className={`${classes.stretch} ${round_player_attendance ? round_player_attendance.status === 'I' ? 'active' : '' : ''}`}
                                                    onClick={() => { attendanceButtonClick(round_id, user_player_id, 'I') }}
                                                >
                                                    In
                                                </GreenButton>
                                                <OrangeButton
                                                    className={`${classes.stretch} ${round_player_attendance ? round_player_attendance.status === 'M' ? 'active' : '' : ''}`}
                                                    onClick={() => { attendanceButtonClick(round_id, user_player_id, 'M') }}
                                                >
                                                    Maybe
                                                </OrangeButton>
                                                <RedButton
                                                    className={`${classes.stretch} ${round_player_attendance ? round_player_attendance.status === 'O' ? 'active' : '' : ''}`}
                                                    onClick={() => { attendanceButtonClick(round_id, user_player_id, 'O') }}
                                                >
                                                    Out
                                                </RedButton>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Box mt={2} mb={4}>
                                            {console.log('round_player_attendance', round_player_attendance)}
                                            <TextField
                                                label="Let us know why."
                                                placeholder="I'm on holidays.  I'll probably be 15min late."
                                                // error={touched && invalid}
                                                // helperText={touched && error}
                                                className={`${classes.fullWidth}`}
                                                inputProps={{
                                                    maxLength: 100,
                                                }}
                                                name={`${round_id}-round_comment`}
                                                autoComplete="off"
                                                value={round_player_attendance && round_player_attendance.player_comment ? round_player_attendance.player_comment : ''}
                                                onChange={(event) => { onInputChange(event, round_id, user_player_id) }}
                                            />
                                        </Box>
                                    </Grid>

                                </Grid>

                            </Box>
                        </div>
                    )
                })
            }
        </>
    )
}


const RoundAttendance = props => {
    console.log('[] RoundAttendance')

    const round_id = props.match.params.round_id

    // const attendance_rounds = [99, 100];
    // const attendance_rounds = [round_id];

    const { classes, round, numberOfGamesButtonClick, attendanceButtonClick, loadRoundAttendance, loadRoundCard, loadRound, saveAttendance } = props

    // if(round.hasOwnProperty('round_id'))
    // {
    //     var supporting_rounds = [];
    // }

    useEffect(() => {
        if(!round.hasOwnProperty(round_id) || !round[round_id].hasOwnProperty('details'))
        {
            // //console.log('Trigger the action to load round attendance')
            loadRound(round_id)
        }

        // if(!round.hasOwnProperty(round_id) || !round[round_id].hasOwnProperty('attendance'))
        // {
        // //console.log('Trigger the action to load round attendance')
        loadRoundAttendance(round_id)
        // }

        if(!round.hasOwnProperty(round_id) || !round[round_id].hasOwnProperty('card'))
        {
            // //console.log('Trigger the action to load round card')
            loadRoundCard(round_id)
        }
    }, [round_id])


    useEffect(() => {
        if(round.hasOwnProperty(round_id) && round[round_id].hasOwnProperty('details') && round[round_id].details.hasOwnProperty('supporting_rounds'))
        {
            for(var r_id of round[round_id].details.supporting_rounds)
            {
                console.log(`Load round: ${r_id}`)

                if(!round.hasOwnProperty(r_id) || !round[r_id].hasOwnProperty('details'))
                {
                    // console.log('Trigger the action to load round attendance')
                    loadRound(r_id)
                }

                if(!round.hasOwnProperty(r_id) || !round[r_id].hasOwnProperty('attendance'))
                {
                    // //console.log('Trigger the action to load round attendance')
                    loadRoundAttendance(r_id)
                }

                if(!round.hasOwnProperty(r_id) || !round[r_id].hasOwnProperty('card'))
                {
                    // //console.log('Trigger the action to load round card')
                    loadRoundCard(r_id)
                }
            }
        }
    }, [round[round_id]])

    // Ensure that the store has been populated before rendering the round(s)

    if(!round.hasOwnProperty(round_id) || !round[round_id].hasOwnProperty('attendance') || !round[round_id].hasOwnProperty('card') || !round[round_id].hasOwnProperty('details'))
    {
        return <RoundLoading quantity={1} />
    }


    // Load any supporting rounds
    if(round[round_id].details.hasOwnProperty('supporting_rounds'))
    {
        for(var r_id of round[round_id].details.supporting_rounds)
        {
            if(!round.hasOwnProperty(r_id) || !round[r_id].hasOwnProperty('attendance') || !round[r_id].hasOwnProperty('card') || !round[r_id].hasOwnProperty('details'))
            {
                return <RoundLoading quantity={1} />
            }
        }
    }

    var attendance_rounds = [round_id]

    if(round[round_id].details.hasOwnProperty('supporting_rounds'))
    {
        attendance_rounds = [round_id, ...round[round_id].details.supporting_rounds]
    }

    const renderCanAttendButtons = () => {

        var buttons = []
        var x = 0

        for(var r_id of attendance_rounds)
        {
            const { user_player_id } = round[r_id].details

            // If user is an admin but not a registered player for the season then don't show the set attendance options for that round.
            if(!user_player_id)
            {
                continue
            }

            const round_player_attendance = round[r_id].attendance.hasOwnProperty(user_player_id) ? round[r_id].attendance[user_player_id] : null

            if(round_player_attendance && (round_player_attendance.status === 'I' ||
                round_player_attendance.status === 'M'))
            {

                const c = x
                const { can_play_count } = round[r_id].attendance[user_player_id]

                if(x % 2 === 0)
                {
                    buttons.push(
                        <GreenButton
                            className={`${classes.stretch} ${Number(can_play_count) === x || (Number(can_play_count) === -1 && x === 0) ? 'active' : ''}`}
                            onClick={() => { numberOfGamesButtonClick(attendance_rounds, c) }}
                            key={`bn-green-${x}`}
                        >
                            {x++ === 0 ? 'All' : x - 1}
                        </GreenButton>
                    )
                }
                else
                {
                    buttons.push(
                        <BlueButton
                            className={`${classes.stretch} ${Number(can_play_count) === x ? 'active' : ''}`}
                            onClick={() => { numberOfGamesButtonClick(attendance_rounds, c) }}
                            key={`bn-blue-${x}`}
                        >
                            {x++}
                        </BlueButton>
                    )
                }
            }
        }

        if(buttons.length <= 1)
        {
            // No buttons to output
            return
        }

        return (
            <>
                <Grid item>
                    <Grid container
                        wrap="nowrap"
                        justify="center"
                        alignItems="stretch"
                        alignContent="stretch"
                        direction="column"
                        spacing={1}
                    >
                        <Grid item>
                            <Box my={2}>
                                <Typography variant="body1">Of the games I'm In/Maybe for I can play:</Typography>
                            </Box>
                            <ButtonGroup
                                color="secondary"
                                size="medium"
                                className={`${classes.flex}`}
                            >
                                {buttons}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }


    const onInputChange = (event, round_id, player_id) => {
        // //console.log(event.target.value)

        props.updateAttendanceComment(round_id, player_id, event.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log('Submit Form')
        saveAttendance(round_id)
        // //console.log(round_players)
        // castVote(round_id, player.player_id)
        // this.props.onSubmit(formValues);
    }


    const listPlayers = (players, user_player_id) => {
        // Copy players into an array to sort

        var ordered_players = []

        for(var player_id in players)
        {
            // Exclude the user as we'll add them to the top of the pile to make it easier for them to see their current status
            if(Number(player_id) !== Number(user_player_id))
            {
                ordered_players.push([player_id, players[player_id].sort_order])
            }
        }

        // Sort the players by date
        ordered_players.sort(function(a, b) {
            return a[1] - b[1]
        })

        // Add the user in to the top of the pile
        ordered_players.unshift([user_player_id])

        var output = []

        for(var ordered_ids of ordered_players)
        {
            player_id = ordered_ids[0]

            var comment = players.hasOwnProperty(player_id) ? players[player_id].player_comment : ''

            var highlightColor = ''

            switch(players.hasOwnProperty(player_id) ? players[player_id].status : '')
            {
                case 'I':
                    highlightColor = 'green'
                    break
                case 'M':
                    highlightColor = 'orange'
                    break
                case 'O':
                    highlightColor = 'red'
                    break
                default:
                    highlightColor = ''
            }

            output.push(
                <div key={player_id}>
                    <Box my={2}>
                        <PlayerHeaderBasic delay player_id={player_id} comment={comment} highlightColor={highlightColor} />
                    </Box>
                </div>
            )
        }

        return output
    }

    return (
        <>
            <Box mx={1} my={3}>
                <Typography variant="h1">Attendance</Typography>
            </Box>

            {/* {round && round.hasOwnProperty('details') && round.details.user_player_id !== false && */}

            <form onSubmit={onSubmit}>

                {renderSetAttendanceList(round_id, round, attendance_rounds, classes, attendanceButtonClick, onInputChange, loadRound)}

                <Box mx={1}>
                    {renderCanAttendButtons()}
                </Box>

                <Box mx={1} mt={5}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                        Save Attendance
                    </Button>
                </Box>



                <Box my={3}>
                    <Divider variant="fullWidth" component="div" />
                </Box>

            </form>
            {/* } */}
            {
                attendance_rounds.map(round_id => {

                    return (
                        <Box key={`round-container-${round_id}`}>

                            <Box mb={3}>
                                <RoundListItem round_id={round_id} />
                            </Box>

                            <Box mb={2}>
                                <WhiteboardScribble round_id={round_id} />
                            </Box>

                            <Map location_name={round[round_id].details.google_place_name} />

                            <Box mx={1} mb={5}>
                                <RoundAttendanceCounts show_load_players round_id={round_id} />
                                <RoundAttendancePlayerList round_id={round_id} user_player_id={round[round_id].details.user_player_id} />
                            </Box>

                        </Box>
                    )
                })
            }
        </>
    )
}

// const createLoadingSelector = actions => state => {
//     return actions.some(action => state.loading[action]);
// }

// const loadingSelector = createLoadingSelector(['LOAD_ROUND']);

const mapStateToProps = state => {
    return {
        round: state.round,
        // isFetching: loadingSelector(state)
    }
}

export default withStyles(styles)(connect(mapStateToProps, {
    saveAttendance,
    attendanceButtonClick,
    updateAttendanceComment,
    numberOfGamesButtonClick,
    loadRound,
    loadRoundCard,
    loadRoundAttendance
})(RoundAttendance))


