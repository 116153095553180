import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import { logOut } from '../../actions'
import { Link } from 'react-router-dom';

// Navigation
import {
    HOME
} from '../../navigation'


const AuthButton = (props) => {
    //console.log('Auth Button')

    const { is_logged_in, logOut } = props

    if(is_logged_in)
    {
        return (
            <Button onClick={() => {logOut()}} color="inherit">Logout</Button>
        )
    }

    return (
        <Link to={HOME} style={{textDecoration: 'none', color: 'inherit'}} >
            <Button color="inherit">Login</Button>
        </Link>
    )
}

const mapStateToProps = state => {
    return {
        is_logged_in: state.user.is_logged_in
    };
}

export default connect(mapStateToProps, { logOut })(AuthButton)
