import React from 'react'
import { connect } from 'react-redux'
import RoundListItem from './rounds/RoundListItem';
import PlayerRoundVote from './player/PlayerRoundVote';
import { hideRoundLeaderboard } from '../actions'

const Vote = (props) => {
    console.log("[] Vote")

    const round_id = props.match.params.round_id

    const { hideRoundLeaderboard } = props

    hideRoundLeaderboard(round_id)

    return (
        <>
            <RoundListItem round_id={round_id} />

            <PlayerRoundVote round_id={round_id} />

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    return {

    };
}

export default connect(mapStateToProps, { hideRoundLeaderboard })(Vote)
// export default Vote
