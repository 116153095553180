import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, ButtonBase, Grid, ListSubheader, FormHelperText } from '@material-ui/core'

import { createTeam, updateCreateTeamFormFieldValue, loadSports } from '../../../actions'
import Alert from '@material-ui/lab/Alert'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const CreateTeam = props => {

    const history = useHistory()

    const { classes } = props
    const { createTeam, updateCreateTeamFormFieldValue, loadSports, sports } = props

    const {
        name,
        registration_code,
        sport_id
    } = props //Form fields

    useEffect(() => {

        console.log('[] Create Team')

        loadSports()

    }, [])

    const onSubmit = () => {
        createTeam(history)
    }

    console.log('sport_id', sport_id)

    const renderSportOptions = () => {

        var ordered_sports = []

        for(var sport_id in sports)
        {
            ordered_sports.push([sport_id, sports[sport_id].name])
        }

        // Sort the sport by name
        ordered_sports.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_sports)
        {
            const sport = sports[ordered_ids[0]]

            output.push(
                <MenuItem key={sport.sport_id} value={sport.sport_id}>{sport.name}</MenuItem>
            )
        }

        return output
    }
    console.log('sport_id', sport_id)

    return (
        <>
            <Box m={1}>

                <Box mt={2} mx={0}>
                    <Typography content="h1" variant="h1">Create Team</Typography>
                </Box>

                <TextField
                    label="Team Name"
                    placeholder="The Somewhere Somethings"
                    // error={touched && invalid}
                    // helperText="Give your Team a name. This can be the one the Club gave you or whatever you referr to yourselves as.  No need to put in a year as a Team can have many Seasons.  We'll get to that later..."
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 100,
                    }}
                    name={`name`}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                />
                <Box mb={2}><Alert severity="info">Give your Team a name. This can be the one the Club gave you or whatever you referr to yourselves as.  No need to put in a year as a Team can have many Seasons.  We'll get to that later....</Alert></Box>

                <TextField
                    label="Registration Code"
                    placeholder="TheSomewhereSomethings"
                    // error={touched && invalid}
                    // helperText="Enter a short code for the Team.  You'll share this with the Players and they'll enter it in when they register. E.g. MYTEAM123.  Note it has to be unique to your Team so you can't use something that another Team is using."
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 50,
                    }}
                    name={`registration_code`}
                    autoComplete="off"
                    value={registration_code}
                    onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                />
                <Box mb={2}><Alert severity="info">Enter a short code for the Team.  You'll share this with the Players and they'll enter it in when they register. <br />E.g.<b>MYTEAM123</b>. <br />Note it has to be unique to your Team so you can't use something that another Team is using.</Alert></Box>

                <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                    <InputLabel id="sport-select-label">Sport</InputLabel>
                    <Select
                        labelId="sport-select-label"
                        id="sport-select"
                        value={Number(sport_id)}
                        name="sport_id"
                        onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                    >
                        {/* <MenuItem value="G">Game / Competition Round</MenuItem> */}
                        {renderSportOptions()}
                    </Select>
                    <Box mb={2}><Alert severity="info">Let us know what sport you play as a few of the settings and options will be different for different sports.</Alert></Box>
                </FormControl>

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Create My Team
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = state => {

    return {
        sports: state.admin.hasOwnProperty('sports') ? state.admin.sports : {},

        name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('name') ? state.admin.create.team.name : '',
        registration_code: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('registration_code') ? state.admin.create.team.registration_code : '',
        sport_id: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('sport_id') ? state.admin.create.team.sport_id : null
    }
}

export default withStyles(styles)(connect(mapStateToProps, { createTeam, updateCreateTeamFormFieldValue, loadSports })(CreateTeam))
