import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography } from '@material-ui/core/'
import Divider from "@material-ui/core/Divider"
import PlayerStat from './stats/PlayerStat'
import PlayerStatComments from './PlayerStatComments'
import PlayerStatCommentsListLoading from '../loading/PlayerStatCommentsListLoading'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    }
})


const PlayerStatCommentsList = (props) => {
    console.log('[] PlayerStatCommentsList')

    const { classes, stat } = props

    console.log('stat', stat)

    const renderPlayers = stat => {

        var output = []

        for(var item in stat.items)
        {
            if(stat.items.hasOwnProperty(item))
            {
                item = stat.items[item]

                output.push(
                    <Grid xs={8} item key={`comment-container-${stat.stat_id}-${item.player_id}`}>
                        <PlayerStatComments key={`comment-${stat.name}-${item.player_id}`} player_id={item.player_id} banter={item.banter} />
                    </Grid>
                )
                output.push(

                    <Grid xs={4} item key={`stat-container-${stat.stat_id}-${item.player_id}`}>
                        <PlayerStat key={`stat-${stat.name}-${item.player_id}`} amount_icon={stat.amount_icon} amount_icon_color={stat.amount_icon_color} amount_label={stat.amount_label} player_id={item.player_id} stat_total={Number(item.stat_total) * (stat.amount_multiplier == null ? 1 : Number(stat.amount_multiplier))} colour={stat.colour} />
                    </Grid>

                )
            }
        }

        return output
    }

    return (
        <>
            <Box mb={1} className={classes.fullWidth}>
                <Box mt={2} mx={1}>
                    <Typography content="h1" variant="h1">{stat.name}</Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    {stat && stat.hasOwnProperty('items') && stat.items ? renderPlayers(stat) : <PlayerStatCommentsListLoading stat={stat} />}

                </Grid>
            </Box>

            <Divider variant="fullWidth" component="div" />
        </>
    )
}

export default withStyles(styles)(PlayerStatCommentsList)
