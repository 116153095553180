import React, { Component } from 'react'
import NavAppBar from './NavAppBar'
import SideNav from './SideNav'

class Header extends Component {
    state = { side: false }

    render() {
        return (
            <>
                <NavAppBar />
                <SideNav />
            </>
        )
    }
}

export default Header

