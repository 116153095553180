import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import reducers from './reducers'
import App from './components/App'
import 'typeface-roboto'
import "@fontsource/kodchasan"
import "@fontsource/kodchasan/500.css"
import "@fontsource/kodchasan/600.css"
import "@fontsource/kodchasan/700.css"
import { loadState, saveState } from './utils/localStorage'
import throttle from 'lodash/throttle'


import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors/'

import { SnackbarProvider } from 'notistack'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedState = loadState()
const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(reduxThunk))
)

store.subscribe(throttle(() => {
    saveState({
        user: store.getState().user
    })
}, 1000))

if(!Object.entries)
{
    Object.entries = function(obj) {
        const keys = Object.keys(obj)

        const keyValuePairs = keys.map(key => {
            const value = obj[key]

            return [key, value]
        })

        return keyValuePairs
    }
}

//Suppress console logs in production.  Doesn't remove them from the code, just prevents the output
if(process.env.NODE_ENV !== 'development')
{
    console.log = () => {}
}

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        // primary: '#85cbf4',
        primary: {
            // light: purple[300],
            main: '#85cbf4',
            // dark: purple[700]
        },
        secondary: {
            light: green[300],
            main: green[500],
            dark: green[700]
        },
        background: {
            default: "#0d2431"
            // default: "# 122835"
        }
    },
    overrides: {
        // Name of the component ⚛️ / style sheet
        // MuiButton: {
        //   // Name of the rule
        //   text: {
        //     // Some CSS
        //     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //     borderRadius: 3,
        //     border: 0,
        //     color: 'white',
        //     height: 48,
        //     padding: '0 30px',
        //     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        //   },
        // },
        MuiPaper: {
            root: {
                backgroundColor: '#0d2431'
            }
        },
        MuiList: {
            root: {
                backgroundColor: '#0d2431'
            }
        },
        MuiChip: {
            root: {
                height: 'auto',
                // alignItems: 'center',
                whiteSpace: 'unset'
            },
            label: {
                whiteSpace: 'unset'
            },
            avatar: {
                marginTop: '4px',
                marginBottom: '4px'
            }
        },
        MuiAvatar: {
            img: {
                height: 'auto',
                // marginTop: '-10px' // Remove after making player images square
            },
            colorDefault: {
                backgroundColor: 'transparent'
            }
        }
    },
    typography: {
        h1: {
            fontSize: "3em",
            fontWeight: "600",
            fontFamily: "'Kodchasan', sans-serif"
        },
        h2: {
            fontSize: "2.6em",
            fontWeight: "500",
            fontFamily: "'Kodchasan', sans-serif",
            color: "#35c8ff"
        },
        h3: {
            fontSize: "2.2em",
            fontWeight: "500",
            fontFamily: "'Kodchasan', sans-serif",
            color: "#ff355c"
        },
        h4: {
            fontSize: "1.8em",
            fontWeight: "500",
            fontFamily: "'Kodchasan', sans-serif",
            color: "#35c8ff"
        },
        h5: {
            fontSize: "1.4em",
            fontWeight: "500",
            fontFamily: "'Kodchasan', sans-serif",
            color: "#ff355c"
        }
    }
})

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                preventDuplicate
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <App />
            </SnackbarProvider>
        </MuiThemeProvider>
    </Provider>,
    document.querySelector('#root')
)