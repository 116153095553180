import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamSeasons, loadManagedTeams, loadSeasonResults } from '../../../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography, Divider, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import PlayerStatHero from '../../../player/PlayerStatHero'
import Alert from '@material-ui/lab/Alert'
import TeamSeasonListItem from '../../TeamSeasonListItem'
// import TeamSeasonListItem from '../TeamSeasonListItem';

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    adminMenuButton: {
        width: '100%'
    },
    highlight: {
        color: '#85cbf4'
    }
})


const SeasonResults = props => {
    console.log('[] Team Seasons')

    const team_season_id = props.match.params.team_season_id

    const { classes, points_results, stats_results, loadSeasonResults, season, team } = props // Connect

    const { team_id } = season

    console.log('season', season)
    console.log('team_id', team_id)



    // Load the round data
    useEffect(() => {
        // loadTeamSeasons(team_id)
        // loadManagedTeams()
        loadSeasonResults(team_season_id)
    }, [])


    const renderResults = () => {

        console.log('points_results', points_results)

        const leaderboard = []

        // Points results

        for(const result_key of Object.keys(points_results))
        {
            const result = points_results[result_key]

            console.log('result', result)

            leaderboard.push(

                <Grid item key={`${result_key}-headings`}
                    className={`${classes.fullWidth}`}
                >
                    <Typography variant='h2' id={`heading-${result_key}`}>{result.title}</Typography>
                    <Box mt={1}><Typography variant='h3' id={`subheading-${result_key}`}>{result.subtitle}</Typography></Box>
                    <Box mt={2} mb={2}><Alert severity="info" id={`desc-${result_key}`}>{result.description}.</Alert></Box>
                    {/* <Typography variant='caption' id={`desc-${result_key}`}>{result.description}</Typography> */}
                    {/* <Box my={2}><Divider /></Box> */}
                </Grid>
            )

            leaderboard.push(
                result.players.map(stat => {
                    return (
                        <Grid item key={`${result_key}-${stat.player_id}`}
                            className={`${classes.fullWidth}`}
                        >
                            <PlayerStatHero player_id={stat.player_id} points_total={stat.total_points} secondary_stats={stat.substats} />
                        </Grid>
                    )
                })
            )

            leaderboard.push(
                <Grid item key={`${result_key}-headings`}
                    className={`${classes.fullWidth}`}
                    key={`divider-${result_key}`}
                >
                    <Box mt={2} mb={4}><Divider /></Box>
                </Grid>
            )
        }

        return leaderboard
    }

    const renderStats = () => {

        console.log('stats_results', stats_results)

        const stats_output = []

        // Points results

        for(const stat_key of Object.keys(stats_results))
        {
            const stat = stats_results[stat_key]

            console.log('stat', stat)

            stats_output.push(

                <Grid item key={`${stat_key}-headings`}
                    className={`${classes.fullWidth}`}
                >
                    <Typography variant='h4' id={`heading-${stat_key}`}>{stat.name}</Typography>
                    {/* <Box mt={1}><Typography variant='h5'className={`${classes.highlight}`} id={`subheading-${stat_key}`}>{stat.subtitle}</Typography></Box> */}
                    {/* <Box mt={2} mb={2}><Alert severity="info" id={`desc-${stat_key}`}>{stat.description}.</Alert></Box> */}
                    {/* <Typography variant='caption' id={`desc-${stat_key}`}>{stat.description}</Typography> */}
                    {/* <Box my={2}><Divider /></Box> */}
                </Grid>
            )

            stats_output.push(
                stat.items.map(player => {
                    return (
                        <Grid item key={`${stat_key}-${player.player_id}`}
                            className={`${classes.fullWidth}`}
                        >
                            <PlayerStatHero player_id={player.player_id} points_total={player.stat_total} />
                        </Grid>
                    )
                })
            )

            stats_output.push(
                <Grid item key={`${stat_key}-headings`}
                    className={`${classes.fullWidth}`}
                    key={`divider-${stat_key}`}
                >
                    <Box mt={2} mb={4}><Divider /></Box>
                </Grid>
            )
        }

        return stats_output
    }


    return (
        <>
            <Box my={2} mx={1}>
                <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
            </Box>

            <Divider />

            <Box my={2} mx={1}>
                <Typography content="h1" variant="h1">Season Results</Typography>
            </Box>

            <Box mt={2} mb={8} mx={1}>

                <Grid
                    container
                    direction="row"
                    alignContent="space-around"
                    justify="center"
                    alignItems="center"
                >
                    {renderResults()}
                    {renderStats()}
                </Grid>
            </Box>

            <Divider />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_season_id } = ownProps.match.params

    return {
        points_results: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('results') && state.season[team_season_id].admin.results.hasOwnProperty('points') ? state.season[team_season_id].admin.results.points : [],
        stats_results: state.season.hasOwnProperty(team_season_id) && state.season[team_season_id].hasOwnProperty('admin') && state.season[team_season_id].admin.hasOwnProperty('results') && state.season[team_season_id].admin.results.hasOwnProperty('stats') ? state.season[team_season_id].admin.results.stats : [],
        season: state.season.hasOwnProperty(team_season_id) ? state.season[team_season_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeasons, loadManagedTeams, loadSeasonResults })(SeasonResults))


