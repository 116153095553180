import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux'
import { loadScribble } from '../../actions';
import { Divider, Box, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
  });

function WhiteboardScribble(props) {
	console.log('[] Whiteboard Scribble')

	const { round_id } = props

	const { loadScribble, scribble } = props

    useEffect(() => {

        loadScribble(round_id)

    }, [])

	// Don't show the whiteboard if there is no scribble.  There will be not scribble either if the admin hasn't added anything to the whiteboard and/or if the whiteboard has not been published.
	if(scribble === '')
	{
		return ''
	}

	return (
		<ReactQuill
			theme="snow"
			modules={{toolbar: false}}
			value={scribble}
			placeholder="Impart your wisdom"
			readOnly={true}
		/>
);
}

const mapStateToProps = (state, ownProps) => {
	const { round_id } = ownProps

	return {
		scribble: state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('whiteboard') && state.round[round_id].whiteboard.hasOwnProperty('scribble') ? state.round[round_id].whiteboard.scribble : ''
	};
}

export default withStyles(styles)(connect(mapStateToProps, { loadScribble })(WhiteboardScribble))