import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { cardButtonClick, loadRoundAttendance, loadRoundCard, saveLineup, loadRound } from '../../../../actions'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { red, green, orange } from '@material-ui/core/colors'
import RoundListItem from '../../RoundListItem'
import { Grid, Typography, Box, Fab } from '@material-ui/core'
import PlayerHeaderBasic from '../../../player/PlayerHeaderBasic'
import RoundCardAttendanceDetail from './RoundCardAttendanceDetail'
import RoundLoading from '../../../loading/RoundLoading'
import { GreenButton, RedButton } from '../../../ui/Buttons'
import Alert from '@material-ui/lab/Alert'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center',
        position: 'relative'
    },
    textLeft: {
        justifyContent: 'flex-start'
    },
    textRight: {
        textAlign: 'right'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    bordered: {
        border: '1px solid red',
        margin: '4px'
    },
    flex: {
        display: 'flex'
    },
    stretch: {
        flex: '1'
    },
    fab: {
        position: 'fixed',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 100,
        backgroundColor: green[500],
        borderColor: green[400],
    },
    red: {
        backgroundColor: red[500],
        borderColor: red[400],
    },
    orange: {
        backgroundColor: orange[500],
        borderColor: orange[400],
    },
    green: {
        backgroundColor: green[500],
        borderColor: green[400],
    }
})


const renderList = (round_id, round, rounds, card, attendance, classes, cardButtonClick, onSubmit) => {

    const { type } = round.details

    // Copy players into an array to sort
    var ordered_players = []

    for(var player_id in attendance)
    {
        ordered_players.push([player_id, attendance[player_id].sort_order])
    }

    // Sort the players by date
    ordered_players.sort(function(a, b) {
        return a[1] - b[1]
    })

    var output = []

    for(var ordered_ids of ordered_players)
    {
        const player_id = ordered_ids[0]
        const { user_id, player_comment, status, season_rounds_played_count } = attendance[player_id]

        var comment = attendance.hasOwnProperty(player_id) ? player_comment : ''

        var highlightColor = ''

        switch(attendance.hasOwnProperty(player_id) ? status : '')
        {
            case 'I':
                highlightColor = 'green'
                break
            case 'M':
                highlightColor = 'orange'
                break
            case 'O':
                highlightColor = 'red'
                break
            default:
                highlightColor = ''
        }

        output.push(
            <div key={player_id}>
                <Box my={5}>
                    <PlayerHeaderBasic delay player_id={player_id} comment={comment} highlightColor={highlightColor} season_rounds_played_count={season_rounds_played_count} />

                    <Box mt={1}>
                        <Grid item>
                            <Grid container
                                wrap="nowrap"
                                justify="center"
                                alignItems="stretch"
                                alignContent="stretch"
                                direction="column"
                                spacing={1}
                            >
                                <Grid item>
                                    {
                                        type === 'G' &&
                                        <Typography variant="subtitle2" align="right"><RoundCardAttendanceDetail player_id={player_id} round_id={round_id} rounds={rounds} /></Typography>
                                    }
                                    <ButtonGroup
                                        color="secondary"
                                        size="small"
                                        fullWidth
                                    >
                                        <GreenButton
                                            className={`${card.hasOwnProperty(player_id) && card[player_id].on_card ? 'active' : ''}`}
                                            onClick={() => { cardButtonClick(round_id, player_id, user_id, 'card') }}
                                        >
                                            {type === 'G' ? 'Card' : 'Attended'}
                                        </GreenButton>
                                        <RedButton
                                            className={`${!card.hasOwnProperty(player_id) || !card[player_id].on_card ? 'active' : ''}`}
                                            onClick={() => { cardButtonClick(round_id, player_id, user_id, 'cut') }}
                                        >
                                            {type === 'G' ? 'Cut' : 'Absent'}
                                        </RedButton>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>

                            {/* {renderCommentFields(player_id, vote_option, onInputChange, classes)} */}

                        </Grid>
                    </Box>
                </Box>
            </div>
        )
    }

    return (
        <form onSubmit={onSubmit}>
            {output}

            <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}>
                {type === 'G' ? 'Save Card' : 'Save Attendance'}
            </Button>
            {
                type === 'G' &&
                <Box mt={2}><Alert severity="error">Removing a player from the Card will also remove all their votes and stats.</Alert></Box>
            }
        </form>
    )
}

const getOnCardCount = card => {
    var count = 0

    for(var player_id in card)
    {
        count += card[player_id].on_card ? 1 : 0
    }

    return count
}

const RoundCard = props => {
    console.log('[] Round Card')
    // const round_id = 1;

    const { classes, round, rounds, cardButtonClick, loadRoundAttendance, loadRoundCard, loadRound, saveLineup } = props

    const round_id = props.match.params.round_id

    // Load the round data
    useEffect(() => {

        if(!round || !round.hasOwnProperty('attendance'))
        {
            // console.log('Trigger the action to load round attendance')
            loadRoundAttendance(round_id)
        }

        if(!round || !round.hasOwnProperty('card'))
        {
            // console.log('Trigger the action to load round card')
            loadRoundCard(round_id)
        }

        if(!round || !round.hasOwnProperty('details'))
        {
            // console.log('Trigger the action to load round card')
            loadRound(round_id)
        }
    }, [round_id])

    // Load required data for any supporting rounds
    useEffect(() => {
        if(round && round.hasOwnProperty('details') && round.hasOwnProperty('card') && round.hasOwnProperty('attendance'))
        {
            for(var supporting_round_id of round.details.supporting_rounds)
            {
                if(!rounds.hasOwnProperty(supporting_round_id) || !rounds[supporting_round_id].hasOwnProperty('card'))
                {
                    loadRoundCard(supporting_round_id)
                }

                if(!rounds.hasOwnProperty(supporting_round_id) || !rounds[supporting_round_id].hasOwnProperty('details'))
                {
                    loadRound(supporting_round_id)
                }

                if(!rounds.hasOwnProperty(supporting_round_id) || !rounds[supporting_round_id].hasOwnProperty('attendance'))
                {
                    loadRoundAttendance(supporting_round_id)
                }
            }
        }
    }, [round])

    if(!round || !round.hasOwnProperty('attendance') || !round.hasOwnProperty('card') || !round.hasOwnProperty('details'))
    {
        return <RoundLoading quantity={1} />
    }

    const card = round.card
    const attendance = round.attendance


    const onSubmit = (e) => {
        e.preventDefault()
        saveLineup(round_id)
    }

    const onCardCount = getOnCardCount(card)
    const { type } = round.details

    return (
        <>
            <RoundListItem round_id={round_id} />

            <Fab size="large" color="secondary" aria-label="add" className={`${classes.fab} ${type === 'G' && (onCardCount < 11 || onCardCount > 16) ? classes.red : classes.green}`}>
                {onCardCount}
            </Fab>

            <Box m={1} mb={8}>

                {renderList(round_id, round, rounds, card, attendance, classes, cardButtonClick, onSubmit)}
                {/* , pointsButtonClick, onInputChange, updateVoteComment)} */}

            </Box>

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { round_id } = ownProps.match.params

    return {
        round: state.round[round_id],
        rounds: state.round
    }
}

export default withStyles(styles)(connect(mapStateToProps, { cardButtonClick, loadRound, loadRoundAttendance, loadRoundCard, saveLineup })(RoundCard))


