import React from 'react'
import {
    isPushNotificationSupported,
    initializePushNotifications,
    registerServiceWorker,
    getUserSubscription,
    createNotificationSubscription
} from "./push-notifications.js"
import { queueToast, subscribeWebPush, resetWebPush, unsubscribeWebPush } from '../../actions'
import { connect } from 'react-redux'
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { DialogDarkerBG } from '../ui/Dialog.js'

const styles = theme => ({
    notification: {
        position: 'absolute',
        bottom: '0',
        margin: '8px'
    },
    push_ok_button: {
        marginRight: "12px"
    }
})

const Messaging = props => {

    // Local state for subscribe snackbar (toast)
    const [open, setOpen] = React.useState(true)
    const [modal_open, setModalOpen] = React.useState(false)

    const { classes } = props

    const { queueToast, subscribeWebPush, unsubscribeWebPush, resetWebPush } = props // connect functions
    const { is_logged_in, web_push_subscription_status } = props // store

    /**
     * updates the DOM printing the user consent and activates buttons
     * @param {String} userConsent
     */
    const updateUserConsent = userConsent => {

        console.log(`userConsent`, userConsent)

        if(userConsent === "granted")
        {
            //enable push notification subscribe button
            // susbribeToPushNotificationButton.disabled = false;
            susbribeToPushNotification()

            if(modal_open)
            {
                setModalOpen(false)
            }
        }
        else if(userConsent === "denied")
        {
            // User has explicitly said no at the browser level to recieving push notifications
            unsubscribeWebPush()

            if(modal_open)
            {
                setModalOpen(false)
            }
        }
        else
        {
            // Default.  The user has not selected Allow or Block.
            // Either the user has not selected an option yet or has set it back to the default "Ask" option
            // Flag the subscription as false so the subscribe notification shows again
            resetWebPush('ask')
        }

    }

    /**
     * ask the user consent to receive push notification
     */
    function askUserPermission() {
        initializePushNotifications().then(updateUserConsent)
    }

    /**
     * creates a push notification subscription, that has to be sent to the push server
     */
    function susbribeToPushNotification() {
        createNotificationSubscription().then(function(subscrition) {
            showUserSubscription(subscrition)
        })
    }

    /**
     * displays the subscription details in the page and enables the "send Subscription Button"
     * @param {PushSubscription} subscrition
     */
    const showUserSubscription = subscrition => {
        userSubscription = subscrition
        console.log(`subscrition`, subscrition)
        console.log(`JSON.stringify(subscrition, null, " ")`, JSON.stringify(subscrition, null, " "))
        sendSubscriptionToPushServer()
    }

    /**
     * sends the push susbcribtion to the push server
     */
    function sendSubscriptionToPushServer() {
        console.log(`Send userSubscription to Server`, userSubscription)
        console.log(`userSubscriptoin Function`, userSubscription.getKey('auth'))
        console.log(`userSubscription.keys`, JSON.parse(JSON.stringify(userSubscription)))

        const subscription = JSON.parse(JSON.stringify(userSubscription))

        subscribeWebPush(subscription.endpoint, subscription.keys.p256dh, subscription.keys.auth)
    }


    let userSubscription

    //checks if the browser supports push notification and service workers
    const pushNotificationSuported = isPushNotificationSupported()

    if(pushNotificationSuported)
    {
        updateUserConsent(Notification.permission)

        // register the service worker: file "sw.js" in the root of our project
        registerServiceWorker()
        getUserSubscription().then(function(subscrition) {
            if(subscrition)
            {
                showUserSubscription(subscrition)
            }
        })
    }

    const action = (
        <>
            <CheckIcon
                className={`${classes.push_ok_button}`}
                onClick={() => { web_push_subscribe() }}
            />
            <CloseIcon onClick={() => { handleClose() }} />
        </>
    )

    const handleClose = (event, reason) => {
        // Don't close the toast when the user interacts with other parts of the UI
        if(reason === 'clickaway')
        {
            return
        }

        setOpen(false)
    }

    const web_push_subscribe = () => {
        console.log('Trigger web push subscribe')
        askUserPermission()
        setOpen(false)
        setModalOpen(true)
    }

    console.log(`##is_logged_in`, is_logged_in)
    console.log(`##pushNotificationSuported`, pushNotificationSuported)
    console.log(`##web_push_subscription_status`, web_push_subscription_status)

    const handleModalClose = () => {
        setModalOpen(false)
    }

    return (
        <>
            { is_logged_in && pushNotificationSuported && web_push_subscription_status === 'ask' && //!notifications_allowed &&
                <Snackbar open={open} autoHideDuration={30000} onClose={handleClose}>
                    <Alert severity="success" action={action}>
                        Get Push Notifications on this device?
                </Alert>
                </Snackbar>
            }

            <DialogDarkerBG
                open={modal_open}
                onClose={handleModalClose}
                scroll="paper"
                aria-labelledby="Web Push Notifications"
                aria-describedby="Get push notifications on this device"
            >
                {/* <DialogTitle id="scroll-dialog-title">Confirm subscirption</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        // ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        Tap "Allow" above/below
                    </DialogContentText>
                </DialogContent> */}
            </DialogDarkerBG>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        is_logged_in: state.user.is_logged_in,
        web_push_subscription_status: state.user.web_push_subscription_status
    }
}

export default connect(mapStateToProps, { queueToast, subscribeWebPush, unsubscribeWebPush, resetWebPush })(withStyles(styles)(Messaging))