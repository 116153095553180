import {
    UPDATE_VOTE_COMMENT,
    POINTS_BUTTON_CLICK,
    LOAD_ROUND_VOTES_REQUEST,
    LOAD_ROUND_VOTES_SUCCESS,
    LOAD_ROUND_VOTES_FAILURE,
    QUEUE_TOAST,
    LOG_OUT,
    FORCED_LOG_OUT,
    CAST_VOTE_REQUEST,
    CAST_VOTE_SUCCESS,
    CAST_VOTE_FAILURE
} from './types'
import { loadRound } from './roundActions'
import streams from '../apis/streams'

import ReactGA from 'react-ga'


// export const castVote = (round_id, player_id) => (dispatch, getState) => {

//     //console.log('Action - Cast Vote')
//     // //console.log(getState().round[round_id].votes)

//     return { type: CAST_VOTE, payload: {round_id, player_id} };

// };

export const castVote = (round_id) => async (dispatch, getState) => {
    console.log('** Action Cast Vote')

    dispatch({ type: CAST_VOTE_REQUEST })

    const { user_id, token } = getState().user
    const { user_player_id } = getState().round[round_id].details
    const { votes } = getState().round[round_id].vote

    //Check that the 3, 2 & 1 votes have been cast
    var vote_3pt = false
    var vote_2pt = false
    var vote_1pt = false
    var vote_glory = true
    var vote_cut = true

    for(const player_id in votes)
    {
        const vote = votes[player_id].vote

        if(vote.hasOwnProperty('reason_3pt'))
        {
            vote_3pt = true
        }
        else if(vote.hasOwnProperty('reason_2pt'))
        {
            vote_2pt = true
        }
        else if(vote.hasOwnProperty('reason_1pt'))
        {
            vote_1pt = true
        }

        if(vote.hasOwnProperty('reason_glory') && vote.reason_glory === '')
        {
            vote_glory = false
        }

        if(vote.hasOwnProperty('reason_cut') && vote.reason_cut === '')
        {
            vote_cut = false
        }
    }

    var error = false

    if(!vote_3pt || !vote_2pt || !vote_1pt)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'You must assign your 3, 2 & 1 votes!' } })
        error = true
    }

    if(!vote_glory)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'You must leave a comment for your Glory vote' } })
        error = true
    }

    if(!vote_cut)
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'You must leave a comment for your Cut vote' } })
        error = true
    }

    if(error)
    {
        return
    }

    await streams.post(`/rounds/votes/${round_id}`, {
        user_id,
        token,
        round_id,
        player_id: user_player_id,
        votes
    })
        .then(response => {

            dispatch(loadRound(round_id))
            dispatch({ type: CAST_VOTE_SUCCESS, payload: { round_id, message: response.data } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })

            ReactGA.event({
                category: 'Round',
                action: 'Vote for Round',
                value: 50
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to save your votes - ' + message

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: CAST_VOTE_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })

}

export const pointsButtonClick = (round_id, player_id, type) => {
    console.log('** Points Button Click')

    return { type: POINTS_BUTTON_CLICK, payload: { round_id, player_id, type } }

}

export const updateVoteComment = (round_id, player_id, type, comment_text) => {

    return { type: UPDATE_VOTE_COMMENT, payload: { round_id, player_id, type, comment_text } }

}

export const loadRoundVotes = (round_id) => async (dispatch, getState) => {
    console.log('** Action - Load Round Votes')

    dispatch({ type: LOAD_ROUND_VOTES_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/rounds/votes/${round_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_ROUND_VOTES_SUCCESS, payload: { round_id, votes: response.data.votes } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load attendance - ' + message

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: LOAD_ROUND_VOTES_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}