
import { sleep, randBetween } from '../utils/utils'

export const delayRetry = async (delay, retry_count) => {
        // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
        if(delay || retry_count)
        {
            if(retry_count)
            {
                //Retry
                await sleep(randBetween(2000 * retry_count * 1.5, 5000 * retry_count * 1.5))
            }
            else
            {
                // Delay
                await sleep(randBetween(0, 2000))
            }
        }
}