import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Avatar, Tooltip, } from '@material-ui/core/'
import { FaTrophy, FaFutbol, FaHandsHelping, FaRegCalendarAlt } from "react-icons/fa"
import PlayerStatHeroLoading from '../loading/PlayerStatHeroLoading'
import { LightTooltip } from "../ui/Tooltips"

import { RedChip, GreenChip, BlueChip, OrangeChip, YellowChip } from '../ui/Chips'
import { stat_type_icons } from '../ui/Icons'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        // height: '26px'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    fullHeight: {
        height: '100%'
    },
    bigAvatar: {
        display: 'block',
        // maxWidth: '240px',
        width: '100%',
        height: 'auto'
    },
    playerAvatar: {
        width: '128px'
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    bordered: {
        // border: "1px solid red"
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        paddingLeft: '8px'
    },
    highlight: {
        color: theme.palette.primary.main
    },
    textWhite: {
        color: '#fff'
    },
})


// const PlayerStatHero = (props) => {
function PlayerStatHero(props) {
    console.log('[] Player Stat Hero')

    const { classes } = props

    // State
    const { player, loadPlayer } = props

    const { player_id, points_total, secondary_stats } = props
    // const { goals, assists, games_played } = secondary_stats

    // Check if the player has been loaded
    useEffect(() => {
        if(!player)
        {
            loadPlayer(player_id)
        }
    }, [])

    if(!player)
    {
        return <PlayerStatHeroLoading />
    }

    const chips = {
        'Green': GreenChip,
        'Orange': OrangeChip,
        'Red': RedChip,
        'Blue': BlueChip,
        'Yellow': YellowChip
    }

    return (
        <>
            <Box p={1} mb={2}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                // spacing={1}
                >
                    <Grid item xs={4} className={`${classes.textRight}`}>
                        {
                            player.nickname ?
                                <>
                                    <Typography variant="body1">{player.first_name}</Typography>
                                    <Typography variant="h5" className={`${classes.highlight}`}>{player.nickname}</Typography>
                                    <Typography variant="body1">{player.last_name}</Typography>
                                </>
                                :
                                <Typography variant="h5" className={`${classes.textWhite}`}>{player.first_name} {player.last_name}</Typography>
                        }
                        <Typography variant="subtitle1">{player.position}</Typography>
                        <Typography variant="subtitle2" className={`${classes.highlight}`}>{player.shirt_number ? `#${player.shirt_number}` : ''}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={4} className={`${classes.textCenter} ${classes.bordered}`}>
                        <Box px={1}>
                            <Avatar alt="" src={`data:image/jpeg;base64, ${player.avatar}`} className={`${classes.bigAvatar} ${classes.square}`} >
                                <Avatar alt="" src={`https://avatars.dicebear.com/api/${player.avatar_gender}/${player_id}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />
                            </Avatar>
                            <Box mt={1}>
                                <GreenChip color="secondary" avatar={<Avatar><FaTrophy /></Avatar>} label={`${points_total} Points`} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={2} className={`${classes.bordered}`}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            {
                                secondary_stats &&
                                secondary_stats.map(stat => {

                                    console.log('stat', stat)

                                    const { stat_id, tooltip, stat_total, colour, amount_icon } = stat

                                    const StatIcon = stat_type_icons[amount_icon].icon ? stat_type_icons[amount_icon].icon : stat_type_icons['FaTrophy'].icon
                                    // const stat_icon_styles = amount_icon_color ? {color: `#${amount_icon_color}`} : {}

                                    const Chip = chips[colour] ? chips[colour] : chips['Green']

                                    return (
                                        <Grid item className={`${classes.fullWidth}`} key={`hero-stat-${player_id}-${stat_id}`}>
                                            <LightTooltip disableFocusListener title={tooltip} placement="top-end">
                                                <Chip classes={{ root: `${classes.cardSamll} ${classes.fullWidth}` }} size="small" color="primary" avatar={<Avatar><StatIcon /></Avatar>} label={stat_total} />
                                            </LightTooltip>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const player_id = ownProps.player_id

    return {
        player: state.player.hasOwnProperty(player_id) ? state.player[player_id] : false
    }
}

export default connect(mapStateToProps, { loadPlayer })(withStyles(styles)(PlayerStatHero))
