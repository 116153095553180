import {
    LOAD_LEADERBOARD_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, action) => {

    let leaderboard, updated_state

    switch(action.type) {

        case LOAD_LEADERBOARD_SUCCESS:
            console.log('-- Load Leaderboard');

            ({ leaderboard } = action.payload);

            updated_state = leaderboard

            return updated_state

        default:
            return state;
    }
}