import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPlayer } from '../../actions'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Avatar, Box  } from '@material-ui/core/'

import PlayerStatLoading from '../loading/PlayerStatLoading';
// import shadows from '@material-ui/core/styles/shadows';
// import { CSSTransition } from 'react-transition-group';

const styles = theme => ({
    paper: {
      padding: theme.spacing(1),
      border: 0,
      boxShadow: 'none',
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    bigAvatar: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    square: {
        height: '0',
        paddingBottom: '100%',
        overflow: 'hidden'
    },
    fullWidthCard: {
        width: '100%',
        justifyContent: 'flex-start',
        textAlign: 'center'
    },
    highlight: {
        color: theme.palette.primary.main
    }
  });


const PlayerHeaderSmall = (props) => {
    const { classes } = props;

    const { player, loadPlayer, player_id } = props

    // Check if the player has been loaded
    useEffect(() => {
        if(!player)
        {
            loadPlayer(player_id)
        }
    }, [player_id])

    if(!player)
    {
        return <PlayerStatLoading  />
    }


    return (
        <>
        <Box m={1} textAlign="center">
            <Typography component="div" variant="subtitle1">
                {
                    player.nickname ?
                        <>
                            {player.first_name}<br/><span className={`${classes.highlight}`}>{player.nickname}</span><br/>{player.last_name}
                        </>
                        :
                        <>
                            {player.first_name}<br/>{player.last_name}
                        </>
                }
            </Typography>
            <Avatar alt="" src={`data:image/jpeg;base64, ${player.avatar}`} className={`${classes.bigAvatar} ${classes.square}`} >
                <Avatar alt="" src={`https://avatars.dicebear.com/api/${player.avatar_gender}/${player_id}.svg?mood[]=happy`} className={`${classes.bigAvatar} ${classes.square}`} />
            </Avatar>
        </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { player_id } = ownProps

    return {
        player: state.player[player_id]
    };
}

export default connect(mapStateToProps, { loadPlayer })(withStyles(styles)(PlayerHeaderSmall))

