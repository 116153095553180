import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import EventIcon from '@material-ui/icons/Event'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import AddIcon from '@material-ui/icons/Add'
import PersonIcon from '@material-ui/icons/Person'
import { slideOpen, slideClose, loadManagedTeams, loadSeasons } from '../../actions'
import { Link } from 'react-router-dom'
import { AccountGroup } from 'mdi-material-ui'
import { Divider, ListSubheader } from '@material-ui/core'

// Navigation
import {
    HOME,
    REGISTER_FOR_TEAM,
    TEAMS_ADMIN,
    SEASON_PLAYER_REGISTRATION,
    ROUND_CREATE,
    TEAM_ADMIN,
    TEAM_CREATE,
    PLAYER_PROFILE,
    USER_SEASONS_PAST
} from '../../navigation'
import TeamsAdmin from '../teams/admin/TeamsAdmin'
import { History } from '@material-ui/icons'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

const SideNav = (props) => {
    const classes = useStyles()

    const { teams, loadManagedTeams, loadSeasons, is_logged_in, seasons } = props //Connect

    const has_past_seasons = Object.keys(seasons).length ? true : false

    // Load the teams data
    useEffect(() => {
        if(is_logged_in)
        {
            loadManagedTeams()
            loadSeasons()
        }
    }, [is_logged_in])

    const toggleDrawer = (side, open) => event => {
        //console.log('toggleDrawer')

        if(event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
        {
            return
        }

        if(open)
        {
            //console.log('open');
            props.slideOpen()
        }
        else
        {
            //console.log('close')
            props.slideClose()
        }
    }

    const renderTeams = () => {

        var ordered_teams = []

        for(var team_id in teams)
        {
            ordered_teams.push([team_id, teams[team_id].name])
        }

        // Sort the players by date
        ordered_teams.sort(function(a, b) {
            return a[1] - b[1]
        })

        console.log('ordered_teams', ordered_teams)

        var output = []

        for(var ordered_ids of ordered_teams)
        {
            const { team_id, name } = teams[ordered_ids[0]]

            output.push(
                <Link key={`nav-team-${team_id}`} to={`${TEAM_ADMIN}/${team_id}`} style={{ textDecoration: 'none', color: '#fff' }} >
                    <ListItem button key="MyTeams">
                        <ListItemIcon><AccountGroup /></ListItemIcon>
                        <ListItemText primary={name} />
                    </ListItem>
                </Link>
            )
        }

        return output
    }

    const managed_teams_count = Object.entries(teams).length ? true : false

    const sideList = side => (

        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <Link to={HOME} style={{ textDecoration: 'none', color: "#fff" }}>
                    <ListItem button key="Leaderboard">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Leaderboard" />
                    </ListItem>
                </Link>
                <Link to={PLAYER_PROFILE} style={{ textDecoration: 'none', color: "#fff" }}>
                    <ListItem button key="PlayerProfile">
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        <ListItemText primary="Player Profile" />
                    </ListItem>
                </Link>
                {
                    has_past_seasons &&
                    <>
                        <Link to={USER_SEASONS_PAST} style={{ textDecoration: 'none', color: "#fff" }}>
                            <ListItem button key="CreateRound">
                                <ListItemIcon><History /></ListItemIcon>
                                <ListItemText primary="Past Seasons" />
                            </ListItem>
                        </Link>
                    </>
                }
            </List>
            <Divider />
            <List>
                <Link to={REGISTER_FOR_TEAM} style={{ textDecoration: 'none', color: "#fff" }}>
                    <ListItem button key="TeamRegistation">
                        <ListItemIcon><GroupAddIcon /></ListItemIcon>
                        <ListItemText primary="Join a Team" />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            {
                managed_teams_count &&
                <>
                    <List>
                        <Link to={ROUND_CREATE} style={{ textDecoration: 'none', color: "#fff" }}>
                            <ListItem button key="CreateRound">
                                <ListItemIcon><EventIcon /></ListItemIcon>
                                <ListItemText primary="Create Round" />
                            </ListItem>
                        </Link>
                    </List>
                    <Divider />
                </>
            }

            {
                managed_teams_count &&
                <>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Managed Teams
                        </ListSubheader>
                        }
                    >
                        {renderTeams()}
                    </List>
                    <Divider />
                </>
            }
            <List>
                <Link to={TEAM_CREATE} style={{ textDecoration: 'none', color: "#fff" }}>
                    <ListItem button key="CreateTeam">
                        <ListItemIcon><AddIcon /></ListItemIcon>
                        <ListItemText primary="Create a New Team" />
                    </ListItem>
                </Link>
            </List>
            <Divider />
        </div>
    )

    return (
        <div>
            <SwipeableDrawer
                open={props.sideNavShow}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {sideList('left')}
            </SwipeableDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        sideNavShow: state.navigation.sideNavShow,
        teams: state.admin.hasOwnProperty('team') ? state.admin.team : {},
        is_logged_in: state.user.is_logged_in,
        seasons: state.season
    }
}

export default connect(mapStateToProps, { slideOpen, slideClose, loadManagedTeams, loadSeasons })(SideNav)
