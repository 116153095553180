import {
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_IN_FAILURE,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAILURE,
    LOG_OUT,
    FORCED_LOG_OUT,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAILURE,
    UPDATE_USER_ID,
    UPDATE_PASSWORD,
    UPDATE_TRUSTED_DEVICE,
    QUEUE_TOAST,
    FORCED_LOG_OUT_RESET
} from './types'
import streams from '../apis/streams'

import ReactGA from 'react-ga'

// Navigation
import {
    HOME, LOGIN
} from '../navigation'
import { loadAnnouncements } from './userActions'

export const forcedLogout = (history) => async (dispatch, getState) => {
    console.log('** Forced Logout')

    dispatch({ type: FORCED_LOG_OUT_RESET })

    history.push(HOME)

}

export const logIn = () => async (dispatch, getState) => {
    console.log('** Login')

    const { username, password, trusted_device } = getState().user

    dispatch({ type: LOG_IN_REQUEST })

    await streams.get(`/authentication/log_in`, {
        params: {
            username,
            password,
            trusted_device: trusted_device ? 1 : 0
        }
    })
        .then(response => {
            const { authenticated, message } = response.data

            if(authenticated)
            {
                dispatch({ type: LOG_IN_SUCCESS, payload: response.data })

                dispatch(loadAnnouncements())

                ReactGA.event({
                    category: 'User',
                    action: 'Login',
                    value: 20
                })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
                dispatch({ type: LOG_IN_FAILURE, payload: { message } })
            }
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            if(response && response.data.hasOwnProperty('message'))
            {
                // Server was able to generate a valid response.
                message = response.data.message
            }
            else
            {
                // Unable to contact the server or server was unable to generate a response
                message = 'Login Failed - ' + message
            }

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
            dispatch({ type: LOG_IN_FAILURE, payload: { message } })
        })

}

export const sendPasswordResetEmail = (history) => async (dispatch, getState) => {
    console.log('** Send password reset email')

    const { username } = getState().user

    dispatch({ type: PASSWORD_RESET_REQUEST })

    await streams.get(`/authentication/passwordreset`, {
        params: {
            username
        }
    })
        .then(response => {

            dispatch({ type: PASSWORD_RESET_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })
            history.push(HOME)

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            if(response && response.data.hasOwnProperty('message'))
            {
                // Server was able to generate a valid response.
                message = response.data.message
            }
            else
            {
                // Unable to contact the server or server was unable to generate a response
                message = 'Failed to send reset email - ' + message
            }

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
            dispatch({ type: PASSWORD_RESET_FAILURE, payload: { message: message } })
        })
}

export const setNewPassword = (password_reset_token, history) => async (dispatch, getState) => {
    console.log('** Set new password')

    const { password } = getState().user

    dispatch({ type: SET_PASSWORD_REQUEST })

    await streams.post(`/authentication/setpassword`, {
        password,
        password_reset_token
    })
        .then(response => {

            dispatch({ type: SET_PASSWORD_SUCCESS })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: response.data.message } })
            history.push(LOGIN)

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            if(response && response.data.hasOwnProperty('message'))
            {
                // Server was able to generate a valid response.
                message = response.data.message
            }
            else
            {
                // Unable to contact the server or server was unable to generate a response
                message = 'Failed to set new password - ' + message
            }

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
            dispatch({ type: SET_PASSWORD_FAILURE, payload: { message: message } })
        })

}

// export const logOut = () => {
//     return {
//         type: LOG_OUT
//     }
// }
export const logOut = () => async (dispatch, getState) => {
    console.log('** Logout')

    const { user_id, token } = getState().user

    dispatch({ type: LOG_OUT_REQUEST })

    await streams.get(`/authentication/log_out`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {
            const { authenticated, message } = response.data

            if(!authenticated)
            {
                dispatch({ type: LOG_OUT })

                ReactGA.event({
                    category: 'User',
                    action: 'Logout'
                })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
                dispatch({ type: LOG_OUT_FAILURE, payload: { message } })
            }
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            if(response && response.data.hasOwnProperty('message'))
            {
                // Server was able to generate a valid response.
                message = response.data.message
            }
            else
            {
                // Unable to contact the server or server was unable to generate a response
                message = 'Logout Failed - ' + message
            }

            dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } }) // Allowed to show when logged out
            dispatch({ type: LOG_IN_FAILURE, payload: { message } })
        })
}

export const updateUserId = (username_text) => {

    return { type: UPDATE_USER_ID, payload: { username_text } }

}

export const updatePassword = (password_text) => {

    return { type: UPDATE_PASSWORD, payload: { password_text } }

}

export const updateTrustedDevice = (trusted_device) => {

    return { type: UPDATE_TRUSTED_DEVICE, payload: { trusted_device } }

}