import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamSeason, loadManagedTeams } from '../../actions'

import Button from '@material-ui/core/Button'
import { Box, Typography, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { SEASON_ADMIN } from '../../navigation'
import TeamSeasonListItemLoading from '../loading/TeamSeasonListItemLoading'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
    navRoundDayNumber: {
        FontWeight: "bold",
        FontSize: "2.6em",
        lineHeight: "1em"
    },
    highlight: {
        color: theme.palette.primary.main
    },
    main: {
        fontSize: '2.6em',
        lineHeight: '1em'
    },
    teamName: {
        fontSize: '1.6em'
    },
    ageDivision: {
        fontSize: '1.4em'
    }
})


const TeamSeasonListItem = props => {
    console.log('[] Team Season List Item')

    const { team_id, team_season_id, link } = props // Parent

    const { classes, loadTeamSeason, loadManagedTeams, season, team } = props // Connect

    // Load the round data
    useEffect(() => {

        if(Object.entries(season).length === 0)
        {
            loadTeamSeason(team_season_id)
        }

        if(Object.entries(team).length === 0)
        {
            loadManagedTeams()
        }

    }, [team_season_id])

    if(Object.entries(season).length === 0)
    {
        return <TeamSeasonListItemLoading key={team_season_id} />
    }

    const link_path = link ? link : `${SEASON_ADMIN}/${team_id}/${team_season_id}`

    const { club_name, age_group, division, start_date, end_date } = season

    var start_dt = start_date ? new Date(Number(start_date) * 1000) : new Date()

    const start_month = start_dt.toLocaleString('default', { month: 'short' })
    const start_day_no = start_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const start_year = start_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    var end_dt = end_date ? new Date(Number(end_date) * 1000) : new Date()

    const end_month = end_dt.toLocaleString('default', { month: 'short' })
    const end_day_no = end_dt.toLocaleString(window.navigator.language, { day: '2-digit' })
    const end_year = end_dt.toLocaleString(window.navigator.language, { year: 'numeric' })

    return (
        <>
            <Link to={link_path} style={{ textDecoration: 'none', color: 'inherit' }} >
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    wrap='nowrap'
                    key={`round-list-item-${team_season_id}`}
                >

                    <Grid item xs={3}>
                        <Grid
                            container
                            direction='column'
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item className={classes.highlight}>FROM</Grid>
                            <Grid item className={classes.main}>{start_year}</Grid>
                            <Grid item>{start_day_no} <span className={classes.highlight}>{start_month.toUpperCase()}</span></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mr={1}>
                            <Grid container
                                direction='column'
                            >
                                <Grid item>
                                    <Grid item>
                                        {club_name}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid item className={`${classes.teamName} ${classes.highlight}`}>
                                        {team.name}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid item className={`${classes.ageDivision}`}>
                                        {age_group} - {division}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid
                            container
                            direction='column'
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item className={classes.highlight}>TO</Grid>
                            <Grid item className={classes.main}>{end_year}</Grid>
                            <Grid item>{end_day_no} <span className={classes.highlight}>{end_month.toUpperCase()}</span></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Link>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id, team_season_id } = ownProps

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {},
        season: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) && state.admin.team[team_id].hasOwnProperty('seasons') && state.admin.team[team_id].seasons.hasOwnProperty(team_season_id) ? state.admin.team[team_id].seasons[team_season_id] : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeason, loadManagedTeams })(TeamSeasonListItem))


