import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { loadTeamSeasons, loadManagedTeams, loadTeamSeasonRegistration, addSeasonPlayer, deactivateSeasonPlayer } from '../../../actions'

import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { red, green, orange } from '@material-ui/core/colors'
import { Grid, Typography, Box, Fab, Button, Divider } from '@material-ui/core'
import User from '../../user/User'
import { GreenButton, RedButton, OrangeButton } from '../../ui/Buttons'
import {
    FaFutbol,
    FaHandsHelping,
    FaFile,
    FaCut,
    FaMinus,
    FaTrophy,
    FaTrashAlt,
    FaThumbsUp,
    FaUserPlus,
    FaUserMinus
} from "react-icons/fa"
import ContentCut from 'mdi-material-ui/ContentCut'
import TeamSeasonListItem from '../TeamSeasonListItem'

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    cardSamll: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        height: '26px'
    },
    textCenter: {
        textAlign: 'center',
        position: 'relative'
    },
    textLeft: {
        justifyContent: 'flex-start'
    },
    textRight: {
        textAlign: 'right'
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    bordered: {
        border: '1px solid red',
        margin: '4px'
    },
    flex: {
        display: 'flex'
    },
    stretch: {
        flex: '1'
    },
    iconButton: {
        padding: '1.2em',
        minWidth: 'unset'
    }
})


const renderList = (team_id, team_season_id, registration, addSeasonPlayer, deactivateSeasonPlayer, classes) => { //}, pointsButtonClick, onInputChange) => {
    // Copy players into an array to sort
    var season_players = []
    var bench_players = []

    for(var user_id in registration)
    {

        const { is_player, first_name, last_name } = registration[user_id]

        if(is_player)
        {
            season_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
        }
        else
        {
            bench_players.push([user_id, first_name.replace(/[^a-zA-Z0-9]/g, '') + last_name])
        }
    }

    // Sort the players by first name
    season_players.sort((a, b) => a[1] > b[1] ? 1 : -1)
    bench_players.sort((a, b) => a[1] > b[1] ? 1 : -1)


    var players_output = []

    for(var players_ids of season_players)
    {
        const registration_user = registration[players_ids[0]]

        players_output.push(
            <>
                <Box mx={2} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <RedButton
                                onClick={() => { deactivateSeasonPlayer(team_id, team_season_id, registration_user.user_id) }}
                                className={`${classes.iconButton}`}
                            >
                                <FaUserMinus fontSize="large" />
                            </RedButton>
                        </Grid>
                        <Grid item>
                            <User user={registration_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(players_output.length !== 0)
    {
        players_output.unshift(
            <Box mt={2} p={1} key={`heading-players`}>
                <Typography content="h2" variant="h2">Team Players</Typography>
            </Box>
        )
    }


    var bench_output = []

    for(var bench_ids of bench_players)
    {
        const registration_user = registration[bench_ids[0]]

        bench_output.push(
            <>
                <Box mx={2} my={2}>
                    <Grid container
                        wrap="nowrap"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <GreenButton
                                onClick={() => { addSeasonPlayer(team_id, team_season_id, registration_user.user_id) }}
                                className={`${classes.iconButton}`}
                            >
                                <FaUserPlus fontSize="large" />
                            </GreenButton>
                        </Grid>
                        <Grid item>
                            <User user={registration_user} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )

    }

    if(bench_output.length !== 0)
    {
        bench_output.unshift(
            <Box mt={2} p={1} key={`heading-bench`}>
                <Typography content="h2" variant="h2">Not Playing</Typography>
            </Box>
        )
    }

    return (
        <>
            {players_output}
            {bench_output}
        </>
    )
}


const TeamSeasonRegistration = props => {
    console.log('[] Teams Registration')
    // const round_id = 1;

    const { team_id, team_season_id } = props.match.params

    const { classes, team, loadManagedTeams, loadTeamSeasons, loadTeamSeasonRegistration, team_season_registrations, addSeasonPlayer, deactivateSeasonPlayer } = props

    // Load the round data
    useEffect(() => {

        loadManagedTeams()
        loadTeamSeasonRegistration(team_id, team_season_id)

    }, [])

    return (
        <>
            <Box mt={2} mb={1}>
                <TeamSeasonListItem team_id={team_id} team_season_id={team_season_id} />
            </Box>

            <Divider />

            <Box mt={2} mx={1} key={`heading`}>
                <Typography content="h1" variant="h1">Player Registrations</Typography>
            </Box>

            {renderList(team_id, team_season_id, team_season_registrations, addSeasonPlayer, deactivateSeasonPlayer, classes)}

        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const { team_id, team_season_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {},
        team_season_registrations: state.admin.hasOwnProperty('team')
            && state.admin.team.hasOwnProperty(team_id)
            && state.admin.team[team_id].hasOwnProperty('seasons')
            && state.admin.team[team_id].seasons.hasOwnProperty(team_season_id)
            && state.admin.team[team_id].seasons[team_season_id].hasOwnProperty('registrations')
            ? state.admin.team[team_id].seasons[team_season_id].registrations
            : {}
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamSeasonRegistration, loadManagedTeams, loadTeamSeasons, addSeasonPlayer, deactivateSeasonPlayer })(TeamSeasonRegistration))


