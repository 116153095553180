import {
    QUEUE_TOAST,
    REMOVE_TOAST
} from './types';

export const queueToast = (status, message) => async (dispatch, getState) => {
    console.log('** Queue Toast')

    dispatch({ type: QUEUE_TOAST, payload: {status, message} })
}

export const removeToast = (toast_id) => async (dispatch, getState) => {
    console.log('** Remove Toast')

    dispatch({ type: REMOVE_TOAST, payload: {toast_id} });
}
