import { LOAD_PLAYER_SUCCESS, LOAD_PROFILE_STATS_SUCCESS, UPDATE_UPDATE_PLAYER_FORM_FIELD } from '../actions/types';

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {

    let player, player_id, updated_state

    switch(action.type) {

        case LOAD_PLAYER_SUCCESS:
        {
            console.log('-- Load Player');

            ({ player_id, player } = action.payload);

            const player_data = state.hasOwnProperty(player_id) ? state[player_id] : {}

            return {
                ...state,
                [player_id]:
                {
                    ...player_data,
                    ...player
                }
            }
        }

        case LOAD_PROFILE_STATS_SUCCESS:
        {
            console.log('-- Load Player Profile Stats')

            const { player_id, profile_stats } = action.payload

            const player_data = state.hasOwnProperty(player_id) ? state[player_id] : {}

            return {
                ...state,
                [player_id]:
                {
                    ...player_data,
                    profile_stats
                }
            }
        }

        case UPDATE_UPDATE_PLAYER_FORM_FIELD:
        {
            console.log('-- Load Player Profile Stats')

            const { player_id, field_name, value } = action.payload

            const player_data = state.hasOwnProperty(player_id) ? state[player_id] : {}

            return {
                ...state,
                [player_id]:
                {
                    ...player_data,
                    [field_name]: value
                }
            }
        }

        default:
            return state;
    }
}