import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

import { Link } from 'react-router-dom';
import { Button, Box, Divider } from '@material-ui/core';
import RoundListItem from '../RoundListItem';
import { hideRoundLeaderboard, deleteRound } from '../../../actions'
import { RedButton } from '../../ui/Buttons';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Navigation
import {
    ROUND_UPDATE,
    ROUND_CARD,
    ROUND_SCORE,
    ROUND_STATS,
    WHITEBOARD_ADMIN,
    ROUND_ATTENDANCE,
    ROUND_PLAYER_VOTING_STATS
} from '../../../navigation'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
  });


const RoundAdmin = props => {
    console.log('[] Round Admin')

    const history = useHistory();

    const round_id = props.match.params.round_id

    const { classes, hideRoundLeaderboard, deleteRound } = props
    const { round_details } = props

    const { type } = round_details

    hideRoundLeaderboard(round_id)

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    return (
        <>
            <RoundListItem round_id={round_id} />

            <Box my={2} mx={1}>
                <Link to={`${ROUND_UPDATE}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Update Details
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${WHITEBOARD_ADMIN}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        Scribble on the Whiteboard
                    </Button>
                </Link>
            </Box>
            <Box my={2} mx={1}>
                <Link to={`${ROUND_CARD}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        {type === 'G' ? 'Manage Card' : 'Manage Attendance'}
                    </Button>
                </Link>
            </Box>
            {
                type === 'G' &&
                <Box my={2} mx={1}>
                    <Link to={`${ROUND_SCORE}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                        <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                            Manage Score
                        </Button>
                    </Link>
                </Box>
            }
            {
                type === 'G' &&
                <Box my={2} mx={1}>
                    <Link to={`${ROUND_STATS}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                        <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                            Manage Stats
                        </Button>
                    </Link>
                </Box>
            }
            {
                type === 'G' &&
                <Box my={2} mx={1}>
                    <Link to={`${ROUND_PLAYER_VOTING_STATS}/${round_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                        <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                            Player Voting Stats
                        </Button>
                    </Link>
                </Box>
            }
            <Divider />
            <Box my={4} mx={1}>
                <RedButton type="submit" variant="contained" color="primary" className={classes.adminMenuButton}
                        onClick={() => { setOpen(true) }}
                >
                    Delete Round
                </RedButton>
            </Box>


            <Dialog
                open={(open)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Permanently Delete the Round?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting this Round will permanently remove any associated votes, stats, card and attendance.  There is no way to recover this informaiton or the Round.<br />
                        <b>Are you sure you want to delete this round?</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }} color="primary" autoFocus>
                        No, keep it
                    </Button>
                    <RedButton onClick={() => { setOpen(false); deleteRound(round_id, history) }} color="primary">
                        Yes, delete the Round
                    </RedButton>
                </DialogActions>
            </Dialog>

        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { round_id } = ownProps.match.params

    return {
        round_details: state.round.hasOwnProperty(round_id) && state.round[round_id].hasOwnProperty('details') ? state.round[round_id].details : {},
        // isFetching: loadingSelector(state)
    };
}

export default withStyles(styles)(connect(mapStateToProps, { hideRoundLeaderboard, deleteRound })(RoundAdmin))


