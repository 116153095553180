import { withStyles, Chip } from "@material-ui/core";
// import { green, red } from "@material-ui/core/colors";

  export const RedChip = withStyles({
    root: {
      backgroundColor:'#de6461'
    },
    avatar: {
        backgroundColor: '#ff9895 !important'
        // backgroundColor: '#bd3d39 !important'
    }
  })(Chip);

  export const OrangeChip = withStyles({
    root: {
      backgroundColor:'#de9b61'
    },
    avatar: {
        backgroundColor: '#ffc695 !important'
        // backgroundColor: '#bd7639 !important'
    }
  })(Chip);

  export const BlueChip = withStyles({
    root: {
      backgroundColor:'#53bcc0'
      // backgroundColor:'#3b8386'
    },
    avatar: {
        backgroundColor: '#7ed6da !important'
        // backgroundColor: '#5e9da0 !important'
        // backgroundColor: '#236f72 !important'
    }
  })(Chip);

  export const GreenChip = withStyles({
    root: {
      backgroundColor: '#4caf52'
    },
    avatar: {
        backgroundColor: '#77cc7d !important'
        // backgroundColor: '#2d9434 !important'
    }
  })(Chip);

  export const YellowChip = withStyles({
    root: {
      backgroundColor: '#deda61'
    },
    avatar: {
        backgroundColor: '#fffb95 !important'
        // backgroundColor: '#2d9434 !important'
    }
  })(Chip);