import {
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAILURE,
    LOAD_USER_TEAMS_REQUEST,
    LOAD_USER_TEAMS_SUCCESS,
    LOAD_USER_TEAMS_FAILURE,
    LOAD_SEASONS_REQUEST,
    LOAD_SEASONS_SUCCESS,
    LOAD_SEASONS_FAILURE,
    LOAD_TEAMS_MANAGED_REQUEST,
    LOAD_TEAMS_MANAGED_SUCCESS,
    LOAD_TEAMS_MANAGED_FAILURE,
    LOAD_TEAMS_REGISTRATION_REQUEST,
    LOAD_TEAMS_REGISTRATION_SUCCESS,
    LOAD_TEAMS_REGISTRATION_FAILURE,
    UPDATE_USER_TEAM_REGISTRATION_STATUS_REQUEST,
    UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS,
    UPDATE_USER_TEAM_REGISTRATION_STATUS_FAILURE,
    ADD_SEASON_PLAYER_REQUEST,
    ADD_SEASON_PLAYER_SUCCESS,
    ADD_SEASON_PLAYER_FAILURE,
    DEACTIVATE_SEASON_PLAYER_REQUEST,
    DEACTIVATE_SEASON_PLAYER_SUCCESS,
    DEACTIVATE_SEASON_PLAYER_FAILURE,
    LOAD_TEAM_SEASONS_REQUEST,
    LOAD_TEAM_SEASONS_SUCCESS,
    LOAD_TEAM_SEASONS_FAILURE,
    LOAD_TEAM_SEASON_REQUEST,
    LOAD_TEAM_SEASON_SUCCESS,
    LOAD_TEAM_SEASON_FAILURE,
    LOAD_TEAM_SEASON_DETAIL_REQUEST,
    LOAD_TEAM_SEASON_DETAIL_SUCCESS,
    LOAD_TEAM_SEASON_DETAIL_FAILURE,
    LOAD_TEAM_SEASON_REGISTRATION_REQUEST,
    LOAD_TEAM_SEASON_REGISTRATION_SUCCESS,
    LOAD_TEAM_SEASON_REGISTRATION_FAILURE,
    LOAD_TEAM_SEASON_OPPOSITION_REQUEST,
    LOAD_TEAM_SEASON_OPPOSITION_SUCCESS,
    LOAD_TEAM_SEASON_OPPOSITION_FAILURE,
    LOAD_SPORTS_REQUEST,
    LOAD_SPORTS_SUCCESS,
    LOAD_SPORTS_FAILURE,
    LOAD_TEAM_DETAIL_REQUEST,
    LOAD_TEAM_DETAIL_SUCCESS,
    LOAD_TEAM_DETAIL_FAILURE,
    UPDATE_TEAM_REQUEST,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAILURE,
    QUEUE_TOAST,
    LOG_OUT,
    FORCED_LOG_OUT,
    LOAD_TEAM_OPPOSITION_REQUEST,
    LOAD_TEAM_OPPOSITION_SUCCESS,
    LOAD_TEAM_OPPOSITION_FAILURE
} from './types'
import streams from '../apis/streams'
import { delayRetry } from './actionHelpers'

import ReactGA from 'react-ga'

// Navigation
import {
    SEASON_CREATE
} from '../navigation'


export const loadUserTeams = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load User Teams')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_USER_TEAMS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_USER_TEAMS_SUCCESS, payload: { teams: response.data.teams } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadUserTeams(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_USER_TEAMS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

// Get all seasons the user is a Player in or an Admin of
export const loadSeasons = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_SEASONS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_SEASONS_SUCCESS, payload: { seasons: response.data.seasons } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSeasons(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_SEASONS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const createTeam = (history, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Create Team')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: CREATE_TEAM_REQUEST })

    const { user_id, token } = getState().user
    const { name, registration_code, sport_id } = getState().admin.create.team

    await streams.post(`/teams/`, {
        user_id,
        token,
        name,
        registration_code,
        sport_id
    })
        .then(response => {

            const { team_id } = response.data

            // dispatch({ type: CREATE_TEAM_SUCCESS, payload: { team_id, name, registration_code, sport_id } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Team created successfully' } })

            // Take the user to the create Season page
            // http://localhost:3000/team/seasons/create/17
            history.push(`${SEASON_CREATE}/${team_id}`)

            dispatch(loadManagedTeams())

            ReactGA.event({
                category: 'Team Admin',
                action: 'Create Team',
                value: 100
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(createTeam(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to create Team - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: CREATE_TEAM_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamsRegistration = (team_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Stats')

    // // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAMS_REGISTRATION_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/registration/${team_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAMS_REGISTRATION_SUCCESS, payload: { teams_registration: response.data.teams_registration } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamsRegistration(team_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load registration details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAMS_REGISTRATION_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadManagedTeams = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Managed Teams')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAMS_MANAGED_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/managed`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_TEAMS_MANAGED_SUCCESS, payload: { teams_managed: response.data.teams_managed } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadManagedTeams(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAMS_MANAGED_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamSeasons = (team_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Seasons')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_SEASONS_REQUEST, payload: { id: team_id } })

    const { user_id, token } = getState().user

    await streams.get(`/teams/seasons/${team_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_TEAM_SEASONS_SUCCESS, payload: { id: team_id, team_id, team_seasons: response.data.team_seasons } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamSeasons(team_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Team Seasons - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_SEASONS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamSeason = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Season')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_SEASON_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/season/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_TEAM_SEASON_SUCCESS, payload: { team_season: response.data.team_season } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamSeason(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_SEASON_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}
export const loadTeamSeasonDetail = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Season Detail for Create Form')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_SEASON_DETAIL_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/season/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: LOAD_TEAM_SEASON_DETAIL_SUCCESS, payload: { team_season: response.data.team_season } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamSeasonDetail(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Season Details. - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_SEASON_DETAIL_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamSeasonRegistration = (team_id, team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Season Registration')

    // // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_SEASON_REGISTRATION_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/registration/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAM_SEASON_REGISTRATION_SUCCESS, payload: { team_id, team_season_id, team_season_registration: response.data.team_season_registration } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamSeasonRegistration(team_id, team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load season registrations - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_SEASON_REGISTRATION_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const updateUserTeamRegistrationStatus = (status, team_id, requestor_user_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Update User Team Registration Status')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: UPDATE_USER_TEAM_REGISTRATION_STATUS_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/teams/registration/${team_id}`, {
        user_id,
        token,
        requestor_user_id,
        status
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: UPDATE_USER_TEAM_REGISTRATION_STATUS_SUCCESS, payload: { requestor_user_id, team_id, status } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Updated registration status successfully' } })

            ReactGA.event({
                category: 'Team Admin',
                action: 'Update Player Team Registration',
                value: 10
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(updateUserTeamRegistrationStatus(status, team_id, requestor_user_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed load Teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: UPDATE_USER_TEAM_REGISTRATION_STATUS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const addSeasonPlayer = (team_id, team_season_id, registration_user_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Add Season Player')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: ADD_SEASON_PLAYER_REQUEST })

    const { user_id, token } = getState().user

    await streams.post(`/players/${team_season_id}`, {
        user_id,
        token,
        registration_user_id
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: ADD_SEASON_PLAYER_SUCCESS, payload: { team_id, team_season_id, registration_user_id } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Updated registration status successfully' } })

            ReactGA.event({
                category: 'Team Admin',
                action: 'Register Player to Season',
                value: 10
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(addSeasonPlayer(team_id, team_season_id, registration_user_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to register the player - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: ADD_SEASON_PLAYER_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const deactivateSeasonPlayer = (team_id, team_season_id, registration_user_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Deactivate Season Player')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: DEACTIVATE_SEASON_PLAYER_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/players/unregister/${team_season_id}`, {
        user_id,
        token,
        registration_user_id
    })
        .then(response => {

            console.log('response', response)

            dispatch({ type: DEACTIVATE_SEASON_PLAYER_SUCCESS, payload: { team_id, team_season_id, registration_user_id } })
            dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Updated registration status successfully' } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }

            ReactGA.event({
                category: 'Team Admin',
                action: 'Unregister Player from Season',
                value: 10
            })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(deactivateSeasonPlayer(team_id, team_season_id, registration_user_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to unregister the player - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: DEACTIVATE_SEASON_PLAYER_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamSeasonOpposition = (team_season_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Season Opposition')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_SEASON_OPPOSITION_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/seasons/opposition/${team_season_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_TEAM_SEASON_OPPOSITION_SUCCESS, payload: { team_season_id, opposition: response.data.opposition } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamSeasonOpposition(team_season_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the opposition teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_SEASON_OPPOSITION_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamOpposition = (team_id, delay, retry_count) => async (dispatch, getState) => {
    console.log('** Load Team Opposition')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_OPPOSITION_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/opposition/${team_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            console.log('opposition response', response)

            dispatch({ type: LOAD_TEAM_OPPOSITION_SUCCESS, payload: { opposition: response.data.opposition } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamOpposition(team_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the opposition teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_OPPOSITION_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadSports = (delay, retry_count) => async (dispatch, getState) => {
    console.log('** Get Sports')

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_SPORTS_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/sports/`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {

            dispatch({ type: LOAD_SPORTS_SUCCESS, payload: { sports: response.data.sports } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadSports(delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the opposition teams - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_SPORTS_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}

export const loadTeamDetail = (team_id, delay, retry_count) => async (dispatch, getState) => {
    // await streams.get(`/team/${team_id}`);
    // //console.log('Action Load team - getState:')
    console.log('** Load Tean Detail - ' + team_id)

    // Due to poor server performance if to many requests start togeter they can fail.  A short random delay helps prevent this.
    await delayRetry(delay, retry_count)

    dispatch({ type: LOAD_TEAM_DETAIL_REQUEST })

    const { user_id, token } = getState().user

    await streams.get(`/teams/${team_id}`, {
        params: {
            user_id,
            token
        }
    })
        .then(response => {
            dispatch({ type: LOAD_TEAM_DETAIL_SUCCESS, payload: { team_detail: response.data.team } })

        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            if(error.message === 'Network Error' && (!retry_count || retry_count < 3)) // Allow 3 retries
            {
                dispatch(loadTeamDetail(team_id, delay, retry_count ? ++retry_count : 1))
            }
            else
            {
                const { response } = error
                var { message } = error

                message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to load the team details - ' + message

                getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
                dispatch({ type: LOAD_TEAM_DETAIL_FAILURE, payload: { message } })

                if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
                {
                    dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
                }
            }
        })
}


export const updateTeam = () => async (dispatch, getState) => {
    console.log('** Update Team')

    // Check all form fields have been completed.
    const { team_id, name, registration_code, sport_id } = getState().admin.create.team

    if(!team_id
        || !name
        || !registration_code
        || !sport_id
    )
    {
        dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message: 'Umm... I think you missed a spot. Please complete all the form fields.' } })
        return
    }

    dispatch({ type: UPDATE_TEAM_REQUEST })

    const { user_id, token } = getState().user

    await streams.patch(`/teams/${team_id}`, {
        user_id,
        token,
        name,
        registration_code,
        sport_id
    })
        .then(response => {

            const { updated } = response.data

            dispatch({ type: UPDATE_TEAM_SUCCESS, payload: { team_id, name, registration_code, sport_id } })

            if(!updated)
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'warning', message: 'Updated. But nothing changed.' } })
            }
            else
            {
                dispatch({ type: QUEUE_TOAST, payload: { status: 'success', message: 'Team Updated Successfully' } })
            }

            ReactGA.event({
                category: 'Team Admin',
                action: 'Update Team',
                value: 10
            })
        })
        .catch(error => {
            // Catches network errors and not found responses from the API

            const { response } = error
            var { message } = error

            message = response && response.data.hasOwnProperty('message') ? response.data.message : 'Failed to update Team - ' + message

            getState().user.is_logged_in && dispatch({ type: QUEUE_TOAST, payload: { status: 'error', message } })
            dispatch({ type: UPDATE_TEAM_FAILURE, payload: { message } })

            if(response && response.data.hasOwnProperty('logout') && Boolean(response.data.logout) === true)
            {
                dispatch({ type: response.data.hasOwnProperty('soft_logout') && Boolean(response.data.soft_logout) === true ? LOG_OUT : FORCED_LOG_OUT })
            }
        })
}

