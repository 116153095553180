import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, ButtonBase, Grid, ListSubheader } from '@material-ui/core'

import { loadTeamDetail, updateTeam, updateCreateTeamFormFieldValue, loadSports } from '../../../actions'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})

const UpdateTeam = props => {

    const team_id = props.match.params.team_id

    const { classes } = props
    const { updateTeam, loadTeamDetail, updateCreateTeamFormFieldValue, loadSports, sports } = props

    const {
        name,
        registration_code,
        sport_id
    } = props //Form fields

    useEffect(() => {

        console.log('[] Update Team')

        //Load the team details
        loadTeamDetail(team_id)

        loadSports()

    }, [])

    const onSubmit = () => {
        updateTeam(team_id)
    }

    console.log('sport_id', sport_id)

    const renderSportOptions = () => {

        var ordered_sports = []

        for(var sport_id in sports)
        {
            ordered_sports.push([sport_id, sports[sport_id].name])
        }

        // Sort the sport by name
        ordered_sports.sort((a, b) => a[1] > b[1] ? 1 : -1)

        var output = []

        for(var ordered_ids of ordered_sports)
        {
            const sport = sports[ordered_ids[0]]

            output.push(
                <MenuItem key={sport.sport_id} value={sport.sport_id}>{sport.name}</MenuItem>
            )
        }

        return output
    }
    console.log('sport_id', sport_id)

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h2" variant="h2">{name ? name : 'Loading'}</Typography>
                    <Typography content="h1" variant="h1">Update Team</Typography>
                </Box>

                <TextField
                    label="Team Name"
                    placeholder="1"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 100,
                    }}
                    name={`name`}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                />

                <TextField
                    label="Registration Code"
                    placeholder="1"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 50,
                    }}
                    name={`registration_code`}
                    autoComplete="off"
                    value={registration_code}
                    onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                />

                <FormControl className={`${classes.formControl} ${classes.spaced}`} fullWidth>
                    <InputLabel id="sport-select-label">Sport</InputLabel>
                    <Select
                        labelId="sport-select-label"
                        id="sport-select"
                        value={Number(sport_id)}
                        name="sport_id"
                        onChange={(e) => { updateCreateTeamFormFieldValue(e.target.name, e.target.value) }}
                    >
                        {/* <MenuItem value="G">Game / Competition Round</MenuItem> */}
                        {renderSportOptions()}
                    </Select>
                </FormControl>

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = state => {

    return {
        sports: state.admin.hasOwnProperty('sports') ? state.admin.sports : {},

        name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('name') ? state.admin.create.team.name : '',
        registration_code: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('registration_code') ? state.admin.create.team.registration_code : '',
        sport_id: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('team') && state.admin.create.team.hasOwnProperty('sport_id') ? state.admin.create.team.sport_id : null
    }
}

export default withStyles(styles)(connect(mapStateToProps, { loadTeamDetail, updateTeam, updateCreateTeamFormFieldValue, loadSports })(UpdateTeam))
