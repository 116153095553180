import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { loadManagedTeams} from '../../../actions'

import Button from '@material-ui/core/Button';
import { Box, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Navigation
import {
    TEAM_ADMIN,
    TEAM_CREATE
  } from '../../../navigation'

const styles = theme => ({
    adminMenuButton: {
        width: '100%'
    },
  });



const renderTeams = (teams, classes) => {

    console.log('teams', teams)

    var ordered_teams = [];

    for (var team_id in teams) {
        ordered_teams.push([team_id, teams[team_id].name]);
    }

    // Sort the players by date
    ordered_teams.sort(function(a, b) {
        return a[1] - b[1];
    });

    console.log('ordered_teams', ordered_teams)

    var output = []

    for( var ordered_ids of ordered_teams )
    {
        const { team_id, name} = teams[ordered_ids[0]]

        output.push(

            <Box my={2} mx={1} key={team_id}>
                <Link to={`${TEAM_ADMIN}/${team_id}`} style={{textDecoration: 'none', color: 'inherit'}} >
                    <Button type="submit" variant="contained" color="primary" className={classes.adminMenuButton}>
                        {name}
                    </Button>
                </Link>
            </Box>
        )
    }

    return output
}

const TeamsAdmin = props => {
    console.log('[] Teams Admin')

    const { classes, loadManagedTeams, teams } = props // Connect

    // Load the round data
    useEffect(() => {
        loadManagedTeams()
    }, [])

    return (
        <>
            {renderTeams(teams, classes)}
            <Divider />
            <Box my={2} mx={1} key="addnewteam">
                <Link to={TEAM_CREATE} style={{textDecoration: 'none', color: 'inherit'}} >
                    <Button type="submit" variant="contained" color="secondary" className={classes.adminMenuButton}>
                        Create a New Team
                    </Button>
                </Link>
            </Box>

        </>
    )
}

const mapStateToProps = (state) => {

    return {
        teams: state.admin.hasOwnProperty('team') ? state.admin.team : {}
    };
}

export default withStyles(styles)(connect(mapStateToProps, { loadManagedTeams })(TeamsAdmin))


