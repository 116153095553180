import React from 'react'
import { Grid } from '@material-ui/core/'
import PlayerStatCommentsLoading from './PlayerStatCommentsLoading';
import PlayerStatLoading from './PlayerStatLoading';



const PlayerStatCommentsListLoading = (props) => {
    console.log('[] PlayerStatCommentsListLoading');

    const { stat } = props;

    const renderPlayers = stat => {

        var output = []

        // for(var item in stat.items)
        for (let i = 0; i < 3; i++)
        {
                output.push(
                    <Grid xs={8} item key={`comment-container-${stat.stat_id}-${i}-loading`}>
                        <PlayerStatCommentsLoading key={`comment-${i}`} />
                    </Grid>
                )
                output.push(
                    <Grid xs={4} item key={`stat-container-${i}-loading`}>
                        <PlayerStatLoading amount_icon={stat.amount_icon} amount_icon_color={stat.amount_icon_color} amount_label={stat.amount_label}  colour={stat.colour} />
                    </Grid>
                )
        }

        return output
    }

    return renderPlayers(stat)

}

export default PlayerStatCommentsListLoading