import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Box, TextField, Button, Typography, InputLabel, Select, ButtonBase, Grid, ListSubheader, Divider } from '@material-ui/core'

import { createSeason, updateCreateSeasonFormFieldValue, loadManagedTeams, loadTeamSeasons, loadTeamSeasonOpposition } from '../../../actions'

import moment from 'moment'
import {
    DatePicker
} from '@material-ui/pickers'
import Alert from '@material-ui/lab/Alert'

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    spaced: {
        marginTop: theme.spacing(2)
    },
    grow: {
        flexGrow: '2'
    },
    paddedRight: {
        paddingRight: theme.spacing(1)
    },
    menuTeamName: {
        // fontSize: '1em',
        color: theme.palette.primary.main
    }
})


const CreateSeason = props => {

    const history = useHistory()

    const team_id = props.match.params.team_id

    const { classes } = props
    const { team, createSeason, updateCreateSeasonFormFieldValue } = props

    const {
        club_name,
        start_date,
        end_date,
        age_group,
        division
    } = props //Form fields

    useEffect(() => {

        console.log('[] Create Season')
        loadManagedTeams()

    }, [])

    //console.log(props)

    const onSubmit = () => {
        createSeason(team_id, history)
    }

    return (
        <>
            <Box m={1}>

                <Box my={2} mx={0}>
                    <Typography content="h2" variant="h2">{team.name ? team.name : 'Loading'}</Typography>
                    <Typography content="h1" variant="h1">Create Season</Typography>
                    {/* <Alert severity="info"></Alert> */}
                </Box>

                <TextField
                    label="Club Name"
                    placeholder="The [Somewhere] [Sport] CLub"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 100,
                    }}
                    name={`club_name`}
                    autoComplete="off"
                    value={club_name}
                    onChange={(e) => { updateCreateSeasonFormFieldValue(e.target.name, e.target.value) }}
                />

                <DatePicker
                    name="date"
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    autoOk={true}
                    emptyLabel='DD/MM/YYYY'
                    margin="normal"
                    id="season-start-date"
                    label="Season Start Date"
                    value={start_date}
                    onChange={(start_date) => { updateCreateSeasonFormFieldValue('start_date', start_date) }}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                />

                <DatePicker
                    name="date"
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    autoOk={true}
                    emptyLabel='DD/MM/YYYY'
                    margin="normal"
                    id="season-end-date"
                    label="Season End Date"
                    value={end_date}
                    onChange={(end_date) => { updateCreateSeasonFormFieldValue('end_date', end_date) }}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                />


                <TextField
                    label="Age Group"
                    placeholder="MAA"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`age_group`}
                    autoComplete="off"
                    value={age_group}
                    onChange={(e) => { updateCreateSeasonFormFieldValue(e.target.name, e.target.value) }}
                />

                <TextField
                    label="Division"
                    placeholder="1"
                    // error={touched && invalid}
                    // helperText={touched && error}
                    className={`${classes.fullWidth} ${classes.spaced}`}
                    inputProps={{
                        maxLength: 45,
                    }}
                    name={`division`}
                    autoComplete="off"
                    value={division}
                    onChange={(e) => { updateCreateSeasonFormFieldValue(e.target.name, e.target.value) }}
                />

                <Box my={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.fullWidth}
                        onClick={() => { onSubmit() }}
                    >
                        Create
                    </Button>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { team_id } = ownProps.match.params

    return {
        team: state.admin.hasOwnProperty('team') && state.admin.team.hasOwnProperty(team_id) ? state.admin.team[team_id] : {},

        club_name: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('season') && state.admin.create.season.hasOwnProperty('club_name') ? state.admin.create.season.club_name : '',
        start_date: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('season') && state.admin.create.season.hasOwnProperty('start_date') ? state.admin.create.season.start_date : null,
        end_date: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('season') && state.admin.create.season.hasOwnProperty('end_date') ? state.admin.create.season.end_date : null,
        age_group: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('season') && state.admin.create.season.hasOwnProperty('age_group') ? state.admin.create.season.age_group : '',
        division: state.admin.hasOwnProperty('create') && state.admin.create.hasOwnProperty('season') && state.admin.create.season.hasOwnProperty('division') ? state.admin.create.season.division : '',

    }
}

export default withStyles(styles)(connect(mapStateToProps, { createSeason, updateCreateSeasonFormFieldValue, loadManagedTeams, loadTeamSeasons })(CreateSeason))
