import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Avatar  } from '@material-ui/core/'
import Chat from '@material-ui/icons/Chat'
import { GreenChip } from '../ui/Chips'
import { randBetween } from '../../utils/utils'

const styles = theme => ({
    paper: {
      padding: theme.spacing(1),
      border: 0,
      boxShadow: 'none',
      textAlign: 'right',
      color: theme.palette.text.secondary
    },
    blurryTextBlack: {
        color: 'transparent !important',
        textShadow: '0 0 5px rgba(0,0,0,0.5)'
    },
    faded: {
        opacity: '0.2'
    }
  });


const PlayerStatCommentsLoading = (props) => {
    console.log('[] PlayerStatCommentsLoading')

    const { classes } = props;

    const banter = ['Wonder goal!!', 'A literal defencive wall', 'Did that thing we like', 'Ran all day', 'Those corners!', 'Saved all the shots']

    var output = []
    var limit =  randBetween(0, 5)

    for (let i = 0; i < limit; i++)
    {
        output.push(
            <GreenChip className={`${classes.faded} ${classes.blurryTextBlack}`} key={`banter-${i}`} color="secondary" avatar={<Avatar><Chat style={{fontSize: '1rem'}} /></Avatar>} label={`${banter[randBetween(0, 5)]}`} style={{margin: '4px'}} />
        )
    }

    return (
        <>
            <Paper className={classes.paper}>
                {output}
            </Paper>
        </>
    )
}

export default withStyles(styles)(PlayerStatCommentsLoading)
