import { withStyles, Tooltip } from "@material-ui/core"
import { green, orange, red } from "@material-ui/core/colors"


export const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: '20px'
    },
    arrow: {
        color: theme.palette.primary.white,
    }
}))(Tooltip)

export const TooltipGreen = withStyles(theme => ({
    tooltip: {
        backgroundColor: green[500],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '10px'
    },
    arrow: {
        color: green[500],
    }
}))(Tooltip)

export const TooltipOrange = withStyles(theme => ({
    tooltip: {
        backgroundColor: orange[500],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '10px'
    },
    arrow: {
        color: orange[500],
    }
}))(Tooltip)

export const TooltipRed = withStyles(theme => ({
    tooltip: {
        backgroundColor: red[500],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '10px'
    },
    arrow: {
        color: red[500],
    }
}))(Tooltip)

export const TooltipPrimary = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: '10px'
    },
    arrow: {
        color: theme.palette.primary.main,
    }
}))(Tooltip)